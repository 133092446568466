import { Field, FieldGroup } from "../generated/api-service";

/**
 * Indexes all fields from the given field groups with the field id.
 */
export const fieldsByIdFromGroups = (
  fieldGroups: FieldGroup[]
): Record<Field["id"], Field> =>
  fieldGroups
    .map((group) => group.fields)
    .flat()
    .filter((f): f is Field => !!f)
    .reduce((result, field) => ({
        ...result,
        [field.id]: field,
      }), {});
