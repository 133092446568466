// tslint:disable
/**
 * TaMoC API V1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs';
import {
    ConfirmUserEmailPost,
    EOnboardingStatus, FilesMeta,
    ForgotPasswordPost,
    LoginPost,
    LoginResponse,
    UpdateForgottenPasswordPost,
    UpdatePasswordPost,
    User,
    UserInLoginResponse,
    UserPost,
    UserPutUser
} from '../models';
import { BaseAPI, encodeURI, HttpHeaders, HttpQuery, OperationOpts, RawAjaxResponse, throwIfNullOrUndefined } from '../runtime';

export interface ConfirmEmailRequest {
    confirmUserEmailPost?: ConfirmUserEmailPost;
}

export interface DeleteUserRequest {
    accessToken: string;
    client: string;
    uid: string;
    id: number;
}

export interface ForgotPasswordRequest {
    forgotPasswordPost: ForgotPasswordPost;
}

export interface GetUserRequest {
    accessToken: string;
    client: string;
    uid: string;
    sortBy?: GetUserSortByEnum;
    id?: number | string;
    provider?: string;
    uid2?: string;
    encryptedPassword?: string;
    resetPasswordToken?: string;
    resetPasswordSentAt?: string;
    allowPasswordChange?: string;
    rememberCreatedAt?: string;
    confirmationToken?: string;
    confirmedAt?: string;
    confirmationSentAt?: string;
    unconfirmedEmail?: string;
    image?: string;
    email?: string;
    tokens?: string;
    createdAt?: string;
    updatedAt?: string;
    signInCount?: number | string;
    currentSignInAt?: string;
    lastSignInAt?: string;
    currentSignInIp?: string;
    lastSignInIp?: string;
    firstName?: string;
    lastName?: string;
    pinCode?: string;
    onboardingStatus?: EOnboardingStatus;
    verificationStatus?: string;
}

export interface GetUserByIdRequest {
    accessToken: string;
    client: string;
    uid: string;
    id: number;
}

export interface LoginRequest {
    loginPost?: LoginPost;
}

export interface PatchUserRequest {
    accessToken: string;
    client: string;
    uid: string;
    id: number;
    user: UserPutUser;
    files?: Array<Blob>;
    filesMeta?: FilesMeta;
}

export interface RegisterUserRequest {
    userPost?: UserPost;
}

export interface UpdateAvatarRequest {
    accessToken: string;
    client: string;
    uid: string;
    id: number;
    file: Blob;
    name: string;
    userId: string;
}

export interface UpdatePasswordRequest {
    updatePasswordPostUpdateForgottenPasswordPost?: UpdatePasswordPost | UpdateForgottenPasswordPost;
}

export interface UpdateUserRequest {
    accessToken: string;
    client: string;
    uid: string;
    id: number;
    user: UserPutUser;
    files?: Array<Blob>;
    filesMeta?: FilesMeta;
}

export interface ValidatePasswordTokenRequest {
    token: string;
}

/**
 * no description
 */
export class UserApi extends BaseAPI {

    /**
     * Confirm E-Mail
     */
    confirmEmail({ confirmUserEmailPost }: ConfirmEmailRequest): Observable<User>
    confirmEmail({ confirmUserEmailPost }: ConfirmEmailRequest, opts?: OperationOpts): Observable<RawAjaxResponse<User>>
    confirmEmail({ confirmUserEmailPost }: ConfirmEmailRequest, opts?: OperationOpts): Observable<User | RawAjaxResponse<User>> {

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return this.request<User>({
            url: '/api/v1/registration/email_confirmation',
            method: 'POST',
            headers,
            body: confirmUserEmailPost,
        }, opts?.responseOpts);
    };

    /**
     * Deletes a User
     */
    deleteUser({ accessToken, client, uid, id }: DeleteUserRequest): Observable<void>
    deleteUser({ accessToken, client, uid, id }: DeleteUserRequest, opts?: OperationOpts): Observable<void | RawAjaxResponse<void>>
    deleteUser({ accessToken, client, uid, id }: DeleteUserRequest, opts?: OperationOpts): Observable<void | RawAjaxResponse<void>> {
        throwIfNullOrUndefined(accessToken, 'accessToken', 'deleteUser');
        throwIfNullOrUndefined(client, 'client', 'deleteUser');
        throwIfNullOrUndefined(uid, 'uid', 'deleteUser');
        throwIfNullOrUndefined(id, 'id', 'deleteUser');

        const headers: HttpHeaders = {
            ...(accessToken != null ? { 'access-token': String(accessToken) } : undefined),
            ...(client != null ? { 'client': String(client) } : undefined),
            ...(uid != null ? { 'uid': String(uid) } : undefined),
        };

        return this.request<void>({
            url: '/api/v1/users/{id}'.replace('{id}', encodeURI(id)),
            method: 'DELETE',
            headers,
        }, opts?.responseOpts);
    };

    /**
     * Forgot Password
     */
    forgotPassword({ forgotPasswordPost }: ForgotPasswordRequest): Observable<void>
    forgotPassword({ forgotPasswordPost }: ForgotPasswordRequest, opts?: OperationOpts): Observable<void | RawAjaxResponse<void>>
    forgotPassword({ forgotPasswordPost }: ForgotPasswordRequest, opts?: OperationOpts): Observable<void | RawAjaxResponse<void>> {
        throwIfNullOrUndefined(forgotPasswordPost, 'forgotPasswordPost', 'forgotPassword');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return this.request<void>({
            url: '/api/v1/users//request_forgot_password',
            method: 'POST',
            headers,
            body: forgotPasswordPost,
        }, opts?.responseOpts);
    };

    /**
     * Retrieve a User list
     */
    getUser({ accessToken, client, uid, sortBy, id, provider, uid2, encryptedPassword, resetPasswordToken, resetPasswordSentAt, allowPasswordChange, rememberCreatedAt, confirmationToken, confirmedAt, confirmationSentAt, unconfirmedEmail, image, email, tokens, createdAt, updatedAt, signInCount, currentSignInAt, lastSignInAt, currentSignInIp, lastSignInIp, firstName, lastName, pinCode, onboardingStatus, verificationStatus }: GetUserRequest): Observable<Array<User>>
    getUser({ accessToken, client, uid, sortBy, id, provider, uid2, encryptedPassword, resetPasswordToken, resetPasswordSentAt, allowPasswordChange, rememberCreatedAt, confirmationToken, confirmedAt, confirmationSentAt, unconfirmedEmail, image, email, tokens, createdAt, updatedAt, signInCount, currentSignInAt, lastSignInAt, currentSignInIp, lastSignInIp, firstName, lastName, pinCode, onboardingStatus, verificationStatus }: GetUserRequest, opts?: OperationOpts): Observable<RawAjaxResponse<Array<User>>>
    getUser({ accessToken, client, uid, sortBy, id, provider, uid2, encryptedPassword, resetPasswordToken, resetPasswordSentAt, allowPasswordChange, rememberCreatedAt, confirmationToken, confirmedAt, confirmationSentAt, unconfirmedEmail, image, email, tokens, createdAt, updatedAt, signInCount, currentSignInAt, lastSignInAt, currentSignInIp, lastSignInIp, firstName, lastName, pinCode, onboardingStatus, verificationStatus }: GetUserRequest, opts?: OperationOpts): Observable<Array<User> | RawAjaxResponse<Array<User>>> {
        throwIfNullOrUndefined(accessToken, 'accessToken', 'getUser');
        throwIfNullOrUndefined(client, 'client', 'getUser');
        throwIfNullOrUndefined(uid, 'uid', 'getUser');

        const headers: HttpHeaders = {
            ...(accessToken != null ? { 'access-token': String(accessToken) } : undefined),
            ...(client != null ? { 'client': String(client) } : undefined),
            ...(uid != null ? { 'uid': String(uid) } : undefined),
        };

        const query: HttpQuery = {};

        if (sortBy != null) { query['sort_by'] = sortBy; }
        if (id != null) { query['id'] = id; }
        if (provider != null) { query['provider'] = provider; }
        if (uid2 != null) { query['uid'] = uid2; }
        if (encryptedPassword != null) { query['encrypted_password'] = encryptedPassword; }
        if (resetPasswordToken != null) { query['reset_password_token'] = resetPasswordToken; }
        if (resetPasswordSentAt != null) { query['reset_password_sent_at'] = (resetPasswordSentAt as any).toISOString(); }
        if (allowPasswordChange != null) { query['allow_password_change'] = allowPasswordChange; }
        if (rememberCreatedAt != null) { query['remember_created_at'] = (rememberCreatedAt as any).toISOString(); }
        if (confirmationToken != null) { query['confirmation_token'] = confirmationToken; }
        if (confirmedAt != null) { query['confirmed_at'] = (confirmedAt as any).toISOString(); }
        if (confirmationSentAt != null) { query['confirmation_sent_at'] = (confirmationSentAt as any).toISOString(); }
        if (unconfirmedEmail != null) { query['unconfirmed_email'] = unconfirmedEmail; }
        if (image != null) { query['image'] = image; }
        if (email != null) { query['email'] = email; }
        if (tokens != null) { query['tokens'] = tokens; }
        if (createdAt != null) { query['created_at'] = (createdAt as any).toISOString(); }
        if (updatedAt != null) { query['updated_at'] = (updatedAt as any).toISOString(); }
        if (signInCount != null) { query['sign_in_count'] = signInCount; }
        if (currentSignInAt != null) { query['current_sign_in_at'] = (currentSignInAt as any).toISOString(); }
        if (lastSignInAt != null) { query['last_sign_in_at'] = (lastSignInAt as any).toISOString(); }
        if (currentSignInIp != null) { query['current_sign_in_ip'] = currentSignInIp; }
        if (lastSignInIp != null) { query['last_sign_in_ip'] = lastSignInIp; }
        if (firstName != null) { query['first_name'] = firstName; }
        if (lastName != null) { query['last_name'] = lastName; }
        if (pinCode != null) { query['pin_code'] = pinCode; }
        if (onboardingStatus != null) { query['onboarding_status'] = onboardingStatus; }
        if (verificationStatus != null) { query['verification_status'] = verificationStatus; }

        return this.request<Array<User>>({
            url: '/api/v1/users/',
            method: 'GET',
            headers,
            query,
        }, opts?.responseOpts);
    };

    /**
     * Retrieves a User
     */
    getUserById({ accessToken, client, uid, id }: GetUserByIdRequest): Observable<User>
    getUserById({ accessToken, client, uid, id }: GetUserByIdRequest, opts?: OperationOpts): Observable<RawAjaxResponse<User>>
    getUserById({ accessToken, client, uid, id }: GetUserByIdRequest, opts?: OperationOpts): Observable<User | RawAjaxResponse<User>> {
        throwIfNullOrUndefined(accessToken, 'accessToken', 'getUserById');
        throwIfNullOrUndefined(client, 'client', 'getUserById');
        throwIfNullOrUndefined(uid, 'uid', 'getUserById');
        throwIfNullOrUndefined(id, 'id', 'getUserById');

        const headers: HttpHeaders = {
            ...(accessToken != null ? { 'access-token': String(accessToken) } : undefined),
            ...(client != null ? { 'client': String(client) } : undefined),
            ...(uid != null ? { 'uid': String(uid) } : undefined),
        };

        return this.request<User>({
            url: '/api/v1/users/{id}'.replace('{id}', encodeURI(id)),
            method: 'GET',
            headers,
        }, opts?.responseOpts);
    };

    /**
     * Login user
     */
    login({ loginPost }: LoginRequest): Observable<LoginResponse>
    login({ loginPost }: LoginRequest, opts?: OperationOpts): Observable<RawAjaxResponse<LoginResponse>>
    login({ loginPost }: LoginRequest, opts?: OperationOpts): Observable<LoginResponse | RawAjaxResponse<LoginResponse>> {

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return this.request<LoginResponse>({
            url: '/api/v1/auth/sign_in/',
            method: 'POST',
            headers,
            body: loginPost,
        }, opts?.responseOpts);
    };

    /**
     * Patch a User
     */
    patchUser({ accessToken, client, uid, id, user, files, filesMeta }: PatchUserRequest): Observable<User>
    patchUser({ accessToken, client, uid, id, user, files, filesMeta }: PatchUserRequest, opts?: OperationOpts): Observable<RawAjaxResponse<User>>
    patchUser({ accessToken, client, uid, id, user, files, filesMeta }: PatchUserRequest, opts?: OperationOpts): Observable<User | RawAjaxResponse<User>> {
        throwIfNullOrUndefined(accessToken, 'accessToken', 'patchUser');
        throwIfNullOrUndefined(client, 'client', 'patchUser');
        throwIfNullOrUndefined(uid, 'uid', 'patchUser');
        throwIfNullOrUndefined(id, 'id', 'patchUser');
        throwIfNullOrUndefined(user, 'user', 'patchUser');

        const headers: HttpHeaders = {
            ...(accessToken != null ? { 'access-token': String(accessToken) } : undefined),
            ...(client != null ? { 'client': String(client) } : undefined),
            ...(uid != null ? { 'uid': String(uid) } : undefined),
        };

        const formData = new FormData();
        if (user !== undefined) {
          let formElement: string | Blob;
          if (typeof user === "object") {
            if ((user as any) instanceof Blob) {
              formElement = (user as any) as Blob;
            } else {
              formElement = JSON.stringify(user);
            }
          } else {
            formElement = user;
          }

          formData.append('user', formElement); }
        if (files !== undefined) {
              if (Array.isArray(files)) {
                for (let file of files) {
                  formData.append('files[]', file);
                }
              } else {
                formData.append('files', files);
              }
        }

        if (filesMeta !== undefined) {
          let formElement: string | Blob;
          if (typeof filesMeta === "object") {
            if ((filesMeta as any) instanceof Blob) {
              formElement = (filesMeta as any) as Blob;
            } else {
              formElement = JSON.stringify(filesMeta);
            }
          } else {
            formElement = filesMeta;
          }

          formData.append('filesMeta', formElement); }

        return this.request<User>({
            url: '/api/v1/users/{id}'.replace('{id}', encodeURI(id)),
            method: 'PATCH',
            headers,
            body: formData,
        }, opts?.responseOpts);
    };

    /**
     * Register user
     */
    registerUser({ userPost }: RegisterUserRequest): Observable<LoginResponse>
    registerUser({ userPost }: RegisterUserRequest, opts?: OperationOpts): Observable<RawAjaxResponse<LoginResponse>>
    registerUser({ userPost }: RegisterUserRequest, opts?: OperationOpts): Observable<LoginResponse | RawAjaxResponse<LoginResponse>> {

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return this.request<LoginResponse>({
            url: '/api/v1/registration/new_user',
            method: 'POST',
            headers,
            body: userPost,
        }, opts?.responseOpts);
    };

    /**
     * Update Avatar
     */
    updateAvatar({ accessToken, client, uid, id, file, name, userId }: UpdateAvatarRequest): Observable<UserInLoginResponse>
    updateAvatar({ accessToken, client, uid, id, file, name, userId }: UpdateAvatarRequest, opts?: OperationOpts): Observable<RawAjaxResponse<UserInLoginResponse>>
    updateAvatar({ accessToken, client, uid, id, file, name, userId }: UpdateAvatarRequest, opts?: OperationOpts): Observable<UserInLoginResponse | RawAjaxResponse<UserInLoginResponse>> {
        throwIfNullOrUndefined(accessToken, 'accessToken', 'updateAvatar');
        throwIfNullOrUndefined(client, 'client', 'updateAvatar');
        throwIfNullOrUndefined(uid, 'uid', 'updateAvatar');
        throwIfNullOrUndefined(id, 'id', 'updateAvatar');
        throwIfNullOrUndefined(file, 'file', 'updateAvatar');
        throwIfNullOrUndefined(name, 'name', 'updateAvatar');
        throwIfNullOrUndefined(userId, 'userId', 'updateAvatar');

        const headers: HttpHeaders = {
            ...(accessToken != null ? { 'access-token': String(accessToken) } : undefined),
            ...(client != null ? { 'client': String(client) } : undefined),
            ...(uid != null ? { 'uid': String(uid) } : undefined),
        };

        const formData = new FormData();
        if (file !== undefined) {
          let formElement: string | Blob;
          if (typeof file === "object") {
            if ((file as any) instanceof Blob) {
              formElement = (file as any) as Blob;
            } else {
              formElement = JSON.stringify(file);
            }
          } else {
            formElement = file;
          }

          formData.append('file', formElement); }
        if (name !== undefined) {
          let formElement: string | Blob;
          if (typeof name === "object") {
            if ((name as any) instanceof Blob) {
              formElement = (name as any) as Blob;
            } else {
              formElement = JSON.stringify(name);
            }
          } else {
            formElement = name;
          }

          formData.append('name', formElement); }
        if (userId !== undefined) {
          let formElement: string | Blob;
          if (typeof userId === "object") {
            if ((userId as any) instanceof Blob) {
              formElement = (userId as any) as Blob;
            } else {
              formElement = JSON.stringify(userId);
            }
          } else {
            formElement = userId;
          }

          formData.append('userId', formElement); }

        return this.request<UserInLoginResponse>({
            url: '/api/v1/users/{id}/update_avatar'.replace('{id}', encodeURI(id)),
            method: 'PATCH',
            headers,
            body: formData,
        }, opts?.responseOpts);
    };

    /**
     * Update Password
     */
    updatePassword({ updatePasswordPostUpdateForgottenPasswordPost }: UpdatePasswordRequest): Observable<LoginResponse>
    updatePassword({ updatePasswordPostUpdateForgottenPasswordPost }: UpdatePasswordRequest, opts?: OperationOpts): Observable<RawAjaxResponse<LoginResponse>>
    updatePassword({ updatePasswordPostUpdateForgottenPasswordPost }: UpdatePasswordRequest, opts?: OperationOpts): Observable<LoginResponse | RawAjaxResponse<LoginResponse>> {

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return this.request<LoginResponse>({
            url: '/api/v1/users//update_password',
            method: 'POST',
            headers,
            body: updatePasswordPostUpdateForgottenPasswordPost,
        }, opts?.responseOpts);
    };

    /**
     * Update a User
     */
    updateUser({ accessToken, client, uid, id, user, files, filesMeta }: UpdateUserRequest): Observable<User>
    updateUser({ accessToken, client, uid, id, user, files, filesMeta }: UpdateUserRequest, opts?: OperationOpts): Observable<RawAjaxResponse<User>>
    updateUser({ accessToken, client, uid, id, user, files, filesMeta }: UpdateUserRequest, opts?: OperationOpts): Observable<User | RawAjaxResponse<User>> {
        throwIfNullOrUndefined(accessToken, 'accessToken', 'updateUser');
        throwIfNullOrUndefined(client, 'client', 'updateUser');
        throwIfNullOrUndefined(uid, 'uid', 'updateUser');
        throwIfNullOrUndefined(id, 'id', 'updateUser');
        throwIfNullOrUndefined(user, 'user', 'updateUser');

        const headers: HttpHeaders = {
            ...(accessToken != null ? { 'access-token': String(accessToken) } : undefined),
            ...(client != null ? { 'client': String(client) } : undefined),
            ...(uid != null ? { 'uid': String(uid) } : undefined),
        };

        const formData = new FormData();
        if (user !== undefined) {
          let formElement: string | Blob;
          if (typeof user === "object") {
            if ((user as any) instanceof Blob) {
              formElement = (user as any) as Blob;
            } else {
              formElement = JSON.stringify(user);
            }
          } else {
            formElement = user;
          }

          formData.append('user', formElement); }
        if (files !== undefined) {
              if (Array.isArray(files)) {
                for (let file of files) {
                  formData.append('files[]', file);
                }
              } else {
                formData.append('files', files);
              }
        }

        if (filesMeta !== undefined) {
          let formElement: string | Blob;
          if (typeof filesMeta === "object") {
            if ((filesMeta as any) instanceof Blob) {
              formElement = (filesMeta as any) as Blob;
            } else {
              formElement = JSON.stringify(filesMeta);
            }
          } else {
            formElement = filesMeta;
          }

          formData.append('filesMeta', formElement); }

        return this.request<User>({
            url: '/api/v1/users/{id}'.replace('{id}', encodeURI(id)),
            method: 'PUT',
            headers,
            body: formData,
        }, opts?.responseOpts);
    };

    /**
     * Validate Password Token
     */
    validatePasswordToken({ token }: ValidatePasswordTokenRequest): Observable<void>
    validatePasswordToken({ token }: ValidatePasswordTokenRequest, opts?: OperationOpts): Observable<void | RawAjaxResponse<void>>
    validatePasswordToken({ token }: ValidatePasswordTokenRequest, opts?: OperationOpts): Observable<void | RawAjaxResponse<void>> {
        throwIfNullOrUndefined(token, 'token', 'validatePasswordToken');

        return this.request<void>({
            url: '/api/v1/users//validate_password_reset_token?token={token}'.replace('{token}', encodeURI(token)),
            method: 'POST',
        }, opts?.responseOpts);
    };

}

/**
 * @export
 * @enum {string}
 */
export enum GetUserSortByEnum {
    IdAsc = 'id_asc',
    IdDesc = 'id_desc',
    ProviderAsc = 'provider_asc',
    ProviderDesc = 'provider_desc',
    UidAsc = 'uid_asc',
    UidDesc = 'uid_desc',
    EncryptedPasswordAsc = 'encrypted_password_asc',
    EncryptedPasswordDesc = 'encrypted_password_desc',
    ResetPasswordTokenAsc = 'reset_password_token_asc',
    ResetPasswordTokenDesc = 'reset_password_token_desc',
    ResetPasswordSentAtAsc = 'reset_password_sent_at_asc',
    ResetPasswordSentAtDesc = 'reset_password_sent_at_desc',
    AllowPasswordChangeAsc = 'allow_password_change_asc',
    AllowPasswordChangeDesc = 'allow_password_change_desc',
    RememberCreatedAtAsc = 'remember_created_at_asc',
    RememberCreatedAtDesc = 'remember_created_at_desc',
    ConfirmationTokenAsc = 'confirmation_token_asc',
    ConfirmationTokenDesc = 'confirmation_token_desc',
    ConfirmedAtAsc = 'confirmed_at_asc',
    ConfirmedAtDesc = 'confirmed_at_desc',
    ConfirmationSentAtAsc = 'confirmation_sent_at_asc',
    ConfirmationSentAtDesc = 'confirmation_sent_at_desc',
    UnconfirmedEmailAsc = 'unconfirmed_email_asc',
    UnconfirmedEmailDesc = 'unconfirmed_email_desc',
    ImageAsc = 'image_asc',
    ImageDesc = 'image_desc',
    EmailAsc = 'email_asc',
    EmailDesc = 'email_desc',
    TokensAsc = 'tokens_asc',
    TokensDesc = 'tokens_desc',
    CreatedAtAsc = 'created_at_asc',
    CreatedAtDesc = 'created_at_desc',
    UpdatedAtAsc = 'updated_at_asc',
    UpdatedAtDesc = 'updated_at_desc',
    SignInCountAsc = 'sign_in_count_asc',
    SignInCountDesc = 'sign_in_count_desc',
    CurrentSignInAtAsc = 'current_sign_in_at_asc',
    CurrentSignInAtDesc = 'current_sign_in_at_desc',
    LastSignInAtAsc = 'last_sign_in_at_asc',
    LastSignInAtDesc = 'last_sign_in_at_desc',
    CurrentSignInIpAsc = 'current_sign_in_ip_asc',
    CurrentSignInIpDesc = 'current_sign_in_ip_desc',
    LastSignInIpAsc = 'last_sign_in_ip_asc',
    LastSignInIpDesc = 'last_sign_in_ip_desc',
    FirstNameAsc = 'first_name_asc',
    FirstNameDesc = 'first_name_desc',
    LastNameAsc = 'last_name_asc',
    LastNameDesc = 'last_name_desc',
    PinCodeAsc = 'pin_code_asc',
    PinCodeDesc = 'pin_code_desc',
    OnboardingStatusAsc = 'onboarding_status_asc',
    OnboardingStatusDesc = 'onboarding_status_desc',
    VerificationStatusAsc = 'verification_status_asc',
    VerificationStatusDesc = 'verification_status_desc'
}
