import { useCallback, useContext, useEffect, useMemo } from "react";
import { CableContext } from "../appContext";
import {
  ActiveCable,
  ActiveCableCommand,
  ActiveCableIdentifier,
  ActiveCableMessage,
  ActiveCableResponse,
  ActiveCableStreamType,
} from "../interfaces/activeCable";

export function useCable<T extends ActiveCableMessage>(
  props: ActiveCable<T>
): void {
  const { onReceived, onConnected, channel, room } = props;
  const subject = useContext(CableContext);

  const cableIdentifier = JSON.stringify({
    channel,
    room,
  });

  const multiplex = useMemo(
    () =>
      subject?.multiplex(
        () => {},
        () => ({
          command: ActiveCableCommand.Unsubscribe,
          identifier: cableIdentifier,
        }),
        (value) => {
          const { identifier: identifierString } = value;

          if (!identifierString) {
            return false;
          }

          const identifier: ActiveCableIdentifier = JSON.parse(
            identifierString
          );

          return identifier.channel === channel && identifier.room === room;
        }
      ),
    [channel, room, subject, cableIdentifier]
  );

  useEffect(() => {
    subject?.next({
      command: ActiveCableCommand.Subscribe,
      identifier: cableIdentifier,
    });
  }, [subject, cableIdentifier]);

  const subscribe = useCallback(() => multiplex?.subscribe((response) => {
      // The multiplex on the RxJS library types the response
      // callback as any. We manually need to type it here
      const data = response as ActiveCableResponse<T>;
      if (data.type === ActiveCableStreamType.ConfirmSubscription) {
        return onConnected?.();
      }

      onReceived(data.message);
      return data;
    }), [multiplex, onReceived, onConnected]);

  useEffect(() => {
    const subscription = subscribe();

    return () => {
      subscription?.unsubscribe();
    };
  }, [subscribe, props.channel, props.room]);
}
