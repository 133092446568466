import { ReactComponent as ArrowRefresh } from "@iconset/arrow.refresh.svg";
import { Box, Button, Tooltip, Typography } from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import React from "react";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import TCBlobDownloadButton from "../../../button/TCBlobDownloadButton/TCBlobDownloadButton";
import { TCLoadingIndicator } from "../../../tamocComponents/TCLoadingIndicator/TCLoadingIndicator";
import { TCRenderPdfProps } from "./TCRenderPdfProps";
import { useTCRenderPdfStyles } from "./TCRenderPdfStyles";
import { useTCRenderPdfViewModel } from "./TCRenderPdfViewModel";

/**
 * Renders a pdf and displays navigation elements.
 */
const TCRenderPdf: React.FunctionComponent<TCRenderPdfProps> = (props) => {
  const viewModel = useTCRenderPdfViewModel(props);
  const classes = useTCRenderPdfStyles();

  return (
    <Box>
      <Box className={classes.pdfNavigation}>
        <Box>
          <TCBlobDownloadButton
            blob={props.pdf}
            fileName={props.fileName}
            mediaType={"application/pdf"}
          />

          {props.contract && (
            <Tooltip arrow={true} title="Vertrag updaten">
              <Button
                onClick={viewModel.onRefreshContract}
                disabled={viewModel.contractRefreshPending}
              >
                <ArrowRefresh />
              </Button>
            </Tooltip>
          )}
        </Box>

        {!viewModel.contractRefreshPending && (
          <Box className={classes.pageIndicator}>
            {viewModel.pageNumber > 1 && (
              <Tooltip arrow={true} title="Vorherige Seite">
                <Button onClick={viewModel.onClickPreviousPage}>
                  <KeyboardArrowDownIcon />
                </Button>
              </Tooltip>
            )}

            <Typography className={classes.pageStatus}>
              {`Page ${viewModel.pageNumber} / ${viewModel.numberOfPages}`}
            </Typography>

            {viewModel.pageNumber < (viewModel.numberOfPages || 1) && (
              <Tooltip arrow={true} title="Nächste Seite">
                <Button onClick={viewModel.onClickNextPage}>
                  <KeyboardArrowUpIcon />
                </Button>
              </Tooltip>
            )}
          </Box>
        )}
      </Box>

      <Box className={classes.renderedPdfBox}>
        {viewModel.contractRefreshPending && (
          <TCLoadingIndicator isLoading={true} />
        )}

        {!viewModel.contractRefreshPending && (
          <Document
            file={URL.createObjectURL(props.pdf)}
            onLoadSuccess={viewModel.onDocumentLoadSuccess}
            loading={"PDF wird geladen"}
            error={"Beim Laden der PDF Datei ist ein Fehler aufgetreten"}
          >
            <Page pageNumber={viewModel.pageNumber} />
          </Document>
        )}
      </Box>
    </Box>
  );
};

export default TCRenderPdf;
