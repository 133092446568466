import { Tooltip } from "@material-ui/core";
import React from "react";
import { SliderLabelProps } from "../TCEditFieldIntProps";

export const ValueLabelComponent = (props: SliderLabelProps): JSX.Element => {
  const { children, open, value } = props;

  return (
    <Tooltip open={open} enterTouchDelay={0} placement="top" title={value}>
      {children}
    </Tooltip>
  );
};
