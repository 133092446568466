import { TCFieldWithoutContext } from "../hooks/dynamicFields/useFieldWithoutContext/useFieldWithoutContext";

export const mapFieldValues = <T>(props: TCFieldWithoutContext): Partial<T> => {
  const { fields, fieldValueMap } = props;
  const requestFieldIds = fields.map((field) => field.id);

  const result = Object.keys(fieldValueMap).reduce(
    (dataSet: Partial<T>, key) => {
      const fieldValue = fieldValueMap[key];
      if (requestFieldIds?.includes(fieldValue.fieldId)) {
        const fieldName = fieldValue.field.name;
        return {
          ...dataSet,
          [fieldName]: fieldValue.valuable.value,
        };
      }
      return dataSet;
    },
    {}
  );

  return result;
};
