// tslint:disable
/**
 * TaMoC API V1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum EContractType {
    Unspecific = 'unspecific',
    PlatformEmployer = 'platform_employer',
    PlatformEmployerForJob = 'platform_employer_for_job',
    PlatformWorkerForJob = 'platform_worker_for_job'
}

