import clsx from "clsx";
import { merge } from "lodash";
import { CanHaveClassName } from "../../interfaces/typeAliases";

/**
 * Merges a set of default values with values passed as props.
 * Additionally, a className (e.g. from `makeStyles`) can be passed.
 * While props will override defaults, classNames will be added.
 *
 * This has been wrapped in a hook (useMergedProps.ts)
 */
export function mergeDefaultPropsAndClass<T extends CanHaveClassName>(
  defaultProps: Partial<T> | undefined,
  props: Partial<T> | undefined,
  className?: string | undefined
): Partial<T> {
  /**
   * While regular props should be overwritten, class names must be added
   *  to the list.
   */
  const classNameMerged = clsx(
    defaultProps?.className,
    props?.className,
    className
  );
  return merge({}, defaultProps, props, { className: classNameMerged });
}
