import { makeStyles } from "@material-ui/core";
import { decorationIconRight } from "../../../helpers/styles/decorationIconRight";
import { CustomMuiTheme } from "../../../interfaces/CustomMuiTheme";

export const useTCTimePickerStyles = makeStyles<CustomMuiTheme>((theme) => ({
  wrapper: {
    position: "relative",
  },
  icon: decorationIconRight(theme),
}));
