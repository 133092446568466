import {
  Grid,
  IconButton,
  Step,
  Stepper,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { merge, noop } from "lodash";
import React, { Fragment, useMemo, useRef, useState } from "react";
import { animated, config, useSpring } from "react-spring";
import { TCStepButton } from "../../button/TCStepButton/TCStepButton";
import { TCDialog } from "../TCDialog/TCDialog";
import { TCTabDialogProps, TCTabDialogStepperType } from "./TCTabDialogProps";
import { useTCTabDialogStyles } from "./TCTabDialogStyles";

const dummyStepButton = (
  <TCStepButton
    active={false}
    currentStep={0}
    numberOfSteps={0}
    completed={false}
    label="Inhalt wird geladen"
    onClick={noop}
  />
);

const defaultProps: Partial<TCTabDialogProps> = {
  stepperType: TCTabDialogStepperType.Progressive,
};

export const TCTabDialog: React.FunctionComponent<TCTabDialogProps> = (
  passedProps
) => {
  const props = merge({}, defaultProps, passedProps);
  const modalContentRef = useRef<HTMLDivElement>(null);
  const [activeStep, setActiveStep] = useState(0);

  /**
   * Use react-spring to make the scrollUp silky smooth
   */
  const scrollUp = () => {
    if (modalContentRef.current) {
      modalContentRef.current.scrollTop = 0;
    }
  };
  const goBackward = () => {
    setActiveStep(activeStep - 1);
    scrollUp();
  };
  const goForward = () => {
    setActiveStep(activeStep + 1);
    scrollUp();
  };
  const goToStep = (index) => {
    setActiveStep(index);
    scrollUp();
  };

  const welcomeElement = props.renderWelcomeScreen
    ? props.renderWelcomeScreen(goForward, goBackward)
    : undefined;
  const numberOfStaticScreens = props.renderWelcomeScreen ? 1 : 0;
  const tabElements = props.render(goForward, goBackward);
  const isLoadingContent = tabElements.length === 0;
  const numberOfGroups = tabElements.length + numberOfStaticScreens;
  const transformPercent: number = -(100 / numberOfGroups) * activeStep || 0;
  const isShowingWelcomeScreen = !!(welcomeElement && activeStep === 0);
  const theme = useTheme();
  const showDesktopStepper = useMediaQuery(theme.breakpoints.up("sm"));
  const canGoBackward = activeStep > numberOfStaticScreens;
  const canGoForward =
    activeStep < tabElements.length - 1 + numberOfStaticScreens;

  const classes = useTCTabDialogStyles({
    numberOfGroups,
    isLoadingContent,
    isShowingWelcomeScreen,
  });

  const stepper = (
    <Stepper
      nonLinear={true}
      className={classes.stepper}
      activeStep={activeStep}
    >
      {isLoadingContent ? (
        dummyStepButton
      ) : (
        <Fragment>
          <IconButton
            style={{ opacity: canGoBackward ? 1 : 0 }}
            onClick={goBackward}
            className={classes.stepNavButton}
          >
            <ArrowBackIosIcon className={classes.fixBackIcon} />
          </IconButton>

          {tabElements.map((tab, index) => {
            const active = index + numberOfStaticScreens === activeStep;
            return (
              <Step
                key={tab.title}
                className={classes.step}
                style={{
                  display: active || showDesktopStepper ? "flex" : "none",
                }}
              >
                <TCStepButton
                  active={active}
                  currentStep={index + 1}
                  numberOfSteps={tabElements.length}
                  completed={index + numberOfStaticScreens < activeStep}
                  label={tab.title}
                  noCompleteIcon={
                    props.stepperType === TCTabDialogStepperType.Single
                  }
                  onlyOneActive={
                    props.stepperType === TCTabDialogStepperType.Single
                  }
                  onClick={
                    props.stepsNotClickable
                      ? undefined
                      : () => goToStep(index + numberOfStaticScreens)
                  }
                />
              </Step>
            );
          })}

          <IconButton
            style={{ opacity: canGoForward ? 1 : 0 }}
            onClick={goForward}
            className={classes.stepNavButton}
          >
            <ArrowForwardIosIcon />
          </IconButton>
        </Fragment>
      )}
    </Stepper>
  );

  const transformPercentSpring = useSpring({
    transform: `translateX(${transformPercent}%)`,
    config: config.slow,
  });

  const stepElements = useMemo(
    () =>
      (welcomeElement ? [welcomeElement] : []).concat(
        tabElements.map((tab) => tab.element)
      ),
    [tabElements, welcomeElement]
  );

  const WrapperElement = props.contentWrapper || "div";

  return (
    <TCDialog
      headerElement={stepper}
      title={props.title}
      open={props.open}
      elementProps={{
        dialog: {
          onExited: () => {
            setActiveStep(0);
          },
        },
      }}
      onClose={props.onClose}
      contentRef={modalContentRef}
    >
      <WrapperElement className="tabDialogWrapper">
        <div style={{ overflow: "hidden" }}>
          <animated.div
            className={classes.stepsWrapper}
            style={transformPercentSpring}
          >
            <Fragment>
              {stepElements.map((step, index) => (
                <Grid
                  container={true}
                  // I guess it makes sense here to use index as key
                  // eslint-disable-next-line react/no-array-index-key
                  key={`step-${index}`}
                  className={classes.singleStepWrapper}
                >
                  {step}
                </Grid>
              ))}
            </Fragment>
          </animated.div>
        </div>
      </WrapperElement>
    </TCDialog>
  );
};
