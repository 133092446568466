import React, { Fragment } from "react";
import { TCFieldComponent } from "../../../../../../../interfaces/dynamicFieldInterfaces";
import { TCCreateNewTimeSlotsPattern } from "../TCCreateNewTimeSlotsPattern/TCCreateNewTimeSlotsPattern";
import { useTCEditFieldTimeSlotsPatternsViewModel } from "./TCEditFieldTimeSlotsPatternsViewModel";

/**
 * Represents a full list of time slot patterns.
 * Each individual pattern is made up of multiple time slots.
 */
export const TCEditFieldTimeSlotsPatterns: TCFieldComponent = ({ field }) => {
  const {
    allShiftPatterns,
    renderShiftPattern,
    addNewTimeSlotPattern,
  } = useTCEditFieldTimeSlotsPatternsViewModel(field);

  return (
    <Fragment>
      {allShiftPatterns.map(renderShiftPattern)}

      <TCCreateNewTimeSlotsPattern
        onNew={addNewTimeSlotPattern}
        optionsFromDisplayConfig={
          field.displayConfiguration.valueEditing?.timeSlots?.newTimeSlots
        }
      />
    </Fragment>
  );
};
