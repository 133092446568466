// tslint:disable
/**
 * TaMoC API V1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum JobFields {
    Title = 'job_title',
    Location = 'job_location',
    WorkExperienceInYears = 'job_work_experience_in_years',
    Worker = 'job_worker',
    WorkerSpecialisation = 'job_worker_specialisation',
    Languages = 'job_languages',
    Documents = 'job_documents',
    DrivingLicenseType = 'job_driving_license_type',
    HourlyWage = 'job_hourly_wage',
    WorkingClothesProvided = 'job_working_clothes_provided',
    Description = 'job_description',
    TimingTypeToggle = 'job_timing_type_toggle',
    SingleShiftDate = 'job_single_shift_date',
    SingleShiftTimespan = 'job_single_shift_timespan',
    PeriodDateSpan = 'job_period_date_span',
    PeriodHoursPerWeek = 'job_period_hours_per_week',
    SingleDateSelectWidget = 'job_single_date_select_widget',
    WorkingClothes = 'job_working_clothes',
    PublicTransport = 'job_public_transport',
    SingleShiftReferenceToShift = 'job_single_shift_reference_to_shift',
    PeriodReferenceToShiftPattern = 'job_period_reference_to_shift_pattern'
}

