import { createStyles, makeStyles } from "@material-ui/core";
import { CustomMuiTheme } from "../../../interfaces/CustomMuiTheme";

export const useTCPageLayoutStyles = makeStyles((theme: CustomMuiTheme) =>
  createStyles({
    pageWrapper: {
      height: "100%",
      position: "relative",

      display: "flex",
      flexDirection: "column",
    },
    pageContent: {
      maxHeight: "100%",
      flex: 1,
      overflowY: "scroll",
      margin: theme.spacing(8, 8, 4, 8),
    },
  })
);
