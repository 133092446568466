import { IconButton, Tooltip } from "@material-ui/core";
import React from "react";
import { conversationUsersStore } from "../../../../dataHandling/stores/conversationUsersStore";
import { TCAvatar } from "../../../tamocComponents/TCAvatar/TCAvatar";
import { TCAvatarModes } from "../../../tamocComponents/TCAvatar/TCAvatarProps";
import { TCHeaderAvatarIconsProps } from "./TCHeaderAvatarIconsProps";
import { useTCHeaderAvatarIconsViewModel } from "./TCHeaderAvatarIconsViewModel";

export const TCHeaderAvatarIcons: React.FunctionComponent<TCHeaderAvatarIconsProps> = (
  props
) => {
  const viewModel = useTCHeaderAvatarIconsViewModel(props);
  if (!viewModel.user) {
    return null;
  }

  return (
    <Tooltip title={viewModel.title}>
      <IconButton
        onClick={() =>
          conversationUsersStore.setCurrentConversationUser(
            props.conversationUser
          )
        }
      >
        <TCAvatar
          size={40}
          user={viewModel.user}
          avatarMode={TCAvatarModes.Display}
        />
      </IconButton>
    </Tooltip>
  );
};
