import { Box, Typography } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";
import React from "react";
import { useAutocompletePropsForField } from "../../../../../../../hooks/useAutocompleteProps";
import { TCFieldComponent } from "../../../../../../../interfaces/dynamicFieldInterfaces";
import { TCInfoBlock } from "../../../../../../tamocComponents/TCInfoBlock/TCInfoBlock";
import { InfoBlockTyp } from "../../../../../../tamocComponents/TCInfoBlock/TCInfoBlockProps";
import { useTCEditFieldReferenceTimeSlotsViewModel } from "./TCEditFieldReferenceTimeSlotsViewModel";

/**
 * Pick a set of time slots (with a given name) from the referenced field.
 */
export const TCEditFieldReferenceTimeSlots: TCFieldComponent = (props) => {
  const {
    options,
    currentValue: value,
    onChange,
    valueIsOutdated,
  } = useTCEditFieldReferenceTimeSlotsViewModel(props);

  const autocompleteProps = useAutocompletePropsForField(props);

  if (!options || !options.length) {
    return null;
  }

  return (
    <Box>
      <Autocomplete
        {...autocompleteProps}
        options={options}
        value={value}
        onChange={onChange}
      />

      {valueIsOutdated && (
        <TCInfoBlock infoBlockType={InfoBlockTyp.Error}>
          <Typography variant="caption">
            Das von Ihnen gewählte Arbeitszeitmodell <i>{value.label}</i> wurde
            mittlerweile angepasst. Sie können den aktuellen Eintrag belassen,
            oder ein aktuelles Arbeitszeitmodell aus der Liste wählen.
          </Typography>
        </TCInfoBlock>
      )}
    </Box>
  );
};
