import { BoxProps, IconProps } from "@material-ui/core";
import { HTMLAttributes } from "react";
import {
  AllFields,
  DisplayConfiguration,
  EPurpose,
  Field,
  FieldGroup,
  FieldValue,
  FieldValueValuable,
  TcDocument,
} from "../generated/api-service";

export type IPurposeContextData = {
  fieldGroups: FieldGroup[];
  fieldValueMap: FieldValueMap;
  fieldsById: Record<Field["id"], Field>;
  purpose: EPurpose;
};

/**
 * Returning a string means error! They will be collected and output together.
 */
export type ComplexTypeChecker = (
  props: TCFieldComponentProps
) => string | undefined;

export type IPurposeContext = IPurposeContextData | undefined;

export interface FieldValueMap {
  [id: number]: FieldValue;
}

export interface FieldValuableMap {
  [id: number]: FieldValueValuable["value"];
}

export enum DisplayMode {
  "Input" = "input",
  "Display" = "display",
}

export interface TCFieldBaseProps {
  field: Field;
  displayConfiguration: DisplayConfiguration;
  fieldGroup?: FieldGroup;
  initialValue?: FieldValueValuable["value"];
  initialFiles?: TcDocument[];
}

export interface TCFieldProps extends TCFieldBaseProps, TCFieldRenderingProps {
  fieldValueMap?: FieldValueMap;
  displayMode: DisplayMode;
  className?: string;
}

export interface TCDisplayFieldProps
  extends TCFieldBaseProps,
    TCFieldRenderingProps {
  fieldValueMap?: FieldValueMap;
}

export interface TCEditFieldProps extends TCFieldBaseProps {
  className?: string;
  fieldValueMap?: FieldValueMap;
}

export interface PropsWithFieldAndFieldGroup {
  field: Field;
  fieldGroup?: FieldGroup;
}

export function hasFieldAndFieldGroup(
  props: Partial<PropsWithFieldAndFieldGroup>
): props is PropsWithFieldAndFieldGroup {
  return !!(props.field && props.fieldGroup);
}

export interface ValueListComponentProps extends TCFieldComponentProps {
  fieldName: string;
  options: string[];
}

export interface TCFieldComponentProps
  extends TCFieldProps,
    TCFieldRenderingProps {
  initialValue?: FieldValueValuable["value"];
}

export interface TCFieldRenderingProps {
  fieldLabelDisplayProps?: BoxProps;
  fieldValueDisplayProps?: BoxProps;
  fieldIconBeforeValueProps?: IconProps;
  fieldDisplayWrapperProps?: HTMLAttributes<HTMLDivElement>;
}

export type TCFieldComponent = React.FunctionComponent<TCFieldComponentProps>;

export interface UserKeyAttributes {
  [AllFields.FirstName]: string;
  [AllFields.LastName]: string;
  [AllFields.EmailAddress]: string;
}

export type FieldValueParentable = Pick<
  FieldValue,
  "parentableId" | "parentableType"
>;
