// tslint:disable
/**
 * TaMoC API V1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum UserFields {
    EmailAddress = 'email_address',
    FirstName = 'first_name',
    LastName = 'last_name',
    LocationWithoutCountry = 'location_without_country',
    PhoneNumber = 'phone_number',
    PrivacyOptIn = 'privacy_opt_in',
    PasswordNew = 'password_new',
    Salutation = 'salutation',
    PasswordOld = 'password_old'
}

