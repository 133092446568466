import {
  createStyles,
  makeStyles,
  TextField as PlainTextField,
} from "@material-ui/core";
import { Field } from "formik";
import React, { ChangeEvent, Fragment, useState } from "react";
import { fieldIsRequired } from "../../../../../helpers/forms/fieldIsRequired";
import { fieldValuesFromFormikContext } from "../../../../../helpers/forms/fieldValuesFromFormikContext";
import { TextFieldWrapperProps } from "../../../../../helpers/forms/formInterfaces";
import { updateFormikValue } from "../../../../../helpers/forms/updateFormikValue";
import { fieldTopMargin } from "../../../../../helpers/styles/variables";
import { useTCFormikContext } from "../../../../../hooks/useTCFormikContext";
import { CustomMuiTheme } from "../../../../../interfaces/CustomMuiTheme";
import {
  hasFieldAndFieldGroup,
  TCFieldComponent,
} from "../../../../../interfaces/dynamicFieldInterfaces";

const useStyles = makeStyles((theme: CustomMuiTheme) =>
  createStyles({
    confirmLabel: {
      marginTop: fieldTopMargin(theme),
    },
    passwordConfirm: {
      marginTop: fieldTopMargin(theme),
    },
  })
);

export type NewPassword = {
  password: string;
  confirmation: string;
};

export const TCEditFieldPasswordNew: TCFieldComponent = (props) => {
  const classes = useStyles();
  const formikContext = useTCFormikContext();

  const {
    formikIdentifier,
    fieldData,
    fieldValue,
  } = fieldValuesFromFormikContext<string>(formikContext, props.field);

  const [localPasswordValue, setLocalPasswordValue] = useState<string>("");
  const [localConfirmValue, setLocalConfirmValue] = useState<string>("");
  const [confirmError, setConfirmError] = useState<string>();

  if (!hasFieldAndFieldGroup(props)) {
    return null;
  }
  const required = fieldIsRequired(props);

  const genericProps: Omit<TextFieldWrapperProps, "name"> = {
    autoComplete: "new-password",
    type: "password",
    required,
  };

  const confirmFieldName = `${formikIdentifier}-confirm`;

  const passwordFieldProps: TextFieldWrapperProps = {
    ...genericProps,
    component: PlainTextField,
    name: formikIdentifier,
    label: props.displayConfiguration.label?.labelOverride ?? props.field.label,
    onChange: (event) => {
      const newValue = event.target.value;
      setLocalPasswordValue(newValue);
    },
    onBlur: () => {
      if (fieldValue !== localPasswordValue) {
        formikContext.setFieldTouched(formikIdentifier, true);
        updateFormikValue(formikContext, formikIdentifier, {
          ...fieldData,
          value: localPasswordValue,
        });
      }
    },
  };

  const passwordConfirmProps: TextFieldWrapperProps = {
    ...genericProps,
    component: PlainTextField,
    name: confirmFieldName,
    label: `${
      props.displayConfiguration.label?.labelOverride ?? "Passwort "
    } bestätigen`,
    value: localConfirmValue,
    helperText: confirmError,
    className: classes.passwordConfirm,
    onChange: (e: ChangeEvent<HTMLInputElement>) =>
      setLocalConfirmValue(e.target.value),
    onBlur: () => formikContext.setFieldTouched(formikIdentifier, true),
    onFocus: () => setConfirmError(undefined),
    validate: () => {
      const err =
        localConfirmValue !== localPasswordValue
          ? "Passwörter stimmen nicht überein"
          : undefined;
      setConfirmError(err);
      return err;
    },
  };

  return (
    <Fragment>
      <Field {...passwordFieldProps} />

      <Field {...passwordConfirmProps} />
    </Fragment>
  );
};
