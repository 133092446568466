// tslint:disable
/**
 * TaMoC API V1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * condition that must hold true to show the field
 * @export
 * @interface DisplayConfigurationCondition
 */
export interface DisplayConfigurationCondition {
    /**
     * Relevant when multiple conditions are checked.
     * @type {string}
     * @memberof DisplayConfigurationCondition
     */
    conditionType: DisplayConfigurationConditionConditionTypeEnum;
    /**
     * The type of check to be performed.
     * @type {string}
     * @memberof DisplayConfigurationCondition
     */
    operator: DisplayConfigurationConditionOperatorEnum;
    /**
     * The value that the related field value is compared against, as a JSON string.
     * @type {string}
     * @memberof DisplayConfigurationCondition
     */
    comparisonValue: string;
}

/**
 * @export
 * @enum {string}
 */
export enum DisplayConfigurationConditionConditionTypeEnum {
    And = 'AND'
}
/**
 * @export
 * @enum {string}
 */
export enum DisplayConfigurationConditionOperatorEnum {
    Equals = 'equals',
    NotEquals = 'notEquals'
}

