import { Box } from "@material-ui/core";
import clsx from "clsx";
import { omit } from "lodash";
import React from "react";
import TCDocumentListItem from "./TCDocumentListItem/TCDocumentListItem";
import { TCDocumentListProps } from "./TCDocumentListProps";
import { useTCDocumentListStyles } from "./TCDocumentListStyles";
/**
 * A list of documents from the database that have an attached file
 */
export const TCDocumentList: React.FunctionComponent<TCDocumentListProps> = (
  props
) => {
  const classes = useTCDocumentListStyles();

  const documentList = props.documents.map((document) => (
    <TCDocumentListItem key={document.id} {...props} document={document} />
  ));

  return (
    <Box
      {...omit(props.documentListWrapperProps, "className")}
      className={clsx(
        classes.listWrapper,
        props.documentListWrapperProps?.className
      )}
    >
      {documentList}
    </Box>
  );
};
