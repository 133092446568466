import { FormLabel } from "@material-ui/core";
import { DatePickerProps } from "@material-ui/pickers";
import { omit } from "lodash";
import React, { useMemo } from "react";
import { useMergedProps } from "../../../hooks/useMergedProps";
import { TCDatePickerProps, TCDatePickerViewModel } from "./TCDatePickerProps";
import { useTCDatePickerStyles } from "./TCDatePickerStyles";

export const datePickerMinDateMessage = "Datum vor zulässigem Mindestdatum";

const defaultPickerProps = (required = false): Partial<DatePickerProps> => ({
  orientation: "portrait",
  animateYearScrolling: true,
  inputVariant: "outlined",
  minDateMessage: datePickerMinDateMessage,
  inputProps: {
    required,
  },
  DialogProps: {
    PaperProps: {
      style: {
        padding: 0,
      },
    },
  },
  disablePast: true,
  format: "L",
  cancelLabel: "abbrechen",
});

export const useTCDatePickerViewModel = (
  props: TCDatePickerProps,
  classes: ReturnType<typeof useTCDatePickerStyles>
): TCDatePickerViewModel => {
  const { pickerProps, wrapperProps, value, onChange } = props;
  const mergedWrapperProps = useMergedProps({}, wrapperProps, classes.wrapper);

  // Rendering the label within DatePicker causes the icon to be off,
  //  so we use a separate label instead.
  const pickerPropsWithoutLabel = useMemo(() => omit(pickerProps, "label"), [
    pickerProps,
  ]);

  const mergedPickerProps = useMergedProps<DatePickerProps>(
    {
      ...defaultPickerProps(props.required),
      value,
      onChange,
    },
    pickerPropsWithoutLabel
  ) as DatePickerProps;

  const labelElement = pickerProps?.label ? (
    <FormLabel required={props.required}>{pickerProps.label}</FormLabel>
  ) : null;

  return {
    mergedPickerProps,
    mergedWrapperProps,
    labelElement,
  };
};
