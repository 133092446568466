import { Field } from "../../generated/api-service";
import { IPurposeContext } from "../../interfaces/dynamicFieldInterfaces";
import {
  TCFormikFieldData,
  TCFormikValues,
} from "../../interfaces/formikInterfaces";
import { constructFormikFieldIdentifier } from "../dynamicFields/fieldName";
import { defaultValueForField } from "./defaultValueForField";

const getInitialValue = (
  purposeContext: IPurposeContext,
  field: Field,
  fieldId: string
): TCFormikFieldData => {
  const plainValue =
    purposeContext?.fieldValueMap[fieldId]?.valuable?.value ||
    defaultValueForField(field);

  return {
    initialFiles: purposeContext?.fieldValueMap[fieldId]?.documents,
    value: plainValue,
    field: purposeContext?.fieldsById[fieldId],
  };
};

export function initialValuesFromPurposeContext(
  purposeContext: IPurposeContext
): TCFormikValues {
  return purposeContext?.fieldsById
    ? Object.entries(purposeContext?.fieldsById).reduce(
        (result, [fieldId, field]) => ({
            ...result,
            [constructFormikFieldIdentifier(field)]: getInitialValue(
              purposeContext,
              field,
              fieldId
            ),
          }),
        {}
      )
    : {};
}
