import moment from "moment";
import { Conversation, ConversationUser, User } from "../generated/api-service";

export const notCurrentUser = (
  user: User,
  currentUser: User | undefined
): boolean => user.id !== currentUser?.id;

export const withMessages = (conversationUser: ConversationUser): boolean =>
  (conversationUser.conversation?.messages?.length ?? 0) > 0;

export const withoutMessages = (conversationUser: ConversationUser): boolean =>
  (conversationUser.conversation?.messages?.length ?? 0) === 0;

export const byLastMessage = (
  a: ConversationUser,
  b: ConversationUser
): number => {
  if (!a.conversation?.messages?.[0] || !b.conversation?.messages?.[0]) {
    return 0;
  }

  return moment(b.conversation?.messages?.[0].createdAt).diff(
    moment(a.conversation?.messages?.[0].createdAt)
  );
};

export const extractParticipants = (
  conversation: Conversation,
  currentUser: User
): User[] =>
  conversation.users?.filter((user) => notCurrentUser(user, currentUser)) ?? [];
