import React, { FunctionComponent, useCallback, useContext } from "react";
import { PurposeContext } from "../../../appContext";
import { TCSaveOrDismissButtons } from "../../button/TCSaveOrDismissButtons/TCSaveOrDismissButtons";
import TCFormikBulkUpdateForPurposeContext from "../../dynamicFields/purpose/TCFormikBulkUpdateForPurposeContext/TCFormikBulkUpdateForPurposeContext";
import { FormikSaveHandler } from "../../dynamicFields/purpose/TCFormikBulkUpdateForPurposeContext/TCFormikBulkUpdateForPurposeContextProps";
import { TCDialogStep } from "../TCDialogStep/TCDialogStep";
import { TCTabDialog } from "../TCTabDialog/TCTabDialog";
import {
  TCTabDialogStepperType,
  TCTabDialogTab,
} from "../TCTabDialog/TCTabDialogProps";
import { TCEditFieldGroupsDialogProps } from "./TCEditFieldGroupsDialogProps";
import { useTCEditFieldGroupsDialogStyles } from "./TCEditFieldGroupsDialogStyles";

export const TCEditFieldGroupsDialog: FunctionComponent<TCEditFieldGroupsDialogProps> = (
  props
) => {
  const purposeContext = useContext(PurposeContext);

  const classes = useTCEditFieldGroupsDialogStyles();

  const { onClose } = props;
  const onAfterSave = useCallback(() => onClose, [onClose]);

  const renderProfileSections = (): TCTabDialogTab[] => {
    if (!purposeContext) {
      return [];
    }
    return purposeContext.fieldGroups.map((fieldGroup) => ({
        title: fieldGroup.label,
        element: (
          <TCDialogStep
            {...{
              fieldGroup,
              forwardHandler: undefined,
              backwardHandler: undefined,
              fieldValueMap: purposeContext.fieldValueMap,
              // eslint-disable-next-line react/no-multi-comp
              contentAfterInput: () => (
                <TCSaveOrDismissButtons
                  boxProps={{ className: classes.saveOrDismissButtonGroup }}
                />
              ),
            }}
          />
        ),
      }));
  };

  const formComponent: FunctionComponent = (formComponentProps) => (
    <TCFormikBulkUpdateForPurposeContext
      saveHandler={FormikSaveHandler.InComponent}
      noFormValidate={false}
      bulkUpdateInformation={{
        parentableId: props.parentableId,
        parentableType: props.parentableType,
        additionalProps: {},
        onAfterSave,
      }}
    >
      {formComponentProps.children}
    </TCFormikBulkUpdateForPurposeContext>
  );

  return (
    <TCTabDialog
      title={props.title}
      stepperType={TCTabDialogStepperType.Single}
      open={props.open}
      onClose={props.onClose}
      render={renderProfileSections}
      contentWrapper={formComponent}
    />
  );
};
