import { Box } from "@material-ui/core";
import { omit } from "lodash";
import React from "react";
import { TCBlobList } from "./TCBlobList/TCBlobList";
import { TCDocumentBlobListProps } from "./TCDocumentBlobListProps";
import { TCDocumentList } from "./TCDocumentList/TCDocumentList";

/**
 * A list that holds two types of files.
 * 1. Documents from the database that have an attached file.
 * 2. Blobs/Files that are uploaded via a dropzone or something similar.
 */
export const TCDocumentBlobList: React.FunctionComponent<TCDocumentBlobListProps> = (
  props
) => (
    <Box {...props.fileDocumentListWrapperProps}>
      {props.blobs &&
        (props.isUsingDeleteIcon ? (
          <TCBlobList
            {...props}
            enableDelete={true}
            blobs={props.blobs}
            onBlobDelete={props.onBlobDelete}
          />
        ) : (
          <TCBlobList
            {...omit(props, "onBlobDelete")}
            enableDelete={false}
            blobs={props.blobs}
            onBlobDelete={undefined}
          />
        ))}

      {props.documents &&
        (props.isUsingDeleteIcon ? (
          <TCDocumentList
            {...props}
            isUsingDeleteIcon={true}
            isUsingThreeDotMenu={false}
            documents={props.documents}
            onDocumentDelete={props.onDocumentDelete}
          />
        ) : (
          <TCDocumentList
            {...props}
            isUsingDeleteIcon={false}
            isUsingThreeDotMenu={false}
            documents={props.documents}
          />
        ))}
    </Box>
  );
