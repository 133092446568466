// tslint:disable
/**
 * TaMoC API V1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs';
import {
    ERole, UserRole,
    UserRolePost,
    UserRolePut
} from '../models';
import { BaseAPI, encodeURI, HttpHeaders, HttpQuery, OperationOpts, RawAjaxResponse, throwIfNullOrUndefined } from '../runtime';

export interface CreateUserRoleRequest {
    accessToken: string;
    client: string;
    uid: string;
    userRolePost?: UserRolePost;
}

export interface DeleteUserRoleRequest {
    accessToken: string;
    client: string;
    uid: string;
    id: number;
}

export interface GetUserRoleRequest {
    accessToken: string;
    client: string;
    uid: string;
    sortBy?: GetUserRoleSortByEnum;
    id?: number | string;
    userId?: number | string;
    createdAt?: string;
    updatedAt?: string;
    role?: ERole;
}

export interface GetUserRoleByIdRequest {
    accessToken: string;
    client: string;
    uid: string;
    id: number;
}

export interface PatchUserRoleRequest {
    accessToken: string;
    client: string;
    uid: string;
    id: number;
    userRolePut?: UserRolePut;
}

export interface UpdateUserRoleRequest {
    accessToken: string;
    client: string;
    uid: string;
    id: number;
    userRolePut?: UserRolePut;
}

/**
 * no description
 */
export class UserRoleApi extends BaseAPI {

    /**
     * Create UserRole
     */
    createUserRole({ accessToken, client, uid, userRolePost }: CreateUserRoleRequest): Observable<UserRole>
    createUserRole({ accessToken, client, uid, userRolePost }: CreateUserRoleRequest, opts?: OperationOpts): Observable<RawAjaxResponse<UserRole>>
    createUserRole({ accessToken, client, uid, userRolePost }: CreateUserRoleRequest, opts?: OperationOpts): Observable<UserRole | RawAjaxResponse<UserRole>> {
        throwIfNullOrUndefined(accessToken, 'accessToken', 'createUserRole');
        throwIfNullOrUndefined(client, 'client', 'createUserRole');
        throwIfNullOrUndefined(uid, 'uid', 'createUserRole');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(accessToken != null ? { 'access-token': String(accessToken) } : undefined),
            ...(client != null ? { 'client': String(client) } : undefined),
            ...(uid != null ? { 'uid': String(uid) } : undefined),
        };

        return this.request<UserRole>({
            url: '/api/v1/user_roles/',
            method: 'POST',
            headers,
            body: userRolePost,
        }, opts?.responseOpts);
    };

    /**
     * Deletes a UserRole
     */
    deleteUserRole({ accessToken, client, uid, id }: DeleteUserRoleRequest): Observable<void>
    deleteUserRole({ accessToken, client, uid, id }: DeleteUserRoleRequest, opts?: OperationOpts): Observable<void | RawAjaxResponse<void>>
    deleteUserRole({ accessToken, client, uid, id }: DeleteUserRoleRequest, opts?: OperationOpts): Observable<void | RawAjaxResponse<void>> {
        throwIfNullOrUndefined(accessToken, 'accessToken', 'deleteUserRole');
        throwIfNullOrUndefined(client, 'client', 'deleteUserRole');
        throwIfNullOrUndefined(uid, 'uid', 'deleteUserRole');
        throwIfNullOrUndefined(id, 'id', 'deleteUserRole');

        const headers: HttpHeaders = {
            ...(accessToken != null ? { 'access-token': String(accessToken) } : undefined),
            ...(client != null ? { 'client': String(client) } : undefined),
            ...(uid != null ? { 'uid': String(uid) } : undefined),
        };

        return this.request<void>({
            url: '/api/v1/user_roles/{id}'.replace('{id}', encodeURI(id)),
            method: 'DELETE',
            headers,
        }, opts?.responseOpts);
    };

    /**
     * Retrieve a UserRole list
     */
    getUserRole({ accessToken, client, uid, sortBy, id, userId, createdAt, updatedAt, role }: GetUserRoleRequest): Observable<Array<UserRole>>
    getUserRole({ accessToken, client, uid, sortBy, id, userId, createdAt, updatedAt, role }: GetUserRoleRequest, opts?: OperationOpts): Observable<RawAjaxResponse<Array<UserRole>>>
    getUserRole({ accessToken, client, uid, sortBy, id, userId, createdAt, updatedAt, role }: GetUserRoleRequest, opts?: OperationOpts): Observable<Array<UserRole> | RawAjaxResponse<Array<UserRole>>> {
        throwIfNullOrUndefined(accessToken, 'accessToken', 'getUserRole');
        throwIfNullOrUndefined(client, 'client', 'getUserRole');
        throwIfNullOrUndefined(uid, 'uid', 'getUserRole');

        const headers: HttpHeaders = {
            ...(accessToken != null ? { 'access-token': String(accessToken) } : undefined),
            ...(client != null ? { 'client': String(client) } : undefined),
            ...(uid != null ? { 'uid': String(uid) } : undefined),
        };

        const query: HttpQuery = {};

        if (sortBy != null) { query['sort_by'] = sortBy; }
        if (id != null) { query['id'] = id; }
        if (userId != null) { query['user_id'] = userId; }
        if (createdAt != null) { query['created_at'] = (createdAt as any).toISOString(); }
        if (updatedAt != null) { query['updated_at'] = (updatedAt as any).toISOString(); }
        if (role != null) { query['role'] = role; }

        return this.request<Array<UserRole>>({
            url: '/api/v1/user_roles/',
            method: 'GET',
            headers,
            query,
        }, opts?.responseOpts);
    };

    /**
     * Retrieves a UserRole
     */
    getUserRoleById({ accessToken, client, uid, id }: GetUserRoleByIdRequest): Observable<UserRole>
    getUserRoleById({ accessToken, client, uid, id }: GetUserRoleByIdRequest, opts?: OperationOpts): Observable<RawAjaxResponse<UserRole>>
    getUserRoleById({ accessToken, client, uid, id }: GetUserRoleByIdRequest, opts?: OperationOpts): Observable<UserRole | RawAjaxResponse<UserRole>> {
        throwIfNullOrUndefined(accessToken, 'accessToken', 'getUserRoleById');
        throwIfNullOrUndefined(client, 'client', 'getUserRoleById');
        throwIfNullOrUndefined(uid, 'uid', 'getUserRoleById');
        throwIfNullOrUndefined(id, 'id', 'getUserRoleById');

        const headers: HttpHeaders = {
            ...(accessToken != null ? { 'access-token': String(accessToken) } : undefined),
            ...(client != null ? { 'client': String(client) } : undefined),
            ...(uid != null ? { 'uid': String(uid) } : undefined),
        };

        return this.request<UserRole>({
            url: '/api/v1/user_roles/{id}'.replace('{id}', encodeURI(id)),
            method: 'GET',
            headers,
        }, opts?.responseOpts);
    };

    /**
     * Patch a UserRole
     */
    patchUserRole({ accessToken, client, uid, id, userRolePut }: PatchUserRoleRequest): Observable<UserRole>
    patchUserRole({ accessToken, client, uid, id, userRolePut }: PatchUserRoleRequest, opts?: OperationOpts): Observable<RawAjaxResponse<UserRole>>
    patchUserRole({ accessToken, client, uid, id, userRolePut }: PatchUserRoleRequest, opts?: OperationOpts): Observable<UserRole | RawAjaxResponse<UserRole>> {
        throwIfNullOrUndefined(accessToken, 'accessToken', 'patchUserRole');
        throwIfNullOrUndefined(client, 'client', 'patchUserRole');
        throwIfNullOrUndefined(uid, 'uid', 'patchUserRole');
        throwIfNullOrUndefined(id, 'id', 'patchUserRole');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(accessToken != null ? { 'access-token': String(accessToken) } : undefined),
            ...(client != null ? { 'client': String(client) } : undefined),
            ...(uid != null ? { 'uid': String(uid) } : undefined),
        };

        return this.request<UserRole>({
            url: '/api/v1/user_roles/{id}'.replace('{id}', encodeURI(id)),
            method: 'PATCH',
            headers,
            body: userRolePut,
        }, opts?.responseOpts);
    };

    /**
     * Update a UserRole
     */
    updateUserRole({ accessToken, client, uid, id, userRolePut }: UpdateUserRoleRequest): Observable<UserRole>
    updateUserRole({ accessToken, client, uid, id, userRolePut }: UpdateUserRoleRequest, opts?: OperationOpts): Observable<RawAjaxResponse<UserRole>>
    updateUserRole({ accessToken, client, uid, id, userRolePut }: UpdateUserRoleRequest, opts?: OperationOpts): Observable<UserRole | RawAjaxResponse<UserRole>> {
        throwIfNullOrUndefined(accessToken, 'accessToken', 'updateUserRole');
        throwIfNullOrUndefined(client, 'client', 'updateUserRole');
        throwIfNullOrUndefined(uid, 'uid', 'updateUserRole');
        throwIfNullOrUndefined(id, 'id', 'updateUserRole');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(accessToken != null ? { 'access-token': String(accessToken) } : undefined),
            ...(client != null ? { 'client': String(client) } : undefined),
            ...(uid != null ? { 'uid': String(uid) } : undefined),
        };

        return this.request<UserRole>({
            url: '/api/v1/user_roles/{id}'.replace('{id}', encodeURI(id)),
            method: 'PUT',
            headers,
            body: userRolePut,
        }, opts?.responseOpts);
    };

}

/**
 * @export
 * @enum {string}
 */
export enum GetUserRoleSortByEnum {
    IdAsc = 'id_asc',
    IdDesc = 'id_desc',
    UserIdAsc = 'user_id_asc',
    UserIdDesc = 'user_id_desc',
    CreatedAtAsc = 'created_at_asc',
    CreatedAtDesc = 'created_at_desc',
    UpdatedAtAsc = 'updated_at_asc',
    UpdatedAtDesc = 'updated_at_desc',
    RoleAsc = 'role_asc',
    RoleDesc = 'role_desc'
}
