import { Button, ButtonProps, CircularProgress } from "@material-ui/core";
import { omit } from "lodash";
import React from "react";

export interface TCButtonProps extends ButtonProps {
  isLoading?: boolean;
  loadingStateProps?: ButtonProps;
}

const primaryButtonDefaults: TCButtonProps = {
  size: "large",
  variant: "contained",
  disableElevation: true,
  disabled: false,
  color: "primary",
  isLoading: false,
  loadingStateProps: {
    "aria-label": "Loading",
    startIcon: (
      <CircularProgress
        style={{ marginTop: 0, color: "inherit" }}
        size="1rem"
      />
    ),
    disabled: true,
  },
};

function getMergedProps(props: TCButtonProps): ButtonProps {
  const mergedProps = omit(
    {
      ...primaryButtonDefaults,
      ...props,
    },
    "loadingStateProps"
  );
  if (!mergedProps.isLoading) {
    return mergedProps;
  }
  const mergedLoadingProps = {
    ...primaryButtonDefaults.loadingStateProps,
    ...props.loadingStateProps,
  };
  return { ...mergedProps, ...mergedLoadingProps };
}

export const TCPrimaryButton: React.FunctionComponent<TCButtonProps> = (
  props: TCButtonProps
) => 
  /**
   * Todo: merge classes from above, but still allow overriding
   * via styles and classes props!
   */
   <Button {...omit(getMergedProps(props), "isLoading")} />
;
