import { Field } from "../../generated/api-service";
import { getFieldLabel } from "./getFieldLabel";

/**
 * Returns a placeholder for the field, or undefined if no
 *  meaningful placeholder can be determined.
 */
export const getFieldPlaceholder = (field: Field): string | undefined => {
  const placeholderFromDisplayConfig =
    field.displayConfiguration?.valueEditing?.placeholderValue;
  if (placeholderFromDisplayConfig) {
    return placeholderFromDisplayConfig;
  }

  const fieldLabel = getFieldLabel(field);
  return fieldLabel || field.label;
};
