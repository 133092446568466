import {
  path,
  reactRouterStateFromLocation,
} from "../../helpers/routingHelper";
import { TCAppRoutes } from "../../interfaces/TCAppRoutes";
import { useTCHistory } from "./useTCHistory";
import { useTCLocation } from "./useTCLocation";

export const useNavigate = (): ((route: TCAppRoutes, id?: number) => void) => {
  const history = useTCHistory();
  const location = useTCLocation();
  return (route: TCAppRoutes, id?: number) =>
    history.push(path(route, id), reactRouterStateFromLocation(location));
};
