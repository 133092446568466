import { TCDisplayFieldComplex } from "../../components/dynamicFields/displayFields/complex/TCDisplayFieldComplex/TCDisplayFieldComplex";
import { TCDisplayFieldComplexFile } from "../../components/dynamicFields/displayFields/complex/TCDisplayFieldComplexFile/TCDisplayFieldComplexFile";
import { TCEditFieldComplexPlaceholder } from "../../components/dynamicFields/editFields/complex/TCEditFieldComplexPlaceholder/TCEditFieldComplexPlaceholder";
import { TCEditFieldFile } from "../../components/dynamicFields/editFields/complex/TCEditFieldFile/TCEditFieldFile";
import { TCEditFieldLocation } from "../../components/dynamicFields/editFields/complex/TCEditFieldLocation/TCEditFieldLocation";
import { TCEditFieldPasswordNew } from "../../components/dynamicFields/editFields/complex/TCEditFieldPassword/TCEditFieldPasswordNew";
import { TCEditFieldPasswordUpdate } from "../../components/dynamicFields/editFields/complex/TCEditFieldPassword/TCEditFieldPasswordUpdate";
import { TCEditFieldRange } from "../../components/dynamicFields/editFields/complex/TCEditFieldRange/TCEditFieldRange";
import { TCEditFieldReference } from "../../components/dynamicFields/editFields/complex/TCEditFieldReference/TCEditFieldReference";
import { TCEditFieldTimeSlots } from "../../components/dynamicFields/editFields/complex/TCEditFieldTimeSlots/TCEditFieldTimeSlots";
import { TCEditFieldValueList } from "../../components/dynamicFields/editFields/complex/TCEditFieldValueList/TCEditFieldValueList";
import { EComplexType } from "../../generated/api-service";
import {
  DisplayMode,
  TCFieldComponent,
} from "../../interfaces/dynamicFieldInterfaces";

interface ComponentMapping {
  [DisplayMode.Display]: TCFieldComponent;
  [DisplayMode.Input]: TCFieldComponent;
}

const editFieldwithGenericDisplayField = (
  editComponent: TCFieldComponent
): ComponentMapping => ({
  [DisplayMode.Display]: TCDisplayFieldComplex,
  [DisplayMode.Input]: editComponent,
});

const placeholder: ComponentMapping = {
  [DisplayMode.Display]: TCDisplayFieldComplex,
  [DisplayMode.Input]: TCEditFieldComplexPlaceholder,
};

export const componentsForComplexTypes: {
  [complexType in EComplexType]: {
    [displayMode in DisplayMode]: TCFieldComponent;
  };
} = {
  [EComplexType.File]: {
    [DisplayMode.Display]: TCDisplayFieldComplexFile,
    [DisplayMode.Input]: TCEditFieldFile,
  },
  [EComplexType.Location]: editFieldwithGenericDisplayField(
    TCEditFieldLocation
  ),
  [EComplexType.PasswordNew]: editFieldwithGenericDisplayField(
    TCEditFieldPasswordNew
  ),
  [EComplexType.PasswordUpdate]: editFieldwithGenericDisplayField(
    TCEditFieldPasswordUpdate
  ),
  [EComplexType.Range]: editFieldwithGenericDisplayField(TCEditFieldRange),
  [EComplexType.TimeSlots]: editFieldwithGenericDisplayField(
    TCEditFieldTimeSlots
  ),
  [EComplexType.ValueList]: editFieldwithGenericDisplayField(
    TCEditFieldValueList
  ),
  [EComplexType.FieldReference]: editFieldwithGenericDisplayField(
    TCEditFieldReference
  ),
  [EComplexType.Payment]: placeholder,
  [EComplexType.ValueMap]: placeholder,
  [EComplexType.VideoIdent]: placeholder,
};
