import { Box } from "@material-ui/core";
import React from "react";
import { TCDisplayFieldProps } from "../../../../../../interfaces/dynamicFieldInterfaces";
import { TCDocumentList } from "../../../../../lists/TCDocumentBlobList/TCDocumentList/TCDocumentList";
import { TCLabeledFieldValue } from "../../../TCLabeledFieldValue/TCLabeledFieldValue";

export const TCDisplayFieldComplexFileLabeledValue: React.FunctionComponent<TCDisplayFieldProps> = (
  props
) => {
  if (!props.initialFiles || props.initialFiles.length === 0) {
    return null;
  }

  const elementToDisplay = (
    <Box>
      {props.initialFiles.map((document) => (
        <TCDocumentList
          key={document.id}
          documents={[document]}
          isUsingDeleteIcon={false}
          isUsingThreeDotMenu={false}
        />
      ))}
    </Box>
  );

  return <TCLabeledFieldValue value={elementToDisplay} {...props} />;
};
