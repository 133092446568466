import { makeStyles } from "@material-ui/core";
import { CustomMuiTheme } from "../../interfaces/CustomMuiTheme";

export const useTCChatStyles = makeStyles<CustomMuiTheme>(() => ({
  chatContainer: {
    display: "flex",
    height: "100%",
    flexDirection: "column",
  },
}));
