import { Drawer } from "@material-ui/core";
import React from "react";
import { TCConversationUser } from "../../conversationUser/TCConversationUser";
import { useTCMessageDrawerStyles } from "./TCMessageDrawerStyles";
import { useTCMessageDrawerViewModel } from "./TCMessageDrawerViewModel";

export const TCMessageDrawer: React.FunctionComponent = () => {
  const viewModel = useTCMessageDrawerViewModel();
  const classes = useTCMessageDrawerStyles();

  return (
    <Drawer
      anchor="right"
      classes={{
        paper: classes.paper,
      }}
      onClose={viewModel.onClose}
      open={viewModel.isPanelOpen}
    >
      <TCConversationUser />
    </Drawer>
  );
};
