// tslint:disable
/**
 * TaMoC API V1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs';
import {
    ContractTemplate,
    ContractTemplatePost,
    ContractTemplatePut,
    EContractType
} from '../models';
import { BaseAPI, encodeURI, HttpHeaders, HttpQuery, OperationOpts, RawAjaxResponse, throwIfNullOrUndefined } from '../runtime';

export interface CreateContractTemplateRequest {
    accessToken: string;
    client: string;
    uid: string;
    contractTemplatePost?: ContractTemplatePost;
}

export interface DeleteContractTemplateRequest {
    accessToken: string;
    client: string;
    uid: string;
    id: number;
}

export interface GetContractTemplateRequest {
    accessToken: string;
    client: string;
    uid: string;
    sortBy?: GetContractTemplateSortByEnum;
    id?: number | string;
    name?: string;
    content?: string;
    createdAt?: string;
    updatedAt?: string;
    contractType?: EContractType;
    deletedAt?: string;
}

export interface GetContractTemplateByIdRequest {
    accessToken: string;
    client: string;
    uid: string;
    id: number;
}

export interface PatchContractTemplateRequest {
    accessToken: string;
    client: string;
    uid: string;
    id: number;
    contractTemplatePut?: ContractTemplatePut;
}

export interface UpdateContractTemplateRequest {
    accessToken: string;
    client: string;
    uid: string;
    id: number;
    contractTemplatePut?: ContractTemplatePut;
}

/**
 * no description
 */
export class ContractTemplateApi extends BaseAPI {

    /**
     * Create ContractTemplate
     */
    createContractTemplate({ accessToken, client, uid, contractTemplatePost }: CreateContractTemplateRequest): Observable<ContractTemplate>
    createContractTemplate({ accessToken, client, uid, contractTemplatePost }: CreateContractTemplateRequest, opts?: OperationOpts): Observable<RawAjaxResponse<ContractTemplate>>
    createContractTemplate({ accessToken, client, uid, contractTemplatePost }: CreateContractTemplateRequest, opts?: OperationOpts): Observable<ContractTemplate | RawAjaxResponse<ContractTemplate>> {
        throwIfNullOrUndefined(accessToken, 'accessToken', 'createContractTemplate');
        throwIfNullOrUndefined(client, 'client', 'createContractTemplate');
        throwIfNullOrUndefined(uid, 'uid', 'createContractTemplate');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(accessToken != null ? { 'access-token': String(accessToken) } : undefined),
            ...(client != null ? { 'client': String(client) } : undefined),
            ...(uid != null ? { 'uid': String(uid) } : undefined),
        };

        return this.request<ContractTemplate>({
            url: '/api/v1/contract_templates/',
            method: 'POST',
            headers,
            body: contractTemplatePost,
        }, opts?.responseOpts);
    };

    /**
     * Deletes a ContractTemplate
     */
    deleteContractTemplate({ accessToken, client, uid, id }: DeleteContractTemplateRequest): Observable<void>
    deleteContractTemplate({ accessToken, client, uid, id }: DeleteContractTemplateRequest, opts?: OperationOpts): Observable<void | RawAjaxResponse<void>>
    deleteContractTemplate({ accessToken, client, uid, id }: DeleteContractTemplateRequest, opts?: OperationOpts): Observable<void | RawAjaxResponse<void>> {
        throwIfNullOrUndefined(accessToken, 'accessToken', 'deleteContractTemplate');
        throwIfNullOrUndefined(client, 'client', 'deleteContractTemplate');
        throwIfNullOrUndefined(uid, 'uid', 'deleteContractTemplate');
        throwIfNullOrUndefined(id, 'id', 'deleteContractTemplate');

        const headers: HttpHeaders = {
            ...(accessToken != null ? { 'access-token': String(accessToken) } : undefined),
            ...(client != null ? { 'client': String(client) } : undefined),
            ...(uid != null ? { 'uid': String(uid) } : undefined),
        };

        return this.request<void>({
            url: '/api/v1/contract_templates/{id}'.replace('{id}', encodeURI(id)),
            method: 'DELETE',
            headers,
        }, opts?.responseOpts);
    };

    /**
     * Retrieve a ContractTemplate list
     */
    getContractTemplate({ accessToken, client, uid, sortBy, id, name, content, createdAt, updatedAt, contractType, deletedAt }: GetContractTemplateRequest): Observable<Array<ContractTemplate>>
    getContractTemplate({ accessToken, client, uid, sortBy, id, name, content, createdAt, updatedAt, contractType, deletedAt }: GetContractTemplateRequest, opts?: OperationOpts): Observable<RawAjaxResponse<Array<ContractTemplate>>>
    getContractTemplate({ accessToken, client, uid, sortBy, id, name, content, createdAt, updatedAt, contractType, deletedAt }: GetContractTemplateRequest, opts?: OperationOpts): Observable<Array<ContractTemplate> | RawAjaxResponse<Array<ContractTemplate>>> {
        throwIfNullOrUndefined(accessToken, 'accessToken', 'getContractTemplate');
        throwIfNullOrUndefined(client, 'client', 'getContractTemplate');
        throwIfNullOrUndefined(uid, 'uid', 'getContractTemplate');

        const headers: HttpHeaders = {
            ...(accessToken != null ? { 'access-token': String(accessToken) } : undefined),
            ...(client != null ? { 'client': String(client) } : undefined),
            ...(uid != null ? { 'uid': String(uid) } : undefined),
        };

        const query: HttpQuery = {};

        if (sortBy != null) { query['sort_by'] = sortBy; }
        if (id != null) { query['id'] = id; }
        if (name != null) { query['name'] = name; }
        if (content != null) { query['content'] = content; }
        if (createdAt != null) { query['created_at'] = (createdAt as any).toISOString(); }
        if (updatedAt != null) { query['updated_at'] = (updatedAt as any).toISOString(); }
        if (contractType != null) { query['contract_type'] = contractType; }
        if (deletedAt != null) { query['deleted_at'] = (deletedAt as any).toISOString(); }

        return this.request<Array<ContractTemplate>>({
            url: '/api/v1/contract_templates/',
            method: 'GET',
            headers,
            query,
        }, opts?.responseOpts);
    };

    /**
     * Retrieves a ContractTemplate
     */
    getContractTemplateById({ accessToken, client, uid, id }: GetContractTemplateByIdRequest): Observable<ContractTemplate>
    getContractTemplateById({ accessToken, client, uid, id }: GetContractTemplateByIdRequest, opts?: OperationOpts): Observable<RawAjaxResponse<ContractTemplate>>
    getContractTemplateById({ accessToken, client, uid, id }: GetContractTemplateByIdRequest, opts?: OperationOpts): Observable<ContractTemplate | RawAjaxResponse<ContractTemplate>> {
        throwIfNullOrUndefined(accessToken, 'accessToken', 'getContractTemplateById');
        throwIfNullOrUndefined(client, 'client', 'getContractTemplateById');
        throwIfNullOrUndefined(uid, 'uid', 'getContractTemplateById');
        throwIfNullOrUndefined(id, 'id', 'getContractTemplateById');

        const headers: HttpHeaders = {
            ...(accessToken != null ? { 'access-token': String(accessToken) } : undefined),
            ...(client != null ? { 'client': String(client) } : undefined),
            ...(uid != null ? { 'uid': String(uid) } : undefined),
        };

        return this.request<ContractTemplate>({
            url: '/api/v1/contract_templates/{id}'.replace('{id}', encodeURI(id)),
            method: 'GET',
            headers,
        }, opts?.responseOpts);
    };

    /**
     * Patch a ContractTemplate
     */
    patchContractTemplate({ accessToken, client, uid, id, contractTemplatePut }: PatchContractTemplateRequest): Observable<ContractTemplate>
    patchContractTemplate({ accessToken, client, uid, id, contractTemplatePut }: PatchContractTemplateRequest, opts?: OperationOpts): Observable<RawAjaxResponse<ContractTemplate>>
    patchContractTemplate({ accessToken, client, uid, id, contractTemplatePut }: PatchContractTemplateRequest, opts?: OperationOpts): Observable<ContractTemplate | RawAjaxResponse<ContractTemplate>> {
        throwIfNullOrUndefined(accessToken, 'accessToken', 'patchContractTemplate');
        throwIfNullOrUndefined(client, 'client', 'patchContractTemplate');
        throwIfNullOrUndefined(uid, 'uid', 'patchContractTemplate');
        throwIfNullOrUndefined(id, 'id', 'patchContractTemplate');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(accessToken != null ? { 'access-token': String(accessToken) } : undefined),
            ...(client != null ? { 'client': String(client) } : undefined),
            ...(uid != null ? { 'uid': String(uid) } : undefined),
        };

        return this.request<ContractTemplate>({
            url: '/api/v1/contract_templates/{id}'.replace('{id}', encodeURI(id)),
            method: 'PATCH',
            headers,
            body: contractTemplatePut,
        }, opts?.responseOpts);
    };

    /**
     * Update a ContractTemplate
     */
    updateContractTemplate({ accessToken, client, uid, id, contractTemplatePut }: UpdateContractTemplateRequest): Observable<ContractTemplate>
    updateContractTemplate({ accessToken, client, uid, id, contractTemplatePut }: UpdateContractTemplateRequest, opts?: OperationOpts): Observable<RawAjaxResponse<ContractTemplate>>
    updateContractTemplate({ accessToken, client, uid, id, contractTemplatePut }: UpdateContractTemplateRequest, opts?: OperationOpts): Observable<ContractTemplate | RawAjaxResponse<ContractTemplate>> {
        throwIfNullOrUndefined(accessToken, 'accessToken', 'updateContractTemplate');
        throwIfNullOrUndefined(client, 'client', 'updateContractTemplate');
        throwIfNullOrUndefined(uid, 'uid', 'updateContractTemplate');
        throwIfNullOrUndefined(id, 'id', 'updateContractTemplate');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(accessToken != null ? { 'access-token': String(accessToken) } : undefined),
            ...(client != null ? { 'client': String(client) } : undefined),
            ...(uid != null ? { 'uid': String(uid) } : undefined),
        };

        return this.request<ContractTemplate>({
            url: '/api/v1/contract_templates/{id}'.replace('{id}', encodeURI(id)),
            method: 'PUT',
            headers,
            body: contractTemplatePut,
        }, opts?.responseOpts);
    };

}

/**
 * @export
 * @enum {string}
 */
export enum GetContractTemplateSortByEnum {
    IdAsc = 'id_asc',
    IdDesc = 'id_desc',
    NameAsc = 'name_asc',
    NameDesc = 'name_desc',
    ContentAsc = 'content_asc',
    ContentDesc = 'content_desc',
    CreatedAtAsc = 'created_at_asc',
    CreatedAtDesc = 'created_at_desc',
    UpdatedAtAsc = 'updated_at_asc',
    UpdatedAtDesc = 'updated_at_desc',
    ContractTypeAsc = 'contract_type_asc',
    ContractTypeDesc = 'contract_type_desc',
    DeletedAtAsc = 'deleted_at_asc',
    DeletedAtDesc = 'deleted_at_desc'
}
