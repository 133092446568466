import React, { ReactElement } from "react";

/**
 * A noop-component rendering its children without any additional effects.
 */

interface TCNoopComponentProps {
  children: ReactElement;
}

export const TCNoopComponent: React.FunctionComponent<TCNoopComponentProps> = (
  props: TCNoopComponentProps
) => props.children;
