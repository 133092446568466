import { Box, InputLabel } from "@material-ui/core";
import React from "react";
import { useTCDateRangePickerStyles } from "../../dynamicFields/editFields/complex/TCEditFieldRange/TCEditFieldDateRange/TCEditFieldDateRangeStyles";
import { TCTimePicker } from "../TCTimePicker/TCTimePicker";
import { TCTimeRangePickerProps } from "./TCTimeRangePickerProps";

/**
 * Component wrapping two time pickers.
 */
export const TCTimeRangePicker: React.FunctionComponent<TCTimeRangePickerProps> = (
  props
) => {
  const classes = useTCDateRangePickerStyles();

  return (
    <Box className={classes.wrap}>
      {props.label && (
        <InputLabel className={classes.label}>{props.label}</InputLabel>
      )}

      <TCTimePicker
        className={classes.picker}
        value={props.leftValue}
        onChange={props.leftOnChange}
        {...props.leftPickerProps}
      />

      <span className={classes.untilMarker}>&mdash;</span>

      <TCTimePicker
        className={classes.picker}
        value={props.rightValue}
        onChange={props.rightOnChange}
        {...props.rightPickerProps}
      />
    </Box>
  );
};
