import {
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import { Field } from "formik";
import React, { Fragment, FunctionComponent, useEffect } from "react";
import { getFieldLabel } from "../../../../../helpers/dynamicFields/getFieldLabel";
import { fieldIsRequired } from "../../../../../helpers/forms/fieldIsRequired";
import { fieldValuesFromFormikContext } from "../../../../../helpers/forms/fieldValuesFromFormikContext";
import { updateFormikValue } from "../../../../../helpers/forms/updateFormikValue";
import { useTCFormikContext } from "../../../../../hooks/useTCFormikContext";
import { ValueListComponentProps } from "../../../../../interfaces/dynamicFieldInterfaces";

export const TCEditFieldRadioGroup: FunctionComponent<ValueListComponentProps> = (
  props
) => {
  const formikContext = useTCFormikContext();

  const {
    formikIdentifier,
    fieldData,
    fieldValue,
  } = fieldValuesFromFormikContext<string[]>(formikContext, props.field);

  const label = getFieldLabel(props.field);
  const isRequired = fieldIsRequired(props);
  const radioGroupDefaultValue =
    props.displayConfiguration.valueEditing?.defaultValue;

  const valueForRadioGroup = fieldValue[0]
    ? fieldValue[0]
    : radioGroupDefaultValue || "";

  useEffect(() => {
    if (!fieldValue[0] && radioGroupDefaultValue) {
      updateFormikValue(formikContext, formikIdentifier, {
        ...fieldData,
        value: [radioGroupDefaultValue],
      });
    }
  }, [
    radioGroupDefaultValue,
    fieldValue,
    fieldData,
    formikContext,
    formikIdentifier,
  ]);

  return (
    <Field name={props.fieldName}>
      {() => (
          <Fragment>
            {label && (
              <FormLabel required={isRequired} component="legend">
                {label}
              </FormLabel>
            )}

            <RadioGroup
              aria-label={label}
              value={valueForRadioGroup}
              onChange={(e) => {
                updateFormikValue(formikContext, formikIdentifier, {
                  ...fieldData,
                  value: [e.target.value],
                });
              }}
            >
              {props.options.map((option) => (
                <FormControlLabel
                  key={option}
                  value={option}
                  control={<Radio required={isRequired} />}
                  label={option}
                />
              ))}
            </RadioGroup>
          </Fragment>
        )}
    </Field>
  );
};
