import { useSnackbar } from "notistack";
import { useMemo } from "react";

export const useTCSnackbars = (): {
  successSnackbar: (message: string) => void;
  errorSnackbar: (message: string) => void;
} => {
  const { enqueueSnackbar } = useSnackbar();
  return useMemo(
    () => ({
      successSnackbar: (message: string) =>
        enqueueSnackbar(message, {
          variant: "success",
        }),
      errorSnackbar: (message: string) =>
        enqueueSnackbar(message, {
          variant: "error",
        }),
    }),
    [enqueueSnackbar]
  );
};
