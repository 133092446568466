import {
  CustomMuiTheme,
  TCAuthPageMuiProps,
} from "../../../interfaces/CustomMuiTheme";
import { TCTenantInformation } from "../../../interfaces/TCTenantInformation";
import "../../../resources/fonts/PlusJakartaSans/stylesheet.css";
import backgroundImageUrl from "../login-image.jpg";
import logoSvg from "../logo.svg";
import mobileLogoSvg from "../logoMobile.svg";
import welcomeSvg from "../welcomeCaresome.svg";

const tenantInformation: TCTenantInformation = {
  platformName: "Caresome",
  managingDirector: {
    firstName: "Felix",
    lastName: "Buchmüller",
    gender: "male",
  },
  dataProtectionOfficer: {
    firstName: "Felix",
    lastName: "Buchmüller",
    company: "NVON GmbH",
    gender: "male",
    contact: {
      email: "email@nvon.com",
      telephone: "+49 6131 6246560",
    },
    address: {
      city: "Mainz",
      postalCode: "55118",
      street: "Taunusstr.",
      streetNumber: "59-61",
    },
  },
  responsibleForContent: {
    firstName: "Felix",
    lastName: "Buchmüller",
    address: {
      city: "Mainz",
      postalCode: "55118",
      street: "Taunusstr.",
      streetNumber: "59-61",
    },
  },
  company: {
    name: "NVON GmbH",
    commercialRegisterIdentifier: "HRB 50583",
    commercialRegisterPlace: "Amtsgericht Mainz",
    seatCity: "Mainz",
    ustId: "DE 345354616",
  },
  address: {
    city: "Mainz",
    postalCode: "55118",
    street: "Taunusstr.",
    streetNumber: "59-61",
  },
  contact: {
    email: "email@nvon.com",
    telephone: "+49 6131 6246560",
  },
};

const loginScreen: TCAuthPageMuiProps = {
  headlineLeft: "Willkommen",
  heroSectionProps: {
    headline: "Kapazitäten und Schichten planen und vergeben",
    description:
      "Stellen Sie Ihre Kapazitäten ein und haben eine Übersicht über all Ihre besetzten und noch nicht besetzten Schichten.",
    backgroundImageUrl,
  },
};

export const tcCustom: CustomMuiTheme["tcCustom"] = {
  logoSvg,
  mobileLogoSvg,
  welcomeSvg,
  contractSigning: {
    helloSignClientId: "f3b55d77eedfff311fedc231ab0242e4",
    useContractSigning: true,
  },
  layout: {
    authSectionMaxWidth: 650,
    sidebarWidth: 70,
    headerHeight: 80,
    mobileBottomNavHeight: 80,
    messagesDrawerMaxWidth: 421,
  },
  workerName: "Pflegekraft",
  employerName: "Klinik",
  loginScreen,
  registerScreen: {
    worker: {
      ...loginScreen,
      headlineLeft: "Erhalten Sie vollautomatisch passende Stellen.",
    },
    employer: {
      ...loginScreen,
      headlineLeft:
        "Mit wenigen Klicks Pflegekraft finden, und die Lücke im Dienstplan füllen",
    },
  },
  shadows: {
    cardShadow: "rgba(130, 150, 185, 0.11) 0px 12px 18px 0px",
  },
  tenantInformation,
};
