/**
 * A Blob is almost a File - it's just missing a name and modified date,
 *  which this function will add.
 */
export const blobToFile = (
  blob: Blob,
  name: string,
  modifiedDate: Date = new Date()
): File => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const fileObject = blob as any;

  fileObject.lastModifiedDate = modifiedDate;
  fileObject.name = name;

  return fileObject as File;
};
