import React from "react";
import { valueFromPrimitive } from "../../../../helpers/dynamicFields/values/valueFromPrimitive";
import { TCFieldComponent } from "../../../../interfaces/dynamicFieldInterfaces";
import { TCLabeledFieldValue } from "../TCLabeledFieldValue/TCLabeledFieldValue";

export const TCDisplayFieldPrimitive: TCFieldComponent = (props) => {
  const value = props.initialValue || "";

  const valueToDisplay = valueFromPrimitive(value as string | number | boolean);

  return (
    <TCLabeledFieldValue
      value={valueToDisplay[0]}
      label={valueToDisplay[1] ? props.field.label : undefined}
      {...props}
    />
  );
};
