import clsx from "clsx";
import React, { Fragment } from "react";
import { validateFormGroupNatively } from "../../../helpers/forms/validateFormGroupNatively";
import { DisplayMode } from "../../../interfaces/dynamicFieldInterfaces";
import { TCBackwardButton } from "../../button/TCBackwardButton";
import { TCForwardButton } from "../../button/TCForwardButton";
import { TCFieldGroup } from "../../dynamicFields/TCFieldGroup/TCFieldGroup";
import { TCDialogStepGrid } from "../TCDialogStepGrid/TCDialogStepGrid";
import { createInfoSectionForGroup } from "../TCTabDialog/createInfoSectionForGroup";
import { TCDialogStepProps } from "./TCDialogStepProps";
import { useTCDialogStepStyles } from "./TCDialogStepStyles";

export const TCDialogStep: React.FunctionComponent<TCDialogStepProps> = (
  props
) => {
  const classes = useTCDialogStepStyles();
  const forwardButton = props.forwardHandler ? (
    <TCForwardButton
      className={classes.withTopMargin}
      onClick={async () => {
        if (validateFormGroupNatively(props.fieldGroup)) {
          props.forwardHandler?.();
        }
      }}
    />
  ) : null;
  return (
    <TCDialogStepGrid
      contentLeft={
        <Fragment>
          {createInfoSectionForGroup(props.fieldGroup.id)}

          {props.backwardHandler && (
            <TCBackwardButton
              className={clsx(classes.withTopMargin, classes.backwardButton)}
              onClick={props.backwardHandler}
            />
          )}
        </Fragment>
      }
      contentRight={
        <Fragment>
          <TCFieldGroup
            fieldGroupGridItemProps={{
              xs: 12,
            }}
            displayMode={DisplayMode.Input}
            fieldGroup={props.fieldGroup}
          />

          {forwardButton}

          {props.contentAfterInput && <props.contentAfterInput />}
        </Fragment>
      }
    />
  );
};
