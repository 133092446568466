import { FormikHelpers } from "formik";
import { useContext } from "react";
import { PurposeContext } from "../../../../appContext";
import { fieldValueBulkUpdateServices } from "../../../../dataHandling/services/fieldValueBulkUpdateServices";
import { createSubmitHandlerWithInitialValues } from "../../../../helpers/forms/createSubmitHandlerWithInitialValues";
import { extractInformationFromFormikSubmitValues } from "../../../../helpers/forms/extractInformationFromFormikValues";
import { initialValuesFromPurposeContext } from "../../../../helpers/forms/initialValuesFromPurposeContext";
import { validateFormGroupNatively } from "../../../../helpers/forms/validateFormGroupNatively";
import { useAuthenticationParameter } from "../../../../hooks/tamocApiHooks/authentication/useAuthenticationParameter";
import { useTCSnackbars } from "../../../../hooks/useTCSnackbars";
import { TCFormikValues } from "../../../../interfaces/formikInterfaces";
import {
  CustomFormikSubmitHandler,
  FormikSubmitHandler,
  FormikSubmitValues,
} from "../../../../interfaces/typeAliases";
import { TCFormikBulkUpdateForPurposeContextProps } from "./TCFormikBulkUpdateForPurposeContextProps";

interface TCFormikBulkUpdateForPurposeContextViewModel {
  submitHandler: FormikSubmitHandler;
  initialValues: TCFormikValues;
}

export const useTCFormikBulkUpdateForPurposeContextViewModel = (
  props: TCFormikBulkUpdateForPurposeContextProps
): TCFormikBulkUpdateForPurposeContextViewModel => {
  const purposeContext = useContext(PurposeContext);
  const initialValues = initialValuesFromPurposeContext(purposeContext);
  const { errorSnackbar, successSnackbar } = useTCSnackbars();
  const authenticationParameter = useAuthenticationParameter();

  let customFormikSubmitHandler: CustomFormikSubmitHandler | undefined =
    props.onSave;

  if (!customFormikSubmitHandler) {
    customFormikSubmitHandler = (
      values: FormikSubmitValues,
      form: FormikHelpers<TCFormikValues>
    ) => {
      if (!purposeContext) {
        return;
      }

      const updateInformation = props.bulkUpdateInformation;

      if (!purposeContext.fieldGroups.every(validateFormGroupNatively)) {
        return;
      }

      const snackbarSuccessMessage =
        updateInformation?.snackbarMessages?.success || `Angaben gespeichert ✓`;

      const snackbarErrorMessage =
        updateInformation?.snackbarMessages?.error ||
        `Speichern fehlgeschlagen.`;

      if (!purposeContext || !updateInformation) {
        return;
      }

      const dataToSave = extractInformationFromFormikSubmitValues(values);

      form.setSubmitting(true);

      const subscription = fieldValueBulkUpdateServices[
        updateInformation.parentableType
      ](
        authenticationParameter,
        updateInformation.parentableId.toString(),
        dataToSave,
        updateInformation.additionalProps
      ).subscribe(
        (newData) => {
          form.setSubmitting(false);
          successSnackbar(snackbarSuccessMessage);

          subscription.unsubscribe();

          if (updateInformation.onAfterSave) {
            updateInformation.onAfterSave(newData);
          }
        },
        (error) => {
          form.setSubmitting(false);
          errorSnackbar(snackbarErrorMessage);
          subscription.unsubscribe();

          if (updateInformation.onError) {
            updateInformation.onError(error);
          }
        }
      );
    };
  }

  return {
    submitHandler: createSubmitHandlerWithInitialValues(
      initialValues,
      customFormikSubmitHandler
    ),
    initialValues,
  };
};
