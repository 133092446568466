import { createStyles, makeStyles } from "@material-ui/core";
import { CustomMuiTheme } from "../../../../../../../interfaces/CustomMuiTheme";

export const useTCEditSingleShiftStyles = makeStyles((theme: CustomMuiTheme) =>
  createStyles({
    wrapper: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "stretch",
      alignItems: "center",
      marginBottom: theme.spacing(1),
    },
    name: {
      marginRight: theme.spacing(1),
      // pretty random value, just to avoid the box taking up too much space
      maxWidth: "150px",
    },
  })
);
