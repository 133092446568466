import { createStyles, makeStyles } from "@material-ui/core";
import { forceWidth } from "../../../helpers/styles/forceWidth";
import { fieldTopMargin } from "../../../helpers/styles/variables";
import { CustomMuiTheme } from "../../../interfaces/CustomMuiTheme";

const innerPadding = "4rem";

export const useTCDialogStepStyles = makeStyles((theme: CustomMuiTheme) =>
  createStyles({
    infoSection: {
      paddingRight: innerPadding,
      borderRight: `1px solid ${theme.palette.secondary.light}`,
      display: "flex",
      alignItems: "center",
      textAlign: "center",
      flexDirection: "column",
      [theme.breakpoints.down("xs")]: {
        paddingRight: 0,
        borderRight: "none",
        paddingBottom: theme.spacing(4),
      },
      [theme.breakpoints.down("sm")]: {
        "& > *": {
          maxWidth: "250px",
        },
      },
    },
    backwardButton: {
      [theme.breakpoints.down("xs")]: {
        display: "none",
      },
    },
    pullingDiv: {
      [theme.breakpoints.down("xs")]: {
        marginBottom: "-2.5rem",
      },
    },
    withTopMargin: {
      marginTop: fieldTopMargin(theme),
    },
    inputSection: {
      paddingLeft: innerPadding,
      paddingRight: theme.spacing(4),
      marginLeft: "auto",
      marginRight: "auto",
      [theme.breakpoints.down("xs")]: {
        marginTop: theme.spacing(3),
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
    welcomeSection: {
      maxWidth: "100%",
      "& button": {
        float: "right",
      },
      "& img": {
        ...forceWidth("100%"),
      },
    },
  })
);
