import { useCallback, useContext, useState } from "react";
import { PurposeContext, UserContext } from "../../../appContext";
import {
  EOnboardingStatus,
  EParentableType,
} from "../../../generated/api-service";
import { IPurposeContext } from "../../../interfaces/dynamicFieldInterfaces";
import { BulkUpdateInformation } from "../../dynamicFields/purpose/TCFormikBulkUpdateForPurposeContext/TCFormikBulkUpdateForPurposeContextProps";

interface TCOnboardingViewModel {
  isVisible: boolean;
  purposeContext: IPurposeContext;
  bulkUpdateInformation: BulkUpdateInformation;
}

export const useTCOnboardingViewModel = (): TCOnboardingViewModel | null => {
  const [user, setUser] = useContext(UserContext);
  const [isVisible, setIsVisible] = useState<boolean>(true);
  const purposeContext = useContext(PurposeContext);

  const onAfterSave = useCallback(() => {
    if (!user) {
      return;
    }

    setUser({
      ...user,
      onboardingStatus: EOnboardingStatus.Complete,
    });
    setIsVisible(false);
  }, [setUser, user]);

  const onError = useCallback(() => {
    setIsVisible(false);
  }, []);

  if (!user || !purposeContext) {
    return null;
  }

  const bulkUpdateInformation: BulkUpdateInformation = {
    parentableId: user.id,
    parentableType: EParentableType.User,
    additionalProps: {
      onboardingStatus: EOnboardingStatus.Complete,
    },
    onAfterSave,
    onError,
  };

  return { bulkUpdateInformation, isVisible, purposeContext };
};
