/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */

import clsx from "clsx";
import { merge } from "lodash";

export const mergeClassNameIntoDisplayProp = (
  displayProp: any,
  className: string
): any => {
  const mergedDisplayProp = merge({}, displayProp);
  mergedDisplayProp.className = clsx(mergedDisplayProp.className, className);
  return mergedDisplayProp;
};
