import useSWR from "swr";
import { fieldValueApi } from "../../dataHandling/autogeneratedApiServices";
import { FieldValue, GetFieldValueRequest } from "../../generated/api-service";
import { getUniqueSwrKeyFromParameter } from "../../helpers/getUniqueSwrKeyFromParameter";
import { WithoutAuthentication } from "../../interfaces/authentication";
import { BasicSwrInterface } from "../../interfaces/basicSwrInterface";
import { useParameterWithAuthentication } from "./authentication/useParameterWithAuthentication";

export const useFieldValuesSwr = (
  requestParameters: WithoutAuthentication<GetFieldValueRequest>
): BasicSwrInterface<FieldValue[]> => {
  const parameters = useParameterWithAuthentication(requestParameters);

  const fetcher = () => fieldValueApi.getFieldValue(parameters).toPromise();

  const key = getUniqueSwrKeyFromParameter(["fieldValueApi", "getFieldValue"], {
    ...parameters,
  });

  const { data, error } = useSWR(key, fetcher);

  return {
    result: data,
    isLoading: !error && !data,
    isError: error,
  };
};
