import { createStyles, makeStyles } from "@material-ui/core";
import { CustomMuiTheme } from "../../../../../interfaces/CustomMuiTheme";

export const useTCDocumentListItemStyles = makeStyles((theme: CustomMuiTheme) =>
  createStyles({
    linkWrapper: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
    },
    fileNameWrapper: {
      flex: "1",
      display: "flex",
      alignContent: "center",
    },
    fileNameButton: {
      textAlign: "left",
    },
    controls: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifySelf: "flex-end",
    },
    deleteIcon: {
      marginLeft: theme.spacing(3),
      cursor: "pointer",
    },
  })
);
