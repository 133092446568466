import { createStyles, makeStyles } from "@material-ui/core";

export const useTCConversationTitleStyles = makeStyles(() =>
  createStyles({
    root: {
      display: "flex",
      alignItems: "center",
    },
    avatar: {
      marginRight: 12,
      marginLeft: 12,
    },
  })
);
