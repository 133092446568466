import { useState } from "react";
import { TCUser } from "../interfaces/typeAliases";

if (!window.localStorage) {
  throw Error(`Browser does not support localStorage`);
}

export enum LocalStorageKey {
  User = "user",
}

export type DataTypeForLocalStorageKey<
  T extends LocalStorageKey
> = T extends LocalStorageKey.User ? TCUser : unknown;

export function useLocalStorage<T extends LocalStorageKey>(
  key: T
): [
  DataTypeForLocalStorageKey<T> | undefined,
  (newValue: DataTypeForLocalStorageKey<T> | undefined) => void
] {
  const [storedValue, setStoredValue] = useState<
    DataTypeForLocalStorageKey<T> | undefined
  >(() => {
    const item = window.localStorage.getItem(key);
    return item ? JSON.parse(item) : undefined;
  });

  const setValue = (value: DataTypeForLocalStorageKey<T> | undefined): void => {
    if (!value) {
      window.localStorage.removeItem(key);
      setStoredValue(value);
    } else {
      setStoredValue(value);
      window.localStorage.setItem(key, JSON.stringify(value));
    }
  };

  return [storedValue, setValue];
}
