import { isEqual } from "lodash";
import { EFieldTypes } from "../../generated/api-service";
import {
  TCFormikFieldData,
  TCFormikValues,
} from "../../interfaces/formikInterfaces";
import { FormikSubmitValues } from "../../interfaces/typeAliases";

export function getChangedFormFields(
  values: FormikSubmitValues
): TCFormikValues {
  /**
   * For the time shifts, this does not work.
   * The default value will not pass this function, and therefore not be saved.
   * This is what makes the hard comparison to the field type necessary.
   */
  return Object.entries(values.current).reduce(
    (result, [fieldName, field]: [string, TCFormikFieldData]) => isEqual(field, values.initial[fieldName]) &&
        field.field.fieldType.name !== EFieldTypes.WorkingShiftsPattern
        ? result
        : {
            ...result,
            [fieldName]: field,
          },
    {}
  );
}
