import { createStyles, IconButton, IconButtonProps } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import React from "react";
import { extendElementWithDefaults } from "../../helpers/extendElementWithDefaults";
import { CustomMuiTheme } from "../../interfaces/CustomMuiTheme";

export const TCCloseButton = extendElementWithDefaults({
  baseElement: IconButton,
  defaults: {
    children: <CloseIcon />,
    color: "primary",
  },
  makeStylesInput: (theme: CustomMuiTheme) => {
    const marginForSize = ({ size }: { size?: IconButtonProps["size"] }) =>
      size === "small" ? 5 : 20;
    return createStyles({
      clearButton: {
        position: "absolute" as const,
        right: marginForSize,
        top: marginForSize,
        color: theme.palette.grey[900],
      },
    });
  },
});
