import { Grid } from "@material-ui/core";
import { merge, omit } from "lodash";
import React, { Fragment } from "react";
import { TCFieldGroupHeader } from "./TCFieldGroupHeader/TCFieldGroupHeader";
import {
  TCFieldGroupHeaderStyle,
  TCFieldGroupProps,
} from "./TCFieldGroupProps";
import { TCFieldGroupRenderedFields } from "./TCFieldGroupRenderedFields/TCFieldGroupRenderedFields";
import { useTCFieldGroupViewModel } from "./TCFieldGroupViewModel";

const defaultProps: Partial<TCFieldGroupProps> = {
  headerStyle: TCFieldGroupHeaderStyle.None,
};

export const TCFieldGroup: React.FunctionComponent<TCFieldGroupProps> = (
  passedProps
) => {
  const props = merge({}, defaultProps, passedProps);
  const viewModel = useTCFieldGroupViewModel(props);

  return (
    <Fragment>
      {viewModel.titleFields &&
        props.headerStyle === TCFieldGroupHeaderStyle.Complex && (
          <TCFieldGroupHeader
            headerStyle={TCFieldGroupHeaderStyle.Complex}
            titleFields={viewModel.titleFields}
            subTitleFields={viewModel.subTitleFields}
            {...omit(props, "headerStyle")}
          />
        )}

      {viewModel.titleFields &&
        props.headerStyle === TCFieldGroupHeaderStyle.TitleOnly && (
          <TCFieldGroupHeader
            headerStyle={TCFieldGroupHeaderStyle.TitleOnly}
            titleField={viewModel.titleFields[0]}
            {...omit(props, "headerStyle")}
          />
        )}

      <Grid container={true} spacing={1}>
        <TCFieldGroupRenderedFields
          fields={viewModel.restOfFields}
          {...props}
        />
      </Grid>
    </Fragment>
  );
};
