// tslint:disable
/**
 * TaMoC API V1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs';
import {
    Contract,
    ContractPost,
    ContractRendered,
    EContractParentableType,
    EContractStatus
} from '../models';
import { BaseAPI, encodeURI, HttpHeaders, HttpQuery, OperationOpts, RawAjaxResponse, throwIfNullOrUndefined } from '../runtime';

export interface CreateContractRequest {
    accessToken: string;
    client: string;
    uid: string;
    contractPost?: ContractPost;
}

export interface DeleteContractRequest {
    accessToken: string;
    client: string;
    uid: string;
    id: number;
}

export interface DownloadHelloSignFilesRequest {
    accessToken: string;
    client: string;
    uid: string;
    id: number;
}

export interface GetContractRequest {
    accessToken: string;
    client: string;
    uid: string;
    sortBy?: GetContractSortByEnum;
    id?: number | string;
    contractTemplateId?: number | string;
    contractParentableId?: number | string;
    contractParentableType?: EContractParentableType;
    contractStatus?: EContractStatus;
    renderedContract?: string;
    helloSignRequestId?: string;
    createdAt?: string;
    updatedAt?: string;
    deletedAt?: string;
}

export interface GetContractByIdRequest {
    accessToken: string;
    client: string;
    uid: string;
    id: number;
}

export interface ReRenderContractRequest {
    accessToken: string;
    client: string;
    uid: string;
    contractId: number;
}

export interface RecreateSignatureRequestsRequest {
    accessToken: string;
    client: string;
    uid: string;
    contractId: number;
}

export interface RenderContractAsPDFRequest {
    accessToken: string;
    client: string;
    uid: string;
    contractId: number;
}

export interface RenderContractAsTextRequest {
    accessToken: string;
    client: string;
    uid: string;
    contractId: number;
}

/**
 * no description
 */
export class ContractApi extends BaseAPI {

    /**
     * Create Contract
     */
    createContract({ accessToken, client, uid, contractPost }: CreateContractRequest): Observable<Contract>
    createContract({ accessToken, client, uid, contractPost }: CreateContractRequest, opts?: OperationOpts): Observable<RawAjaxResponse<Contract>>
    createContract({ accessToken, client, uid, contractPost }: CreateContractRequest, opts?: OperationOpts): Observable<Contract | RawAjaxResponse<Contract>> {
        throwIfNullOrUndefined(accessToken, 'accessToken', 'createContract');
        throwIfNullOrUndefined(client, 'client', 'createContract');
        throwIfNullOrUndefined(uid, 'uid', 'createContract');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(accessToken != null ? { 'access-token': String(accessToken) } : undefined),
            ...(client != null ? { 'client': String(client) } : undefined),
            ...(uid != null ? { 'uid': String(uid) } : undefined),
        };

        return this.request<Contract>({
            url: '/api/v1/contracts/',
            method: 'POST',
            headers,
            body: contractPost,
        }, opts?.responseOpts);
    };

    /**
     * Deletes a Contract
     */
    deleteContract({ accessToken, client, uid, id }: DeleteContractRequest): Observable<void>
    deleteContract({ accessToken, client, uid, id }: DeleteContractRequest, opts?: OperationOpts): Observable<void | RawAjaxResponse<void>>
    deleteContract({ accessToken, client, uid, id }: DeleteContractRequest, opts?: OperationOpts): Observable<void | RawAjaxResponse<void>> {
        throwIfNullOrUndefined(accessToken, 'accessToken', 'deleteContract');
        throwIfNullOrUndefined(client, 'client', 'deleteContract');
        throwIfNullOrUndefined(uid, 'uid', 'deleteContract');
        throwIfNullOrUndefined(id, 'id', 'deleteContract');

        const headers: HttpHeaders = {
            ...(accessToken != null ? { 'access-token': String(accessToken) } : undefined),
            ...(client != null ? { 'client': String(client) } : undefined),
            ...(uid != null ? { 'uid': String(uid) } : undefined),
        };

        return this.request<void>({
            url: '/api/v1/contracts/{id}'.replace('{id}', encodeURI(id)),
            method: 'DELETE',
            headers,
        }, opts?.responseOpts);
    };

    /**
     * Download Hello Sign files
     */
    downloadHelloSignFiles({ accessToken, client, uid, id }: DownloadHelloSignFilesRequest): Observable<Contract>
    downloadHelloSignFiles({ accessToken, client, uid, id }: DownloadHelloSignFilesRequest, opts?: OperationOpts): Observable<RawAjaxResponse<Contract>>
    downloadHelloSignFiles({ accessToken, client, uid, id }: DownloadHelloSignFilesRequest, opts?: OperationOpts): Observable<Contract | RawAjaxResponse<Contract>> {
        throwIfNullOrUndefined(accessToken, 'accessToken', 'downloadHelloSignFiles');
        throwIfNullOrUndefined(client, 'client', 'downloadHelloSignFiles');
        throwIfNullOrUndefined(uid, 'uid', 'downloadHelloSignFiles');
        throwIfNullOrUndefined(id, 'id', 'downloadHelloSignFiles');

        const headers: HttpHeaders = {
            ...(accessToken != null ? { 'access-token': String(accessToken) } : undefined),
            ...(client != null ? { 'client': String(client) } : undefined),
            ...(uid != null ? { 'uid': String(uid) } : undefined),
        };

        return this.request<Contract>({
            url: '/api/v1/contracts/{id}/download_hello_sign_files'.replace('{id}', encodeURI(id)),
            method: 'PATCH',
            headers,
        }, opts?.responseOpts);
    };

    /**
     * Retrieve a Contract list
     */
    getContract({ accessToken, client, uid, sortBy, id, contractTemplateId, contractParentableId, contractParentableType, contractStatus, renderedContract, helloSignRequestId, createdAt, updatedAt, deletedAt }: GetContractRequest): Observable<Array<Contract>>
    getContract({ accessToken, client, uid, sortBy, id, contractTemplateId, contractParentableId, contractParentableType, contractStatus, renderedContract, helloSignRequestId, createdAt, updatedAt, deletedAt }: GetContractRequest, opts?: OperationOpts): Observable<RawAjaxResponse<Array<Contract>>>
    getContract({ accessToken, client, uid, sortBy, id, contractTemplateId, contractParentableId, contractParentableType, contractStatus, renderedContract, helloSignRequestId, createdAt, updatedAt, deletedAt }: GetContractRequest, opts?: OperationOpts): Observable<Array<Contract> | RawAjaxResponse<Array<Contract>>> {
        throwIfNullOrUndefined(accessToken, 'accessToken', 'getContract');
        throwIfNullOrUndefined(client, 'client', 'getContract');
        throwIfNullOrUndefined(uid, 'uid', 'getContract');

        const headers: HttpHeaders = {
            ...(accessToken != null ? { 'access-token': String(accessToken) } : undefined),
            ...(client != null ? { 'client': String(client) } : undefined),
            ...(uid != null ? { 'uid': String(uid) } : undefined),
        };

        const query: HttpQuery = {};

        if (sortBy != null) { query['sort_by'] = sortBy; }
        if (id != null) { query['id'] = id; }
        if (contractTemplateId != null) { query['contract_template_id'] = contractTemplateId; }
        if (contractParentableId != null) { query['contract_parentable_id'] = contractParentableId; }
        if (contractParentableType != null) { query['contract_parentable_type'] = contractParentableType; }
        if (contractStatus != null) { query['contract_status'] = contractStatus; }
        if (renderedContract != null) { query['rendered_contract'] = renderedContract; }
        if (helloSignRequestId != null) { query['hello_sign_request_id'] = helloSignRequestId; }
        if (createdAt != null) { query['created_at'] = (createdAt as any).toISOString(); }
        if (updatedAt != null) { query['updated_at'] = (updatedAt as any).toISOString(); }
        if (deletedAt != null) { query['deleted_at'] = (deletedAt as any).toISOString(); }

        return this.request<Array<Contract>>({
            url: '/api/v1/contracts/',
            method: 'GET',
            headers,
            query,
        }, opts?.responseOpts);
    };

    /**
     * Retrieves a Contract
     */
    getContractById({ accessToken, client, uid, id }: GetContractByIdRequest): Observable<Contract>
    getContractById({ accessToken, client, uid, id }: GetContractByIdRequest, opts?: OperationOpts): Observable<RawAjaxResponse<Contract>>
    getContractById({ accessToken, client, uid, id }: GetContractByIdRequest, opts?: OperationOpts): Observable<Contract | RawAjaxResponse<Contract>> {
        throwIfNullOrUndefined(accessToken, 'accessToken', 'getContractById');
        throwIfNullOrUndefined(client, 'client', 'getContractById');
        throwIfNullOrUndefined(uid, 'uid', 'getContractById');
        throwIfNullOrUndefined(id, 'id', 'getContractById');

        const headers: HttpHeaders = {
            ...(accessToken != null ? { 'access-token': String(accessToken) } : undefined),
            ...(client != null ? { 'client': String(client) } : undefined),
            ...(uid != null ? { 'uid': String(uid) } : undefined),
        };

        return this.request<Contract>({
            url: '/api/v1/contracts/{id}'.replace('{id}', encodeURI(id)),
            method: 'GET',
            headers,
        }, opts?.responseOpts);
    };

    /**
     * Re render contract
     */
    reRenderContract({ accessToken, client, uid, contractId }: ReRenderContractRequest): Observable<Contract>
    reRenderContract({ accessToken, client, uid, contractId }: ReRenderContractRequest, opts?: OperationOpts): Observable<RawAjaxResponse<Contract>>
    reRenderContract({ accessToken, client, uid, contractId }: ReRenderContractRequest, opts?: OperationOpts): Observable<Contract | RawAjaxResponse<Contract>> {
        throwIfNullOrUndefined(accessToken, 'accessToken', 'reRenderContract');
        throwIfNullOrUndefined(client, 'client', 'reRenderContract');
        throwIfNullOrUndefined(uid, 'uid', 'reRenderContract');
        throwIfNullOrUndefined(contractId, 'contractId', 'reRenderContract');

        const headers: HttpHeaders = {
            ...(accessToken != null ? { 'access-token': String(accessToken) } : undefined),
            ...(client != null ? { 'client': String(client) } : undefined),
            ...(uid != null ? { 'uid': String(uid) } : undefined),
        };

        return this.request<Contract>({
            url: '/api/v1/contracts/{contract_id}/re_render'.replace('{contract_id}', encodeURI(contractId)),
            method: 'PATCH',
            headers,
        }, opts?.responseOpts);
    };

    /**
     * Recreate signature requests
     */
    recreateSignatureRequests({ accessToken, client, uid, contractId }: RecreateSignatureRequestsRequest): Observable<Contract>
    recreateSignatureRequests({ accessToken, client, uid, contractId }: RecreateSignatureRequestsRequest, opts?: OperationOpts): Observable<RawAjaxResponse<Contract>>
    recreateSignatureRequests({ accessToken, client, uid, contractId }: RecreateSignatureRequestsRequest, opts?: OperationOpts): Observable<Contract | RawAjaxResponse<Contract>> {
        throwIfNullOrUndefined(accessToken, 'accessToken', 'recreateSignatureRequests');
        throwIfNullOrUndefined(client, 'client', 'recreateSignatureRequests');
        throwIfNullOrUndefined(uid, 'uid', 'recreateSignatureRequests');
        throwIfNullOrUndefined(contractId, 'contractId', 'recreateSignatureRequests');

        const headers: HttpHeaders = {
            ...(accessToken != null ? { 'access-token': String(accessToken) } : undefined),
            ...(client != null ? { 'client': String(client) } : undefined),
            ...(uid != null ? { 'uid': String(uid) } : undefined),
        };

        return this.request<Contract>({
            url: '/api/v1/contracts/{contract_id}/recreate_signature_requests'.replace('{contract_id}', encodeURI(contractId)),
            method: 'PATCH',
            headers,
        }, opts?.responseOpts);
    };

    /**
     * Filled out PDF contract from specified contract
     */
    renderContractAsPDF({ accessToken, client, uid, contractId }: RenderContractAsPDFRequest): Observable<Blob>
    renderContractAsPDF({ accessToken, client, uid, contractId }: RenderContractAsPDFRequest, opts?: OperationOpts): Observable<RawAjaxResponse<Blob>>
    renderContractAsPDF({ accessToken, client, uid, contractId }: RenderContractAsPDFRequest, opts?: OperationOpts): Observable<Blob | RawAjaxResponse<Blob>> {
        throwIfNullOrUndefined(accessToken, 'accessToken', 'renderContractAsPDF');
        throwIfNullOrUndefined(client, 'client', 'renderContractAsPDF');
        throwIfNullOrUndefined(uid, 'uid', 'renderContractAsPDF');
        throwIfNullOrUndefined(contractId, 'contractId', 'renderContractAsPDF');

        const headers: HttpHeaders = {
            ...(accessToken != null ? { 'access-token': String(accessToken) } : undefined),
            ...(client != null ? { 'client': String(client) } : undefined),
            ...(uid != null ? { 'uid': String(uid) } : undefined),
        };

        return this.request<Blob>({
            url: '/api/v1/contracts/{contract_id}/as_pdf'.replace('{contract_id}', encodeURI(contractId)),
            method: 'GET',
            headers,
            responseType: 'blob',
        }, opts?.responseOpts);
    };

    /**
     * Filled out contract from specified contract
     */
    renderContractAsText({ accessToken, client, uid, contractId }: RenderContractAsTextRequest): Observable<ContractRendered>
    renderContractAsText({ accessToken, client, uid, contractId }: RenderContractAsTextRequest, opts?: OperationOpts): Observable<RawAjaxResponse<ContractRendered>>
    renderContractAsText({ accessToken, client, uid, contractId }: RenderContractAsTextRequest, opts?: OperationOpts): Observable<ContractRendered | RawAjaxResponse<ContractRendered>> {
        throwIfNullOrUndefined(accessToken, 'accessToken', 'renderContractAsText');
        throwIfNullOrUndefined(client, 'client', 'renderContractAsText');
        throwIfNullOrUndefined(uid, 'uid', 'renderContractAsText');
        throwIfNullOrUndefined(contractId, 'contractId', 'renderContractAsText');

        const headers: HttpHeaders = {
            ...(accessToken != null ? { 'access-token': String(accessToken) } : undefined),
            ...(client != null ? { 'client': String(client) } : undefined),
            ...(uid != null ? { 'uid': String(uid) } : undefined),
        };

        return this.request<ContractRendered>({
            url: '/api/v1/contracts/{contract_id}/as_text'.replace('{contract_id}', encodeURI(contractId)),
            method: 'GET',
            headers,
        }, opts?.responseOpts);
    };

}

/**
 * @export
 * @enum {string}
 */
export enum GetContractSortByEnum {
    IdAsc = 'id_asc',
    IdDesc = 'id_desc',
    ContractTemplateIdAsc = 'contract_template_id_asc',
    ContractTemplateIdDesc = 'contract_template_id_desc',
    ContractParentableIdAsc = 'contract_parentable_id_asc',
    ContractParentableIdDesc = 'contract_parentable_id_desc',
    ContractParentableTypeAsc = 'contract_parentable_type_asc',
    ContractParentableTypeDesc = 'contract_parentable_type_desc',
    ContractStatusAsc = 'contract_status_asc',
    ContractStatusDesc = 'contract_status_desc',
    RenderedContractAsc = 'rendered_contract_asc',
    RenderedContractDesc = 'rendered_contract_desc',
    HelloSignRequestIdAsc = 'hello_sign_request_id_asc',
    HelloSignRequestIdDesc = 'hello_sign_request_id_desc',
    CreatedAtAsc = 'created_at_asc',
    CreatedAtDesc = 'created_at_desc',
    UpdatedAtAsc = 'updated_at_asc',
    UpdatedAtDesc = 'updated_at_desc',
    DeletedAtAsc = 'deleted_at_asc',
    DeletedAtDesc = 'deleted_at_desc'
}
