import React, { ReactElement } from "react";
import { TCIconProps } from "./TCIconProps";
import { useTCIconStyles } from "./TCIconStyles";

const styles = {
  small: {
    fontSize: 12,
  },
  default: {
    fontSize: 18,
  },
  medium: {
    fontSize: 20,
  },
  normal: {
    fontSize: 24,
  },
  big: {
    fontSize: 30,
  },
};

export const TCIcon = (props: TCIconProps): ReactElement => {
  const {
    background,
    className,
    fill,
    iconName,
    onClick,
    size = "default",
    style,
    variant = "default",
  } = props;
  const classes = useTCIconStyles(props);

  const handleClick = (e) => {
    if (onClick === undefined) {
      return;
    }

    onClick(e);
  };

  if (variant === "circle") {
    return (
      <span
        onClick={handleClick}
        className={[classes.circled, `tio-${iconName}`].join(" ")}
        style={{ ...styles[size], ...style }}
      />
    );
  }

  return (
    <span
      onClick={handleClick}
      className={`${className} tio-${iconName}`}
      style={{ background, color: fill, ...styles[size], ...style }}
    />
  );
};
