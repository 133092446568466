import { DisplayConfigurationDisplayConditions } from "../../../generated/api-service";
import { FieldValuableMap } from "../../../interfaces/dynamicFieldInterfaces";
import { displayConditionIsNotMetWithGivenValue } from "./displayConditionIsNotMetWithGivenValues";
import { fieldIsEmpty } from "./fieldIsEmpty";

/**
 * The 'related' displayConfiguration option allows us to render fields
 *  conditionally.
 * This function checks all conditions for a single related field.
 *
 * @returns true if some condition is NOT met
 */
export function relatedFieldIsNotMatchingItsConditionsWithGivenValue(
  relatedConfig: DisplayConfigurationDisplayConditions,
  valuableMap?: FieldValuableMap
): boolean {
  const relatedFieldValue = valuableMap?.[relatedConfig.fieldId];
  if (!relatedFieldValue) {
    return true;
  }

  // Case 1 – simple scenario:
  // Only a fieldId is provided. Check if the related field is not empty.
  if (!relatedConfig.condition) {
    return fieldIsEmpty(relatedFieldValue);
  }

  // Case 2 – more elaborate display conditions are provided
  const displayConditionIsNotMet = (condition) =>
    displayConditionIsNotMetWithGivenValue(condition, relatedFieldValue);

  return relatedConfig.condition.some(displayConditionIsNotMet);
}
