import {
  DisplayConfigurationCondition,
  DisplayConfigurationConditionConditionTypeEnum,
  DisplayConfigurationConditionOperatorEnum,
  FieldValueValuable,
} from "../../../generated/api-service";

interface ComparisonFunction {
  (
    valueOne: FieldValueValuable["value"],
    valueTow: FieldValueValuable["value"]
  ): boolean;
}

/**
 * Each operator (equals, notEquals, …) is mapped to a function comparing two values.
 * The values are passed as parsed JavaScript values (NOT as JSON strings).
 */
const comparisonFunctionsForOperators: {
  [key in DisplayConfigurationConditionOperatorEnum]: ComparisonFunction;
} = {
  [DisplayConfigurationConditionOperatorEnum.Equals]: (
    valueOne: FieldValueValuable["value"],
    valueTwo: FieldValueValuable["value"]
  ) => JSON.stringify(valueOne) === JSON.stringify(valueTwo),
  [DisplayConfigurationConditionOperatorEnum.NotEquals]: (
    valueOne: FieldValueValuable["value"],
    valueTwo: FieldValueValuable["value"]
  ) => JSON.stringify(valueOne) !== JSON.stringify(valueTwo),
};

export function displayConditionIsNotMetWithGivenValue(
  condition: DisplayConfigurationCondition,
  relatedFieldValue: FieldValueValuable["value"]
): boolean {
  checkForIllegalArguments(condition);
  const comparisonValue = JSON.parse(condition.comparisonValue);
  return !comparisonFunctionsForOperators[condition.operator](
    relatedFieldValue,
    comparisonValue
  );
}

function checkForIllegalArguments(
  condition: DisplayConfigurationCondition
): void | never {
  if (
    condition.conditionType !==
    DisplayConfigurationConditionConditionTypeEnum.And
  ) {
    throw Error(
      `Currently, only 'AND' conditions are supported for related fields.`
    );
  }
}
