import { useCallback, useState } from "react";

interface FieldValueData {
  onChange: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  onBlur: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  value: string;
}

/**
 * Hook for handling field values.
 * The onChange event will only update a local copy of the value,
 *  while onBlur will trigger a custom callback provided to the hook.
 *
 * @param updateFn will be called on blur with the new value
 * @param initialValue initial value for the local state
 */
export const useLocalFieldValue = (
  updateFn: (newValue: string) => void,
  initialValue: string
): FieldValueData => {
  const [localValue, setLocalValue] = useState<string>(initialValue);

  const onChange = useCallback(
    (event: Parameters<FieldValueData["onChange"]>[0]) =>
      setLocalValue(event.target.value),
    []
  );

  const onBlur = useCallback(
    (event: Parameters<FieldValueData["onBlur"]>[0]) => {
      const newValue = event.target.value;
      setLocalValue(newValue);
      updateFn(newValue);
    },
    [updateFn]
  );

  return {
    onChange,
    onBlur,
    value: localValue,
  };
};
