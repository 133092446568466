import { AlertType, FileObject } from "material-ui-dropzone";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { TCEditFieldFileDropzoneProps } from "./TCEditFieldFileDropzoneProps";

interface TCEditFieldFileDropzoneViewModelProps {
  onDropzoneAdd: (files: FileObject[]) => void;
  onDropzoneDelete: (file: FileObject, index: number) => void;
  onAlert: (message: string, variant: AlertType) => void;
  uploadedFiles: FileObject[];
}

const formatDropzoneMessage = (msg: string): string | JSX.Element => {
  const messages = msg.split(/(?=Datei)/g);

  return messages.length > 1 ? (
    <ul>
      {messages.map((message) => (
        <li key={message}>{message}</li>
      ))}
    </ul>
  ) : (
    msg
  );
};

export const useTCEditFieldFileDropzoneViewModel = (
  props: TCEditFieldFileDropzoneProps
): TCEditFieldFileDropzoneViewModelProps => {
  const [uploadedFiles, setUploadedFiles] = useState<FileObject[]>([]);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setUploadedFiles(props.uploadedFiles);
  }, [props.uploadedFiles]);

  const onDropzoneDelete = (file: FileObject, index: number) => {
    const dropZoneFiles = uploadedFiles.filter((f, idx) => idx !== index);
    setUploadedFiles(dropZoneFiles);
    props.onChangeOfUploadedFiles(dropZoneFiles);
  };

  const onDropzoneAdd = (files: FileObject[]) => {
    const newUploadedFilesState = ([] as FileObject[]).concat(
      uploadedFiles,
      files
    );
    setUploadedFiles(newUploadedFilesState);
    props.onChangeOfUploadedFiles(newUploadedFilesState);
  };

  const onAlert = (msg: string, variant: AlertType) => {
    let textToShow;
    if (variant === "success") {
      textToShow = formatDropzoneMessage(msg);
    }
    enqueueSnackbar(textToShow || msg, { variant });
  };

  return { onDropzoneDelete, onDropzoneAdd, onAlert, uploadedFiles };
};
