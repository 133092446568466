import { Button, IconButton } from "@material-ui/core";
import { ReactComponent as CloudUploadIcon } from "@iconset/cloud.upload.svg";
import React, { Fragment } from "react";
import { ValidationFile } from "../../../../../generated/api-service";
import { megaByteToByte } from "../../../../../helpers/file/fileSizes";
import { useTCFormikContext } from "../../../../../hooks/useTCFormikContext";
import { TCFieldComponent } from "../../../../../interfaces/dynamicFieldInterfaces";
import { TCDialog } from "../../../../dialogs/TCDialog/TCDialog";
import { TCEditFieldFileCurrentState } from "./TCEditFieldFileCurrentState/TCEditFieldFileCurrentState";
import { TCEditFieldFileDropzone } from "./TCEditFieldFileDropzone/TCEditFieldFileDropzone";
import { useTCFieldFileViewModel } from "./TCEditFieldFileViewModel";
import { useTCEditFieldFieldStyles } from "./TCEditFieldFileStyles";

export const TCEditFieldFile: TCFieldComponent = (props) => {
  const formikContext = useTCFormikContext();

  const viewModel = useTCFieldFileViewModel(props, formikContext);

  const classes = useTCEditFieldFieldStyles();

  if (!viewModel) {
    return null;
  }

  const label = `${props.field.label} hochladen`;

  const currentStateOfFileField = (displayedInDialog: boolean) => {
    const mappedFiles =
      viewModel.dropZoneFiles.map((fileObject) => fileObject.file) || [];

    return (
      <TCEditFieldFileCurrentState
        field={props.field}
        displayedInModal={displayedInDialog}
        documents={viewModel.formikFieldData.initialFiles || []}
        files={mappedFiles}
        onFileDelete={viewModel.onListFileDelete}
        onDocumentDelete={viewModel.onDocumentDelete}
      />
    );
  };

  const maxFileSize = (props.field.fieldType.validationSchema as ValidationFile)
    ?.maxFileSize;
  const maxFileSizeInByte = maxFileSize
    ? megaByteToByte(maxFileSize)
    : undefined;

  const dropzone = (
    <Fragment>
      <TCEditFieldFileDropzone
        uploadedFiles={viewModel.dropZoneFiles}
        onChangeOfUploadedFiles={viewModel.onChangeOfUploadedFiles}
        maxFileSize={maxFileSizeInByte}
        allowedMimeTypes={
          (props.field.fieldType.validationSchema as ValidationFile)
            ?.allowedMimeTypes || []
        }
      />

      {currentStateOfFileField(true)}
    </Fragment>
  );

  return viewModel.showInDialog ? (
    <Fragment>
      {currentStateOfFileField(false)}

      <Button
        onClick={viewModel.showUploadDialog}
        endIcon={
          <IconButton className={classes.IconFill}>
            <CloudUploadIcon />
          </IconButton>
        }
        color="primary"
        className={classes.ButtonRightAlign}
      >
        {label}
      </Button>

      <TCDialog
        onClose={viewModel.hideUploadDialog}
        open={viewModel.isUploadDialogVisible}
        title={label}
      >
        {dropzone}
      </TCDialog>
    </Fragment>
  ) : (
    dropzone
  );
};
