export function clearAuthTokens(): void {
  window.localStorage.removeItem("access-token");
  window.localStorage.removeItem("client");
  window.localStorage.removeItem("uid");
}
export function setAuthTokens(headers: {
  client: string;
  uid: number | string;
  "access-token": string;
}): void {
  window.localStorage.setItem("access-token", headers["access-token"]);
  window.localStorage.setItem("client", headers.client);
  window.localStorage.setItem("uid", `${headers.uid}`);
}
