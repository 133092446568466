import { useState } from "react";
import { MenuOption } from "./TCMenuThreeDotProps";

interface TCMenuThreeDotViewModelProps {
  anchorElement: null | HTMLElement;
  onMenuButtonClick: (event: React.MouseEvent<HTMLElement>) => void;
  onMenuClose: () => void;
  onMenuOptionClick: (menuOption: MenuOption) => void;
}

export const useTCMenuThreeDotViewModel = (): TCMenuThreeDotViewModelProps => {
  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);

  const onMenuOptionClick = (menuOption: MenuOption) => {
    setAnchorElement(null);
    menuOption.onClick();
  };

  return {
    anchorElement,
    onMenuButtonClick: (event) => setAnchorElement(event.currentTarget),
    onMenuClose: () => setAnchorElement(null),
    onMenuOptionClick,
  };
};
