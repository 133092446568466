import { createStyles, makeStyles } from "@material-ui/core";
import { CustomMuiTheme } from "../../../../../../interfaces/CustomMuiTheme";

export const useTCDisplayFieldComplexFileEditableStyles = makeStyles(
  (theme: CustomMuiTheme) =>
    createStyles({
      additionalMetaInformation: {
        marginLeft: theme.spacing(2),
      },
      additionalMetaInformationTypography: {
        color: theme.palette.secondary.main,
        fontSize: theme.typography.fontSize * 0.7,
      },
    })
);
