import { merge } from "lodash";
import React, { ReactElement, useCallback, useEffect, useMemo } from "react";
import { UserContext, UserSettableHook } from "../appContext";
import { setAuthTokens } from "../dataHandling/services/setAuthTokens";
import isAuthPath from "../helpers/isAuthPath";
import { userIsAdmin } from "../helpers/userHelper";
import { useNavigate } from "../hooks/routing/useNavigate";
import { useTCLocation } from "../hooks/routing/useTCLocation";
import { LocalStorageKey, useLocalStorage } from "../hooks/useLocalStorage";
import { TCAppRoutes } from "../interfaces/TCAppRoutes";
import { TCUser } from "../interfaces/typeAliases";

interface TCAuthenticationLayerProps {
  children: ReactElement;
}

export const TCAuthenticationLayer: React.FunctionComponent<TCAuthenticationLayerProps> = (
  props
) => {
  const location = useTCLocation();
  const navigate = useNavigate();
  const [user, setUser] = useLocalStorage(LocalStorageKey.User);

  useEffect(() => {
    if (!user && !isAuthPath(location.pathname)) {
      navigate(TCAppRoutes.Login);
    }

    if (user && isAuthPath(location.pathname)) {
      const route = userIsAdmin(user) ? TCAppRoutes.Admin : TCAppRoutes.Home;
      navigate(route);
    }
  }, [navigate, user, location.pathname]);

  const userSetter = useCallback(
    (newUser: Partial<TCUser> | undefined): void => {
      const mergedUser = newUser ? merge({}, user, newUser) : undefined;
      if (mergedUser) {
        setAuthTokens({
          "access-token": mergedUser.accessToken,
          client: mergedUser.client,
          uid: mergedUser.id,
        });
      }
      setUser(mergedUser);
    },
    [setUser, user]
  );

  const result: UserSettableHook = useMemo(() => [user, userSetter], [
    user,
    userSetter,
  ]);

  return (
    <UserContext.Provider value={result}>{props.children}</UserContext.Provider>
  );
};
