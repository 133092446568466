import { DisplayConfiguration, Field } from "../../../generated/api-service";
import {
  DisplayMode,
  FieldValuableMap,
} from "../../../interfaces/dynamicFieldInterfaces";
import { fieldIsEmpty } from "./fieldIsEmpty";
import { relatedFieldIsNotMatchingItsConditionsWithGivenValue } from "./relatedFieldIsNotMatchingItsConditionWithGivenValues";

export const fieldShouldBeRendered = (
  field: Field,
  displayConfiguration: DisplayConfiguration,
  displayMode: DisplayMode,
  valuableMap?: FieldValuableMap
): boolean => {
  if (!field.fieldType) {
    return false;
  }

  const relatedFieldIsNotMatchingItsConditions = (related) =>
    relatedFieldIsNotMatchingItsConditionsWithGivenValue(related, valuableMap);

  if (
    displayConfiguration.displayConditions?.some(
      relatedFieldIsNotMatchingItsConditions
    )
  ) {
    return false;
  }

  // hide fields in display mode when they are empty
  if (
    displayMode === DisplayMode.Display &&
    fieldIsEmpty(valuableMap?.[field.id])
  ) {
    return false;
  }

  return true;
};
