import { useMemo } from "react";
import { AuthenticationParameter } from "../../../interfaces/authentication";
import { useUser } from "../../useUser";

export const useAuthenticationParameter = (): AuthenticationParameter => {
  const user = useUser();
  return useMemo(() => ({
      uid: user?.uid || "",
      client: user?.client || "",
      accessToken: user?.accessToken || "",
      tokenType: user?.tokenType || "",
    }), [user]);
};
