import MomentUtils from "@date-io/moment";
import { CssBaseline, ThemeProvider } from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { createBrowserHistory } from "history";
import moment from "moment";
import "moment/locale/de";
import React, { ReactElement } from "react";
import { Router } from "react-router-dom";
import { SWRConfig } from "swr";
import { TCAuthenticationLayer } from "./components/TCAuthenticationLayer";
import { tenantTheme } from "./helpers/tenantTheme";
import { TCSnackbarProvider } from "./higherOrderComponents/snackbarProvider";
import { TCCableProvider } from "./higherOrderComponents/TCCableProvider";
import { TCDebuggingProvider } from "./higherOrderComponents/TCDebuggingProvider";
import { TCRouterHistory } from "./interfaces/routing";

moment.locale("de");

/**
 * Collection of Higher Order Components which are to be mounted at app level.
 */

interface TCHigherOrderComponentProps {
  children: ReactElement;
}

export const TCHigherOrderComponent: React.FC<TCHigherOrderComponentProps> = (
  props: TCHigherOrderComponentProps
) => {
  const customHistory = createBrowserHistory<TCRouterHistory>();

  return (
    <Router history={customHistory}>
      <TCAuthenticationLayer>
        <TCSnackbarProvider>
          <TCDebuggingProvider>
            <SWRConfig
              value={{
                refreshInterval: undefined,
                revalidateOnFocus: false,
              }}
            >
              <TCCableProvider>
                <MuiPickersUtilsProvider
                  libInstance={moment}
                  utils={MomentUtils}
                  locale="de"
                >
                  <ThemeProvider theme={tenantTheme}>
                    <CssBaseline />

                    {props.children}
                  </ThemeProvider>
                </MuiPickersUtilsProvider>
              </TCCableProvider>
            </SWRConfig>
          </TCDebuggingProvider>
        </TCSnackbarProvider>
      </TCAuthenticationLayer>
    </Router>
  );
};
