import { ChangeEvent, useCallback, useRef, useState } from "react";
import { conversationApi } from "../../../dataHandling/autogeneratedApiServices";
import { useAuthenticationParameter } from "../../../hooks/tamocApiHooks/authentication/useAuthenticationParameter";
import { useErrorHandler } from "../../../hooks/useErrorHandler";
import { useUser } from "../../../hooks/useUser";
import { TCChatInputProps, TCChatInputViewModel } from "./TCChatInputProps";

export const useTCChatInputViewModel = (
  props: TCChatInputProps
): TCChatInputViewModel => {
  const user = useUser();

  const [messageBody, setMessageBody] = useState<string>("");
  const [isLoading, setLoading] = useState<boolean>(false);
  const authenticationParameter = useAuthenticationParameter();

  const inputRef = useRef<HTMLInputElement>(null);
  const errorHandler = useErrorHandler();

  const canSendMessage =
    !!messageBody.trim().length && props.conversationId && user?.id;

  const sendMessage = useCallback(() => {
    if (!canSendMessage || !user) {
      return;
    }
    setLoading(true);

    // Tried to use the ReactHookFromObservable but it not handle
    // multiple requests in a short period of time
    conversationApi
      .postMessageToConversation({
        conversationId: props.conversationId,
        messagePost: {
          message: {
            userId: user.id,
            body: messageBody,
          },
        },
        ...authenticationParameter,
      })
      .toPromise()
      .then(() => {
        setLoading(false);
        setMessageBody("");
        inputRef?.current?.focus();
      })
      .catch((error) => errorHandler(error))
      .finally(() => setLoading(false));
  }, [
    canSendMessage,
    user,
    props.conversationId,
    messageBody,
    errorHandler,
    authenticationParameter,
  ]);

  const setMessage = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setMessageBody(event.currentTarget.value);
    },
    []
  );

  const catchEnter = useCallback(
    (event: React.KeyboardEvent<HTMLDivElement>) => {
      if (event.key === "Enter") {
        sendMessage();
      }
    },
    [sendMessage]
  );

  return {
    messageBody,
    isLoading,
    inputRef,
    setMessage,
    sendMessage,
    catchEnter,
  };
};
