import React, { ReactElement } from "react";
import { FieldGroup } from "../../../generated/api-service";
import { fieldGroupIntroData } from "../../../helpers/staticData";
import { FieldGroupIntro } from "../TCOnboardingDialog/FieldGroupIntro";

export function createInfoSectionForGroup(
  groupId: FieldGroup["id"]
): ReactElement | null {
  return fieldGroupIntroData[groupId] ? (
    <FieldGroupIntro {...fieldGroupIntroData[groupId]} />
  ) : null;
}
