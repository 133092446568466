import { merge } from "lodash";
import {
  DisplayConfiguration,
  Field,
  FieldGroup,
} from "../../../generated/api-service";

export const mergeDisplayConfigurationForField = (
  field: Field,
  fieldGroup?: FieldGroup
): DisplayConfiguration => {
  const displayConfigurationFromGroup = fieldGroup?.fieldFieldGroups
    ?.filter((fieldFieldGroup) => fieldFieldGroup.fieldId === field.id)
    .map((fieldFieldGroup) => fieldFieldGroup.displayConfiguration);

  return merge(
    {},
    field.fieldType.displayConfiguration,
    field.displayConfiguration,
    displayConfigurationFromGroup || {}
  );
};
