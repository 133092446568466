import { EComplexType, Field } from "../../generated/api-service";

/**
 * Extract the complex type from a field.
 *
 * @throws if it's not a complex field, or if it has no complex type.
 */
export const complexTypeFromField = (field: Field): EComplexType => {
  const complexType = field.fieldType.complexType?.complexType;
  if (!complexType) {
    throw Error(
      `Could not get complex type from field ${field.name}. Is it a complex field?`
    );
  }
  return complexType;
};
