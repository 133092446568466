import slugify from "@sindresorhus/slugify";
import { Field } from "../../generated/api-service";

/**
 * Generate a unique field name to identify fields in forms.
 */
export const constructFormikFieldIdentifier = (field: Field): string =>
  `${slugify(field.name)}_${field.id}`;

export const fieldIdFromFieldName = (fieldName: string): Field["id"] => {
  const match = fieldName.match(/_([0-9]+)(-[a-z]+)?$/);
  if (!match) {
    throw Error(`Could not get the field id from field name '${fieldName}'.`);
  }
  return parseInt(match[1], 10);
};
