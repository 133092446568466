import React from "react";
import { WebSocketSubject } from "rxjs/webSocket";
import { ActiveCableSubject } from "./interfaces/activeCable";
import { IPurposeContext } from "./interfaces/dynamicFieldInterfaces";
import { TCUser } from "./interfaces/typeAliases";

/**
 * Since contexts provide app-scoped data, they should be accessible
 * in a single place – here.
 *
 * Context makes component reuse harder and should only be used when no other
 * method of sharing data can be applied.
 *
 * Before introducing new context objects, please check the react documentation
 * if other alternatives might be more appropriate:
 * https://reactjs.org/docs/context.html
 *
 */

/**
 * Keeps track if the higher order TXOCableProvider is ready and cable
 * connections can be made by using <ActionCable>
 */
export const CableContext = React.createContext<
  WebSocketSubject<ActiveCableSubject> | undefined
>(undefined);

/**
 * Components might react to this property by being more verbose, etc.
 */
export const DebuggingContext = React.createContext<boolean>(false);

/**
 * The current user. If undefined, the user is not logged in.
 */
export type UserSettableHook = [
  TCUser | undefined,
  (user: Partial<TCUser> | undefined) => void
];
export const UserContext = React.createContext<UserSettableHook>([
  undefined,
  () => {
    throw Error(`User not set!`);
  },
]);

/**
 * A list of field groups belonging to a purpose, and the matching value map
 */
export const PurposeContext = React.createContext<IPurposeContext>(undefined);
