import { Avatar, Typography } from "@material-ui/core";
import React from "react";
import { notCurrentUser } from "../../../../helpers/conversationUser";
import { useUser } from "../../../../hooks/useUser";
import { TCConversationTitleProps } from "./TCConversationTitleProps";
import { useTCConversationTitleStyles } from "./TCConversationTitleStyles";

export const TCConversationTitle: React.FunctionComponent<TCConversationTitleProps> = (
  props
) => {
  const classes = useTCConversationTitleStyles();
  const currentUser = useUser();

  const titleImage = props.conversationUser.conversation?.users
    ?.filter((user) => notCurrentUser(user, currentUser))
    .map((user) => user?.image);

  const titleText = props.conversationUser.conversation?.users
    ?.filter((user) => notCurrentUser(user, currentUser))
    .map((user) => `${user.firstName} ${user.lastName}`);

  return (
    <div className={classes.root}>
      <Avatar src={(titleImage ?? [])[0] ?? ""} className={classes.avatar} />

      <Typography>{titleText}</Typography>
    </div>
  );
};
