import React from "react";
import { EPurpose } from "../../../generated/api-service";
import { userIsWorker } from "../../../helpers/userHelper";
import { userNeedsOnboarding } from "../../../helpers/userNeedsOnboarding";
import { TCUser } from "../../../interfaces/typeAliases";
import { TCPurposeProvider } from "../../dynamicFields/purpose/TCPurposeProvider/TCPurposeProvider";
import { TCOnboarding } from "./TCOnboardingDialog";

interface TCOnboardingWrapperProps {
  user: TCUser;
}

export const TCOnboardingWrapper: React.FunctionComponent<TCOnboardingWrapperProps> = (
  props: TCOnboardingWrapperProps
) => userNeedsOnboarding(props.user) ? (
    <TCPurposeProvider
      purpose={
        userIsWorker(props.user)
          ? EPurpose.OnboardingWorker
          : EPurpose.OnboardingEmployer
      }
      userId={props.user.id}
    >
      <TCOnboarding />
    </TCPurposeProvider>
  ) : null;
