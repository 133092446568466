// tslint:disable
/**
 * TaMoC API V1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs';
import {
    EPurpose, FieldGroup,
    FieldGroupPost,
    FieldGroupPut
} from '../models';
import { BaseAPI, encodeURI, HttpHeaders, HttpQuery, OperationOpts, RawAjaxResponse, throwIfNullOrUndefined } from '../runtime';

export interface CreateFieldGroupRequest {
    accessToken: string;
    client: string;
    uid: string;
    fieldGroupPost?: FieldGroupPost;
}

export interface DeleteFieldGroupRequest {
    accessToken: string;
    client: string;
    uid: string;
    id: number;
}

export interface GetFieldGroupRequest {
    sortBy?: GetFieldGroupSortByEnum;
    id?: number | string;
    name?: string;
    order?: number | string;
    createdAt?: string;
    updatedAt?: string;
    purpose?: EPurpose;
    label?: string;
}

export interface GetFieldGroupByIdRequest {
    id: number;
}

export interface PatchFieldGroupRequest {
    accessToken: string;
    client: string;
    uid: string;
    id: number;
    fieldGroupPut?: FieldGroupPut;
}

export interface UpdateFieldGroupRequest {
    accessToken: string;
    client: string;
    uid: string;
    id: number;
    fieldGroupPut?: FieldGroupPut;
}

/**
 * no description
 */
export class FieldGroupApi extends BaseAPI {

    /**
     * Create FieldGroup
     */
    createFieldGroup({ accessToken, client, uid, fieldGroupPost }: CreateFieldGroupRequest): Observable<FieldGroup>
    createFieldGroup({ accessToken, client, uid, fieldGroupPost }: CreateFieldGroupRequest, opts?: OperationOpts): Observable<RawAjaxResponse<FieldGroup>>
    createFieldGroup({ accessToken, client, uid, fieldGroupPost }: CreateFieldGroupRequest, opts?: OperationOpts): Observable<FieldGroup | RawAjaxResponse<FieldGroup>> {
        throwIfNullOrUndefined(accessToken, 'accessToken', 'createFieldGroup');
        throwIfNullOrUndefined(client, 'client', 'createFieldGroup');
        throwIfNullOrUndefined(uid, 'uid', 'createFieldGroup');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(accessToken != null ? { 'access-token': String(accessToken) } : undefined),
            ...(client != null ? { 'client': String(client) } : undefined),
            ...(uid != null ? { 'uid': String(uid) } : undefined),
        };

        return this.request<FieldGroup>({
            url: '/api/v1/field_groups/',
            method: 'POST',
            headers,
            body: fieldGroupPost,
        }, opts?.responseOpts);
    };

    /**
     * Deletes a FieldGroup
     */
    deleteFieldGroup({ accessToken, client, uid, id }: DeleteFieldGroupRequest): Observable<void>
    deleteFieldGroup({ accessToken, client, uid, id }: DeleteFieldGroupRequest, opts?: OperationOpts): Observable<void | RawAjaxResponse<void>>
    deleteFieldGroup({ accessToken, client, uid, id }: DeleteFieldGroupRequest, opts?: OperationOpts): Observable<void | RawAjaxResponse<void>> {
        throwIfNullOrUndefined(accessToken, 'accessToken', 'deleteFieldGroup');
        throwIfNullOrUndefined(client, 'client', 'deleteFieldGroup');
        throwIfNullOrUndefined(uid, 'uid', 'deleteFieldGroup');
        throwIfNullOrUndefined(id, 'id', 'deleteFieldGroup');

        const headers: HttpHeaders = {
            ...(accessToken != null ? { 'access-token': String(accessToken) } : undefined),
            ...(client != null ? { 'client': String(client) } : undefined),
            ...(uid != null ? { 'uid': String(uid) } : undefined),
        };

        return this.request<void>({
            url: '/api/v1/field_groups/{id}'.replace('{id}', encodeURI(id)),
            method: 'DELETE',
            headers,
        }, opts?.responseOpts);
    };

    /**
     * Retrieve a FieldGroup list
     */
    getFieldGroup({ sortBy, id, name, order, createdAt, updatedAt, purpose, label }: GetFieldGroupRequest): Observable<Array<FieldGroup>>
    getFieldGroup({ sortBy, id, name, order, createdAt, updatedAt, purpose, label }: GetFieldGroupRequest, opts?: OperationOpts): Observable<RawAjaxResponse<Array<FieldGroup>>>
    getFieldGroup({ sortBy, id, name, order, createdAt, updatedAt, purpose, label }: GetFieldGroupRequest, opts?: OperationOpts): Observable<Array<FieldGroup> | RawAjaxResponse<Array<FieldGroup>>> {

        const query: HttpQuery = {};

        if (sortBy != null) { query['sort_by'] = sortBy; }
        if (id != null) { query['id'] = id; }
        if (name != null) { query['name'] = name; }
        if (order != null) { query['order'] = order; }
        if (createdAt != null) { query['created_at'] = (createdAt as any).toISOString(); }
        if (updatedAt != null) { query['updated_at'] = (updatedAt as any).toISOString(); }
        if (purpose != null) { query['purpose'] = purpose; }
        if (label != null) { query['label'] = label; }

        return this.request<Array<FieldGroup>>({
            url: '/api/v1/field_groups/',
            method: 'GET',
            query,
        }, opts?.responseOpts);
    };

    /**
     * Retrieves a FieldGroup
     */
    getFieldGroupById({ id }: GetFieldGroupByIdRequest): Observable<FieldGroup>
    getFieldGroupById({ id }: GetFieldGroupByIdRequest, opts?: OperationOpts): Observable<RawAjaxResponse<FieldGroup>>
    getFieldGroupById({ id }: GetFieldGroupByIdRequest, opts?: OperationOpts): Observable<FieldGroup | RawAjaxResponse<FieldGroup>> {
        throwIfNullOrUndefined(id, 'id', 'getFieldGroupById');

        return this.request<FieldGroup>({
            url: '/api/v1/field_groups/{id}'.replace('{id}', encodeURI(id)),
            method: 'GET',
        }, opts?.responseOpts);
    };

    /**
     * Patch a FieldGroup
     */
    patchFieldGroup({ accessToken, client, uid, id, fieldGroupPut }: PatchFieldGroupRequest): Observable<FieldGroup>
    patchFieldGroup({ accessToken, client, uid, id, fieldGroupPut }: PatchFieldGroupRequest, opts?: OperationOpts): Observable<RawAjaxResponse<FieldGroup>>
    patchFieldGroup({ accessToken, client, uid, id, fieldGroupPut }: PatchFieldGroupRequest, opts?: OperationOpts): Observable<FieldGroup | RawAjaxResponse<FieldGroup>> {
        throwIfNullOrUndefined(accessToken, 'accessToken', 'patchFieldGroup');
        throwIfNullOrUndefined(client, 'client', 'patchFieldGroup');
        throwIfNullOrUndefined(uid, 'uid', 'patchFieldGroup');
        throwIfNullOrUndefined(id, 'id', 'patchFieldGroup');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(accessToken != null ? { 'access-token': String(accessToken) } : undefined),
            ...(client != null ? { 'client': String(client) } : undefined),
            ...(uid != null ? { 'uid': String(uid) } : undefined),
        };

        return this.request<FieldGroup>({
            url: '/api/v1/field_groups/{id}'.replace('{id}', encodeURI(id)),
            method: 'PATCH',
            headers,
            body: fieldGroupPut,
        }, opts?.responseOpts);
    };

    /**
     * Update a FieldGroup
     */
    updateFieldGroup({ accessToken, client, uid, id, fieldGroupPut }: UpdateFieldGroupRequest): Observable<FieldGroup>
    updateFieldGroup({ accessToken, client, uid, id, fieldGroupPut }: UpdateFieldGroupRequest, opts?: OperationOpts): Observable<RawAjaxResponse<FieldGroup>>
    updateFieldGroup({ accessToken, client, uid, id, fieldGroupPut }: UpdateFieldGroupRequest, opts?: OperationOpts): Observable<FieldGroup | RawAjaxResponse<FieldGroup>> {
        throwIfNullOrUndefined(accessToken, 'accessToken', 'updateFieldGroup');
        throwIfNullOrUndefined(client, 'client', 'updateFieldGroup');
        throwIfNullOrUndefined(uid, 'uid', 'updateFieldGroup');
        throwIfNullOrUndefined(id, 'id', 'updateFieldGroup');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(accessToken != null ? { 'access-token': String(accessToken) } : undefined),
            ...(client != null ? { 'client': String(client) } : undefined),
            ...(uid != null ? { 'uid': String(uid) } : undefined),
        };

        return this.request<FieldGroup>({
            url: '/api/v1/field_groups/{id}'.replace('{id}', encodeURI(id)),
            method: 'PUT',
            headers,
            body: fieldGroupPut,
        }, opts?.responseOpts);
    };

}

/**
 * @export
 * @enum {string}
 */
export enum GetFieldGroupSortByEnum {
    IdAsc = 'id_asc',
    IdDesc = 'id_desc',
    NameAsc = 'name_asc',
    NameDesc = 'name_desc',
    OrderAsc = 'order_asc',
    OrderDesc = 'order_desc',
    CreatedAtAsc = 'created_at_asc',
    CreatedAtDesc = 'created_at_desc',
    UpdatedAtAsc = 'updated_at_asc',
    UpdatedAtDesc = 'updated_at_desc',
    PurposeAsc = 'purpose_asc',
    PurposeDesc = 'purpose_desc',
    LabelAsc = 'label_asc',
    LabelDesc = 'label_desc'
}
