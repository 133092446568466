import {
  valuableMapFromFormikContext,
  valuableMapFromValueMap,
} from "../../../../helpers/forms/valuableMap";
import { useTCFormikContext } from "../../../../hooks/useTCFormikContext";
import { FieldValuableMap } from "../../../../interfaces/dynamicFieldInterfaces";
import { TCFieldGroupRenderedFieldsProps } from "./TCFieldGroupRenderedFieldsProps";

interface TCFieldGroupRenderedFieldsViewModel {
  valuableMap: FieldValuableMap | undefined;
}

export const useTCFieldGroupRenderedFieldsViewModel = (
  props: TCFieldGroupRenderedFieldsProps
): TCFieldGroupRenderedFieldsViewModel => {
  const formikContext = useTCFormikContext();

  const valuableMap =
    valuableMapFromFormikContext(formikContext) ||
    valuableMapFromValueMap(props.fieldValueMap);

  if (!valuableMap) {
    throw Error("No values given for TCFieldGroup.");
  }

  return { valuableMap };
};
