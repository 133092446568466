import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import React, { useCallback, useMemo } from "react";
import { dbDateFromMoment } from "../../../../helpers/date-time/dbDateFromMoment";
import { momentFromDbDate } from "../../../../helpers/date-time/momentFromDbDate";
import { getFieldLabel } from "../../../../helpers/dynamicFields/getFieldLabel";
import { getFieldPlaceholder } from "../../../../helpers/dynamicFields/getFieldPlaceholder";
import { fieldIsRequired } from "../../../../helpers/forms/fieldIsRequired";
import { useTCFormikField } from "../../../../hooks/useTCFormikField";
import { TCFieldComponent } from "../../../../interfaces/dynamicFieldInterfaces";
import { TCDatePicker } from "../../../pickers/TCDatePicker/TCDatePicker";
import { TCDatePickerProps } from "../../../pickers/TCDatePicker/TCDatePickerProps";

export const TCEditFieldDate: TCFieldComponent = (props) => {
  const {
    formikIdentifier,
    setFieldValue,
    fieldValue,
  } = useTCFormikField<string>(props.field);

  const dateAsMoment = useMemo(() => momentFromDbDate(fieldValue), [
    fieldValue,
  ]);

  const onPickerChange = useCallback(
    (newValue: MaterialUiPickersDate) => {
      setFieldValue(dbDateFromMoment(newValue));
    },
    [setFieldValue]
  );

  const label = getFieldLabel(props.field);
  const placeholder = getFieldPlaceholder(props.field);
  const required = fieldIsRequired(props);

  const pickerProps: TCDatePickerProps["pickerProps"] = useMemo(
    () => ({
      label,
      placeholder,
      name: formikIdentifier,
    }),
    [formikIdentifier, label, placeholder]
  );

  return (
    <TCDatePicker
      value={dateAsMoment}
      required={required}
      pickerProps={pickerProps}
      onChange={onPickerChange}
    />
  );
};
