import { merge } from "lodash";
import moment from "moment";
import { useCallback, useMemo } from "react";
import { dbTimeFormat } from "../../../../../../../Consts";
import {
  TCCreateNewTimeSlotsPatternOption,
  TCCreateNewTimeSlotsPatternProps,
  TCCreateNewTimeSlotsPatternViewModel,
} from "./TCCreateNewTimeSlotsPatternProps";

/**
 * These values can be overwritten using the display config:
 * DisplayConfigurationValueEditingTimeSlotsNewTimeSlots
 */
const createNewTimeslotPatternConfigDefaults = {
  maxNumberOfShifts: 6,
  defaultStartTime: moment("08:00", "HH:mm").format(dbTimeFormat),
  defaultEndTime: moment("17:00", "HH:mm").format(dbTimeFormat),
  placeholderLabel: "Neu hinzufügen",
};

type SelectChangeEvent = React.ChangeEvent<{
  name?: string | undefined;
  value: unknown;
}>;

export const useTCCreateNewTimeSlotsPatternViewModel = (
  onNew: TCCreateNewTimeSlotsPatternProps["onNew"],
  displayConfig: TCCreateNewTimeSlotsPatternProps["optionsFromDisplayConfig"]
): TCCreateNewTimeSlotsPatternViewModel => {
  const optionsFromDisplayConfig = useMemo(
    () => ({
      maxNumberOfShifts: displayConfig?.maximumNumberOfShifts,
      placeholderLabel: displayConfig?.label,
      defaultStartTime: displayConfig?.defaultTimeSlot?.startTime,
      defaultEndTime: displayConfig?.defaultTimeSlot?.endTime,
    }),
    [displayConfig]
  );

  const {
    maxNumberOfShifts,
    placeholderLabel,
    defaultStartTime,
    defaultEndTime,
  } = useMemo(
    () =>
      merge(
        {},
        createNewTimeslotPatternConfigDefaults,
        optionsFromDisplayConfig
      ),
    [optionsFromDisplayConfig]
  );

  const placeholderOption: TCCreateNewTimeSlotsPatternOption = useMemo(
    () => ({
      label: placeholderLabel,
      numberOfShifts: 0,
    }),
    [placeholderLabel]
  );

  /**
   * Dynamic list of options to display in the dropdown
   */
  const options: TCCreateNewTimeSlotsPatternOption[] = useMemo(
    () =>
      Array.from({
        length: maxNumberOfShifts,
      }).map((...[, index]) => ({
        numberOfShifts: index + 1,
        label: `${index + 1} Schichten`,
      })),
    [maxNumberOfShifts]
  );

  const allOptions = useMemo(() => [...options, placeholderOption], [
    options,
    placeholderOption,
  ]);

  const onOptionSelect = useCallback(
    (event: SelectChangeEvent) => {
      const numberOfShifts = event.target
        .value as TCCreateNewTimeSlotsPatternOption["numberOfShifts"];
      onNew({
        name: `${numberOfShifts}-Schicht-Modell`,
        slots: Array.from({ length: numberOfShifts }).map((...[, index]) => ({
          name: `Schicht ${index + 1}`,
          startTime: defaultStartTime,
          endTime: defaultEndTime,
        })),
      });
    },
    [defaultStartTime, defaultEndTime, onNew]
  );

  return useMemo(
    () => ({
      maxNumberOfShifts,
      placeholderOption,
      onOptionSelect,
      allOptions,
    }),
    [maxNumberOfShifts, placeholderOption, onOptionSelect, allOptions]
  );
};
