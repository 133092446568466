import { createStyles, makeStyles } from "@material-ui/core";

export const useTCCreateNewTimeSlotsPatternStyles = makeStyles(() =>
  createStyles({
    formControl: {
      width: "100%",
      textAlign: "left",
    },
  })
);
