import { createMuiTheme, responsiveFontSizes } from "@material-ui/core";
import { Overrides } from "@material-ui/core/styles/overrides";
import { merge } from "lodash";
import { CustomMuiTheme } from "../../interfaces/CustomMuiTheme";
import "../../resources/fonts/PlusJakartaSans/stylesheet.css";
import { globalCss } from "./themeData/globalCss";
import { overrides } from "./themeData/overrides";
import { palette } from "./themeData/palette";
import { props } from "./themeData/props";
import { tcCustom } from "./themeData/tcCustom";
import { typography } from "./themeData/typography";

const baseTheme: CustomMuiTheme | undefined = undefined;

const theme: CustomMuiTheme = {
  tcCustom,
  ...createMuiTheme(
    {
      palette,
      props,
      typography,
    },
    baseTheme || {}
  ),
};

/**
 * Since overrides use properties from the already constructed theme,
 * they must be added after calling createMuiTheme.
 */
const mergedOverrides: Overrides = merge({}, theme.overrides, {
  ...overrides(theme),
  MuiCssBaseline: {
    ...globalCss,
  },
});

const themeWithOverrides: CustomMuiTheme = {
  ...theme,
  overrides: mergedOverrides,
};

export const caresomeTheme = responsiveFontSizes(themeWithOverrides, {
  factor: 3,
  disableAlign: true,
}) as CustomMuiTheme;
