import { ReactComponent as ArchiveFullIcon } from "@iconset/archive.full.svg";
import { ReactComponent as ChecklistIcon } from "@iconset/checklist.svg";
import { ReactComponent as DocumentTextAltIcon } from "@iconset/document.text.alt.svg";
import { ReactComponent as EditIcon } from "@iconset/edit.svg";
import { ReactComponent as HomeIcon } from "@iconset/home.svg";
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  useMediaQuery,
} from "@material-ui/core";
import clsx from "clsx";
import { intersection } from "lodash";
import React, { useEffect, useState } from "react";
import { ERole } from "../../../generated/api-service";
import { useTCLocation } from "../../../hooks/routing/useTCLocation";
import { useCustomMuiTheme } from "../../../hooks/useCustomMuiTheme";
import { useUser } from "../../../hooks/useUser";
import { TCAppRoutes } from "../../../interfaces/TCAppRoutes";
import { TCLink } from "../../tamocComponents/TCLink/TCLink";
import TCFooter from "../TCFooter/TCFooter";
import { SidebarLink } from "./TCSidebarProps";
import { useTCSidebarStyles } from "./TCSidebarStyles";

export const TCSidebar = (): JSX.Element => {
  const theme = useCustomMuiTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const classes = useTCSidebarStyles();

  const [currentTab, setTab] = useState<string>();

  const location = useTCLocation();
  const user = useUser();

  useEffect(() => {
    if (location.pathname) {
      setTab(location.pathname);
    }
  }, [location]);

  const sidebarLinks: SidebarLink[] = [
    {
      paths: [TCAppRoutes.Dashboard, TCAppRoutes.Home],
      title: "Dashboard",
      icon: <HomeIcon className={classes.iconStroke} />,
    },
    {
      paths: [TCAppRoutes.Projects],
      title: "Projekte",
      icon: <ArchiveFullIcon className={classes.iconFill} />,
    },
    {
      paths: [TCAppRoutes.UserValidation],
      title: "User Validieren",
      icon: <ChecklistIcon className={classes.iconStroke} />,
      roles: [ERole.Admin],
    },
  ];

  if (theme.tcCustom.contractSigning.useContractSigning === true) {
    sidebarLinks.push({
      paths: [TCAppRoutes.Signing],
      title: "Verträge unterzeichnen",
      icon: <EditIcon className={classes.iconFill} />,
      roles: [ERole.Admin],
    });
    sidebarLinks.push({
      paths: [TCAppRoutes.Contracts],
      title: "Unterzeichnete Verträge",
      icon: <DocumentTextAltIcon className={classes.iconFill} />,
      roles: [ERole.Admin],
    });
  }

  const byRole = (sideBarLink: SidebarLink): boolean => {
    if (!sideBarLink.roles) {
      return true;
    }

    return (
      intersection(
        user?.userRoles?.map((userRole) => userRole.role),
        sideBarLink.roles
      ).length > 0
    );
  };

  return (
    <Drawer
      className={classes.drawer}
      variant="permanent"
      classes={{
        paper: classes.drawerPaper,
      }}
      anchor={isDesktop ? "left" : "bottom"}
    >
      <List>
        {sidebarLinks.filter(byRole).map((sidebarLink) => (
          <ListItem
            button={true}
            key={sidebarLink.title}
            style={{ padding: 0 }}
            className={classes.sidebarListItem}
          >
            <TCLink
              className={clsx(
                classes.link,
                currentTab &&
                  sidebarLink.paths.includes(currentTab as TCAppRoutes) &&
                  classes.activeLink
              )}
              appRoute={sidebarLink.paths[0]}
              key={sidebarLink.title}
            >
              <Tooltip placement="right" arrow={true} title={sidebarLink.title}>
                <ListItemIcon
                  key={`${sidebarLink.title}-icon`}
                  className={classes.iconWrap}
                >
                  {sidebarLink.icon}
                </ListItemIcon>
              </Tooltip>

              {!isDesktop && (
                <ListItemText
                  key={`${sidebarLink.title}-text`}
                  style={{ flexGrow: 0 }}
                  classes={{ primary: clsx("link-label", classes.linkLabel) }}
                  primary={sidebarLink.title}
                />
              )}
            </TCLink>
          </ListItem>
        ))}
      </List>

      {!isDesktop && <TCFooter />}
    </Drawer>
  );
};
