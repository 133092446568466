import { FieldValueBulk } from "../../generated/api-service";
import { TCFormikValues } from "../../interfaces/formikInterfaces";
import { fieldIdFromFieldName } from "../dynamicFields/fieldName";

export function formikValuesToFieldValueBulkList(
  values: TCFormikValues
): FieldValueBulk[] {
  return Object.entries(values).map(
    ([fieldName, fieldValue]): FieldValueBulk => ({
        fieldId: fieldIdFromFieldName(fieldName),
        valuable: {
          valuableValue: fieldValue.value,
        },
      })
  );
}
