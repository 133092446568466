import { useCallback, useRef, useState } from "react";
import { TcDocument } from "../../../../../../generated/api-service";
import { TCEditFieldFileCurrentStateProps } from "./TCEditFieldFileCurrentStateProps";

interface TCEditFieldFileCurrentStateViewModel {
  onCloseDeleteDocument: () => void;
  onDeleteDocument: (document: TcDocument) => void;
  onCloseDeleteBlob: () => void;
  onDeleteBlob: () => void;
  documentToDelete: TcDocument | undefined;
  showDocumentDeleteDialog: boolean;
  showUploadedBlobDeleteDialog: boolean;
  uploadedFileIndexToDelete: number | undefined;
  showDocumentDelete: (document: TcDocument) => void;
  showUploadedBlobDelete: (file: File, index: number) => void;
}

export const useTCEditFieldFileCurrentStateViewModel = (
  props: TCEditFieldFileCurrentStateProps
): TCEditFieldFileCurrentStateViewModel => {
  const { onDocumentDelete, onFileDelete } = props;

  const [uploadedFileIndexToDelete, setUploadedFileIndexToDelete] = useState<
    number | undefined
  >();
  const [
    showUploadedBlobDeleteDialog,
    setShowUploadedBlobDeleteDialog,
  ] = useState<boolean>(false);

  const [documentToDelete, setDocumentToDelete] = useState<TcDocument>();
  const [
    showDocumentDeleteDialog,
    setShowDocumentDeleteDialog,
  ] = useState<boolean>(false);

  const onCloseDeleteDocument = useRef(() => {
    setShowDocumentDeleteDialog(false);
  }).current;

  const onDeleteDocument = useCallback(
    (document: TcDocument) => {
      setShowDocumentDeleteDialog(false);

      onDocumentDelete(document);
    },
    [onDocumentDelete]
  );

  const onCloseDeleteBlob = useRef(() => {
    setShowUploadedBlobDeleteDialog(false);
  }).current;

  const onDeleteBlob = useCallback(() => {
    setShowUploadedBlobDeleteDialog(false);

    if (uploadedFileIndexToDelete) {
      onFileDelete(uploadedFileIndexToDelete);
    }
  }, [uploadedFileIndexToDelete, onFileDelete]);

  const showDocumentDelete = useRef((document: TcDocument) => {
    setShowDocumentDeleteDialog(true);
    setDocumentToDelete(document);
  }).current;

  const showUploadedBlobDelete = useRef((file: File, index: number) => {
    setShowUploadedBlobDeleteDialog(true);
    setUploadedFileIndexToDelete(index + 1);
  }).current;

  return {
    onCloseDeleteDocument,
    onDeleteDocument,
    documentToDelete,
    onDeleteBlob,
    onCloseDeleteBlob,
    showDocumentDeleteDialog,
    uploadedFileIndexToDelete,
    showUploadedBlobDeleteDialog,
    showDocumentDelete,
    showUploadedBlobDelete,
  };
};
