import { InputLabel, Slider, TextField } from "@material-ui/core";
import { Field } from "formik";
import { merge } from "lodash";
import React, { Fragment, useCallback, useState } from "react";
import { EFieldTypes } from "../../../../../generated/api-service";
import { constructFormikFieldIdentifier } from "../../../../../helpers/dynamicFields/fieldName";
import { fieldValuesFromFormikContext } from "../../../../../helpers/forms/fieldValuesFromFormikContext";
import { updateFormikValue } from "../../../../../helpers/forms/updateFormikValue";
import { useGetFieldPropsForNumber } from "../../../../../hooks/dynamicFields/getFieldPropsForNumber";
import { useTCFormikContext } from "../../../../../hooks/useTCFormikContext";
import { TCFieldComponent } from "../../../../../interfaces/dynamicFieldInterfaces";
import { useTCEditFieldIntStyles } from "./TCEditFieldIntStyles";
import { ValueLabelComponent } from "./ValueLabelComponent/ValueLabelComponent";

export const TCEditFieldInt: TCFieldComponent = (props) => {
  const classes = useTCEditFieldIntStyles();
  const formikContext = useTCFormikContext();

  const {
    formikIdentifier,
    fieldData,
    fieldValue,
  } = fieldValuesFromFormikContext<number>(formikContext, props.field);

  const [fieldIntValue, setFieldIntValue] = useState<number>(fieldValue);

  const onChange = useCallback((event) => {
    const newValue = parseInt(event.target.value, 10);
    setFieldIntValue(newValue);
  }, []);

  const textFieldProps = useGetFieldPropsForNumber({
    dataTypeFieldProps: props,
    fieldIntValue,
    onChange,
  });

  if (props.field.fieldType?.name === EFieldTypes.TravelingDistance) {
    return (
      <Field name={formikIdentifier}>
        {() => (
            <Fragment>
              <InputLabel>{props.field.label}</InputLabel>

              <div className={classes.sliderWrap}>
                <Slider
                  name={formikIdentifier}
                  ValueLabelComponent={ValueLabelComponent}
                  min={0}
                  value={fieldIntValue}
                  max={100}
                  onChange={(event, newValue) =>
                    setFieldIntValue(newValue as number)
                  }
                  onChangeCommitted={(event, newValue) => {
                    setFieldIntValue(newValue as number);
                    updateFormikValue(formikContext, formikIdentifier, {
                      ...fieldData,
                      value: fieldIntValue,
                    });
                  }}
                />

                <div className={classes.rangeLabel}>
                  {fieldIntValue || 0}&nbsp;km
                </div>
              </div>
            </Fragment>
          )}
      </Field>
    );
  }

  return (
    <Field name={constructFormikFieldIdentifier(props.field)}>
      {() => <TextField {...merge({}, textFieldProps)} />}
    </Field>
  );
};
