import { Box } from "@material-ui/core";
import { omit } from "lodash";
import React, { useCallback } from "react";
import TCBlobListItem from "./TCBlobListItem/TCBlobListItem";
import { TCBlobListProps } from "./TCBlobListProps";

/**
 * A list of Blobs/Files that are uploaded via a dropzone or something similar.
 */
export const TCBlobList: React.FunctionComponent<TCBlobListProps> = (props) => {
  const { onBlobDelete } = props;

  const blobDelete = useCallback(
    (file: File, index: number) => {
      if (onBlobDelete) {
        onBlobDelete(file, index);
      }
    },
    [onBlobDelete]
  );

  const blobList = props.blobs.map((file, index) => (
      <TCBlobListItem
        key={file.name}
        file={file}
        index={index}
        onBlobDelete={blobDelete}
        {...omit(props, "onBlobDelete")}
      />
    ));

  return <Box {...props.fileListWrapperProps}>{blobList}</Box>;
};
