import {
  ContractApi,
  ContractTemplateApi,
  ConversationApi,
  ConversationUserApi,
  DocumentApi,
  FieldApi,
  FieldGroupApi,
  FieldTypeApi,
  FieldValueApi,
  JobApi,
  JobUserApi,
  SignatureApi,
  UserApi,
} from "../generated/api-service";

export const contractApi = new ContractApi();
export const contractTemplateApi = new ContractTemplateApi();
export const documentApi = new DocumentApi();
export const fieldApi = new FieldApi();
export const fieldGroupApi = new FieldGroupApi();
export const fieldTypeApi = new FieldTypeApi();
export const fieldValueApi = new FieldValueApi();
export const jobApi = new JobApi();
export const jobUserApi = new JobUserApi();
export const signatureApi = new SignatureApi();
export const userApi = new UserApi();
export const conversationApi = new ConversationApi();
export const conversationUserApi = new ConversationUserApi();

export const dummyFetcher = (): Promise<void> => Promise.resolve(undefined);
