import moment from "moment";
import { defaultWorkingShiftPatterns } from "../../components/dynamicFields/editFields/complex/TCEditFieldTimeSlots/TCEditFieldTimeSlotsPatterns/defaultWorkingShiftPatterns";
import { dbTimeFormat } from "../../Consts";
import {
  EDataType,
  EFieldTypes,
  Field,
  FieldValueValuable,
} from "../../generated/api-service";

const defaultValuesForNormalDataTypes: {
  [dataType in Exclude<EDataType, EDataType.Complex>]:
    | FieldValueValuable["value"]
    | undefined;
} = {
  [EDataType.Bool]: false,
  [EDataType.Date]: undefined,
  [EDataType.Datetime]: undefined,
  [EDataType.Decimal]: undefined,
  [EDataType.Int]: undefined,
  [EDataType.Text]: "",
  [EDataType.Time]: moment().format(dbTimeFormat),
};

export function defaultValueForField(
  field: Field
): FieldValueValuable["value"] | undefined {
  /**
   * TODO: can we get a list of field types statically generated?!
   * This would be terribly helpful here, since there can no be meaningful
   *  default value for a data type:
   * E.g. range can be a date range, time range, number range …
   */
  if (field.fieldType.name === EFieldTypes.WorkingShiftsPattern) {
    return defaultWorkingShiftPatterns;
  }
  if (field.fieldType.dataType === EDataType.Complex) {
    return {};
  }
  return defaultValuesForNormalDataTypes[field.fieldType.dataType];
}
