import { cloneDeep } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { PurposeContext } from "../../../../appContext";
import {
  fieldGroupApi,
  fieldValueApi,
} from "../../../../dataHandling/autogeneratedApiServices";
import {
  EParentableType,
  EPurpose,
  Field,
  FieldGroup,
  FieldValue,
  Job,
  JobUser,
} from "../../../../generated/api-service";
import { fieldValueMapFromFieldValues } from "../../../../helpers/dynamicFields/fieldValueMapFromFieldValues";
import { getFieldIdsFromGroupsOfType } from "../../../../helpers/dynamicFields/getFieldIdsFromGroupsOfType";
import { mergeFieldDisplayConfig } from "../../../../helpers/dynamicFields/mergeFieldDisplayConfig";
import { fieldsByIdFromGroups } from "../../../../helpers/fieldsByIdFromGroups";
import { parentablesFromValueMap } from "../../../../helpers/parentablesHelper";
import { useAuthenticationParameter } from "../../../../hooks/tamocApiHooks/authentication/useAuthenticationParameter";
import { useErrorHandler } from "../../../../hooks/useErrorHandler";
import { useMountedState } from "../../../../hooks/useMountedState";
import { AuthenticationParameter } from "../../../../interfaces/authentication";
import {
  FieldValueParentable,
  IPurposeContext,
} from "../../../../interfaces/dynamicFieldInterfaces";
import {
  ArrayWithAtLeastOneElement,
  TCUser,
} from "../../../../interfaces/typeAliases";
import { TCFieldValuesCable } from "./TCFieldValuesCable";
import {
  IdsForPurposeProvider,
  TCPurposeProviderProps,
} from "./TCPurposeProviderProps";

interface ExtractedIds {
  userFieldIds: ArrayWithAtLeastOneElement<Field["id"]> | undefined;
  jobFieldIds: ArrayWithAtLeastOneElement<Field["id"]> | undefined;
  jobUserFieldIds: ArrayWithAtLeastOneElement<Field["id"]> | undefined;
}

const extractIdsConfig = [
  {
    parentableType: EParentableType.User,
    idKeyToCheck: nameof<IdsForPurposeProvider>((o) => o.userId),
    keyToStoreResult: nameof<ExtractedIds>((o) => o.userFieldIds),
  },
  {
    parentableType: EParentableType.Job,
    idKeyToCheck: nameof<IdsForPurposeProvider>((o) => o.jobId),
    keyToStoreResult: nameof<ExtractedIds>((o) => o.jobFieldIds),
  },
  {
    parentableType: EParentableType.JobUser,
    idKeyToCheck: nameof<IdsForPurposeProvider>((o) => o.jobUserId),
    keyToStoreResult: nameof<ExtractedIds>((o) => o.jobUserFieldIds),
  },
];

const extractFieldIdsFromFieldGroups = (
  fieldGroups: FieldGroup[],
  purpose: EPurpose,
  idsFromProps?: IdsForPurposeProvider
): ExtractedIds => {
  const returnedIds: ExtractedIds = {
    userFieldIds: undefined,
    jobFieldIds: undefined,
    jobUserFieldIds: undefined,
  };

  extractIdsConfig.forEach((extractIdConfig) => {
    const fieldIds = getFieldIdsFromGroupsOfType(
      fieldGroups,
      extractIdConfig.parentableType
    );

    if (
      fieldIds &&
      (!idsFromProps || !idsFromProps[extractIdConfig.idKeyToCheck])
    ) {
      throw Error(
        `Purpose Renderer needs a ${extractIdConfig.idKeyToCheck} to show purpose ${purpose}.` +
          `Fields ${fieldIds.join(", ")} from user detected.`
      );
    }

    returnedIds[extractIdConfig.keyToStoreResult] = fieldIds;
  });

  return returnedIds;
};

/**
 * Returns (in a non-reliable order) a list of FieldValues belonging
 * to the given FieldGroups.
 */
async function getValuesForFieldGroups(
  fieldGroups: FieldGroup[],
  purpose: EPurpose,
  authenticationParameter: AuthenticationParameter,
  idsFromProps?: IdsForPurposeProvider
): Promise<FieldValue[]> {
  const {
    userFieldIds,
    jobFieldIds,
    jobUserFieldIds,
  } = extractFieldIdsFromFieldGroups(fieldGroups, purpose, idsFromProps);

  const [
    userFieldValues,
    jobFieldValues,
    jobUserFieldValues,
  ] = await Promise.all([
    userFieldIds
      ? fieldValueApi
          .getFieldValue({
            fieldId: userFieldIds.join(","),
            parentableType: EParentableType.User,
            parentableId: (idsFromProps?.userId as TCUser["id"]).toString(),
            ...authenticationParameter,
          })
          .toPromise()
      : undefined,
    jobFieldIds
      ? fieldValueApi
          .getFieldValue({
            fieldId: jobFieldIds.join(","),
            parentableType: EParentableType.Job,
            parentableId: (idsFromProps?.jobId as Job["id"]).toString(),
            ...authenticationParameter,
          })
          .toPromise()
      : undefined,
    jobUserFieldIds
      ? fieldValueApi
          .getFieldValue({
            fieldId: jobUserFieldIds.join(","),
            parentableType: EParentableType.JobUser,
            parentableId: (idsFromProps?.jobUserId as JobUser["id"]).toString(),
            ...authenticationParameter,
          })
          .toPromise()
      : undefined,
  ]);

  return ([] as FieldValue[]).concat(
    userFieldValues || [],
    jobFieldValues || [],
    jobUserFieldValues || []
  );
}

const getFieldGroupsForPurpose = (
  purpose: EPurpose,
  authenticationParameter: AuthenticationParameter
): Promise<FieldGroup[]> =>
  fieldGroupApi
    .getFieldGroup({
      purpose,
      ...authenticationParameter,
    })
    .toPromise();

/**
 * Receive all field groups for for purpose, then fetch the field values for
 * those field groups.
 */
const loadData = async (
  props: TCPurposeProviderProps,
  authenticationParameter: AuthenticationParameter
): Promise<IPurposeContext> => {
  const fieldGroups = await getFieldGroupsForPurpose(
    props.purpose,
    authenticationParameter
  );

  fieldGroups.forEach((group) => {
    mergeFieldDisplayConfig(group);
  });

  const fieldsById = fieldsByIdFromGroups(fieldGroups);

  if (props.onlyLoadFieldGroups) {
    return {
      fieldGroups,
      fieldsById,
      fieldValueMap: {},
      purpose: props.purpose,
    };
  }

  const fieldValues: FieldValue[] = await getValuesForFieldGroups(
    fieldGroups,
    props.purpose,
    authenticationParameter,
    {
      userId: props.userId,
      jobId: props.jobId,
      jobUserId: props.jobUserId,
    }
  );

  const fieldValueMap = fieldValueMapFromFieldValues(fieldValues);

  return {
    fieldGroups,
    fieldValueMap,
    fieldsById,
    purpose: props.purpose,
  };
};

export const TCPurposeProvider: React.FunctionComponent<TCPurposeProviderProps> = (
  props
) => {
  const authenticationParameter = useAuthenticationParameter();

  /**
   * While this approach will work, it is not ideal:
   * The field groups for a purpose will be refetched every time they are
   *  requested with a different job/user id.
   */

  const [purposeContextData, setPurposeContextData] = useState<
    IPurposeContext | undefined
  >(undefined);
  const [parentables, setParentables] = useState<FieldValueParentable[]>([]);
  const errorHandler = useErrorHandler();
  const isMounted = useMountedState();

  useEffect(() => {
    loadData(props, authenticationParameter)
      .then((value) => {
        if (isMounted()) {
          setPurposeContextData(value);
        }
      })
      .catch((error) => errorHandler(error));
  }, [props, authenticationParameter, isMounted, errorHandler]);

  useEffect(() => {
    if (!purposeContextData) {
      return;
    }

    const parents = parentablesFromValueMap(purposeContextData.fieldValueMap);
    setParentables(parents);
  }, [purposeContextData]);

  const updateFieldValueMap = useCallback((fieldValue) => {
    setPurposeContextData((prevState) => {
      const newState = cloneDeep(prevState);

      if (!newState) {
        return prevState;
      }
      newState.fieldValueMap[fieldValue.fieldId] = fieldValue;

      return newState;
    });
  }, []);

  return (
    <PurposeContext.Provider value={purposeContextData}>
      {parentables.map(({ parentableId, parentableType }) => (
        <TCFieldValuesCable
          key={`${parentableType}-${parentableId}`}
          parentable={{ parentableId, parentableType }}
          updateList={updateFieldValueMap}
        />
      ))}

      {props.children}
    </PurposeContext.Provider>
  );
};
