// tslint:disable
/**
 * TaMoC API V1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface ValueValidationTimeSlotsSlots
 */
export interface ValueValidationTimeSlotsSlots {
    /**
     * @type {string}
     * @memberof ValueValidationTimeSlotsSlots
     */
    name?: string;
    /**
     * @type {string}
     * @memberof ValueValidationTimeSlotsSlots
     */
    startDate?: string;
    /**
     * @type {string}
     * @memberof ValueValidationTimeSlotsSlots
     */
    endDate?: string;
    /**
     * @type {string}
     * @memberof ValueValidationTimeSlotsSlots
     */
    startTime?: string;
    /**
     * @type {string}
     * @memberof ValueValidationTimeSlotsSlots
     */
    endTime?: string;
    /**
     * On which days this time slot is valid?
     * @type {Array<string>}
     * @memberof ValueValidationTimeSlotsSlots
     */
    dayOfWeek?: Array<ValueValidationTimeSlotsSlotsDayOfWeekEnum>;
}

/**
 * @export
 * @enum {string}
 */
export enum ValueValidationTimeSlotsSlotsDayOfWeekEnum {
    Monday = 'Monday',
    Tuesday = 'Tuesday',
    Wednesday = 'Wednesday',
    Thursday = 'Thursday',
    Friday = 'Friday',
    Saturday = 'Saturday',
    Sunday = 'Sunday'
}

