import { useCallback, useMemo } from "react";
import { ValueValidationTimeSlots } from "../../../../../../../generated/api-service";
import { useTCFormikField } from "../../../../../../../hooks/useTCFormikField";
import { TCFieldComponentProps } from "../../../../../../../interfaces/dynamicFieldInterfaces";
import {
  TimeSlotOptionWithGroup,
  TimeSlotOptionWithGroupAndValue,
} from "../sharedFunctionality/optionInterfaces";
import { timeSlotDummyName } from "../sharedFunctionality/timeSlotDummyName";
import { timeslotFieldHasValue } from "../sharedFunctionality/timeslotFieldHasValue";
import { useReferencedTimeSlotsValue } from "../sharedFunctionality/useReferencedTimeSlotsValue";

interface TCEditFieldReferenceTimeSlotsSingleSlotViewModel {
  options: TimeSlotOptionWithGroup[];
  currentValue: TimeSlotOptionWithGroup;
  onChange: (e, val: TimeSlotOptionWithGroup | null) => void;
}

/**
 * We store a full ValueValidationTimeSlots entry in the database,
 *  mainly for easier validation.
 *
 * But each options represent a single ValueValidationTimeSlotsSlots,
 *  e.g. an indivitual time shift,
 *  which needs to be wrapped/unwrapped accordingly with
 *  fullEntryFromOption/fullEntryFromOption
 */
const optionFromFullEntry = (
  fullEntry: ValueValidationTimeSlots | undefined
): TimeSlotOptionWithGroup => ({
  label: fullEntry?.timeSlots[0].slots[0].name || "",
  value: fullEntry?.timeSlots[0].slots[0],
  groupName: "",
});

/**
 * See optionFromFullEntry
 */
const fullEntryFromOption = (
  option: TimeSlotOptionWithGroupAndValue
): ValueValidationTimeSlots => ({
  name: timeSlotDummyName,
  timeSlots: [
    {
      name: timeSlotDummyName,
      slots: [option.value],
    },
  ],
});

const emptyOption: TimeSlotOptionWithGroup = {
  label: "",
  value: undefined,
  groupName: "",
};

export const useTCEditFieldReferenceTimeSlotsSingleSlotViewModel = (
  params: TCFieldComponentProps
): TCEditFieldReferenceTimeSlotsSingleSlotViewModel => {
  const timeSlots = useReferencedTimeSlotsValue(
    params.field.referencedFieldId || undefined
  );

  const {
    fieldValue,
    setFieldValue,
  } = useTCFormikField<ValueValidationTimeSlots>(params.field);

  const fieldHasValue = timeslotFieldHasValue(fieldValue);

  const currentValue: TimeSlotOptionWithGroup = useMemo(
    () => (fieldHasValue ? optionFromFullEntry(fieldValue) : emptyOption),
    [fieldValue, fieldHasValue]
  );

  const onChange = useCallback(
    (e, option: TimeSlotOptionWithGroup | null) =>
      setFieldValue(
        option?.value
          ? fullEntryFromOption(option as TimeSlotOptionWithGroupAndValue)
          : undefined
      ),
    [setFieldValue]
  );

  const options: TimeSlotOptionWithGroup[] = useMemo(() => {
    if (!timeSlots) {
      return [];
    }
    return timeSlots.timeSlots.flatMap((slot) =>
      slot.slots.map((singleShift) => ({
        label: singleShift.name || "",
        groupName: slot.name || "",
        value: singleShift,
      }))
    );
  }, [timeSlots]);

  return {
    options,
    currentValue,
    onChange,
  };
};
