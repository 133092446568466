import { useMediaQuery, useTheme } from "@material-ui/core";
import React from "react";
import { TCAppRoutes } from "../../../interfaces/TCAppRoutes";
import { TCLink } from "../../tamocComponents/TCLink/TCLink";
import { useTCFooterStyles } from "./TCFooterStyles";

interface FooterLink {
  path: TCAppRoutes;
  title: string;
}

const footerLinks: FooterLink[] = [
  {
    path: TCAppRoutes.DataProtection,
    title: "Datenschutzerklärung",
  },
  {
    path: TCAppRoutes.Imprint,
    title: "Impressum",
  },
];

const TCFooter = (): JSX.Element => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const classes = useTCFooterStyles({ isDesktop });

  return (
    <footer className={classes.footerWrapper}>
      {footerLinks.map((link) => (
        <TCLink
          appRoute={link.path}
          key={link.title}
          className={classes.linkItem}
        >
          {link.title}
        </TCLink>
      ))}
    </footer>
  );
};

export default TCFooter;
