import React, { forwardRef } from "react";
import { TCIcon } from "../TCIcon";
import { TCIconProps } from "../TCIconProps";

export const TCIconButton = forwardRef((props: TCIconProps, ref) => (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    <span ref={ref as any}>
      <TCIcon onClick={props.onClick} {...props} />
    </span>
  ));
