import { ReactElement } from "react";
import { Field } from "../../../generated/api-service";
import { TCFieldProps } from "../../../interfaces/dynamicFieldInterfaces";
import { AnyObject } from "../../../interfaces/typeAliases";
import { valueFromArray } from "./valueFromArray";
import { valueFromObject } from "./valueFromObject";

/**
 * @returns the value to display (string | ReactElement) + a boolean flag to show/hide the label
 */
export const valueFromComplex = (
  field: Field,
  input: string[] | AnyObject,
  props: TCFieldProps
): [string | ReactElement, boolean] => {
  if (Array.isArray(input)) {
    return [valueFromArray(input, props), true];
  }

  const entries = Object.entries(input);
  if (entries) {
    return [valueFromObject(field, entries, props), false];
  }

  return ["no value here", true];
};
