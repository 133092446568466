import { Box, Typography } from "@material-ui/core";
import React from "react";
import TCPageLayout from "../../../components/layout/TCPageLayout/TCPageLayout";
import { useCustomMuiTheme } from "../../../hooks/useCustomMuiTheme";
import { useTCStaticContentPageStyles } from "../TCStaticContentPageStyles";

const TCDataProtectionPage = (): JSX.Element => {
  const classes = useTCStaticContentPageStyles();
  const theme = useCustomMuiTheme();

  return (
    <TCPageLayout title="Datenschutzerklärung">
      <Box>
        <Typography variant="h2" className={classes.h2StaticPage}>
          1. Einleitung
        </Typography>

        <Typography component="p" className={classes.textParagraph}>
          {
            "Mit den folgenden Informationen möchten wir Ihnen als „betroffener Person“ einen Überblick über die Verarbeitung Ihrer personenbezogenen Daten durch uns und Ihre Rechte aus den derzeit geltenden Datenschutzgesetzen geben. Eine Nutzung unserer Webseite(n) ist grundsätzlich auch ohne Eingabe personenbezogener Daten möglich."
          }
        </Typography>

        <Typography component="p" className={classes.textParagraph}>
          {
            "Sofern Sie besondere Services unseres Unternehmens über unsere Webseite(n) in Anspruch nehmen möchten, kann eine Verarbeitung sogenannter personenbezogener Daten dennoch erforderlich werden. Ist die Verarbeitung personenbezogener Daten erforderlich und besteht für eine solche Verarbeitung keine gesetzliche Grundlage, holen wir generell eine Einwilligung von Ihnen ein."
          }
        </Typography>

        <Typography component="p" className={classes.textParagraph}>
          {
            "Die Verarbeitung von personenbezogenen Daten erfolgt stets im Einklang mit der Datenschutz-Grundverordnung (DS-GVO) und in Übereinstimmung mit den für die SURE Personalmanagement GmbH geltenden landesspezifischen Datenschutzbestimmungen."
          }
        </Typography>

        <Typography variant="h2" className={classes.h2StaticPage}>
          2. Verantwortlicher
        </Typography>

        <Typography component="p" className={classes.textParagraph}>
          {`${theme.tcCustom.tenantInformation.company.name}, ${theme.tcCustom.tenantInformation.address.street} ${theme.tcCustom.tenantInformation.address.streetNumber}, ${theme.tcCustom.tenantInformation.address.postalCode} ${theme.tcCustom.tenantInformation.address.city}`}

          <br />

          {`T: ${theme.tcCustom.tenantInformation.contact.telephone} | F: ${theme.tcCustom.tenantInformation.contact.fax} | E-Mail: `}

          <a href={theme.tcCustom.tenantInformation.contact.email}>
            {theme.tcCustom.tenantInformation.contact.email}
          </a>
        </Typography>

        <Typography variant="h2" className={classes.h2StaticPage}>
          3. Datenschutzbeauftragter
        </Typography>

        <Typography component="p" className={classes.textParagraph}>
          {`${theme.tcCustom.tenantInformation.dataProtectionOfficer.firstName} ${theme.tcCustom.tenantInformation.dataProtectionOfficer.lastName} - ${theme.tcCustom.tenantInformation.dataProtectionOfficer.company}, ${theme.tcCustom.tenantInformation.dataProtectionOfficer.address.street} ${theme.tcCustom.tenantInformation.dataProtectionOfficer.address.streetNumber}, ${theme.tcCustom.tenantInformation.dataProtectionOfficer.address.postalCode} ${theme.tcCustom.tenantInformation.dataProtectionOfficer.address.city}`}

          <br />

          {`T: ${theme.tcCustom.tenantInformation.dataProtectionOfficer.contact.telephone} | F: ${theme.tcCustom.tenantInformation.dataProtectionOfficer.contact.fax} | E-Mail: `}

          <a
            href={
              theme.tcCustom.tenantInformation.dataProtectionOfficer.contact
                .email
            }
          >
            {
              theme.tcCustom.tenantInformation.dataProtectionOfficer.contact
                .email
            }
          </a>
        </Typography>

        <Typography variant="h2" className={classes.h2StaticPage}>
          4. Rechtsgrundlage der Verarbeitung
        </Typography>

        <Typography component="p" className={classes.textParagraph}>
          {
            "Art. 6 Abs. 1 lit. a DS-GVO (i.V.m. § 13 Abs. 3 TMG) dient unseren Unternehmen als Rechtsgrundlage für Verarbeitungsvorgänge, bei denen wir eine Einwilligung für einen bestimmten Verarbeitungszweck, bei der betroffenen Person in Form einer freiwilligen Einwilligung einholen."
          }
        </Typography>

        <Typography component="p" className={classes.textParagraph}>
          {
            "Ist die Verarbeitung personenbezogener Daten zur Erfüllung eines Vertrags, dessen Vertragspartei Sie sind, erforderlich, wie dies beispielsweise bei Verarbeitungsvorgängen der Fall ist die für die Erbringung einer sonstigen Leistung oder Gegenleistung notwendig sind, so beruht die Verarbeitung auf Art. 6 Abs. 1 lit. b DS-GVO. Gleiches gilt für Verarbeitungsvorgänge die zur Durchführung vorvertraglicher Maßnahmen erforderlich sind, etwa in Fällen von Anfragen zu unseren Dienstleistungen."
          }
        </Typography>

        <Typography component="p" className={classes.textParagraph}>
          {
            "Unterliegen unsere Unternehmen einer rechtlichen Verpflichtung, durch welche eine Verarbeitung von personenbezogenen Daten erforderlich wird (wie beispielsweise zur Erfüllung steuerlicher Pflichten), so basiert die Verarbeitung auf Art. 6 Abs. 1 lit. c DS-GVO."
          }
        </Typography>

        <Typography component="p" className={classes.textParagraph}>
          {
            "In seltenen Fällen könnte die Verarbeitung von personenbezogenen Daten erforderlich werden, um lebenswichtige Interessen der betroffenen Person oder einer anderen natürlichen Person zu schützen, so würde die Verarbeitung auf Art. 6 Abs. 1 lit. d DS-GVO beruhen."
          }
        </Typography>

        <Typography component="p" className={classes.textParagraph}>
          {
            "Letztlich können Verarbeitungsvorgänge auch auf Art. 6 Abs. 1 lit. c DS-GVO beruhen. Auf dieser Rechtsgrundlage basieren Verarbeitungsvorgänge, die von keiner der zuvor genannten Rechtsgrundlagen erfasst werden, wenn die Verarbeitung zur Wahrung eines berechtigten Interesses unserer Unternehmen oder eines Dritten erforderlich ist und sofern die Interessen, Grundrechte und Grundfreiheiten des Betroffenen nicht überwiegen."
          }
        </Typography>

        <Typography component="p" className={classes.textParagraph}>
          {
            "Solche Verarbeitungsvorgänge sind uns insbesondere deshalb gestattet, weil sie durch den Europäischen Gesetzgeber besonders erwähnt wurden. Er vertrat insoweit die Auffassung, dass ein berechtigtes Interesse anzunehmen sein könnte, wenn Sie ein Kunde unserer Unternehmen sind. (Erwägungsgrund 47 Satz 2 DS-GVO)"
          }
        </Typography>

        <Typography variant="h2" className={classes.h2StaticPage}>
          5. Übermittlung von Daten
        </Typography>

        <Typography component="p" className={classes.textParagraph}>
          {
            "Eine Übermittlung Ihrer persönlichen Daten an Dritte zu anderen als den im Folgenden aufgeführten Zwecken findet nicht statt. Wir geben Ihre persönlichen Daten nur an Dritte weiter, wenn:"
          }
        </Typography>

        <ol>
          <li>
            {
              "Sie Ihre nach Art. 6 Abs. 1 S. 1 lit. a DS-GVO ausdrückliche Einwilligung dazu erteilt haben,"
            }
          </li>

          <li>
            {
              "die Weitergabe nach Art. 6 Abs. 1 S. 1 lit. f DS-GVO zur Wahrung unserer berechtigten Interessen zulässig ist und kein Grund zur Annahme besteht, dass Sie ein überwiegendes schutzwürdiges Interesse an der Nichtweitergabe Ihrer Daten haben,"
            }
          </li>

          <li>
            {
              "für den Fall, dass für die Weitergabe nach Art. 6 Abs. 1 S. 1 lit. c DS-GVO eine gesetzliche Verpflichtung besteht, sowie"
            }
          </li>

          <li>
            {
              "dies gesetzlich zulässig und nach Art. 6 Abs. 1 S. 1 lit. b DS-GVO für die Abwicklung von Vertragsverhältnissen mit Ihnen erforderlich ist."
            }
          </li>
        </ol>

        <Typography component="p" className={classes.textParagraph}>
          {
            "Um Ihre Daten zu schützen und uns gegebenenfalls eine Datenübermittlung in Drittländer (außerhalb der EU/des EWR) zu ermöglichen, haben wir Vereinbarungen zur Auftragsverarbeitung auf Grundlage von Standardvertragsklauseln der Europäischen Kommission abgeschlossen."
          }
        </Typography>

        <Typography variant="h2" className={classes.h2StaticPage}>
          6. Anfragen per E-Mail, Telefon oder Telefax
        </Typography>

        <Typography component="p" className={classes.textParagraph}>
          {
            "Wenn Sie uns per E-Mail, Telefon oder Telefax kontaktieren, wird Ihre Anfrage inklusive aller daraus hervorgehenden personenbezogenen Daten (Name, Anfrage) zum Zwecke der Bearbeitung Ihres Anliegens bei uns gespeichert und verarbeitet. Diese Daten geben wir nicht ohne Ihre Einwilligung weiter."
          }
        </Typography>

        <Typography component="p" className={classes.textParagraph}>
          {
            "Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. b DSGVO, sofern Ihre Anfrage mit der Erfüllung eines Vertrags zusammenhängt oder zur Durchführung vorvertraglicher Maßnahmen erforderlich ist. In allen übrigen Fällen beruht die Verarbeitung auf Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) und/oder auf unseren berechtigten Interessen (Art. 6 Abs. 1 lit. f DSGVO), da wir ein berechtigtes Interesse an der effektiven Bearbeitung der an uns gerichteten Anfragen haben."
          }
        </Typography>

        <Typography component="p" className={classes.textParagraph}>
          {
            "Die von Ihnen an uns per Kontaktanfragen übersandten Daten verbleiben bei uns, bis Sie uns zur Löschung auffordern, Ihre Einwilligung zur Speicherung widerrufen oder der Zweck für die Datenspeicherung entfällt (z. B. nach abgeschlossener Bearbeitung Ihres Anliegens). Zwingende gesetzliche Bestimmungen – insbesondere gesetzliche Aufbewahrungsfristen – bleiben hiervon unberührt."
          }
        </Typography>

        <Typography variant="h2" className={classes.h2StaticPage}>
          7. Kontaktaufnahme / Kontaktformular
        </Typography>

        <Typography component="p" className={classes.textParagraph}>
          {
            "Im Rahmen Ihrer Kontaktaufnahme mit uns (z.B. Kontaktformular oder E-Mail) werden personenbezogene Daten erhoben. Welche Daten im Falle eines Kontaktformulars erhoben werden, ist aus dem jeweiligen Kontaktformular ersichtlich. Diese Daten werden ausschließlich zum Zweck der Beantwortung Ihres Anliegens bzw. für die Kontaktaufnahme und die damit verbundene technische Administration gespeichert und verwendet. Rechtsgrundlage für die Verarbeitung der Daten ist unser berechtigtes Interesse an der Beantwortung Ihres Anliegens gemäß Art. 6 Abs. 1 lit. f DS-GVO. Zielt Ihre Kontaktierung auf den Abschluss eines Vertrages ab, so ist die Rechtsgrundlage für die Verarbeitung Art. 6 Abs. 1 lit. b DS-GVO."
          }
        </Typography>

        <Typography component="p" className={classes.textParagraph}>
          {
            "Ihre Daten werden nach abschließender Bearbeitung Ihrer Anfrage gelöscht, dies ist der Fall, wenn sich aus den Umständen entnehmen lässt, dass der betroffene Sachverhalt abschließend geklärt ist und keine gesetzlichen Aufbewahrungspflichten diesem entgegenstehen."
          }
        </Typography>

        <Typography variant="h2" className={classes.h2StaticPage}>
          8. Bewerbungsmanagement / Stellenbörse
        </Typography>

        <Typography component="p" className={classes.textParagraph}>
          {
            "Wir erheben und verarbeiten personenbezogenen Daten von Bewerbern im Rahmen einer Arbeitnehmerüberlassung und/oder Personalvermittlung zum Zwecke der Abwicklung des Bewerbungsverfahrens."
          }
        </Typography>

        <Typography component="p" className={classes.textParagraph}>
          {
            "Im Rahmen Ihrer Bewerbung übermittelt und verteilt die SURE Personalmanagement GmbH Ihre berufsbezogenen und persönliche Daten an diejenigen Entleiher Firmen, die an Ihrer Person und Ihrem Können interessiert sein könnten. Dazu gehören bspw. u. a. Identitätsnachweise, Qualifikationsnachweise, Ausbildungs- und Berufserfahrungsnachweise, Arbeitseignungsnachweise, Sicherheitsüberprüfungen, Schriftliche Referenzen und evtl. Verrechnungssätze. Diese dienen dem Zweck der Herbeiführung einer sorgfältigen Auswahl/Zusammenführung von Kandidaten und den jeweiligen Entleiher Firmen."
          }
        </Typography>

        <Typography component="p" className={classes.textParagraph}>
          {
            "Schließen wir einen Anstellungsvertrag mit einem Bewerber, werden die übermittelten Daten zum Zwecke der Abwicklung des Beschäftigungsverhältnisses unter Beachtung der gesetzlichen Vorschriften gespeichert."
          }
        </Typography>

        <Typography component="p" className={classes.textParagraph}>
          {
            "Wird von uns kein Anstellungsvertrag mit dem Bewerber geschlossen, so werden die Bewerbungsunterlagen sechs Monate nach Bekanntgabe der Absageentscheidung automatisch gelöscht, sofern einer Löschung keine sonstigen berechtigten Interessen unsererseits entgegenstehen. Sonstiges berechtigtes Interesse in diesem Sinne ist beispielsweise eine Beweispflicht in einem Verfahren nach dem Allgemeinen Gleichbehandlungsgesetz (AGG)."
          }
        </Typography>

        <Typography component="p" className={classes.textParagraph}>
          {
            "Die Rechtsgrundlage der Verarbeitung dieser Daten beruht auf Art. 88 DS-GVO i.V.m. § 26 Abs. 1 BDSG."
          }
        </Typography>

        <Typography component="p" className={classes.textParagraph}>
          {
            "Insofern für die Verarbeitung Nebendienstleistungen hinzuzuziehen sind, erfolgt die Verarbeitung auf Grundlage des Art. 6 Abs. 1 lit. f) DSGVO, aus dem berechtigten Interesse an der Abwicklung des Bewerbungsverfahrens."
          }
        </Typography>

        <Typography variant="h2" className={classes.h2StaticPage}>
          9. Newsletter Versand
        </Typography>

        <Typography component="p" className={classes.textParagraph}>
          {
            "Wir informieren unsere Kunden und Geschäftspartner in regelmäßigen Abständen im Wege eines Newsletters über unsere Angebote."
          }
        </Typography>

        <Typography component="p" className={classes.textParagraph}>
          {
            "Wenn Sie uns Ihre E-Mailadresse im Zusammenhang mit unseren Stellenangeboten zur Verfügung gestellt haben, behalten wir uns vor Ihnen regelmäßig Angebote zu ähnlichen, wie den bereits von Ihnen genutzten, per E-Mail zuzusenden. Hierfür müssen wir gemäß § 7 Abs. 3 UWG keine gesonderte Einwilligung von Ihnen einholen. Die Datenverarbeitung erfolgt insoweit allein auf Basis unseres berechtigten Interesses an personalisierter Direktbewerbung offener Stellenangebote, gemäß Art. 6 Abs. 1 lit. f DS-GVO."
          }
        </Typography>

        <Typography component="p" className={classes.textParagraph}>
          {
            "Haben Sie der Nutzung Ihrer E-Mailadresse zu diesem Zweck anfänglich widersprochen, findet ein Mailversand unsererseits nicht statt. Sie sind berechtigt, der Nutzung Ihrer E-Mailadresse zu dem vorbezeichneten Zweck jederzeit mit Wirkung für die Zukunft durch Mitteilung an uns zu widersprechen. Hierfür fallen lediglich die Übermittlungskosten nach den Basistarifen an."
          }
        </Typography>

        <Typography component="p" className={classes.textParagraph}>
          {
            "Nach Eingang Ihres Widerspruchs wird die Nutzung Ihrer E-Mail-Adresse zu Angebotswecken unverzüglich eingestellt."
          }
        </Typography>

        <Typography variant="h2" className={classes.h2StaticPage}>
          10. Jobnewsletter
        </Typography>

        <Typography component="p" className={classes.textParagraph}>
          {
            "Auf unserer Internetseite wird Ihnen die Möglichkeit eingeräumt, den Newsletter unseres Unternehmens zu abonnieren. Welche personenbezogenen Daten bei der Bestellung des Newsletters an uns übermittelt werden, ergibt sich aus der hierzu verwendeten Eingabemaske."
          }
        </Typography>

        <Typography component="p" className={classes.textParagraph}>
          {
            "Der Newsletter unseres Unternehmens kann von Ihnen grundsätzlich nur dann empfangen werden, wenn"
          }
        </Typography>

        <ul>
          <li>{"Sie über eine gültige E-Mail-Adresse verfügen und"}</li>

          <li>{"Sie sich für den Newsletter Versand registriert haben."}</li>
        </ul>

        <Typography component="p" className={classes.textParagraph}>
          {
            "An die von Ihnen erstmalig für den Newsletter Versand eingetragene E-Mail-Adresse wird aus rechtlichen Gründen eine Bestätigungsmail im Double-Opt-in-Verfahren versendet. Diese Bestätigungsmail dient der Überprüfung, ob Sie als Inhaber der E-Mail-Adresse den Empfang des Newsletters autorisiert haben."
          }
        </Typography>

        <Typography component="p" className={classes.textParagraph}>
          {
            "Bei der Anmeldung zum Newsletter speichern wir ferner die von Ihrem Internet-Service-Provider (ISP) vergebene IP-Adresse des von Ihnen zum Zeitpunkt der Anmeldung verwendeten IT-Systems sowie das Datum und die Uhrzeit der Anmeldung. Die Erhebung dieser Daten ist erforderlich, um den (möglichen) Missbrauch Ihrer E-Mail-Adresse zu einem späteren Zeitpunkt nachvollziehen zu können und dient deshalb unserer rechtlichen Absicherung."
          }
        </Typography>

        <Typography component="p" className={classes.textParagraph}>
          {
            "Die im Rahmen einer Anmeldung zum Newsletter erhobenen personenbezogenen Daten werden ausschließlich zum Versand unseres Newsletters verwendet. Ferner könnten Abonnenten des Newsletters per E-Mail informiert werden, sofern dies für den Betrieb des Newsletter-Dienstes oder eine diesbezügliche Registrierung erforderlich ist, wie dies im Falle von Änderungen am Newsletter Angebot oder bei der Veränderung der technischen Gegebenheiten der Fall sein könnte. Es erfolgt keine Weitergabe der im Rahmen des Newsletter-Dienstes erhobenen personenbezogenen Daten an Dritte. "
          }
        </Typography>

        <Typography component="p" className={classes.textParagraph}>
          {
            "Das Abonnement unseres Newsletters kann durch Sie jederzeit gekündigt werden. Die Einwilligung in die Speicherung personenbezogener Daten, die Sie uns für den Newsletter Versand erteilt haben, kann jederzeit widerrufen werden. Zum Zwecke des Widerrufs der Einwilligung findet sich in jedem Newsletter ein entsprechender Link. Ferner besteht die Möglichkeit, sich jederzeit auch direkt auf unserer Internetseite vom Newsletter Versand abzumelden oder uns dies auf andere Weise mitzuteilen."
          }
        </Typography>

        <Typography component="p" className={classes.textParagraph}>
          {
            "Rechtsgrundlage der Datenverarbeitung zum Zwecke des Newsletter Versands ist Art. 6 Abs. 1 lit. a DS-GVO."
          }
        </Typography>

        <Typography variant="h2" className={classes.h2StaticPage}>
          11. Ihre Rechte als betroffene Person
        </Typography>

        <Typography variant="h3" className={classes.h3StaticPage}>
          11.1 Recht auf Bestätigung
        </Typography>

        <Typography component="p" className={classes.textParagraph}>
          {
            "Sie haben das Recht, von uns eine Bestätigung darüber zu verlangen, ob Sie betreffende personenbezogene Daten verarbeitet werden."
          }
        </Typography>

        <Typography variant="h3" className={classes.h3StaticPage}>
          11.2 Recht auf Auskunft Art. 13 DS-GVO
        </Typography>

        <Typography component="p" className={classes.textParagraph}>
          {
            "Sie haben das Recht, jederzeit von uns unentgeltliche Auskunft über die zu Ihrer Person gespeicherten personenbezogenen Daten sowie eine Kopie dieser Daten nach Maßgabe der gesetzlichen Bestimmungen zu erhalten."
          }
        </Typography>

        <Typography variant="h3" className={classes.h3StaticPage}>
          11.3 Recht auf Berichtigung Art. 14 DS-GVO
        </Typography>

        <Typography component="p" className={classes.textParagraph}>
          {
            "Sie haben das Recht, die Berichtigung Sie betreffender unrichtiger personenbezogener Daten zu verlangen. Ferner steht Ihnen das Recht zu, unter Berücksichtigung der Zwecke der Verarbeitung, die Vervollständigung unvollständiger personenbezogener Daten zu verlangen."
          }
        </Typography>

        <Typography variant="h3" className={classes.h3StaticPage}>
          11.4 Löschung Art. 15 DS-GVO
        </Typography>

        <Typography component="p" className={classes.textParagraph}>
          {
            "Sie haben das Recht, von uns zu verlangen, dass die Sie betreffenden personenbezogenen Daten unverzüglich gelöscht werden, sofern einer der gesetzlich vorgesehenen Gründe zutrifft und soweit die Verarbeitung bzw. Speicherung nicht erforderlich ist."
          }
        </Typography>

        <Typography variant="h3" className={classes.h3StaticPage}>
          11.5 Einschränkung der Verarbeitung Art. 18 DS-GVO
        </Typography>

        <Typography component="p" className={classes.textParagraph}>
          {
            "Sie haben das Recht, von uns die Einschränkung der Verarbeitung zu verlangen, wenn eine der gesetzlichen Voraussetzungen gegeben ist."
          }
        </Typography>

        <Typography variant="h3" className={classes.h3StaticPage}>
          11.6 Datenübertragbarkeit Art. 20 DS-GVO
        </Typography>

        <Typography component="p" className={classes.textParagraph}>
          {
            "Sie haben das Recht, die Sie betreffenden personenbezogenen Daten, welche uns durch Sie bereitgestellt wurden, in einem strukturierten, gängigen und maschinenlesbaren Format zu erhalten."
          }
        </Typography>

        <Typography component="p" className={classes.textParagraph}>
          {
            "Sie haben außerdem das Recht, diese Daten einem anderen Verantwortlichen ohne Behinderung durch uns, dem die personenbezogenen Daten bereitgestellt wurden, zu übermitteln, sofern die Verarbeitung auf der Einwilligung gemäß Art. 6 Abs. 1 lit. a DS-GVO oder Art. 9 Abs. 2 lit. a DS-GVO oder auf einem Vertrag gemäß Art. 6 Abs. 1 lit. b DS-GVO beruht und die Verarbeitung mithilfe automatisierter Verfahren erfolgt, sofern die Verarbeitung nicht für die Wahrnehmung einer Aufgabe erforderlich ist, die im öffentlichen Interesse liegt oder in Ausübung öffentlicher Gewalt erfolgt, welche an uns übertragen wurde."
          }
        </Typography>

        <Typography component="p" className={classes.textParagraph}>
          {
            "Ferner haben Sie bei der Ausübung Ihres Rechts auf Datenübertragbarkeit gemäß Art. 20 Abs. 1 DS-GVO das Recht, zu erwirken, dass die personenbezogenen Daten direkt von einem Verantwortlichen an einen anderen Verantwortlichen übermittelt werden, soweit dies technisch machbar ist und sofern hiervon nicht die Rechte und Freiheiten anderer Personen beeinträchtigt werden."
          }
        </Typography>

        <Typography variant="h3" className={classes.h3StaticPage}>
          11.7 Widerspruch Art. 21 DS-GVO
        </Typography>

        <Typography component="p" className={classes.textParagraph}>
          {
            "SIE HABEN DAS RECHT, AUS GRÜNDEN, DIE SICH AUS IHRER BESONDEREN SITUATION ERGEBEN, JEDERZEIT GEGEN DIE VERARBEITUNG SIE BETREFFENDER PERSONENBEZOGENER DATEN, DIE AUFGRUND VON ART. 6 ABS. 1 LIT. E (DATENVERARBEITUNG IM ÖFFENTLICHEN INTERESSE) ODER F (DATENVERARBEITUNG AUF GRUNDLAGE EINER INTERESSENABWÄGUNG) DS-GVO ERFOLGT, WIDERSPRUCH EINZULEGEN. DIES GILT AUCH FÜR EIN AUF DIESE BESTIMMUNGEN GESTÜTZTES PROFILING IM SINNE VON ART. 4 NR. 4 DS-GVO."
          }
        </Typography>

        <Typography component="p" className={classes.textParagraph}>
          {
            "LEGEN SIE WIDERSPRUCH EIN, WERDEN WIR IHRE PERSONENBEZOGENEN DATEN NICHT MEHR VERARBEITEN, ES SEI DENN, WIR KÖNNEN ZWINGENDE BERECHTIGTE GRÜNDE FÜR DIE VERARBEITUNG NACHWEISEN, DIE IHRE INTERESSEN, RECHTE UND FREIHEITEN ÜBERWIEGEN, ODER DIE VERARBEITUNG DIENT DER GELTENDMACHUNG, AUSÜBUNG ODER VERTEIDIGUNG VON RECHTSANSPRÜCHEN."
          }
        </Typography>

        <Typography component="p" className={classes.textParagraph}>
          {
            "IN EINZELFÄLLEN VERARBEITEN WIR PERSONENBEZOGENE DATEN, UM DIREKTWERBUNG ZU BETREIBEN. SIE KÖNNEN JEDERZEIT WIDERSPRUCH GEGEN DIE VERARBEITUNG DER PERSONENBEZOGENEN DATEN ZUM ZWECKE DERARTIGER WERBUNG EINLEGEN. DIES GILT AUCH FÜR DAS PROFILING, SOWEIT ES MIT SOLCHER DIREKTWERBUNG IN VERBINDUNG STEHT. WIDERSPRECHEN SIE GEGENÜBER UNS DER VERARBEITUNG FÜR ZWECKE DER DIREKTWERBUNG, SO WERDEN WIR DIE PERSONENBEZOGENEN DATEN NICHT MEHR FÜR DIESE ZWECKE VERARBEITEN."
          }
        </Typography>

        <Typography component="p" className={classes.textParagraph}>
          {
            "ZUDEM HABEN SIE DAS RECHT, AUS GRÜNDEN, DIE SICH AUS IHRER BESONDEREN SITUATION ERGEBEN, GEGEN DIE SIE BETREFFENDE VERARBEITUNG PERSONENBEZOGENER DATEN DIE BEI UNS ZU WISSENSCHAFTLICHEN ODER HISTORISCHEN FORSCHUNGSZWECKEN ODER ZU STATISTISCHEN ZWECKEN GEMÄSS ART. 89 ABS. 1 DS-GVO ERFOLGEN, WIDERSPRUCH EINZULEGEN, ES SEI DENN, EINE SOLCHE VERARBEITUNG IST ZUR ERFÜLLUNG EINER IM ÖFFENTLICHEN INTERESSE LIEGENDEN AUFGABE FÜR UNS ERFORDERLICH."
          }
        </Typography>

        <Typography component="p" className={classes.textParagraph}>
          {
            "IHNEN STEHT ES FREI, IM ZUSAMMENHANG MIT DER NUTZUNG VON DIENSTEN DER INFORMATIONSGESELLSCHAFT, UNGEACHTET DER RICHTLINIE 2002/58/EG, IHR WIDERSPRUCHSRECHT AUCH MITTELS AUTOMATISIERTER VERFAHREN AUSZUÜBEN, BEI DENEN TECHNISCHE SPEZIFIKATIONEN VERWENDET WERDEN."
          }
        </Typography>

        <Typography variant="h3" className={classes.h3StaticPage}>
          11.8 Widerruf einer datenschutzrechtlichen Einwilligung
        </Typography>

        <Typography component="p" className={classes.textParagraph}>
          {
            "Sie haben das Recht, eine Einwilligung zur Verarbeitung personenbezogener Daten jederzeit mit Wirkung für die Zukunft zu widerrufen. Durch den Widerruf der Einwilligung wird die Rechtmäßigkeit, der aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung nicht berührt. Die Daten werden im Falle eines Widerrufs umgehend gelöscht, falls diese Daten nicht einer gesetzlichen Regelung zur Aufbewahrungspflicht unterliegen."
          }
        </Typography>

        <Typography component="p" className={classes.textParagraph}>
          {
            "Ein Widerspruch kann postalisch, per E-Mail oder per Fax an uns als den für die Verarbeitung verantwortlichen übermittelt werden. Es entstehen dabei keine anderen Kosten als die Portokosten bzw. die Übermittlungskosten nach den bestehenden Basistarifen."
          }
        </Typography>

        <Typography variant="h3" className={classes.h3StaticPage}>
          11.9 Beschwerde bei einer Aufsichtsbehörde
        </Typography>

        <Typography component="p" className={classes.textParagraph}>
          {
            "Sie haben das Recht, sich bei einer für Datenschutz zuständigen Aufsichtsbehörde über unsere Verarbeitung personenbezogener Daten zu beschweren."
          }
        </Typography>

        <Typography variant="h2" className={classes.h2StaticPage}>
          12. Routinemäßige Speicherung, Löschung und Sperrung
        </Typography>

        <Typography component="p" className={classes.textParagraph}>
          {
            "Wir verarbeiten und speichern Ihre personenbezogenen Daten nur für den Zeitraum, der zur Erreichung des Speicherungszwecks erforderlich ist oder sofern dies durch die Rechtsvorschriften, denen unsere Unternehmen unterliegen, vorgesehen wurde. Entfällt der Speicherungszweck oder läuft eine vorgeschriebene Speicherfrist ab, werden die personenbezogenen Daten routinemäßig und entsprechend den gesetzlichen Vorschriften gesperrt oder gelöscht."
          }
        </Typography>

        <Typography variant="h2" className={classes.h2StaticPage}>
          13. Dauer der Speicherung
        </Typography>

        <Typography component="p" className={classes.textParagraph}>
          {
            "Kriterium für die Dauer der Speicherung von personenbezogenen Daten ist die jeweilige gesetzliche Aufbewahrungsfrist. Nach Ablauf dieser Frist werden die entsprechenden Daten routinemäßig gelöscht, sofern sie nicht mehr erforderlich sind oder Sie der weitergehenden Verarbeitung nicht zugestimmt haben."
          }
        </Typography>

        <Typography variant="h2" className={classes.h2StaticPage}>
          14. Aktualität und Änderung der Datenschutzerklärung
        </Typography>

        <Typography component="p" className={classes.textParagraph}>
          {
            "Diese Datenschutzerklärung ist aktuell gültig mit Stand Oktober 2021. Durch die Weiterentwicklung unserer Webseite(n) und Angebote oder aufgrund geänderter gesetzlicher bzw. behördlicher Vorgaben kann es notwendig werden, diese Datenschutzerklärung zu ändern. "
          }
        </Typography>

        <Typography component="p" className={classes.textParagraph}>
          {
            "Weitere Informationen zum Datenschutz entnehmen Sie bitte unserer allgemeinen Datenschutzerklärung unter:"
          }
        </Typography>
      </Box>
    </TCPageLayout>
  );
};

export default TCDataProtectionPage;
