import { createStyles, IconButton, makeStyles } from "@material-ui/core";
import BugReportIcon from "@material-ui/icons/BugReport";
import ChildFriendlyIcon from "@material-ui/icons/ChildFriendly";
import EditIcon from "@material-ui/icons/Edit";
import React, { Fragment, ReactElement, useCallback, useState } from "react";
import { DebuggingContext } from "../appContext";
import { TCNoopComponent } from "../components/placeholders/noop/noopComponent";
import { environment } from "../Consts";
import { useNavigate } from "../hooks/routing/useNavigate";
import { useToggleOnboardingStatus } from "../hooks/useToggleOnboardingStatus";
import { TCAppRoutes } from "../interfaces/TCAppRoutes";

const useStyles = makeStyles(() =>
  createStyles({
    buttonWrap: {
      position: "fixed",
      zIndex: 10000,
      bottom: "10rem",
      right: "1rem",
    },
  })
);

export const TCDebuggingProvider = ({
  children,
}: {
  children: ReactElement;
}): ReactElement | null => {
  if (environment !== "development") {
    return <TCNoopComponent>{children}</TCNoopComponent>;
  }
  // Since the environment is static, the order of hooks is stable.
  /* eslint-disable react-hooks/rules-of-hooks */
  const [isDebugging, setDebugging] = useState(false);
  const styles = useStyles();
  const navigate = useNavigate();
  const { toggleOnboardingStatus } = useToggleOnboardingStatus();
  const goToPlayground = useCallback(
    () => navigate(TCAppRoutes.Playground),
    [navigate]
  );
  return (
    <Fragment>
      <div className={styles.buttonWrap}>
        <IconButton onClick={goToPlayground} color="primary">
          <ChildFriendlyIcon />
        </IconButton>

        <IconButton
          onClick={() => setDebugging(!isDebugging)}
          color={isDebugging ? "secondary" : "primary"}
        >
          <BugReportIcon />
        </IconButton>

        <IconButton onClick={toggleOnboardingStatus} color="primary">
          <EditIcon />
        </IconButton>
      </div>

      <DebuggingContext.Provider value={isDebugging}>
        {children}
      </DebuggingContext.Provider>
    </Fragment>
  );
};
