import { createStyles, makeStyles } from "@material-ui/core";
import { CustomMuiTheme } from "../../../interfaces/CustomMuiTheme";

export const useTCDialogStyles = makeStyles((theme: CustomMuiTheme) =>
  createStyles({
    dialog: {
      padding: theme.spacing("1rem", "4rem"),
      [theme.breakpoints.down("sm")]: {
        marginLeft: 0,
        marginRight: 0,
        minWidth: 0,
        boxShadow: "none",
        width: "100%",
        padding: theme.spacing(2, 0),
      },
    },
    dialogTitle: {
      [theme.breakpoints.down("sm")]: {
        paddingBottom: 0,
      },
    },
    dialogRoot: {},
    closeButton: {
      [theme.breakpoints.down("sm")]: {
        right: 0,
        top: 0,
      },
    },
    dalogContent: {
      display: "flex",
      flexDirection: "column",
    },
  })
);
