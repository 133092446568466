let formatter;

function getFormatter(numberOfDecimals?: number) {
  let config: Intl.NumberFormatOptions = {
    maximumFractionDigits: 2,
  };

  if (numberOfDecimals) {
    config = {
      maximumFractionDigits: numberOfDecimals,
      minimumFractionDigits: numberOfDecimals,
    };
  }

  if (!formatter) {
    formatter = new Intl.NumberFormat("de-DE", config);
  }
  return formatter;
}

export function formatNumber(
  input: number,
  unit?: string,
  numberOfDecimals?: number
): string {
  const unitString = unit && unit !== "" ? ` ${unit}` : "";

  try {
    return getFormatter(numberOfDecimals).format(input) + unitString;
  } catch (err) {
    return input.toString() + unitString;
  }
}
