import { useState } from "react";
import { TcDocument } from "../../../../../../generated/api-service";

interface TCDisplayFieldComplexFileViewModelProps {
  onDocumentDelete: (document: TcDocument) => void;
  showDeleteDocument: boolean;
  documentToDelete: TcDocument | undefined;
  onCloseOrAfterDelete: () => void;
}

export const useTCDisplayFieldComplexFileViewModel = (): TCDisplayFieldComplexFileViewModelProps => {
  const [showDeleteDocument, setShowDeleteDocument] = useState<boolean>(false);
  const [documentToDelete, setDocumentToDelete] = useState<TcDocument>();

  const onDocumentDelete = (document: TcDocument) => {
    setDocumentToDelete(document);
    setShowDeleteDocument(true);
  };

  const onAfterDelete = () => {
    setShowDeleteDocument(false);
  };

  return {
    onDocumentDelete,
    showDeleteDocument,
    documentToDelete,
    onCloseOrAfterDelete: onAfterDelete,
  };
};
