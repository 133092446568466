import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { noop } from "lodash";
import { useCallback, useMemo } from "react";
import { ValueValidationRange } from "../../../../../../generated/api-service";
import { dbDateFromMoment } from "../../../../../../helpers/date-time/dbDateFromMoment";
import { momentFromDbDate } from "../../../../../../helpers/date-time/momentFromDbDate";
import { fieldIsRequired } from "../../../../../../helpers/forms/fieldIsRequired";
import { fieldValuesFromFormikContext } from "../../../../../../helpers/forms/fieldValuesFromFormikContext";
import { updateFormikValue } from "../../../../../../helpers/forms/updateFormikValue";
import { useTCFormikContext } from "../../../../../../hooks/useTCFormikContext";
import { TCFieldComponentProps } from "../../../../../../interfaces/dynamicFieldInterfaces";
import { TCFormikFieldData } from "../../../../../../interfaces/formikInterfaces";
import { TCDatePickerProps } from "../../../../../pickers/TCDatePicker/TCDatePickerProps";
import { datePickerMinDateMessage } from "../../../../../pickers/TCDatePicker/TCDatePickerViewModel";
import { useTCDateRangePickerStyles } from "./TCEditFieldDateRangeStyles";

type PickerProps = Pick<
  TCDatePickerProps,
  "onChange" | "value" | "pickerProps"
>;
interface TCEditFieldDateRangeViewModel {
  leftPickerProps: PickerProps;
  rightPickerProps: PickerProps;
  isRequired: boolean;
}

export function useTCEditFieldDateRangeViewModel(
  props: TCFieldComponentProps,
  classes: ReturnType<typeof useTCDateRangePickerStyles>
): TCEditFieldDateRangeViewModel {
  const formikContext = useTCFormikContext();

  const {
    formikIdentifier,
    fieldData,
    fieldValue,
  } = fieldValuesFromFormikContext<ValueValidationRange>(
    formikContext,
    props.field
  );

  const isRequired = fieldIsRequired(props);

  const setValue = useCallback(
    (newValue: TCFormikFieldData) => {
      updateFormikValue(formikContext, formikIdentifier, newValue);
    },
    [formikContext, formikIdentifier]
  );

  const genericOnChange = useCallback(
    (keyToAdjust: "minValue" | "maxValue", newDate: MaterialUiPickersDate) => {
      setValue({
        ...fieldData,
        value: {
          ...fieldValue,
          [keyToAdjust]: dbDateFromMoment(newDate),
        },
      });
    },
    [fieldValue, fieldData, setValue]
  );

  const specificHandlers: Record<
    "minValue" | "maxValue",
    TCDatePickerProps["onChange"]
  > = useMemo(
    () => ({
      minValue: (newDate: MaterialUiPickersDate) =>
        genericOnChange("minValue", newDate),
      maxValue: (newDate: MaterialUiPickersDate) =>
        genericOnChange("maxValue", newDate),
    }),
    [genericOnChange]
  );

  const untilMinDate: MaterialUiPickersDate = useMemo(
    () => momentFromDbDate(fieldValue?.minValue as string | undefined),
    [fieldValue?.minValue]
  );

  /**
   * When the 'from' date is re-selected and bigger than the 'to' date,
   *  clear the latter.
   */
  const clearFieldOnMinDateError = useCallback(
    (errorMessage) => {
      if (errorMessage === datePickerMinDateMessage) {
        specificHandlers.maxValue(null);
      }
    },
    [specificHandlers]
  );

  const dynamicProps = useCallback(
    (
      key: "minValue" | "maxValue"
    ): Pick<
      TCDatePickerProps,
      "onChange" | "value" | "pickerProps" | "wrapperProps"
    > => ({
        value: momentFromDbDate(fieldValue?.[key] as string | undefined),
        wrapperProps: {
          className: classes.picker,
        },
        onChange: specificHandlers[key],
        pickerProps: {
          placeholder: key === "minValue" ? "Beginn" : "Ende",
          minDate: key === "maxValue" ? untilMinDate : undefined,
          inputProps: {
            required: isRequired,
          },
          onError: key === "minValue" ? noop : clearFieldOnMinDateError,
        },
      }),
    [
      fieldValue,
      specificHandlers,
      untilMinDate,
      classes,
      isRequired,
      clearFieldOnMinDateError,
    ]
  );

  const leftPickerProps = useMemo(() => dynamicProps("minValue"), [
    dynamicProps,
  ]);
  const rightPickerProps = useMemo(() => dynamicProps("maxValue"), [
    dynamicProps,
  ]);

  return {
    leftPickerProps,
    rightPickerProps,
    isRequired,
  };
}
