/**
 * Narrow down an arbitrary string to a set of allowed values.
 * Use with a 'const' allowedValues array:
 *
 * `oneOfTheseStrings("myVal", ["leVal", "myVal"] as const)`
 */
export function oneOfTheseStrings<T extends readonly string[]>(
  input: string,
  allowedValues: T
): typeof allowedValues[number] {
  if (!allowedValues.includes(input as string)) {
    throw Error(
      `Input value '${input}' is not in allowed list of values (${allowedValues
        .map((v) => `'${v}'`)
        .join(", ")})`
    );
  }
  return input;
}
