import { useEffect, useState } from "react";
import { conversationUsersStore } from "../dataHandling/stores/conversationUsersStore";

export const useMessagesBadge = (): {
  messageBadgeCounter: number;
} => {
  const [conversationUserState, setConversationUserState] = useState(
    conversationUsersStore.initialState
  );

  useEffect(() => {
    const subscription = conversationUsersStore.subscribe(
      setConversationUserState
    );

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  return {
    messageBadgeCounter: conversationUserState.unreadConversationsCount,
  };
};
