import { createStyles, makeStyles } from "@material-ui/core";

export const useTCLoadingIndicatorStyles = makeStyles(() =>
  createStyles({
    loadingWrapper: {
      width: "100%",
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignContent: "center",
    },
    circle: {},
  })
);
