import { AvatarProps, BoxProps } from "@material-ui/core";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import { User } from "../../../generated/api-service";

export enum AvatarSize {
  Default = "default",
  Big = "big",
  Small = "small",
  ExtraSmall = "xSmall",
  Medium = "medium",
  Large = "large",
  ExtraLarge = "xlarge",
}

export interface TCAvatarCustomizationProps {
  boxProps?: Exclude<
    BoxProps,
    {
      item;
      className;
    }
  >;
  avatarProps?: AvatarProps;
  classes?: Partial<ClassNameMap<TCAvatarClassKey>>;
  className?: string;
}

export type TCAvatarClassKey = "root" | "avatar";

export enum TCAvatarModes {
  Edit = "edit",
  Display = "display",
}

export interface TCAvatarProps extends TCAvatarCustomizationProps {
  size: AvatarSize | number;
  avatarMode: TCAvatarModes;
  user: User;
}
