import { BoxProps } from "@material-ui/core";
import { ReactElement } from "react";
import { FieldGroup } from "../../../generated/api-service";
import {
  DisplayMode,
  FieldValueMap,
  TCFieldRenderingProps,
} from "../../../interfaces/dynamicFieldInterfaces";
import { TCFieldGroupRenderedFieldsRenderingProps } from "./TCFieldGroupRenderedFields/TCFieldGroupRenderedFieldsProps";

export enum TCFieldGroupHeaderStyle {
  Complex = "COMPLEX",
  TitleOnly = "TITLE_ONLY",
  None = "NONE",
}
export interface TCFieldGroupRenderingProps {
  titleUntilFieldIndex?: number;
  subTitleUntilFieldIndex?: number;
  preTitleAndSubtitleElement?: ReactElement;
  titleFieldValueRenderingProps?: BoxProps;
  headerStyle?: TCFieldGroupHeaderStyle;
}

export interface TCFieldGroupProps
  extends TCFieldRenderingProps,
    TCFieldGroupRenderingProps,
    TCFieldGroupRenderedFieldsRenderingProps {
  fieldGroup: FieldGroup;
  fieldValueMap?: FieldValueMap;
  displayMode: DisplayMode;
}
