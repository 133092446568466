import { EParentableType, FieldGroup } from "../../generated/api-service";
import { ArrayWithAtLeastOneElement } from "../../interfaces/typeAliases";
import { hasAtLeastOneElement } from "../../interfaces/typeGuards";
import { parentableTypeFromParentType } from "./ parentableTypeFromParentType";

export const getFieldIdsFromGroupsOfType = (
  groups: FieldGroup[],
  type: EParentableType
): ArrayWithAtLeastOneElement<string> | undefined => {
  const ids = groups
    .map((group) =>
      (group.fields || [])
        .filter(
          (field) => parentableTypeFromParentType(field.parentType) === type
        )
        .map((field) => `${field.id}`)
    )
    .flat(1);

  return hasAtLeastOneElement(ids) ? ids : undefined;
};
