import { createStyles, makeStyles } from "@material-ui/core";
import { CustomMuiTheme } from "../../../interfaces/CustomMuiTheme";

export const useTCAppBarStyles = makeStyles((theme: CustomMuiTheme) =>
  createStyles({
    toolbar: {
      backgroundColor: theme.palette.grey[50],
      height: theme.tcCustom.layout.headerHeight,
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end",
      alignItems: "stretch",
      [theme.breakpoints.down("sm")]: {
        padding: theme.spacing(0, 3),
      },
    },
    logo: {
      marginRight: "auto",
    },
    iconButton: {
      color: theme.palette.grey[900],
      alignSelf: "center",
      margin: `0 ${theme.spacing(1)}px`,
      [theme.breakpoints.down("sm")]: {
        margin: 0,
      },
    },
    profileButton: {
      marginLeft: `${theme.spacing(5)}px`,
      [theme.breakpoints.down("sm")]: {
        marginLeft: theme.spacing(1),
      },
    },
    logoutButton: {
      margin: `0 ${theme.spacing(3)}px`,
      [theme.breakpoints.down("sm")]: {
        margin: `0 ${theme.spacing(1)}px`,
      },
    },
    notificationItem: (props: { newNotifications: boolean }) => ({
      transform: `rotateZ(${props.newNotifications ? -33 : 0}deg)`,
    }),
  })
);
