import { TCDisplayFieldPrimitive } from "../../components/dynamicFields/displayFields/TCDisplayFieldPrimitive/TCDisplayFieldPrimitive";
import { TCEditFieldBool } from "../../components/dynamicFields/editFields/primitives/TCEditFieldBool";
import { TCEditFieldDate } from "../../components/dynamicFields/editFields/primitives/TCEditFieldDate";
import { TCEditFieldDatetime } from "../../components/dynamicFields/editFields/primitives/TCEditFieldDatetime";
import { TCEditFieldDecimal } from "../../components/dynamicFields/editFields/primitives/TCEditFieldDecimal";
import { TCEditFieldInt } from "../../components/dynamicFields/editFields/primitives/TCEditFieldInt/TCEditFieldInt";
import { TCEditFieldText } from "../../components/dynamicFields/editFields/primitives/TCEditFieldText";
import { TCEditFieldTime } from "../../components/dynamicFields/editFields/primitives/TCEditFieldTime";
import { EDataType } from "../../generated/api-service";
import {
  DisplayMode,
  TCFieldComponent,
} from "../../interfaces/dynamicFieldInterfaces";

export const componentsForPrimitiveTypes: {
  [fieldDataType in Exclude<EDataType, EDataType.Complex>]: {
    [displayMode in DisplayMode]: TCFieldComponent;
  };
} = {
  [EDataType.Bool]: {
    [DisplayMode.Display]: TCDisplayFieldPrimitive,
    [DisplayMode.Input]: TCEditFieldBool,
  },
  [EDataType.Date]: {
    [DisplayMode.Display]: TCDisplayFieldPrimitive,
    [DisplayMode.Input]: TCEditFieldDate,
  },
  [EDataType.Datetime]: {
    [DisplayMode.Display]: TCDisplayFieldPrimitive,
    [DisplayMode.Input]: TCEditFieldDatetime,
  },
  [EDataType.Decimal]: {
    [DisplayMode.Display]: TCDisplayFieldPrimitive,
    [DisplayMode.Input]: TCEditFieldDecimal,
  },
  [EDataType.Int]: {
    [DisplayMode.Display]: TCDisplayFieldPrimitive,
    [DisplayMode.Input]: TCEditFieldInt,
  },
  [EDataType.Text]: {
    [DisplayMode.Display]: TCDisplayFieldPrimitive,
    [DisplayMode.Input]: TCEditFieldText,
  },
  [EDataType.Time]: {
    [DisplayMode.Display]: TCDisplayFieldPrimitive,
    [DisplayMode.Input]: TCEditFieldTime,
  },
};
