import { createStyles, makeStyles } from "@material-ui/core";
import { CustomMuiTheme } from "../../../../../../../interfaces/CustomMuiTheme";

export const useTCEditSingleTimeSlotsPatternStyles = makeStyles(
  (theme: CustomMuiTheme) =>
    createStyles({
      wrapper: {
        marginBottom: theme.spacing(3),
        textAlign: "right",
      },
      patternName: {
        marginBottom: theme.spacing(1),
      },
      deleteButton: {
        float: "none",
      },
      deleteIcon: {
        "& path": {
          stroke: theme.palette.primary.main,
        },
      },
    })
);
