import { Box } from "@material-ui/core";
import React, { FunctionComponent, useContext } from "react";
import { TCListNavigationContext } from "../TCListNavigationContext";
import { TCListNavigationDetailsViewProps } from "./TCListNavigationDetailsViewProps";
import { useTCListNavigationDetailsViewStyles } from "./TCListNavigationDetailsViewStyles";

export const TCListNavigationDetailsView: FunctionComponent<TCListNavigationDetailsViewProps> = (
  props: TCListNavigationDetailsViewProps
) => {
  const context = useContext(TCListNavigationContext);
  const classes = useTCListNavigationDetailsViewStyles({
    detailsOpened: context?.detailsOpen,
  });

  return <Box className={classes.detailsViewContainer}>{props.children}</Box>;
};
