import { FormikContextType } from "formik";
import { Field, FieldValueValuable } from "../../generated/api-service";
import {
  TCFormikFieldData,
  TCFormikValues,
} from "../../interfaces/formikInterfaces";
import { constructFormikFieldIdentifier } from "../dynamicFields/fieldName";

export interface FormikFieldValues<
  T extends FieldValueValuable["value"] = FieldValueValuable["value"]
> {
  formikIdentifier: string;
  fieldData: TCFormikFieldData;
  fieldValue: T;
}

export const fieldValuesFromFormikContext = <
  T extends FieldValueValuable["value"] = FieldValueValuable["value"]
>(
  formikContext: FormikContextType<TCFormikValues>,
  field: Field
): FormikFieldValues<T> => {
  const formikIdentifier = constructFormikFieldIdentifier(field);
  return {
    formikIdentifier,
    fieldData: formikContext.values[formikIdentifier],
    fieldValue: formikContext.values[formikIdentifier]?.value as T,
  };
};
