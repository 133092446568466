import { arrayFromNodeList } from "./array";

/**
 * Gets an arry of dom elements from an array of selectors.
 * TODO: move to @nvon/toolbox
 */
export const elementsFromSelectors = <T extends Element = Element>(
  domSelectors: string[]
): T[] => {
  const combinedSelector = domSelectors.join(", ");
  const nodeList = document.querySelectorAll(combinedSelector);
  return arrayFromNodeList(nodeList) as T[];
};
