import { Observable } from "rxjs";
import useSWR from "swr";
import { documentApi } from "../../dataHandling/autogeneratedApiServices";
import {
  DeleteDocumentRequest,
  DownloadDocumentRequest,
  GetDocumentRequest,
  PostDocumentRequest,
  TcDocument,
} from "../../generated/api-service";
import { getUniqueSwrKeyFromParameter } from "../../helpers/getUniqueSwrKeyFromParameter";
import { WithoutAuthentication } from "../../interfaces/authentication";
import { BasicSwrInterface } from "../../interfaces/basicSwrInterface";
import { ObservableHookResult } from "../../interfaces/observableHook";
import { useParameterWithAuthentication } from "./authentication/useParameterWithAuthentication";
import { useObservableWithAuthentication } from "./useObservable/useObservableWithAuthentication";

export const useCreateDocument = (): ObservableHookResult<
  WithoutAuthentication<PostDocumentRequest>,
  TcDocument
> =>
  useObservableWithAuthentication(
    documentApi.postDocument.bind(documentApi) as (
      parameter
    ) => Observable<TcDocument>
  );

export const useDeleteDocument = (): ObservableHookResult<
  WithoutAuthentication<DeleteDocumentRequest>,
  void
> =>
  useObservableWithAuthentication(
    documentApi.deleteDocument.bind(documentApi) as (
      parameter
    ) => Observable<void>
  );

export const useDownloadDocument = (): ObservableHookResult<
  WithoutAuthentication<DownloadDocumentRequest>,
  Blob
> =>
  useObservableWithAuthentication(
    documentApi.downloadDocument.bind(documentApi) as (
      parameter
    ) => Observable<Blob>
  );

export const useDownloadDocumentSwr = (
  documentAsBlobRequest: WithoutAuthentication<DownloadDocumentRequest>
): BasicSwrInterface<Blob> => {
  const parameters = useParameterWithAuthentication(documentAsBlobRequest);
  const fetcher = () =>
    documentApi.downloadDocument(parameters || {}).toPromise();

  const key = getUniqueSwrKeyFromParameter(
    ["documentApi", "downloadDocument"],
    {
      ...parameters,
    }
  );
  const { data, error } = useSWR(key, fetcher);

  return {
    result: data,
    isLoading: !error && !data,
    isError: error,
  };
};

export const useListDocuments = (
  filterParameters?: WithoutAuthentication<GetDocumentRequest>
): BasicSwrInterface<TcDocument[]> => {
  const parameters = useParameterWithAuthentication(filterParameters || {});

  const fetcher = () => documentApi.getDocument(parameters).toPromise();

  const key = getUniqueSwrKeyFromParameter(["documentApi", "getDocuments"], {
    ...parameters,
  });
  const { data, error } = useSWR(key, fetcher);

  return {
    result: data,
    isLoading: !error && !data,
    isError: error,
  };
};
