/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */

import camelCase from "lodash/camelCase";
import isArray from "lodash/isArray";
import isObject from "lodash/isObject";

export const keysToCamel = (o): any => {
  if (isArray(o)) {
    return o.map((i) => keysToCamel(i));
  }
  if (isObject(o)) {
    const n = {};

    Object.keys(o).forEach((k) => {
      n[camelCase(k)] = keysToCamel(o[k]);
    });

    return n;
  }

  return o;
};
