import React, { ReactElement } from "react";
import { Route, Switch } from "react-router-dom";
import { TCLoadingIndicator } from "../components/tamocComponents/TCLoadingIndicator/TCLoadingIndicator";
import { genericPath } from "../helpers/routingHelper";
import { TCRoute } from "../interfaces/routing";
import PageNotFound404 from "../pages/pageNotFound404/pageNotFound404";
import { componentsForRoutes } from "./componentsForRoutes";
import { routes } from "./routes";

const dynamicRoutes = Object.keys(routes).map((routeKey) => {
  const route: TCRoute = routes[routeKey];
  const pageComponent = componentsForRoutes[routeKey];
  return (
    <Route
      exact={true}
      key={pageComponent.key || routeKey}
      path={genericPath(route)}
    >
      <React.Suspense fallback={<TCLoadingIndicator isLoading={true} />}>
        {pageComponent}
      </React.Suspense>
    </Route>
  );
});

/**
 * A mapping of our routes to components for the react-router.
 */
export const TCRoutes = (): ReactElement => (
  <Switch>
    {dynamicRoutes}

    <Route path="*">
      <PageNotFound404 />
    </Route>
  </Switch>
);
