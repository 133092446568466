import { ComponentsProps } from "@material-ui/core/styles/props";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { AutocompleteProps } from "@material-ui/lab/Autocomplete/Autocomplete";
import React from "react";
import { elevationLevelForCardShadow } from "./variables";

/**
 * The compiler does not know about the Material UI Lab Types, such as
 *  autocomplete. This is a known issue:
 * https://github.com/mui-org/material-ui/issues/19427
 */
interface MaterialUiLabTypes {
  MuiAutocomplete: Partial<
    AutocompleteProps<unknown, undefined, undefined, undefined>
  >;
}

export const props: ComponentsProps & MaterialUiLabTypes = {
  MuiAccordionSummary: {
    expandIcon: <ExpandMoreIcon />,
  },
  MuiCard: {
    elevation: elevationLevelForCardShadow,
  },
  MuiSwitch: {
    color: "primary",
  },
  MuiTextField: {
    variant: "filled",
    fullWidth: true,
    InputLabelProps: {
      shrink: true,
    },
  },
  MuiAutocomplete: {
    color: "primary",
  },
  MuiPaper: {
    square: true,
    elevation: elevationLevelForCardShadow,
  },
  MuiAppBar: {
    elevation: elevationLevelForCardShadow,
  },
  MuiAccordion: {
    square: true,
  },
  MuiRadio: {
    color: "primary",
  },
};
