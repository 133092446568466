const getValue = (input: string | number | boolean): string => {
  if (typeof input === "boolean") {
    return input ? "Ja" : "Nein";
  }

  return input.toString();
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const valueFromPrimitive = (
  input: string | number | boolean
): [string, boolean] => [getValue(input), true];
