import { Box, IconButton, Menu, MenuItem } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import React, { Fragment } from "react";
import { TCMenuThreeDotProps } from "./TCMenuThreeDotProps";
import { useTCMenuThreeDotStyles } from "./TCMenuThreeDotStyles";
import { useTCMenuThreeDotViewModel } from "./TCMenuThreeDotViewModel";

/** A generic three dot menu. */
export const TCMenuThreeDot: React.FunctionComponent<TCMenuThreeDotProps> = (
  props
) => {
  const classes = useTCMenuThreeDotStyles();
  const viewModel = useTCMenuThreeDotViewModel();

  return (
    <Fragment>
      <Box className={classes.menuButtonBox}>
        <IconButton
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={viewModel.onMenuButtonClick}
        >
          <MoreVertIcon />
        </IconButton>
      </Box>

      <Menu
        id="long-menu"
        anchorEl={viewModel.anchorElement}
        keepMounted={true}
        open={Boolean(viewModel.anchorElement)}
        onClose={viewModel.onMenuClose}
        PaperProps={{
          style: {
            width: "20ch",
          },
        }}
      >
        {props.menuOptions.map((option) => (
          <MenuItem
            key={option.label}
            onClick={() => {
              viewModel.onMenuOptionClick(option);
            }}
          >
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </Fragment>
  );
};
