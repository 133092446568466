import FileSaver from "file-saver";
import { useCallback, useMemo, useRef, useState } from "react";
import { TcDocument } from "../../../../../generated/api-service";
import { useDownloadDocument } from "../../../../../hooks/tamocApiHooks/documentHooks";
import { useUser } from "../../../../../hooks/useUser";
import { RequestStatus } from "../../../../../interfaces/requestStatus";
import { MenuOption } from "../../../../menu/TCMenuThreeDot/TCMenuThreeDotProps";
import { TCDocumentListItemProps } from "./TCDocumentListItemProps";

interface TCDocumentListItemViewModel {
  downloadDocument: () => void;
  documentToDisplay: TcDocument | undefined;
  menuOptions: MenuOption[];
  onDocumentDelete: () => void;
  onDisplayDocument: () => void;
  onDisplayDocumentClose: () => void;
}

export const useTCDocumentListItemViewModel = (
  props: TCDocumentListItemProps
): TCDocumentListItemViewModel | null => {
  const user = useUser();
  const [documentToDownload, setDocumentToDownload] = useState<
    TcDocument | undefined
  >();
  const [documentToDisplay, setDocumentToDisplay] = useState<
    TcDocument | undefined
  >();
  const downloadDocumentService = useDownloadDocument();

  const { document, onDocumentDelete } = props;

  const deleteDocument = useCallback(() => {
    if (onDocumentDelete) {
      onDocumentDelete(document);
    }
  }, [document, onDocumentDelete]);

  const downloadDocument = useCallback(() => {
    if (downloadDocumentService.status !== RequestStatus.Pending) {
      downloadDocumentService.call({
        documentId: document.id,
      });
      setDocumentToDownload(document);
    }
  }, [downloadDocumentService, document]);

  const onDisplayDocument = useCallback(() => {
    setDocumentToDisplay(document);
  }, [document]);

  const onDisplayDocumentClose = useRef(() => {
    setDocumentToDisplay(undefined);
  }).current;

  const menuOptions = useMemo(() => {
    const options = [
      {
        label: "Download",
        onClick: downloadDocument,
      },
    ];

    const userOwnsDocument = user && document && user.id === document.userId;

    if (userOwnsDocument) {
      options.push({
        label: "Löschen",
        onClick: deleteDocument,
      });
    }

    return options;
  }, [user, document, downloadDocument, deleteDocument]);

  if (!user) {
    return null;
  }

  if (downloadDocumentService.result && documentToDownload) {
    const downloadedBlob = new Blob([downloadDocumentService.result], {
      type: documentToDownload?.metaInformation.contentType,
    });
    FileSaver.saveAs(downloadedBlob, documentToDownload?.metaInformation.name);
    setDocumentToDownload(undefined);
  }

  return {
    downloadDocument,
    menuOptions,
    onDocumentDelete: deleteDocument,
    documentToDisplay,
    onDisplayDocument,
    onDisplayDocumentClose,
  };
};
