import { createStyles, fade, makeStyles } from "@material-ui/core";
import { CustomMuiTheme } from "../../../../interfaces/CustomMuiTheme";

export const useTCConversationUserCellStyles = makeStyles(
  (theme: CustomMuiTheme) => {
    const usedColor: string = theme.palette.secondary.main;
    return createStyles({
      cellRoot: (props: { hasUnreadMessages: boolean }) => ({
        paddingTop: 20,
        paddingRight: 25,
        paddingLeft: 19,
        paddingBottom: 18,
        borderBottom: "1px solid",
        borderBottomColor: fade(usedColor, 0.3),
        display: "flex",
        width: "100%",
        backgroundColor: props.hasUnreadMessages
          ? fade(theme.palette.primary.light, 0.2)
          : theme.palette.common.white,
      }),

      avatar: {
        marginRight: 20,
      },

      detailsContainer: {
        display: "flex",
        width: "100%",
        flexWrap: "wrap",
        overflow: "hidden",
      },

      title: {
        flex: 1,
        fontWeight: "bold",
        textAlign: "left",
        color: theme.palette.grey[900],
      },

      timestamp: {
        alignSelf: "flex-end",
        color: usedColor,
        marginBottom: theme.spacing(1),
      },

      messageBody: (props) => ({
        width: "100%",
        textAlign: "left",
        whiteSpace: "nowrap",
        overflow: "hidden",
        color: usedColor,
        fontWeight: props.hasUnreadMessages
          ? theme.typography.fontWeightBold
          : theme.typography.fontWeightRegular,
        textOverflow: "ellipsis",
      }),
    });
  }
);
