import { createStyles, makeStyles } from "@material-ui/core";
import { CustomMuiTheme } from "../../../../interfaces/CustomMuiTheme";

export const useTCMessageDrawerStyles = makeStyles((theme: CustomMuiTheme) =>
  createStyles({
    paper: {
      width: "100%",
      maxWidth: theme.tcCustom.layout.messagesDrawerMaxWidth,
      overflow: "hidden",
    },
  })
);
