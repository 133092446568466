import { Button, Tooltip } from "@material-ui/core";
import GetAppIcon from "@material-ui/icons/GetApp";
import FileSaver from "file-saver";
import React, { useCallback } from "react";
import { TCBlobDownloadButtonProps } from "./TCBlobDownloadButtonProps";

const TCBlobDownloadButton: React.FunctionComponent<TCBlobDownloadButtonProps> = ({
  mediaType,
  blob,
  fileName,
  buttonProps,
}) => {
  const download = useCallback(() => {
    const file = new Blob([blob], { type: mediaType });
    FileSaver.saveAs(file, fileName);
  }, [mediaType, blob, fileName]);

  return (
    <Tooltip arrow={true} title="Datei herunterladen">
      <Button onClick={download} {...buttonProps}>
        <GetAppIcon />
      </Button>
    </Tooltip>
  );
};

export default TCBlobDownloadButton;
