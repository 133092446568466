import { createStyles, makeStyles } from "@material-ui/core";
import { fieldTopMargin } from "../../../../../../helpers/styles/variables";
import { CustomMuiTheme } from "../../../../../../interfaces/CustomMuiTheme";

export const useTCDateRangePickerStyles = makeStyles((theme: CustomMuiTheme) =>
  createStyles({
    confirmLabel: {
      marginTop: fieldTopMargin(theme),
    },
    wrap: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "flex-start",
      flexDirection: "row",
      flexWrap: "wrap",
    },
    untilMarker: {
      alignSelf: "center",
    },
    picker: {
      position: "relative",
      flexBasis: "46%",
    },
    icon: {
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      pointerEvents: "none",
      right: theme.spacing(2),
    },
    label: {
      flexBasis: "100%",
    },
  })
);
