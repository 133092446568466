// tslint:disable
/**
 * TaMoC API V1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs';
import {
    FieldFieldGroup,
    FieldFieldGroupPost,
    FieldFieldGroupPut
} from '../models';
import { BaseAPI, encodeURI, HttpHeaders, HttpQuery, OperationOpts, RawAjaxResponse, throwIfNullOrUndefined } from '../runtime';

export interface CreateFieldFieldGroupRequest {
    accessToken: string;
    client: string;
    uid: string;
    fieldFieldGroupPost?: FieldFieldGroupPost;
}

export interface DeleteFieldFieldGroupRequest {
    accessToken: string;
    client: string;
    uid: string;
    id: number;
}

export interface GetFieldFieldGroupRequest {
    accessToken: string;
    client: string;
    uid: string;
    sortBy?: GetFieldFieldGroupSortByEnum;
    id?: number | string;
    fieldId?: number | string;
    fieldGroupId?: number | string;
    order?: number | string;
    displayConfiguration?: string;
    required?: string;
}

export interface GetFieldFieldGroupByIdRequest {
    accessToken: string;
    client: string;
    uid: string;
    id: number;
}

export interface PatchFieldFieldGroupRequest {
    accessToken: string;
    client: string;
    uid: string;
    id: number;
    fieldFieldGroupPut?: FieldFieldGroupPut;
}

export interface UpdateFieldFieldGroupRequest {
    accessToken: string;
    client: string;
    uid: string;
    id: number;
    fieldFieldGroupPut?: FieldFieldGroupPut;
}

/**
 * no description
 */
export class FieldFieldGroupApi extends BaseAPI {

    /**
     * Create FieldFieldGroup
     */
    createFieldFieldGroup({ accessToken, client, uid, fieldFieldGroupPost }: CreateFieldFieldGroupRequest): Observable<FieldFieldGroup>
    createFieldFieldGroup({ accessToken, client, uid, fieldFieldGroupPost }: CreateFieldFieldGroupRequest, opts?: OperationOpts): Observable<RawAjaxResponse<FieldFieldGroup>>
    createFieldFieldGroup({ accessToken, client, uid, fieldFieldGroupPost }: CreateFieldFieldGroupRequest, opts?: OperationOpts): Observable<FieldFieldGroup | RawAjaxResponse<FieldFieldGroup>> {
        throwIfNullOrUndefined(accessToken, 'accessToken', 'createFieldFieldGroup');
        throwIfNullOrUndefined(client, 'client', 'createFieldFieldGroup');
        throwIfNullOrUndefined(uid, 'uid', 'createFieldFieldGroup');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(accessToken != null ? { 'access-token': String(accessToken) } : undefined),
            ...(client != null ? { 'client': String(client) } : undefined),
            ...(uid != null ? { 'uid': String(uid) } : undefined),
        };

        return this.request<FieldFieldGroup>({
            url: '/api/v1/field_field_groups/',
            method: 'POST',
            headers,
            body: fieldFieldGroupPost,
        }, opts?.responseOpts);
    };

    /**
     * Deletes a FieldFieldGroup
     */
    deleteFieldFieldGroup({ accessToken, client, uid, id }: DeleteFieldFieldGroupRequest): Observable<void>
    deleteFieldFieldGroup({ accessToken, client, uid, id }: DeleteFieldFieldGroupRequest, opts?: OperationOpts): Observable<void | RawAjaxResponse<void>>
    deleteFieldFieldGroup({ accessToken, client, uid, id }: DeleteFieldFieldGroupRequest, opts?: OperationOpts): Observable<void | RawAjaxResponse<void>> {
        throwIfNullOrUndefined(accessToken, 'accessToken', 'deleteFieldFieldGroup');
        throwIfNullOrUndefined(client, 'client', 'deleteFieldFieldGroup');
        throwIfNullOrUndefined(uid, 'uid', 'deleteFieldFieldGroup');
        throwIfNullOrUndefined(id, 'id', 'deleteFieldFieldGroup');

        const headers: HttpHeaders = {
            ...(accessToken != null ? { 'access-token': String(accessToken) } : undefined),
            ...(client != null ? { 'client': String(client) } : undefined),
            ...(uid != null ? { 'uid': String(uid) } : undefined),
        };

        return this.request<void>({
            url: '/api/v1/field_field_groups/{id}'.replace('{id}', encodeURI(id)),
            method: 'DELETE',
            headers,
        }, opts?.responseOpts);
    };

    /**
     * Retrieve a FieldFieldGroup list
     */
    getFieldFieldGroup({ accessToken, client, uid, sortBy, id, fieldId, fieldGroupId, order, displayConfiguration, required }: GetFieldFieldGroupRequest): Observable<Array<FieldFieldGroup>>
    getFieldFieldGroup({ accessToken, client, uid, sortBy, id, fieldId, fieldGroupId, order, displayConfiguration, required }: GetFieldFieldGroupRequest, opts?: OperationOpts): Observable<RawAjaxResponse<Array<FieldFieldGroup>>>
    getFieldFieldGroup({ accessToken, client, uid, sortBy, id, fieldId, fieldGroupId, order, displayConfiguration, required }: GetFieldFieldGroupRequest, opts?: OperationOpts): Observable<Array<FieldFieldGroup> | RawAjaxResponse<Array<FieldFieldGroup>>> {
        throwIfNullOrUndefined(accessToken, 'accessToken', 'getFieldFieldGroup');
        throwIfNullOrUndefined(client, 'client', 'getFieldFieldGroup');
        throwIfNullOrUndefined(uid, 'uid', 'getFieldFieldGroup');

        const headers: HttpHeaders = {
            ...(accessToken != null ? { 'access-token': String(accessToken) } : undefined),
            ...(client != null ? { 'client': String(client) } : undefined),
            ...(uid != null ? { 'uid': String(uid) } : undefined),
        };

        const query: HttpQuery = {};

        if (sortBy != null) { query['sort_by'] = sortBy; }
        if (id != null) { query['id'] = id; }
        if (fieldId != null) { query['field_id'] = fieldId; }
        if (fieldGroupId != null) { query['field_group_id'] = fieldGroupId; }
        if (order != null) { query['order'] = order; }
        if (displayConfiguration != null) { query['display_configuration'] = displayConfiguration; }
        if (required != null) { query['required'] = required; }

        return this.request<Array<FieldFieldGroup>>({
            url: '/api/v1/field_field_groups/',
            method: 'GET',
            headers,
            query,
        }, opts?.responseOpts);
    };

    /**
     * Retrieves a FieldFieldGroup
     */
    getFieldFieldGroupById({ accessToken, client, uid, id }: GetFieldFieldGroupByIdRequest): Observable<FieldFieldGroup>
    getFieldFieldGroupById({ accessToken, client, uid, id }: GetFieldFieldGroupByIdRequest, opts?: OperationOpts): Observable<RawAjaxResponse<FieldFieldGroup>>
    getFieldFieldGroupById({ accessToken, client, uid, id }: GetFieldFieldGroupByIdRequest, opts?: OperationOpts): Observable<FieldFieldGroup | RawAjaxResponse<FieldFieldGroup>> {
        throwIfNullOrUndefined(accessToken, 'accessToken', 'getFieldFieldGroupById');
        throwIfNullOrUndefined(client, 'client', 'getFieldFieldGroupById');
        throwIfNullOrUndefined(uid, 'uid', 'getFieldFieldGroupById');
        throwIfNullOrUndefined(id, 'id', 'getFieldFieldGroupById');

        const headers: HttpHeaders = {
            ...(accessToken != null ? { 'access-token': String(accessToken) } : undefined),
            ...(client != null ? { 'client': String(client) } : undefined),
            ...(uid != null ? { 'uid': String(uid) } : undefined),
        };

        return this.request<FieldFieldGroup>({
            url: '/api/v1/field_field_groups/{id}'.replace('{id}', encodeURI(id)),
            method: 'GET',
            headers,
        }, opts?.responseOpts);
    };

    /**
     * Patch a FieldFieldGroup
     */
    patchFieldFieldGroup({ accessToken, client, uid, id, fieldFieldGroupPut }: PatchFieldFieldGroupRequest): Observable<FieldFieldGroup>
    patchFieldFieldGroup({ accessToken, client, uid, id, fieldFieldGroupPut }: PatchFieldFieldGroupRequest, opts?: OperationOpts): Observable<RawAjaxResponse<FieldFieldGroup>>
    patchFieldFieldGroup({ accessToken, client, uid, id, fieldFieldGroupPut }: PatchFieldFieldGroupRequest, opts?: OperationOpts): Observable<FieldFieldGroup | RawAjaxResponse<FieldFieldGroup>> {
        throwIfNullOrUndefined(accessToken, 'accessToken', 'patchFieldFieldGroup');
        throwIfNullOrUndefined(client, 'client', 'patchFieldFieldGroup');
        throwIfNullOrUndefined(uid, 'uid', 'patchFieldFieldGroup');
        throwIfNullOrUndefined(id, 'id', 'patchFieldFieldGroup');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(accessToken != null ? { 'access-token': String(accessToken) } : undefined),
            ...(client != null ? { 'client': String(client) } : undefined),
            ...(uid != null ? { 'uid': String(uid) } : undefined),
        };

        return this.request<FieldFieldGroup>({
            url: '/api/v1/field_field_groups/{id}'.replace('{id}', encodeURI(id)),
            method: 'PATCH',
            headers,
            body: fieldFieldGroupPut,
        }, opts?.responseOpts);
    };

    /**
     * Update a FieldFieldGroup
     */
    updateFieldFieldGroup({ accessToken, client, uid, id, fieldFieldGroupPut }: UpdateFieldFieldGroupRequest): Observable<FieldFieldGroup>
    updateFieldFieldGroup({ accessToken, client, uid, id, fieldFieldGroupPut }: UpdateFieldFieldGroupRequest, opts?: OperationOpts): Observable<RawAjaxResponse<FieldFieldGroup>>
    updateFieldFieldGroup({ accessToken, client, uid, id, fieldFieldGroupPut }: UpdateFieldFieldGroupRequest, opts?: OperationOpts): Observable<FieldFieldGroup | RawAjaxResponse<FieldFieldGroup>> {
        throwIfNullOrUndefined(accessToken, 'accessToken', 'updateFieldFieldGroup');
        throwIfNullOrUndefined(client, 'client', 'updateFieldFieldGroup');
        throwIfNullOrUndefined(uid, 'uid', 'updateFieldFieldGroup');
        throwIfNullOrUndefined(id, 'id', 'updateFieldFieldGroup');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(accessToken != null ? { 'access-token': String(accessToken) } : undefined),
            ...(client != null ? { 'client': String(client) } : undefined),
            ...(uid != null ? { 'uid': String(uid) } : undefined),
        };

        return this.request<FieldFieldGroup>({
            url: '/api/v1/field_field_groups/{id}'.replace('{id}', encodeURI(id)),
            method: 'PUT',
            headers,
            body: fieldFieldGroupPut,
        }, opts?.responseOpts);
    };

}

/**
 * @export
 * @enum {string}
 */
export enum GetFieldFieldGroupSortByEnum {
    IdAsc = 'id_asc',
    IdDesc = 'id_desc',
    FieldIdAsc = 'field_id_asc',
    FieldIdDesc = 'field_id_desc',
    FieldGroupIdAsc = 'field_group_id_asc',
    FieldGroupIdDesc = 'field_group_id_desc',
    OrderAsc = 'order_asc',
    OrderDesc = 'order_desc',
    DisplayConfigurationAsc = 'display_configuration_asc',
    DisplayConfigurationDesc = 'display_configuration_desc',
    RequiredAsc = 'required_asc',
    RequiredDesc = 'required_desc'
}
