import { FilesMeta } from "../../generated/api-service";
import { TCFormikValues } from "../../interfaces/formikInterfaces";

interface FileUpload {
  // Note: File is a Blob
  // stackoverflow.com/questions/33855167/convert-data-file-to-blob/33855825
  files: Blob[];
  filesMeta: FilesMeta;
}

export const formikValuesToFileUploadData = (
  formikValues: TCFormikValues
): FileUpload | undefined => {
  const result: FileUpload = {
    files: [],
    filesMeta: { fieldIds: [] },
  };

  Object.values(formikValues).forEach((formikValue) => {
    (formikValue.newFiles || []).forEach((file) => {
      result.files.push(file);
      result.filesMeta.fieldIds.push(formikValue.field.id);
    });
  });

  return result.files.length ? result : undefined;
};
