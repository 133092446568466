import { Overrides } from "@material-ui/core/styles/overrides";

export const globalCss: Overrides["MuiCssBaseline"] = {
  "@global": {
    "body .MuiInputBase-multiline": {
      padding: 0,
    },
    ".field-type-name-timespan": {
      textAlign: "left",
    },
    ".MuiPickersTimePickerToolbar-hourMinuteLabel h2": {
      margin: 0,
      lineHeight: 1,
    },
    "*, body": {
      boxSizing: "border-box",

      // Hide scrollbars
      "-ms-overflow-style": "none",
      scrollbarWidth: "none",
      "&::-webkit-scrollbar": {
        display: "none",
      },
    },
  },
};
