import { Field } from "../../generated/api-service";

/**
 * Returns a string for the label to be displayed,
 *  or undefined if there shouldn't be any.
 */
export const getFieldLabel = (field: Field): string | undefined => {
  if (field.displayConfiguration.label?.labelHide) {
    return undefined;
  }
  if (field.displayConfiguration.label?.labelOverride) {
    return field.displayConfiguration.label?.labelOverride;
  }
  if (field.label) {
    return field.label;
  }
  return undefined;
};
