import { Button } from "@material-ui/core";
import HighlightOffRoundedIcon from "@material-ui/icons/HighlightOffRounded";
import React from "react";
import { useMergedProps } from "../../../hooks/useMergedProps";
import { TCDeclineButtonProps } from "./TCDeclineButtonProps";
import { useTCDeclineButtonStyles } from "./TCDeclineButtonStyles";

const declineButtonDefaults: Partial<TCDeclineButtonProps> = {
  buttonProps: { "aria-label": "Ablehnen", size: "small" },
  useIcon: true,
};

/**
 * A colored decline button that can use an icon (useIcon=true) and/or
 * a text (buttonText != '')
 */
export const TCDeclineButton: React.FunctionComponent<TCDeclineButtonProps> = (
  props
) => {
  const classes = useTCDeclineButtonStyles(props);

  const buttonProps = useMergedProps(
    declineButtonDefaults.buttonProps,
    props.buttonProps,
    classes.button
  );

  return (
    <Button {...buttonProps} onClick={props.onClick}>
      {props.useIcon ? (
        <HighlightOffRoundedIcon className={classes.icon} />
      ) : null}

      {props.buttonText}
    </Button>
  );
};
