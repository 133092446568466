import { useCallback } from "react";
import { Observable } from "rxjs";
import { WithoutAuthentication } from "../../../interfaces/authentication";
import { ObservableHookResult } from "../../../interfaces/observableHook";
import { useAuthenticationParameter } from "../authentication/useAuthenticationParameter";
import { useObservable } from "./useObservable";

export const useObservableWithAuthentication = <I, T>(
  observableCreator: (parameter: I) => Observable<T>
): ObservableHookResult<WithoutAuthentication<I>, T> => {
  const authenticationParameter = useAuthenticationParameter();

  const callback = useCallback(
    (parameter: WithoutAuthentication<I>) => {
      const mergedParameter = ({
        ...parameter,
        ...authenticationParameter,
      } as unknown) as I;

      return observableCreator(mergedParameter);
    },
    [authenticationParameter, observableCreator]
  );

  return useObservable<WithoutAuthentication<I>, T>(callback);
};
