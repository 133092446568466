import { activeTenant } from "../Consts";
import { CustomMuiTheme } from "../interfaces/CustomMuiTheme";
import * as themes from "../tenants";

const activeThemeKey = `${activeTenant}Theme`;

export const tenantTheme: CustomMuiTheme = themes[activeThemeKey];

if (!tenantTheme) {
  throw Error(`Fatal: Could not find a theme ${activeThemeKey}.`);
}
