// tslint:disable
/**
 * TaMoC API V1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface ValueValidationFileValidations
 */
export interface ValueValidationFileValidations {
    /**
     * @type {string}
     * @memberof ValueValidationFileValidations
     */
    dateTime: string;
    /**
     * @type {number}
     * @memberof ValueValidationFileValidations
     */
    validatingUserId?: number;
    /**
     * @type {string}
     * @memberof ValueValidationFileValidations
     */
    newValidationStatus: ValueValidationFileValidationsNewValidationStatusEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum ValueValidationFileValidationsNewValidationStatusEnum {
    ValidationFailed = 'validationFailed',
    ValidationSuccessful = 'validationSuccessful'
}

