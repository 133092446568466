// tslint:disable
/**
 * TaMoC API V1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs';
import {
    EDataType, FieldType,
    FieldTypePost,
    FieldTypePut
} from '../models';
import { BaseAPI, encodeURI, HttpHeaders, HttpQuery, OperationOpts, RawAjaxResponse, throwIfNullOrUndefined } from '../runtime';

export interface CreateFieldTypeRequest {
    accessToken: string;
    client: string;
    uid: string;
    fieldTypePost?: FieldTypePost;
}

export interface DeleteFieldTypeRequest {
    accessToken: string;
    client: string;
    uid: string;
    id: number;
}

export interface GetFieldTypeRequest {
    accessToken: string;
    client: string;
    uid: string;
    sortBy?: GetFieldTypeSortByEnum;
    id?: number | string;
    name?: string;
    complexTypeId?: number | string;
    validationSchema?: string;
    displayConfiguration?: string;
    dataType?: EDataType;
    createdAt?: string;
    updatedAt?: string;
}

export interface GetFieldTypeByIdRequest {
    accessToken: string;
    client: string;
    uid: string;
    id: number;
}

export interface PatchFieldTypeRequest {
    accessToken: string;
    client: string;
    uid: string;
    id: number;
    fieldTypePut?: FieldTypePut;
}

export interface UpdateFieldTypeRequest {
    accessToken: string;
    client: string;
    uid: string;
    id: number;
    fieldTypePut?: FieldTypePut;
}

/**
 * no description
 */
export class FieldTypeApi extends BaseAPI {

    /**
     * Create FieldType
     */
    createFieldType({ accessToken, client, uid, fieldTypePost }: CreateFieldTypeRequest): Observable<FieldType>
    createFieldType({ accessToken, client, uid, fieldTypePost }: CreateFieldTypeRequest, opts?: OperationOpts): Observable<RawAjaxResponse<FieldType>>
    createFieldType({ accessToken, client, uid, fieldTypePost }: CreateFieldTypeRequest, opts?: OperationOpts): Observable<FieldType | RawAjaxResponse<FieldType>> {
        throwIfNullOrUndefined(accessToken, 'accessToken', 'createFieldType');
        throwIfNullOrUndefined(client, 'client', 'createFieldType');
        throwIfNullOrUndefined(uid, 'uid', 'createFieldType');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(accessToken != null ? { 'access-token': String(accessToken) } : undefined),
            ...(client != null ? { 'client': String(client) } : undefined),
            ...(uid != null ? { 'uid': String(uid) } : undefined),
        };

        return this.request<FieldType>({
            url: '/api/v1/field_types/',
            method: 'POST',
            headers,
            body: fieldTypePost,
        }, opts?.responseOpts);
    };

    /**
     * Deletes a FieldType
     */
    deleteFieldType({ accessToken, client, uid, id }: DeleteFieldTypeRequest): Observable<void>
    deleteFieldType({ accessToken, client, uid, id }: DeleteFieldTypeRequest, opts?: OperationOpts): Observable<void | RawAjaxResponse<void>>
    deleteFieldType({ accessToken, client, uid, id }: DeleteFieldTypeRequest, opts?: OperationOpts): Observable<void | RawAjaxResponse<void>> {
        throwIfNullOrUndefined(accessToken, 'accessToken', 'deleteFieldType');
        throwIfNullOrUndefined(client, 'client', 'deleteFieldType');
        throwIfNullOrUndefined(uid, 'uid', 'deleteFieldType');
        throwIfNullOrUndefined(id, 'id', 'deleteFieldType');

        const headers: HttpHeaders = {
            ...(accessToken != null ? { 'access-token': String(accessToken) } : undefined),
            ...(client != null ? { 'client': String(client) } : undefined),
            ...(uid != null ? { 'uid': String(uid) } : undefined),
        };

        return this.request<void>({
            url: '/api/v1/field_types/{id}'.replace('{id}', encodeURI(id)),
            method: 'DELETE',
            headers,
        }, opts?.responseOpts);
    };

    /**
     * Retrieve a FieldType list
     */
    getFieldType({ accessToken, client, uid, sortBy, id, name, complexTypeId, validationSchema, displayConfiguration, dataType, createdAt, updatedAt }: GetFieldTypeRequest): Observable<Array<FieldType>>
    getFieldType({ accessToken, client, uid, sortBy, id, name, complexTypeId, validationSchema, displayConfiguration, dataType, createdAt, updatedAt }: GetFieldTypeRequest, opts?: OperationOpts): Observable<RawAjaxResponse<Array<FieldType>>>
    getFieldType({ accessToken, client, uid, sortBy, id, name, complexTypeId, validationSchema, displayConfiguration, dataType, createdAt, updatedAt }: GetFieldTypeRequest, opts?: OperationOpts): Observable<Array<FieldType> | RawAjaxResponse<Array<FieldType>>> {
        throwIfNullOrUndefined(accessToken, 'accessToken', 'getFieldType');
        throwIfNullOrUndefined(client, 'client', 'getFieldType');
        throwIfNullOrUndefined(uid, 'uid', 'getFieldType');

        const headers: HttpHeaders = {
            ...(accessToken != null ? { 'access-token': String(accessToken) } : undefined),
            ...(client != null ? { 'client': String(client) } : undefined),
            ...(uid != null ? { 'uid': String(uid) } : undefined),
        };

        const query: HttpQuery = {};

        if (sortBy != null) { query['sort_by'] = sortBy; }
        if (id != null) { query['id'] = id; }
        if (name != null) { query['name'] = name; }
        if (complexTypeId != null) { query['complex_type_id'] = complexTypeId; }
        if (validationSchema != null) { query['validation_schema'] = validationSchema; }
        if (displayConfiguration != null) { query['display_configuration'] = displayConfiguration; }
        if (dataType != null) { query['data_type'] = dataType; }
        if (createdAt != null) { query['created_at'] = (createdAt as any).toISOString(); }
        if (updatedAt != null) { query['updated_at'] = (updatedAt as any).toISOString(); }

        return this.request<Array<FieldType>>({
            url: '/api/v1/field_types/',
            method: 'GET',
            headers,
            query,
        }, opts?.responseOpts);
    };

    /**
     * Retrieves a FieldType
     */
    getFieldTypeById({ accessToken, client, uid, id }: GetFieldTypeByIdRequest): Observable<FieldType>
    getFieldTypeById({ accessToken, client, uid, id }: GetFieldTypeByIdRequest, opts?: OperationOpts): Observable<RawAjaxResponse<FieldType>>
    getFieldTypeById({ accessToken, client, uid, id }: GetFieldTypeByIdRequest, opts?: OperationOpts): Observable<FieldType | RawAjaxResponse<FieldType>> {
        throwIfNullOrUndefined(accessToken, 'accessToken', 'getFieldTypeById');
        throwIfNullOrUndefined(client, 'client', 'getFieldTypeById');
        throwIfNullOrUndefined(uid, 'uid', 'getFieldTypeById');
        throwIfNullOrUndefined(id, 'id', 'getFieldTypeById');

        const headers: HttpHeaders = {
            ...(accessToken != null ? { 'access-token': String(accessToken) } : undefined),
            ...(client != null ? { 'client': String(client) } : undefined),
            ...(uid != null ? { 'uid': String(uid) } : undefined),
        };

        return this.request<FieldType>({
            url: '/api/v1/field_types/{id}'.replace('{id}', encodeURI(id)),
            method: 'GET',
            headers,
        }, opts?.responseOpts);
    };

    /**
     * Patch a FieldType
     */
    patchFieldType({ accessToken, client, uid, id, fieldTypePut }: PatchFieldTypeRequest): Observable<FieldType>
    patchFieldType({ accessToken, client, uid, id, fieldTypePut }: PatchFieldTypeRequest, opts?: OperationOpts): Observable<RawAjaxResponse<FieldType>>
    patchFieldType({ accessToken, client, uid, id, fieldTypePut }: PatchFieldTypeRequest, opts?: OperationOpts): Observable<FieldType | RawAjaxResponse<FieldType>> {
        throwIfNullOrUndefined(accessToken, 'accessToken', 'patchFieldType');
        throwIfNullOrUndefined(client, 'client', 'patchFieldType');
        throwIfNullOrUndefined(uid, 'uid', 'patchFieldType');
        throwIfNullOrUndefined(id, 'id', 'patchFieldType');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(accessToken != null ? { 'access-token': String(accessToken) } : undefined),
            ...(client != null ? { 'client': String(client) } : undefined),
            ...(uid != null ? { 'uid': String(uid) } : undefined),
        };

        return this.request<FieldType>({
            url: '/api/v1/field_types/{id}'.replace('{id}', encodeURI(id)),
            method: 'PATCH',
            headers,
            body: fieldTypePut,
        }, opts?.responseOpts);
    };

    /**
     * Update a FieldType
     */
    updateFieldType({ accessToken, client, uid, id, fieldTypePut }: UpdateFieldTypeRequest): Observable<FieldType>
    updateFieldType({ accessToken, client, uid, id, fieldTypePut }: UpdateFieldTypeRequest, opts?: OperationOpts): Observable<RawAjaxResponse<FieldType>>
    updateFieldType({ accessToken, client, uid, id, fieldTypePut }: UpdateFieldTypeRequest, opts?: OperationOpts): Observable<FieldType | RawAjaxResponse<FieldType>> {
        throwIfNullOrUndefined(accessToken, 'accessToken', 'updateFieldType');
        throwIfNullOrUndefined(client, 'client', 'updateFieldType');
        throwIfNullOrUndefined(uid, 'uid', 'updateFieldType');
        throwIfNullOrUndefined(id, 'id', 'updateFieldType');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(accessToken != null ? { 'access-token': String(accessToken) } : undefined),
            ...(client != null ? { 'client': String(client) } : undefined),
            ...(uid != null ? { 'uid': String(uid) } : undefined),
        };

        return this.request<FieldType>({
            url: '/api/v1/field_types/{id}'.replace('{id}', encodeURI(id)),
            method: 'PUT',
            headers,
            body: fieldTypePut,
        }, opts?.responseOpts);
    };

}

/**
 * @export
 * @enum {string}
 */
export enum GetFieldTypeSortByEnum {
    IdAsc = 'id_asc',
    IdDesc = 'id_desc',
    NameAsc = 'name_asc',
    NameDesc = 'name_desc',
    ComplexTypeIdAsc = 'complex_type_id_asc',
    ComplexTypeIdDesc = 'complex_type_id_desc',
    ValidationSchemaAsc = 'validation_schema_asc',
    ValidationSchemaDesc = 'validation_schema_desc',
    DisplayConfigurationAsc = 'display_configuration_asc',
    DisplayConfigurationDesc = 'display_configuration_desc',
    DataTypeAsc = 'data_type_asc',
    DataTypeDesc = 'data_type_desc',
    CreatedAtAsc = 'created_at_asc',
    CreatedAtDesc = 'created_at_desc',
    UpdatedAtAsc = 'updated_at_asc',
    UpdatedAtDesc = 'updated_at_desc'
}
