import { useCallback, useEffect, useState } from "react";
import { conversationApi } from "../../../../dataHandling/autogeneratedApiServices";
import { conversationUsersStore } from "../../../../dataHandling/stores/conversationUsersStore";
import {
  ECableActions,
  ECableChannels,
  Message,
} from "../../../../generated/api-service";
import { useAuthenticationParameter } from "../../../../hooks/tamocApiHooks/authentication/useAuthenticationParameter";
import { useCable } from "../../../../hooks/useCable";
import { useErrorHandler } from "../../../../hooks/useErrorHandler";
import { useUser } from "../../../../hooks/useUser";
import {
  MessagesCable,
  TCMessageListProps,
  TCMessageListViewModel,
} from "./TCMessageListProps";

export const useTCMessageListViewModel = (
  props: TCMessageListProps
): TCMessageListViewModel | null => {
  const [messages, setMessages] = useState<Message[]>(props.messages ?? []);
  const handleError = useErrorHandler();
  const user = useUser();
  const authenticationParameter = useAuthenticationParameter();
  const shouldMarkRead = user && messages[0]?.sentMessageableId !== user.id;

  const markAsRead = useCallback(() => {
    conversationApi
      .markMessageAsRead({
        conversationId: props.conversationId,
        ...authenticationParameter,
      })
      .toPromise()
      .then((result) => conversationUsersStore.updateConversationUser(result))
      .catch((error) => handleError(error));
  }, [props.conversationId, handleError, authenticationParameter]);

  useEffect(() => {
    if (shouldMarkRead && user) {
      markAsRead();
    }
  }, [messages, markAsRead, shouldMarkRead, user]);

  const onAddMessage = useCallback((message: Message) => {
    setMessages((prevState) => [message, ...prevState]);
  }, []);

  const onReceived = useCallback(
    (data: MessagesCable) => {
      const { action, message } = data;
      switch (action) {
        case ECableActions.MessagesChannelAdd:
          return onAddMessage(message);
        default:
          return (): void => {};
      }
    },
    [onAddMessage]
  );

  const isMessageLastFromGroup = useCallback(
    (message: Message, index: number): boolean => (
        messages[index - 1]?.sentMessageableId !== message.sentMessageableId
      ),
    [messages]
  );

  useCable<MessagesCable>({
    channel: ECableChannels.MessagesChannel,
    room: props.conversationId,
    onReceived,
  });

  if (!user) {
    return null;
  }

  const { id: currentUserId } = user;

  return {
    messages,
    currentUserId,

    isMessageLastFromGroup,
  };
};
