import { ECableChannels } from "../generated/api-service";
import { ECableActions } from "../generated/api-service/models/ECableActions";

export interface ActiveCableIdentifier {
  channel: ECableChannels;
  room: string;
}

export enum ActiveCableStreamType {
  ConfirmSubscription = "confirm_subscription",
  Ping = "ping",
}

export enum ActiveCableCommand {
  Subscribe = "subscribe",
  Unsubscribe = "unsubscribe",
}

export interface ActiveCableSubject {
  type?: ActiveCableStreamType;
  identifier: string;
  command: ActiveCableCommand;
}

export interface ActiveCableResponse<T> {
  type: ActiveCableStreamType;
  message: T;
}

export interface ActiveCableMessage {
  action: ECableActions;
}

export interface ActiveCable<T> {
  channel: ECableChannels;
  room?: string | number;
  onConnected?: () => void;
  onReceived: (data: T) => void;
}
