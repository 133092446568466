// tslint:disable
/**
 * TaMoC API V1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ECableActions {
    ContractsStatusChannelAdd = 'contracts_status_channel_add',
    ContractsStatusChannelUpdate = 'contracts_status_channel_update',
    ContractsStatusChannelRemove = 'contracts_status_channel_remove',
    ConversationsChannelUpdate = 'conversations-channel-update',
    JobUpdate = 'job-update',
    JobRemove = 'job-remove',
    UsersAdminChannelAdd = 'users_admin_channel_add',
    UsersAdminChannelUpdate = 'users_admin_channel_update',
    JobsWorkerJobAdd = 'jobs-worker-job-add',
    JobsWorkerJobUpdate = 'jobs-worker-job-update',
    JobsWorkerJobDelete = 'jobs-worker-job-delete',
    ContractsParentableChannelAdd = 'contracts_parentable_channel_add',
    ContractsParentableChannelUpdate = 'contracts_parentable_channel_update',
    ContractsParentableChannelRemove = 'contracts_parentable_channel_remove',
    FieldValueParentableAdd = 'field-value-parentable-add',
    FieldValueParentableUpdate = 'field-value-parentable-update',
    FieldValueParentableRemove = 'field-value-parentable-remove',
    MessagesChannelStoppedTyping = 'messages-channel-stopped-typing',
    MessagesChannelTyping = 'messages-channel-typing',
    MessagesChannelAdd = 'messages-channel-add',
    JobsEmployerJobAdd = 'jobs-employer-job-add',
    JobsEmployerJobUpdate = 'jobs-employer-job-update',
    JobsEmployerJobDelete = 'jobs-employer-job-delete'
}

