import { FieldValue } from "../../generated/api-service";
import { FieldValueMap } from "../../interfaces/dynamicFieldInterfaces";

// Transform result to FieldValueMap (fieldId => fieldValue)
export const fieldValueMapFromFieldValues = (
  fieldValues: FieldValue[]
): FieldValueMap => fieldValues.reduce(
    (res: FieldValueMap, current: FieldValue) => ({
      ...res,
      [current.fieldId]: current,
    }),
    {}
  );
