import { TextField as PlainTextField } from "@material-ui/core";
import { Field } from "formik";
import React, { useCallback, useState } from "react";
import { fieldIsRequired } from "../../../../../helpers/forms/fieldIsRequired";
import { fieldValuesFromFormikContext } from "../../../../../helpers/forms/fieldValuesFromFormikContext";
import { TextFieldWrapperProps } from "../../../../../helpers/forms/formInterfaces";
import { updateFormikValue } from "../../../../../helpers/forms/updateFormikValue";
import { useTCFormikContext } from "../../../../../hooks/useTCFormikContext";
import {
  hasFieldAndFieldGroup,
  TCFieldComponent,
} from "../../../../../interfaces/dynamicFieldInterfaces";

export const TCEditFieldPasswordUpdate: TCFieldComponent = (props) => {
  const formikContext = useTCFormikContext();

  const { formikIdentifier, fieldData } = fieldValuesFromFormikContext<string>(
    formikContext,
    props.field
  );

  const [localPasswordValue, setLocalPasswordValue] = useState<string>("");

  const onBlur = useCallback(() => {
    formikContext.setFieldTouched(formikIdentifier, true);
    updateFormikValue(formikContext, formikIdentifier, {
      ...fieldData,
      value: localPasswordValue,
    });
  }, [localPasswordValue, formikContext, formikIdentifier, fieldData]);

  if (!hasFieldAndFieldGroup(props)) {
    return null;
  }
  const required = fieldIsRequired(props);

  const passwordFieldProps: TextFieldWrapperProps = {
    autoComplete: "new-password",
    type: "password",
    required,
    component: PlainTextField,
    name: formikIdentifier,
    label: props.field.label,
    onChange: (event) => {
      const newValue = event.target.value;
      setLocalPasswordValue(newValue);
    },
    onBlur,
  };
  return <Field {...passwordFieldProps} />;
};
