import { pick, uniqWith, values } from "lodash";
import {
  FieldValueMap,
  FieldValueParentable,
} from "../interfaces/dynamicFieldInterfaces";

export const parentablesFromValueMap = (
  fieldValueMap: FieldValueMap
): FieldValueParentable[] => uniqWith(
    values(fieldValueMap),
    (a, b) =>
      a.parentableType === b.parentableType && a.parentableId === b.parentableId
  ).map((fieldValue) => pick(fieldValue, ["parentableType", "parentableId"]));
