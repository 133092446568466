import { useMediaQuery, useTheme } from "@material-ui/core";
import clsx from "clsx";
import { merge, omit } from "lodash";
import React from "react";
import { CustomMuiTheme } from "../../../interfaces/CustomMuiTheme";
import { TCAppRoutes } from "../../../interfaces/TCAppRoutes";
import { TCLink } from "../../tamocComponents/TCLink/TCLink";
import { TCLinkProps } from "../../tamocComponents/TCLink/TCLinkProps";
import { useTCTenantLogoStyles } from "./TCTenantLogoStyles";

type TCTenantLogoProps = Partial<TCLinkProps>;

interface Defaults extends TCTenantLogoProps {
  link: string;
}

const defaults: Defaults = {
  title: "Zur Startseite",
  link: "/",
};

export const TCTenantLogo: React.FunctionComponent<TCTenantLogoProps> = (
  props
) => {
  const classes = useTCTenantLogoStyles();
  const theme = useTheme<CustomMuiTheme>();
  const mergedProps = merge({}, defaults, props);
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const logoImage = theme.tcCustom[isDesktop ? "logoSvg" : "mobileLogoSvg"];

  return (
    <TCLink
      appRoute={TCAppRoutes.Dashboard}
      className={clsx(classes.linkElement, mergedProps.className)}
      {...omit(mergedProps, "imageSrc", "className")}
    >
      <img className={classes.image} src={logoImage} alt="" />
    </TCLink>
  );
};
