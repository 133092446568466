import { Button } from "@material-ui/core";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import React from "react";
import { extendElementWithDefaults } from "../../helpers/extendElementWithDefaults";

export const TCBackwardButton = extendElementWithDefaults({
  baseElement: Button,
  defaults: {
    variant: "text",
    color: "primary",
    children: "Schritt zurück",
    startIcon: <KeyboardBackspaceIcon style={{ marginRight: "1rem" }} />,
  },
});
