import { createStyles, makeStyles } from "@material-ui/core";
import { CustomMuiTheme } from "../../../../interfaces/CustomMuiTheme";

export const useTCMessageListStyles = makeStyles((theme: CustomMuiTheme) =>
  createStyles({
    messagesContainer: (isFirefox) => ({
      height: "100%",
      backgroundColor: "transparent",
      padding: theme.spacing(2),
      flexDirection: isFirefox
        ? ("column" as const)
        : ("column-reverse" as const),
      flexWrap: "nowrap" as const,
      transform: isFirefox ? "scaleY(-1)" : "none",
      overflowY: "auto" as const,
    }),
  })
);
