import { makeStyles } from "@material-ui/core";
import { CustomMuiTheme } from "../../../interfaces/CustomMuiTheme";

export const useTCChatInputStyles = makeStyles<CustomMuiTheme>((theme) => ({
  input: {
    padding: theme.spacing(2),
    paddingRight: theme.spacing(6),
    backgroundColor: theme.palette.grey[200],
  },
}));
