import { createMuiTheme, responsiveFontSizes } from "@material-ui/core";
import { Overrides } from "@material-ui/core/styles/overrides";
import { merge } from "lodash";
import { CustomMuiTheme } from "../../interfaces/CustomMuiTheme";
import "../../resources/fonts/PlusJakartaSans/stylesheet.css";
import { caresomeTheme } from "../caresome/theme";
import { globalCss } from "../caresome/themeData/globalCss";
import { overrides } from "../caresome/themeData/overrides";
import { props } from "../caresome/themeData/props";
import { typography } from "../caresome/themeData/typography";
import { palette } from "./themeData/palette";
import { tcCustom } from "./themeData/tcCustom";

const baseTheme: CustomMuiTheme = caresomeTheme;

const theme: CustomMuiTheme = {
  tcCustom: merge({}, baseTheme.tcCustom, tcCustom),
  /**
   * This currently uses objects from caresome (typography, props). In case you
   * need to adjust them, merge them with more specific objects.
   */
  ...createMuiTheme(
    {
      palette,
      props,
      typography,
    }
    // baseTheme
  ),
};

const mergedOverrides: Overrides = merge({}, theme.overrides, {
  ...overrides(theme),
  MuiCssBaseline: {
    ...globalCss,
  },
});

const themeWithOverrides: CustomMuiTheme = {
  ...theme,
  overrides: mergedOverrides,
};

export const mondiTheme = responsiveFontSizes(themeWithOverrides, {
  factor: 3,
  disableAlign: true,
}) as CustomMuiTheme;
