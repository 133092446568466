import { ThemeOptions } from "@material-ui/core";

export const palette: ThemeOptions["palette"] = {
  primary: {
    main: "#e42a35",
    light: "#fb3a45",
    contrastText: "#fff",
  },
  secondary: {
    main: "#8296b9",
    light: "#cbd1e9",
  },
  grey: {
    "50": "#fff",
    "200": "#fafbff",
    "400": "#f0f3fa",
    "900": "#15192f",
  },
  success: {
    main: "#5fb432",
    light: "#f3fae8",
  },
  error: {
    main: "#ffa18e",
    light: "#fff0ed",
  },
  warning: {
    main: "#ffc846",
    light: "#fff6e0",
  },
};
