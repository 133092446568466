import { Button, Typography } from "@material-ui/core";
import BugReportIcon from "@material-ui/icons/BugReport";
import { ProviderContext, useSnackbar } from "notistack";
import React from "react";
import { Field, FieldTypeWithoutField } from "../../../generated/api-service";
import { tclog } from "../../../helpers/tclog";
import { TCFieldProps } from "../../../interfaces/dynamicFieldInterfaces";
import { useTCFieldDebuggerStyles } from "./TCFieldDebuggerStyles";

function debug(
  field: Field,
  fieldType: FieldTypeWithoutField,
  notifyFn: ProviderContext["enqueueSnackbar"]
) {
  notifyFn(`logged field '${field.name}' and its type to console`);
  tclog(`field ${field.name}:`, field);
  tclog(`fieldType ${fieldType.name}:`, fieldType);
}

export const TCFieldDebugger: React.FunctionComponent<TCFieldProps> = (
  props
) => {
  const styles = useTCFieldDebuggerStyles();
  const { enqueueSnackbar } = useSnackbar();
  return (
    <span className={styles.wrapper}>
      <div className={styles.overlay}>
        <Button
          variant="outlined"
          onClick={() =>
            debug(props.field, props.field.fieldType, enqueueSnackbar)
          }
          startIcon={<BugReportIcon />}
          className={styles.button}
        >
          <Typography noWrap={true}>{props.field.name}</Typography>
        </Button>
      </div>

      {props.children}
    </span>
  );
};
