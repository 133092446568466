import { createStyles, makeStyles } from "@material-ui/core";
import { CustomMuiTheme } from "../../../interfaces/CustomMuiTheme";

export const useTCFooterStyles = makeStyles((theme: CustomMuiTheme) =>
  createStyles({
    footerWrapper: (props: { isDesktop: boolean }) => ({
      backgroundColor: theme.palette.grey[50],
      display: "flex",
      justifyContent: props.isDesktop ? "flex-end" : "center",
      bottom: 0,
      width: "100%",
    }),
    linkItem: {
      color: theme.palette.secondary.light,
      padding: theme.spacing(3),
    },
  })
);
