import { useEffect, useState } from "react";
import { closeMessagesDrawer } from "../../../../dataHandling/services/chatApi";
import { conversationUsersStore } from "../../../../dataHandling/stores/conversationUsersStore";
import { useGetConversationUsers } from "../../../../hooks/tamocApiHooks/conversationUserHooks";
import { useUser } from "../../../../hooks/useUser";

export const useTCMessageDrawerViewModel = (): {
  isPanelOpen: boolean;
  onClose: () => void;
} => {
  const [conversationUserState, setConversationUserState] = useState(
    conversationUsersStore.initialState
  );

  const user = useUser();
  const {
    call: getConversationUser,
    result: conversationUsersResult,
  } = useGetConversationUsers();

  useEffect(() => {
    if (!user) {
      return;
    }

    getConversationUser({ userId: user.id });
  }, [getConversationUser, user]);

  useEffect(() => {
    const subscription = conversationUsersStore.subscribe(
      setConversationUserState
    );

    conversationUsersStore.setConversationUsers(conversationUsersResult || []);
    return () => {
      subscription.unsubscribe();
    };
  }, [user, conversationUsersResult]);

  return {
    isPanelOpen: conversationUserState.isPanelOpen,
    onClose: closeMessagesDrawer,
  };
};
