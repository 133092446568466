import { Box, InputLabel } from "@material-ui/core";
import React from "react";
import { getFieldLabel } from "../../../../../../helpers/dynamicFields/getFieldLabel";
import { TCFieldComponent } from "../../../../../../interfaces/dynamicFieldInterfaces";
import { TCDatePicker } from "../../../../../pickers/TCDatePicker/TCDatePicker";
import { useTCDateRangePickerStyles } from "./TCEditFieldDateRangeStyles";
import { useTCEditFieldDateRangeViewModel } from "./TCEditFieldDateRangeViewModel";

export const TCEditFieldDateRange: TCFieldComponent = (props) => {
  const classes = useTCDateRangePickerStyles();
  const {
    leftPickerProps,
    rightPickerProps,
    isRequired,
  } = useTCEditFieldDateRangeViewModel(props, classes);

  const label = getFieldLabel(props.field);

  return (
    <Box className={classes.wrap}>
      <InputLabel required={isRequired} className={classes.label}>
        {label}
      </InputLabel>

      <TCDatePicker {...leftPickerProps} />

      <span className={classes.untilMarker}>&nbsp;&mdash;&nbsp;</span>

      <TCDatePicker {...rightPickerProps} />
    </Box>
  );
};
