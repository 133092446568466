import { TextField } from "@material-ui/core";
import { Field } from "formik";
import React, { useState } from "react";
import { fieldIsRequired } from "../../../../helpers/forms/fieldIsRequired";
import { fieldValuesFromFormikContext } from "../../../../helpers/forms/fieldValuesFromFormikContext";
import { TextFieldWrapperProps } from "../../../../helpers/forms/formInterfaces";
import { updateFormikValue } from "../../../../helpers/forms/updateFormikValue";
import { useTCFormikContext } from "../../../../hooks/useTCFormikContext";
import {
  hasFieldAndFieldGroup,
  TCFieldComponent,
} from "../../../../interfaces/dynamicFieldInterfaces";
import { JsonSchema } from "../../../../interfaces/typeAliases";

export const TCEditFieldText: TCFieldComponent = (props) => {
  const formikContext = useTCFormikContext();

  const {
    formikIdentifier,
    fieldData,
    fieldValue,
  } = fieldValuesFromFormikContext<string>(formikContext, props.field);

  const [fieldTextValue, setFieldTextValue] = useState<string>(fieldValue);

  const validationSchema = props.field.fieldType.validationSchema as JsonSchema;
  const type = validationSchema.format === "email" ? "email" : "text";
  const autoComplete = type === "email" ? "username" : undefined;

  if (!hasFieldAndFieldGroup(props)) {
    return null;
  }

  const multilineProps = props.displayConfiguration.valueEditing?.isMultiLine
    ? {
        multiline: true,
        rows: 3,
      }
    : {};

  const textFieldProps: TextFieldWrapperProps = {
    component: TextField,
    name: formikIdentifier,
    label: props.field.label,
    autoComplete,
    className: props.className,
    required: fieldIsRequired(props),
    type,
    ...multilineProps,
    value: fieldTextValue,
    InputProps: {
      onChange: (event) => {
        const newValue = event.target.value;
        setFieldTextValue(newValue);
      },
      onBlur: () => {
        if (fieldValue !== fieldTextValue) {
          formikContext.setFieldTouched(formikIdentifier, true);
          updateFormikValue(formikContext, formikIdentifier, {
            ...fieldData,
            value: fieldTextValue,
          });
        }
      },
    },
  };

  return <Field {...textFieldProps} />;
};
