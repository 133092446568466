import { cloneDeep } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  Field,
  FieldValue,
  FieldValueValuable,
} from "../../../generated/api-service";
import { fieldValueMapFromFieldValues } from "../../../helpers/dynamicFields/fieldValueMapFromFieldValues";
import { FieldValueMap } from "../../../interfaces/dynamicFieldInterfaces";
import { useFields } from "../../tamocApiHooks/useFields";
import { useFieldValuesSwr } from "../../tamocApiHooks/useFieldValuesSwr";
import { useFieldValuesCables } from "../FieldValuesCable/useFieldValuesCable";
import { TCFieldWithoutContextProps } from "./useFieldWithoutContextProps";

export interface TCFieldWithoutContext {
  fields: Field[];
  fieldValueMap: FieldValueMap;
  fieldsByName: {
    [key in Field["name"]]: {
      fieldValue: FieldValue | undefined;
      plainValue: FieldValueValuable["value"] | undefined;
      field: Field;
    };
  };
}

export const useTCFieldWithoutContext = (
  props: TCFieldWithoutContextProps
): TCFieldWithoutContext | null => {
  const { fieldNames, parentableId, parentableType } = props;

  const params = useMemo(() => ({ name: fieldNames.join(",") }), [fieldNames]);

  const fields = useFields(params);
  const [fetchedFields, setFetchedFields] = useState<Field[] | undefined>();
  const [fetchedFieldValueMap, setFetchedFieldValueMap] = useState<
    FieldValueMap | undefined
  >();

  const fieldIds = useMemo(() => fetchedFields?.map((field) => field.id), [fetchedFields]);

  useEffect(() => {
    if (fields.result && !fetchedFields) {
      setFetchedFields(fields.result);
    }
  }, [fields, fetchedFields]);

  const { result: fieldValues } = useFieldValuesSwr({
    parentableId,
    parentableType,
    fieldId: fieldIds?.join(","),
  });

  useEffect(() => {
    if (fieldValues) {
      setFetchedFieldValueMap(fieldValueMapFromFieldValues(fieldValues));
    }
  }, [fieldValues]);

  const fieldsByName = useMemo(
    () =>
      fetchedFields?.reduce(
        (
          result: TCFieldWithoutContext["fieldsByName"],
          field
        ): TCFieldWithoutContext["fieldsByName"] => ({
          ...result,
          [field.name]: {
            field,
            fieldValue: fetchedFieldValueMap?.[field.id],
            plainValue: fetchedFieldValueMap?.[field.id]?.valuable.value,
          },
        }),
        {}
      ),
    [fetchedFields, fetchedFieldValueMap]
  );

  const updateList = useCallback((fieldValue: FieldValue) => {
    setFetchedFieldValueMap((prevState) => {
      const newState = cloneDeep(prevState);

      if (!newState) {
        return prevState;
      }
      newState[fieldValue.fieldId] = fieldValue;
      return newState;
    });
  }, []);

  useFieldValuesCables({
    parentable: { parentableId, parentableType },
    updateList,
  });

  if (!fetchedFields || !fetchedFieldValueMap || !fieldsByName) {
    return null;
  }

  return {
    fields: fetchedFields,
    fieldValueMap: fetchedFieldValueMap,
    fieldsByName,
  };
};
