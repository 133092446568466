import { noop } from "lodash";
import { environment } from "../Consts";

export enum LoggingSeverity {
  Debug = "debug",
  Error = "error",
  Warning = "warning",
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function tcLog(args: any[], severity = LoggingSeverity.Debug): void {
  if (severity === LoggingSeverity.Error) {
    // eslint-disable-next-line no-console
    console.error(...args);
  } else if (severity === LoggingSeverity.Warning) {
    // eslint-disable-next-line no-console
    console.warn(...args);
  } else {
    // eslint-disable-next-line no-console
    console.log(...args);
  }
}

const functionToExport = environment === "development" ? tcLog : noop;

export { functionToExport as tclog };
