import { createMuiTheme } from "@material-ui/core";
import { merge } from "lodash";
import { TCHeroSectionProps } from "../../components/basicPageComponents/TCHeroSection/TCHeroSectionProps";
import { CustomMuiTheme } from "../../interfaces/CustomMuiTheme";
import { caresomeTheme } from "../caresome/theme";
import logoSvg from "./logo.svg";

const loginScreen: TCHeroSectionProps = {
  headline:
    "Mit wenigen Klicks Projekt besetzen, Dokumente teilen und Aufgaben zuweisen",
  backgroundImageUrl:
    "https://fw-d7-freedomworks-org.s3.amazonaws.com/field/image/Taxes.jpeg",
};

export const taxworkTheme: CustomMuiTheme = merge({}, caresomeTheme, {
  tcCustom: {
    loginScreen: {
      worker: loginScreen,
      employer: loginScreen,
    },
    logoSvg,
  },
  ...createMuiTheme({
    palette: {
      primary: {
        dark: "#0020D0",
        main: "#0027ff",
        light: "#5f73ff",
      },
      secondary: {
        dark: "#6370a7",
        main: "#7f8fd2",
        light: "#cbd1e9",
      },
      grey: {
        "900": "#001473",
        "400": "#f0f3fa",
        "200": "#fafbff",
        "50": "#ffffff",
      },
      success: {
        main: "#87cd1e",
        light: "#f3fae8",
      },
      warning: {
        main: "#f5b622",
      },
      error: {
        main: "#ff5b39",
        light: "#fff0ed",
      },
    },
  }),
});
