import { Box, Typography } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";
import React from "react";
import { useAutocompletePropsForField } from "../../../../../../../hooks/useAutocompleteProps";
import { TCFieldComponent } from "../../../../../../../interfaces/dynamicFieldInterfaces";
import { TCInfoBlock } from "../../../../../../tamocComponents/TCInfoBlock/TCInfoBlock";
import { InfoBlockTyp } from "../../../../../../tamocComponents/TCInfoBlock/TCInfoBlockProps";
import { useTCEditFieldReferenceTimeSlotsSingleSlotViewModel } from "./TCEditFieldReferenceTimeSlotsSingleSlotViewModel";

const groupByGroupname = (option) => option.groupName;

/**
 * Pick a single shift from a list grouped by shift pattern names.
 */
export const TCEditFieldReferenceTimeSlotsSingleSlot: TCFieldComponent = (
  props
) => {
  const {
    currentValue,
    onChange,
    options,
  } = useTCEditFieldReferenceTimeSlotsSingleSlotViewModel(props);

  const autocompleteProps = useAutocompletePropsForField(props);

  if (!options || !options.length) {
    return null;
  }

  return (
    <Box>
      <Autocomplete
        color="primary"
        {...autocompleteProps}
        groupBy={groupByGroupname}
        options={options}
        value={currentValue}
        onChange={onChange}
      />

      {currentValue.value && (
        <TCInfoBlock infoBlockType={InfoBlockTyp.Info}>
          <Typography variant="caption">
            {currentValue.value.startTime}

            {" – "}

            {currentValue.value.endTime}

            {" Uhr"}
          </Typography>
        </TCInfoBlock>
      )}
    </Box>
  );
};
