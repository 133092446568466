import React, { ReactElement } from "react";
import Playground from "../components/playground/Playground";
import { TCAppRoutes } from "../interfaces/TCAppRoutes";
import TCDataProtectionPage from "../pages/TCStaticContentPage/TCDataProtectionPage/TCDataProtectionPage";
import TCImprintPage from "../pages/TCStaticContentPage/TCImprintPage/TCImprintPage";

const ContractsPage = React.lazy(
  () => import("../pages/ContractsPage/contractsPage")
);

const UserValidationPage = React.lazy(
  () => import("../pages/UserValidationPage/UserValidationPage")
);

const ProjectPage = React.lazy(
  () => import("../pages/ProjectsPage/ProjectsPage")
);

const UserPage = React.lazy(() => import("../pages/UserPage/UserPage"));

const SigningPage = React.lazy(
  () => import("../pages/SigningPage/SigningPage")
);

const AuthPage = React.lazy(() => import("../pages/TCAuthPage/TCAuthPage"));

const LogoutPage = React.lazy(() => import("../pages/LogoutPage/LogoutPage"));

const JobUserPage = React.lazy(
  () => import("../pages/JobUserPage/JobUserPage")
);

const JobPage = React.lazy(() => import("../pages/JobPage/JobPage"));

const DashboardPage = React.lazy(
  () => import("../pages/DashboardPage/DashboardPage")
);

const AdminPage = React.lazy(() => import("../pages/AdminPage/AdminPage"));

const PasswordRecoveryPage = React.lazy(
  () => import("../pages/PasswordRecoveryPage/PasswordRecoveryPage")
);

/**
 * Each route renders a given component.
 * Assign identical keys to avoid re-mounting of pages.
 */
export const componentsForRoutes: Record<TCAppRoutes, ReactElement> = {
  [TCAppRoutes.Home]: <DashboardPage />,

  [TCAppRoutes.Dashboard]: <DashboardPage />,

  [TCAppRoutes.Admin]: <AdminPage />,

  [TCAppRoutes.Signing]: <SigningPage />,

  [TCAppRoutes.Logout]: <LogoutPage />,

  [TCAppRoutes.Contracts]: <ContractsPage />,

  [TCAppRoutes.UserValidation]: <UserValidationPage />,

  [TCAppRoutes.Registration]: (
    <AuthPage key="authPage" leftSection="register" />
  ),

  [TCAppRoutes.Login]: <AuthPage key="authPage" leftSection="login" />,

  [TCAppRoutes.PasswordRecovery]: <PasswordRecoveryPage />,

  [TCAppRoutes.Projects]: <ProjectPage />,

  [TCAppRoutes.Playground]: <Playground />,

  [TCAppRoutes.User]: <UserPage />,

  [TCAppRoutes.JobUser]: <JobUserPage />,

  [TCAppRoutes.Job]: <JobPage />,

  [TCAppRoutes.DataProtection]: <TCDataProtectionPage />,

  [TCAppRoutes.Imprint]: <TCImprintPage />,
};
