import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import moment from "moment";
import { dbDateFormat } from "../../Consts";

/**
 * Convert a date string, as stored in our database, to a moment object.
 */
export const momentFromDbDate = (
  dbDateString: string | undefined
): MaterialUiPickersDate =>
  dbDateString ? moment(dbDateString, dbDateFormat) : null;
