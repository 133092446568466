import { useCallback, useMemo } from "react";
import {
  ValueValidationTimeSlots,
  ValueValidationTimeSlotsTimeSlots,
} from "../../../../../../../generated/api-service";
import { useTCFormikField } from "../../../../../../../hooks/useTCFormikField";
import { TCFieldComponentProps } from "../../../../../../../interfaces/dynamicFieldInterfaces";
import {
  TimeSlotOption,
  TimeSlotOptionWithValue,
} from "../sharedFunctionality/optionInterfaces";
import { timeslotFieldHasValue } from "../sharedFunctionality/timeslotFieldHasValue";
import { useReferencedTimeSlotsValue } from "../sharedFunctionality/useReferencedTimeSlotsValue";

interface TCEditFieldReferenceTimeSlotsViewModel {
  options: TimeSlotOption[] | undefined;
  currentValue: TimeSlotOption;
  onChange: (e, val: TimeSlotOption | null) => void;
  /**
   * When saving values from the referenced field, a copy of the referenced
   *  value is saved.
   * This copy can become outdated if the original values in the referenced field
   *  are changed. This flag will indicate such a value.
   */
  valueIsOutdated: boolean;
}

/**
 * We store a full ValueValidationTimeSlots entry in the database,
 *  mainly for easier validation.
 *
 * But each options represent a single ValueValidationTimeSlotsTimeSlots,
 *  which needs to be wrapped/unwrapped accordingly with
 * fullEntryFromOption/fullEntryFromOption
 */
const optionFromFullEntry = (
  fullEntry: ValueValidationTimeSlots | undefined,
  valueIsOutdated?: boolean
): TimeSlotOption => ({
  label: `${fullEntry?.timeSlots?.[0].name || ""}${
    valueIsOutdated ? " (veraltet)" : ""
  }`,
  value: fullEntry?.timeSlots?.[0],
});

/**
 * See optionFromFullEntry
 */
const fullEntryFromOption = (
  option: TimeSlotOptionWithValue
): ValueValidationTimeSlots => ({
  name: "This should not be needed, but the API wouldn't have it any other way",
  timeSlots: [option.value],
});

const emptyOption: TimeSlotOption = {
  label: "",
  value: undefined,
};

export const useTCEditFieldReferenceTimeSlotsViewModel = (
  props: TCFieldComponentProps
): TCEditFieldReferenceTimeSlotsViewModel => {
  const timeSlots = useReferencedTimeSlotsValue(
    props.field.referencedFieldId || undefined
  );

  const {
    fieldValue,
    setFieldValue,
  } = useTCFormikField<ValueValidationTimeSlots>(props.field);

  const fieldHasValue = timeslotFieldHasValue(fieldValue);

  /**
   * The current value is outdated if no time slot pattern which is equal can
   *  be found in the referenced field values.
   */
  const valueIsOutdated = useMemo(() => {
    if (!fieldHasValue) {
      return false;
    }
    const currentTimeSlotPattern = optionFromFullEntry(fieldValue).value;

    const equalsCurrentPattern = (
      slotPattern: ValueValidationTimeSlotsTimeSlots
    ): boolean =>
      JSON.stringify(slotPattern) === JSON.stringify(currentTimeSlotPattern);

    return !timeSlots?.timeSlots.some(equalsCurrentPattern);
  }, [fieldValue, timeSlots, fieldHasValue]);

  const currentValue: TimeSlotOption = useMemo(
    () =>
      fieldHasValue
        ? optionFromFullEntry(fieldValue, valueIsOutdated)
        : emptyOption,
    [fieldValue, valueIsOutdated, fieldHasValue]
  );

  const onChange = useCallback(
    (e, option: TimeSlotOption | null) =>
      setFieldValue(
        option?.value
          ? fullEntryFromOption(option as TimeSlotOptionWithValue)
          : undefined
      ),
    [setFieldValue]
  );

  const options: TimeSlotOption[] = useMemo(() => {
    // Each time slot pattern corresponds to one option
    const optionsFromTimeSlots =
      timeSlots?.timeSlots.map((slot) => ({
        label: slot.name || "",
        value: slot,
      })) || [];

    // Add an extra option in the dropdown, indicating the current (outdated) value
    if (valueIsOutdated) {
      const legacyOption = optionFromFullEntry(
        fieldValue,
        true
      ) as TimeSlotOptionWithValue;
      optionsFromTimeSlots.push(legacyOption);
    }
    return optionsFromTimeSlots;
  }, [timeSlots, valueIsOutdated, fieldValue]);

  return {
    options,
    currentValue,
    onChange,
    valueIsOutdated,
  };
};
