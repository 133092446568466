import { ThemeOptions } from "@material-ui/core";
import { palette } from "./palette";

export const typography: ThemeOptions["typography"] = {
  fontFamily: "Jakarta Plus Text",
  allVariants: {
    color: palette.grey ? palette.grey[900] : undefined,
  },
  body1: {
    fontFamily: "Jakarta Plus Text",
  },
  body2: {
    fontFamily: "Jakarta Plus Text",
  },
  h1: {
    textAlign: "center",
    fontSize: "24px",
    fontWeight: "bold",
    marginBottom: "1rem",
    fontFamily: "Jakarta Plus Display",
  },
  h2: {
    textAlign: "center",
    fontSize: "1.8rem",
    fontWeight: "bold",
    marginBottom: "1rem",
    fontFamily: "Jakarta Plus Display",
  },
  h3: {
    fontSize: "1.6rem",
    fontWeight: "bold",
    marginBottom: "1rem",
  },
  h4: {
    fontSize: "16px",
    letterSpacing: "0.09px",
    fontWeight: "bold",
  },
  h5: {
    fontSize: "1.3rem",
    fontWeight: "bold",
  },
};
