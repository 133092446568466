// tslint:disable
/**
 * TaMoC API V1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs';
import {
    EJobUserStatus, FilesMeta,
    JobUser,
    JobUserPostJobUser,
    JobUserPutJobUser
} from '../models';
import { BaseAPI, encodeURI, HttpHeaders, HttpQuery, OperationOpts, RawAjaxResponse, throwIfNullOrUndefined } from '../runtime';

export interface CreateJobUserRequest {
    accessToken: string;
    client: string;
    uid: string;
    jobUser: JobUserPostJobUser;
    files?: Array<Blob>;
    filesMeta?: FilesMeta;
}

export interface DeleteJobUserRequest {
    accessToken: string;
    client: string;
    uid: string;
    id: number;
}

export interface GetJobUserRequest {
    accessToken: string;
    client: string;
    uid: string;
    sortBy?: GetJobUserSortByEnum;
    id?: number | string;
    workerId?: number | string;
    jobId?: number | string;
    hiddenForWorker?: string;
    jobUserStatus?: EJobUserStatus;
    createdAt?: string;
    updatedAt?: string;
}

export interface GetJobUserByIdRequest {
    accessToken: string;
    client: string;
    uid: string;
    id: number;
}

export interface PatchJobUserRequest {
    accessToken: string;
    client: string;
    uid: string;
    id: number;
    jobUser: JobUserPutJobUser;
    files?: Array<Blob>;
    filesMeta?: FilesMeta;
}

export interface UpdateJobUserRequest {
    accessToken: string;
    client: string;
    uid: string;
    id: number;
    jobUser: JobUserPutJobUser;
    files?: Array<Blob>;
    filesMeta?: FilesMeta;
}

/**
 * no description
 */
export class JobUserApi extends BaseAPI {

    /**
     * Create JobUser
     */
    createJobUser({ accessToken, client, uid, jobUser, files, filesMeta }: CreateJobUserRequest): Observable<JobUser>
    createJobUser({ accessToken, client, uid, jobUser, files, filesMeta }: CreateJobUserRequest, opts?: OperationOpts): Observable<RawAjaxResponse<JobUser>>
    createJobUser({ accessToken, client, uid, jobUser, files, filesMeta }: CreateJobUserRequest, opts?: OperationOpts): Observable<JobUser | RawAjaxResponse<JobUser>> {
        throwIfNullOrUndefined(accessToken, 'accessToken', 'createJobUser');
        throwIfNullOrUndefined(client, 'client', 'createJobUser');
        throwIfNullOrUndefined(uid, 'uid', 'createJobUser');
        throwIfNullOrUndefined(jobUser, 'jobUser', 'createJobUser');

        const headers: HttpHeaders = {
            ...(accessToken != null ? { 'access-token': String(accessToken) } : undefined),
            ...(client != null ? { 'client': String(client) } : undefined),
            ...(uid != null ? { 'uid': String(uid) } : undefined),
        };

        const formData = new FormData();
        if (jobUser !== undefined) {
          let formElement: string | Blob;
          if (typeof jobUser === "object") {
            if ((jobUser as any) instanceof Blob) {
              formElement = (jobUser as any) as Blob;
            } else {
              formElement = JSON.stringify(jobUser);
            }
          } else {
            formElement = jobUser;
          }

          formData.append('jobUser', formElement); }
        if (files !== undefined) {
              if (Array.isArray(files)) {
                for (let file of files) {
                  formData.append('files[]', file);
                }
              } else {
                formData.append('files', files);
              }
        }

        if (filesMeta !== undefined) {
          let formElement: string | Blob;
          if (typeof filesMeta === "object") {
            if ((filesMeta as any) instanceof Blob) {
              formElement = (filesMeta as any) as Blob;
            } else {
              formElement = JSON.stringify(filesMeta);
            }
          } else {
            formElement = filesMeta;
          }

          formData.append('filesMeta', formElement); }

        return this.request<JobUser>({
            url: '/api/v1/job_users/',
            method: 'POST',
            headers,
            body: formData,
        }, opts?.responseOpts);
    };

    /**
     * Deletes a JobUser
     */
    deleteJobUser({ accessToken, client, uid, id }: DeleteJobUserRequest): Observable<void>
    deleteJobUser({ accessToken, client, uid, id }: DeleteJobUserRequest, opts?: OperationOpts): Observable<void | RawAjaxResponse<void>>
    deleteJobUser({ accessToken, client, uid, id }: DeleteJobUserRequest, opts?: OperationOpts): Observable<void | RawAjaxResponse<void>> {
        throwIfNullOrUndefined(accessToken, 'accessToken', 'deleteJobUser');
        throwIfNullOrUndefined(client, 'client', 'deleteJobUser');
        throwIfNullOrUndefined(uid, 'uid', 'deleteJobUser');
        throwIfNullOrUndefined(id, 'id', 'deleteJobUser');

        const headers: HttpHeaders = {
            ...(accessToken != null ? { 'access-token': String(accessToken) } : undefined),
            ...(client != null ? { 'client': String(client) } : undefined),
            ...(uid != null ? { 'uid': String(uid) } : undefined),
        };

        return this.request<void>({
            url: '/api/v1/job_users/{id}'.replace('{id}', encodeURI(id)),
            method: 'DELETE',
            headers,
        }, opts?.responseOpts);
    };

    /**
     * Retrieve a JobUser list
     */
    getJobUser({ accessToken, client, uid, sortBy, id, workerId, jobId, hiddenForWorker, jobUserStatus, createdAt, updatedAt }: GetJobUserRequest): Observable<Array<JobUser>>
    getJobUser({ accessToken, client, uid, sortBy, id, workerId, jobId, hiddenForWorker, jobUserStatus, createdAt, updatedAt }: GetJobUserRequest, opts?: OperationOpts): Observable<RawAjaxResponse<Array<JobUser>>>
    getJobUser({ accessToken, client, uid, sortBy, id, workerId, jobId, hiddenForWorker, jobUserStatus, createdAt, updatedAt }: GetJobUserRequest, opts?: OperationOpts): Observable<Array<JobUser> | RawAjaxResponse<Array<JobUser>>> {
        throwIfNullOrUndefined(accessToken, 'accessToken', 'getJobUser');
        throwIfNullOrUndefined(client, 'client', 'getJobUser');
        throwIfNullOrUndefined(uid, 'uid', 'getJobUser');

        const headers: HttpHeaders = {
            ...(accessToken != null ? { 'access-token': String(accessToken) } : undefined),
            ...(client != null ? { 'client': String(client) } : undefined),
            ...(uid != null ? { 'uid': String(uid) } : undefined),
        };

        const query: HttpQuery = {};

        if (sortBy != null) { query['sort_by'] = sortBy; }
        if (id != null) { query['id'] = id; }
        if (workerId != null) { query['worker_id'] = workerId; }
        if (jobId != null) { query['job_id'] = jobId; }
        if (hiddenForWorker != null) { query['hidden_for_worker'] = hiddenForWorker; }
        if (jobUserStatus != null) { query['job_user_status'] = jobUserStatus; }
        if (createdAt != null) { query['created_at'] = (createdAt as any).toISOString(); }
        if (updatedAt != null) { query['updated_at'] = (updatedAt as any).toISOString(); }

        return this.request<Array<JobUser>>({
            url: '/api/v1/job_users/',
            method: 'GET',
            headers,
            query,
        }, opts?.responseOpts);
    };

    /**
     * Retrieves a JobUser
     */
    getJobUserById({ accessToken, client, uid, id }: GetJobUserByIdRequest): Observable<JobUser>
    getJobUserById({ accessToken, client, uid, id }: GetJobUserByIdRequest, opts?: OperationOpts): Observable<RawAjaxResponse<JobUser>>
    getJobUserById({ accessToken, client, uid, id }: GetJobUserByIdRequest, opts?: OperationOpts): Observable<JobUser | RawAjaxResponse<JobUser>> {
        throwIfNullOrUndefined(accessToken, 'accessToken', 'getJobUserById');
        throwIfNullOrUndefined(client, 'client', 'getJobUserById');
        throwIfNullOrUndefined(uid, 'uid', 'getJobUserById');
        throwIfNullOrUndefined(id, 'id', 'getJobUserById');

        const headers: HttpHeaders = {
            ...(accessToken != null ? { 'access-token': String(accessToken) } : undefined),
            ...(client != null ? { 'client': String(client) } : undefined),
            ...(uid != null ? { 'uid': String(uid) } : undefined),
        };

        return this.request<JobUser>({
            url: '/api/v1/job_users/{id}'.replace('{id}', encodeURI(id)),
            method: 'GET',
            headers,
        }, opts?.responseOpts);
    };

    /**
     * Patch a JobUser
     */
    patchJobUser({ accessToken, client, uid, id, jobUser, files, filesMeta }: PatchJobUserRequest): Observable<JobUser>
    patchJobUser({ accessToken, client, uid, id, jobUser, files, filesMeta }: PatchJobUserRequest, opts?: OperationOpts): Observable<RawAjaxResponse<JobUser>>
    patchJobUser({ accessToken, client, uid, id, jobUser, files, filesMeta }: PatchJobUserRequest, opts?: OperationOpts): Observable<JobUser | RawAjaxResponse<JobUser>> {
        throwIfNullOrUndefined(accessToken, 'accessToken', 'patchJobUser');
        throwIfNullOrUndefined(client, 'client', 'patchJobUser');
        throwIfNullOrUndefined(uid, 'uid', 'patchJobUser');
        throwIfNullOrUndefined(id, 'id', 'patchJobUser');
        throwIfNullOrUndefined(jobUser, 'jobUser', 'patchJobUser');

        const headers: HttpHeaders = {
            ...(accessToken != null ? { 'access-token': String(accessToken) } : undefined),
            ...(client != null ? { 'client': String(client) } : undefined),
            ...(uid != null ? { 'uid': String(uid) } : undefined),
        };

        const formData = new FormData();
        if (jobUser !== undefined) {
          let formElement: string | Blob;
          if (typeof jobUser === "object") {
            if ((jobUser as any) instanceof Blob) {
              formElement = (jobUser as any) as Blob;
            } else {
              formElement = JSON.stringify(jobUser);
            }
          } else {
            formElement = jobUser;
          }

          formData.append('jobUser', formElement); }
        if (files !== undefined) {
              if (Array.isArray(files)) {
                for (let file of files) {
                  formData.append('files[]', file);
                }
              } else {
                formData.append('files', files);
              }
        }

        if (filesMeta !== undefined) {
          let formElement: string | Blob;
          if (typeof filesMeta === "object") {
            if ((filesMeta as any) instanceof Blob) {
              formElement = (filesMeta as any) as Blob;
            } else {
              formElement = JSON.stringify(filesMeta);
            }
          } else {
            formElement = filesMeta;
          }

          formData.append('filesMeta', formElement); }

        return this.request<JobUser>({
            url: '/api/v1/job_users/{id}'.replace('{id}', encodeURI(id)),
            method: 'PATCH',
            headers,
            body: formData,
        }, opts?.responseOpts);
    };

    /**
     * Update a JobUser
     */
    updateJobUser({ accessToken, client, uid, id, jobUser, files, filesMeta }: UpdateJobUserRequest): Observable<JobUser>
    updateJobUser({ accessToken, client, uid, id, jobUser, files, filesMeta }: UpdateJobUserRequest, opts?: OperationOpts): Observable<RawAjaxResponse<JobUser>>
    updateJobUser({ accessToken, client, uid, id, jobUser, files, filesMeta }: UpdateJobUserRequest, opts?: OperationOpts): Observable<JobUser | RawAjaxResponse<JobUser>> {
        throwIfNullOrUndefined(accessToken, 'accessToken', 'updateJobUser');
        throwIfNullOrUndefined(client, 'client', 'updateJobUser');
        throwIfNullOrUndefined(uid, 'uid', 'updateJobUser');
        throwIfNullOrUndefined(id, 'id', 'updateJobUser');
        throwIfNullOrUndefined(jobUser, 'jobUser', 'updateJobUser');

        const headers: HttpHeaders = {
            ...(accessToken != null ? { 'access-token': String(accessToken) } : undefined),
            ...(client != null ? { 'client': String(client) } : undefined),
            ...(uid != null ? { 'uid': String(uid) } : undefined),
        };

        const formData = new FormData();
        if (jobUser !== undefined) {
          let formElement: string | Blob;
          if (typeof jobUser === "object") {
            if ((jobUser as any) instanceof Blob) {
              formElement = (jobUser as any) as Blob;
            } else {
              formElement = JSON.stringify(jobUser);
            }
          } else {
            formElement = jobUser;
          }

          formData.append('jobUser', formElement); }
        if (files !== undefined) {
              if (Array.isArray(files)) {
                for (let file of files) {
                  formData.append('files[]', file);
                }
              } else {
                formData.append('files', files);
              }
        }

        if (filesMeta !== undefined) {
          let formElement: string | Blob;
          if (typeof filesMeta === "object") {
            if ((filesMeta as any) instanceof Blob) {
              formElement = (filesMeta as any) as Blob;
            } else {
              formElement = JSON.stringify(filesMeta);
            }
          } else {
            formElement = filesMeta;
          }

          formData.append('filesMeta', formElement); }

        return this.request<JobUser>({
            url: '/api/v1/job_users/{id}'.replace('{id}', encodeURI(id)),
            method: 'PUT',
            headers,
            body: formData,
        }, opts?.responseOpts);
    };

}

/**
 * @export
 * @enum {string}
 */
export enum GetJobUserSortByEnum {
    IdAsc = 'id_asc',
    IdDesc = 'id_desc',
    WorkerIdAsc = 'worker_id_asc',
    WorkerIdDesc = 'worker_id_desc',
    JobIdAsc = 'job_id_asc',
    JobIdDesc = 'job_id_desc',
    HiddenForWorkerAsc = 'hidden_for_worker_asc',
    HiddenForWorkerDesc = 'hidden_for_worker_desc',
    JobUserStatusAsc = 'job_user_status_asc',
    JobUserStatusDesc = 'job_user_status_desc',
    CreatedAtAsc = 'created_at_asc',
    CreatedAtDesc = 'created_at_desc',
    UpdatedAtAsc = 'updated_at_asc',
    UpdatedAtDesc = 'updated_at_desc'
}
