import React from "react";
import { complexTypeFromField } from "../../../../../helpers/dynamicFields/complexTypeFromField";
import { TCFieldComponent } from "../../../../../interfaces/dynamicFieldInterfaces";

export const TCEditFieldComplexPlaceholder: TCFieldComponent = (props) => (
    <h2>
      {`This is a placeholder showing the complex type ${complexTypeFromField(
        props.field
      )} for the field ${props.field.name}`}
    </h2>
  );
