import {
  CustomMuiTheme,
  TCAuthPageMuiProps,
} from "../../../interfaces/CustomMuiTheme";
import { TCTenantInformation } from "../../../interfaces/TCTenantInformation";
import { RecursivePartial } from "../../../interfaces/typeAliases";
import logoSvg from "../logo.svg";
import backgroundImageUrl from "../registrierung-asset-sure.jpg";
import welcomeSvg from "../welcomeSure.svg";

const tenantInformation: TCTenantInformation = {
  platformName: "Sure",
  managingDirector: {
    firstName: "Andrea",
    lastName: "Sechting",
    gender: "female",
  },
  dataProtectionOfficer: {
    firstName: "Andreas Peter",
    lastName: "Mückel",
    company: "DDSB GmbH",
    gender: "male",
    contact: {
      email: "datenschutz@ddsb.de",
      telephone: "+49 7471 5010-100",
      fax: "+49 7471 5010-190",
    },
    address: {
      city: "Hechingen",
      postalCode: "72379",
      street: "Untere Dornäcker",
      streetNumber: "21",
    },
  },
  responsibleForContent: {
    firstName: "Andrea",
    lastName: "Sechting",
    address: {
      city: "Schwäbisch Hall",
      postalCode: "74523",
      street: "Dreimühlengasse",
      streetNumber: "4",
    },
  },
  company: {
    name: "SURE Personalmanagement GmbH",
    commercialRegisterIdentifier: "HRB 77895",
    commercialRegisterPlace: "Amtsgericht Stuttgart",
    seatCity: "Schwäbisch Hall",
    ustId: "DE 342514895",
  },
  address: {
    city: "Schwäbisch Hall",
    postalCode: "74523",
    street: "Dreimühlengasse",
    streetNumber: "4",
  },
  contact: {
    telephone: "+49 791 / 9516597-0",
    fax: "+49 791 / 9516597-9",
    email: "info@suregmbh.de",
  },
};

const loginScreen: TCAuthPageMuiProps = {
  headlineLeft: "Willkommen",
  heroSectionProps: {
    headline:
      "Ihr Zugang zu mehreren tausend Mitarbeitern aus der Industrie und Logistik",
    description:
      "Mit unserer branchenspezifischen Matching-Plattform finden Sie mit einem Klick die passenden und verfügbaren Arbeitskräfte.",
    backgroundImageUrl,
  },
};

export const tcCustom: RecursivePartial<CustomMuiTheme["tcCustom"]> = {
  welcomeSvg,
  logoSvg,
  loginScreen,
  contractSigning: {
    helloSignClientId: "49f805ee8ed256ec7258fc108aba7b8e",
    useContractSigning: false,
  },
  registerScreen: {
    worker: {
      ...loginScreen,
      headlineLeft: "Erhalten Sie vollautomatisch passende Stellen.",
    },
    employer: {
      ...loginScreen,
      headlineLeft: "Finden Sie jetzt unkompliziert passende Mitarbeiter",
    },
  },
  mobileLogoSvg: logoSvg,
  workerName: "Arbeitskraft",
  employerName: "Unternehmen",
  tenantInformation,
  layout: {
    authSectionMaxWidth: 650,
    messagesDrawerMaxWidth: 421,
  },
};
