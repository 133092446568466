import { Field } from "../../generated/api-service";
import {
  DisplayMode,
  TCFieldComponent,
} from "../../interfaces/dynamicFieldInterfaces";
import { componentsForComplexTypes } from "./componentsForComplexTypes";
import { componentsForPrimitiveTypes } from "./componentsForPrimitiveTypes";

export const getFieldComponentForDisplayMode = (
  field: Field,
  displayMode: DisplayMode
): TCFieldComponent => {
  if (field.fieldType.complexType?.complexType) {
    return componentsForComplexTypes[field.fieldType.complexType.complexType][
      displayMode
    ];
  }

  return componentsForPrimitiveTypes[field.fieldType.dataType][displayMode];
};
