import moment from "moment";
import { ConversationUser } from "../../../../generated/api-service";
import { notCurrentUser } from "../../../../helpers/conversationUser";
import { useUser } from "../../../../hooks/useUser";

interface TCConversationUserCellViewModel {
  lastMessageBody: string;
  lastMessageTimestamp: string;
  conversationTitle: string[] | undefined;
  conversationImage: string;
  hasUnreadMessages: boolean;
}

export const useTCConversationUserCellViewModel = (
  conversationUser: ConversationUser
): TCConversationUserCellViewModel => {
  const currentUser = useUser();
  const { conversation } = conversationUser;

  const lastMessage = conversation?.messages?.[0];

  const formatDate = (date) => {
    if (!date) {
      return "";
    }

    const localMoment = moment;
    localMoment.updateLocale("de", {
      calendar: {
        lastDay: "[Gestern]",
        sameDay: "[Heute]",
        nextDay: "[Morgen]",
        lastWeek: "ddd DD.MM",
        nextWeek: "ddd DD.MM",
        sameElse: "ddd DD.MM",
      },
    });
    return `${localMoment(date).calendar(moment())} ${localMoment(date).format(
      "HH:mm"
    )} Uhr`;
  };

  return {
    hasUnreadMessages: (conversationUser.unreadMessageUsers?.length ?? 0) > 0,
    lastMessageBody: lastMessage?.body ?? "",

    lastMessageTimestamp: formatDate(lastMessage?.createdAt),

    conversationTitle: conversation?.users
      ?.filter((user) => notCurrentUser(user, currentUser))
      .map((user) => [user.firstName, user.lastName].join(" ")),

    conversationImage:
      conversation?.users
        ?.filter((user) => notCurrentUser(user, currentUser))
        .map((user) => user?.image)?.[0] ?? "",
  };
};
