// tslint:disable
/**
 * TaMoC API V1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum WorkerFields {
    Citizenship = 'citizenship',
    WorkPermit = 'work_permit',
    WorkPermitCertificateFiles = 'work_permit_certificate_files',
    Education = 'education',
    EducationCertificateFiles = 'education_certificate_files',
    WorkExperienceInYears = 'work_experience_in_years',
    WorkerJobTitle = 'worker_job_title',
    WorkerJobMainFocus = 'worker_job_main_focus',
    WorkerJobMainFocusCertificateFiles = 'worker_job_main_focus_certificate_files',
    Languages = 'languages',
    JobPosition = 'job_position',
    FormerOrCurrentEmployer = 'former_or_current_employer',
    OperationArea = 'operation_area',
    WillingnessToTravel = 'willingness_to_travel',
    ReadyToSpendNightAway = 'ready_to_spend_night_away',
    CitizenshipCertificationFiles = 'citizenship_certification_files',
    WorkerDescription = 'worker_description',
    DrivingLicenseType = 'driving_license_type',
    DrivingLicenseCertificateFiles = 'driving_license_certificate_files',
    SocialSecurityNumber = 'social_security_number',
    TaxId = 'tax_id',
    HealthInsuranceName = 'health_insurance_name',
    HealthInsuranceMemberId = 'health_insurance_member_id',
    IdNumber = 'id_number',
    BankAccountSepa = 'bank_account_sepa',
    BankAccountBic = 'bank_account_bic'
}

