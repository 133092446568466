import { SetStateAction, useEffect, useMemo, useRef, useState } from "react";
import { conversationUsersStore } from "../../../dataHandling/stores/conversationUsersStore";
import { ConversationUser } from "../../../generated/api-service";
import {
  byLastMessage,
  withMessages,
  withoutMessages,
} from "../../../helpers/conversationUser";

interface TCConversationUserViewModel {
  conversationUsersWithMessages: ConversationUser[];
  newConversationUsers: ConversationUser[];
  currentConversationUser: ConversationUser | undefined;
  setConversationUser: (
    value: SetStateAction<ConversationUser | undefined>
  ) => void;
  clearCurrentConversationUser: () => void;
}

export const useTCConversationUserViewModel = (): TCConversationUserViewModel => {
  const [conversationUsersState, setChatsState] = useState(
    conversationUsersStore.initialState
  );

  const [currentConversationUser, setConversationUser] = useState<
    ConversationUser | undefined
  >(undefined);

  useEffect(() => {
    const subscription = conversationUsersStore.subscribe(setChatsState);

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (!conversationUsersState.currentConversationUser) {
      return;
    }
    setConversationUser(conversationUsersState.currentConversationUser);
  }, [conversationUsersState]);

  const conversationUsersWithMessages = useMemo(
    () =>
      conversationUsersState.conversationUsers
        .filter(withMessages)
        .sort(byLastMessage),
    [conversationUsersState.conversationUsers]
  );

  const newConversationUsers = useMemo(
    () => conversationUsersState.conversationUsers.filter(withoutMessages),
    [conversationUsersState.conversationUsers]
  );

  const clearCurrentConversationUser = useRef(() => {
    setConversationUser(undefined);
    conversationUsersStore.unsetCurrentConversationUser();
  }).current;

  return {
    conversationUsersWithMessages,
    newConversationUsers,
    currentConversationUser,

    setConversationUser,
    clearCurrentConversationUser,
  };
};
