import { Tooltip } from "@material-ui/core";
import React, { Fragment, useEffect, useState } from "react";
import {
  mediaTypeIsImage,
  mediaTypeIsPdf,
} from "../../../../helpers/file/mediaTypes";
import { ReactComponent as Dislike } from "../../../../resources/iconset/dislike.svg";
import TCBlobDownloadButton from "../../../button/TCBlobDownloadButton/TCBlobDownloadButton";
import { TCPlaceholder } from "../../../placeholders/TCPlaceholder/TCPlaceholder";
import { TCLoadingIndicator } from "../../../tamocComponents/TCLoadingIndicator/TCLoadingIndicator";
import { TCDialog } from "../../TCDialog/TCDialog";
import TCRenderPdf from "../TCRenderPdf/TCRenderPdf";
import { TCDisplayBlobProps } from "./TCDisplayBlobProps";
import { useTCDisplayBlobStyles } from "./TCDisplayBlobStyles";

/**
 * Component renders a preview of a blob
 *
 * It can render PDFs and images. For all other media types a placeholder is displayed.
 */
const TCDisplayBlob: React.FunctionComponent<TCDisplayBlobProps> = ({
  file,
  open,
  onClose,
  isLoading,
  mediaType,
  fileName,
  contract,
}) => {
  const classes = useTCDisplayBlobStyles();
  const [imageSrcData, setImageSrcData] = useState<string>();

  useEffect(() => {
    let fileReaderInstance;

    if (file && mediaTypeIsImage(mediaType)) {
      fileReaderInstance = new FileReader();
      fileReaderInstance.readAsDataURL(file as Blob);
      fileReaderInstance.onload = () => {
        setImageSrcData(fileReaderInstance.result?.toString());
      };
    }

    return () => {
      if (fileReaderInstance) {
        fileReaderInstance.abort();
      }
    };
  }, [file, mediaType]);

  return (
    <TCDialog open={open} onClose={onClose} title={fileName}>
      {isLoading && <TCLoadingIndicator isLoading={true} />}

      {file && (
        <Fragment>
          {mediaTypeIsImage(mediaType) && (
            <Fragment>
              <Tooltip arrow={true} title="Datei herunterladen">
                <TCBlobDownloadButton
                  blob={file}
                  fileName={fileName}
                  mediaType={mediaType}
                  buttonProps={{ className: classes.downloadButton }}
                />
              </Tooltip>

              <img src={imageSrcData} alt={fileName} />
            </Fragment>
          )}

          {mediaTypeIsPdf(mediaType) && (
            <TCRenderPdf fileName={fileName} pdf={file} contract={contract} />
          )}

          {!mediaTypeIsPdf(mediaType) && !mediaTypeIsImage(mediaType) && (
            <TCPlaceholder
              text={"Leider können wir für diese Datei keine Vorschau anbieten"}
              icon={Dislike}
            />
          )}
        </Fragment>
      )}
    </TCDialog>
  );
};

export default TCDisplayBlob;
