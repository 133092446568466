import { TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";
import { Field as FormikField } from "formik";
import React, { FunctionComponent } from "react";
import fieldId from "../../../../../helpers/dynamicFields/fieldId";
import { fieldIsRequired } from "../../../../../helpers/forms/fieldIsRequired";
import { fieldValuesFromFormikContext } from "../../../../../helpers/forms/fieldValuesFromFormikContext";
import { updateFormikValue } from "../../../../../helpers/forms/updateFormikValue";
import { useTCFormikContext } from "../../../../../hooks/useTCFormikContext";
import {
  hasFieldAndFieldGroup,
  ValueListComponentProps,
} from "../../../../../interfaces/dynamicFieldInterfaces";
import { useTCEditFieldValueListStyles } from "./TCEditFieldValueListStyles";

export const TCEditFieldDropdown: FunctionComponent<ValueListComponentProps> = (
  props
) => {
  const formikContext = useTCFormikContext();
  const classes = useTCEditFieldValueListStyles();
  if (!hasFieldAndFieldGroup(props)) {
    return null;
  }

  const {
    formikIdentifier,
    fieldData,
    fieldValue,
  } = fieldValuesFromFormikContext<string[]>(formikContext, props.field);

  return (
    <FormikField name={props.fieldName}>
      {() => (
        <Autocomplete
          classes={{
            root: classes.dropdownRoot,
            inputRoot: classes.dropdown,
          }}
          value={(fieldValue?.[0] as string) || ""}
          color="primary"
          onInputChange={(e, newInput) => {
            updateFormikValue(formikContext, formikIdentifier, {
              ...fieldData,
              value: [newInput],
            });
          }}
          getOptionSelected={(option, value) =>
            value === "" || value === option
          }
          id={fieldId(props.field)}
          options={props.options}
          renderInput={(params) => (
            <TextField
              {...params}
              value={fieldValue?.[0] || ""}
              label={props.field.label}
              variant="outlined"
              required={fieldIsRequired(props)}
            />
          )}
        />
      )}
    </FormikField>
  );
};
