import React, { useCallback, useMemo } from "react";
import {
  Field,
  ValueValidationTimeSlots,
} from "../../../../../../../generated/api-service";
import { fieldValuesFromFormikContext } from "../../../../../../../helpers/forms/fieldValuesFromFormikContext";
import { updateFormikValue } from "../../../../../../../helpers/forms/updateFormikValue";
import { useTCFormikContext } from "../../../../../../../hooks/useTCFormikContext";
import { TimeSlotsPattern } from "../../../../../../../interfaces/typeAliases";
import { timeSlotDummyName } from "../../../TCEditFieldReference/TCEditFieldReferenceTimeSlots/sharedFunctionality/timeSlotDummyName";
import { TCEditSingleTimeSlotsPattern } from "../TCEditSingleTimeSlotsPattern/TCEditSingleTimeSlotsPattern";

interface TCEditFieldTimeSlotsPatternsViewModel {
  allShiftPatterns: TimeSlotsPattern[];
  renderShiftPattern: (
    shiftPattern: TimeSlotsPattern,
    index: number
  ) => JSX.Element;
  addNewTimeSlotPattern: (newPattern: TimeSlotsPattern) => void;
}

export const useTCEditFieldTimeSlotsPatternsViewModel = (
  field: Field
): TCEditFieldTimeSlotsPatternsViewModel => {
  const formikContext = useTCFormikContext();
  const formikValues = fieldValuesFromFormikContext<ValueValidationTimeSlots>(
    formikContext,
    field
  );

  const updateFormik = useCallback(
    (newShiftPatterns: TimeSlotsPattern[]) =>
      updateFormikValue(formikContext, formikValues.formikIdentifier, {
        ...formikValues.fieldData,
        value: {
          name: timeSlotDummyName,
          timeSlots: newShiftPatterns,
        },
      }),
    [formikContext, formikValues]
  );

  const allShiftPatterns: TimeSlotsPattern[] =
    formikValues.fieldValue.timeSlots;

  const setAllShiftPatterns = useCallback(
    (
      generateNewValue: (oldValue: TimeSlotsPattern[]) => TimeSlotsPattern[]
    ) => {
      const newValue = generateNewValue(allShiftPatterns);
      updateFormik(newValue);
    },
    [allShiftPatterns, updateFormik]
  );

  const deleteAtIndex = useCallback(
    (index: number) => () => {
      setAllShiftPatterns((oldPatterns) => [
        ...oldPatterns.slice(0, index),
        ...oldPatterns.slice(index + 1),
      ]);
    },
    [setAllShiftPatterns]
  );

  const updateAtIndex = useCallback(
    (index: number) => (newEntry: TimeSlotsPattern) => {
      setAllShiftPatterns((oldPatterns) => {
        const newPatterns = [...oldPatterns];
        newPatterns[index] = newEntry;
        return newPatterns;
      });
    },
    [setAllShiftPatterns]
  );

  const addNewTimeSlotPattern = useCallback(
    (newPattern: TimeSlotsPattern) =>
      setAllShiftPatterns((oldPatterns) => [...oldPatterns, newPattern]),
    [setAllShiftPatterns]
  );

  const renderShiftPattern = useCallback(
    (timeSlotPattern: TimeSlotsPattern, index: number) => (
      <TCEditSingleTimeSlotsPattern
        key={index}
        timeSlotsPattern={timeSlotPattern}
        onDelete={deleteAtIndex(index)}
        onChange={updateAtIndex(index)}
      />
    ),
    [deleteAtIndex, updateAtIndex]
  );

  return useMemo(
    () => ({
      allShiftPatterns,
      renderShiftPattern,
      addNewTimeSlotPattern,
    }),
    [allShiftPatterns, renderShiftPattern, addNewTimeSlotPattern]
  );
};
