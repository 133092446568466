import { activeTenant } from "../Consts";
import {
  ValidationLocation,
  ValueValidationRange,
} from "../generated/api-service";
import { FieldGroupIntroData } from "../interfaces/typeAliases";

/**
 * Data that is right now hardcoded into the system, but should eventually be
 * retrieved via the api dynamically.
 */

const fieldGroupIntrosMondi: FieldGroupIntroData = {
  1002: {
    headline: "Noch ein paar Schritte",
    text:
      "Damit wir Sie als passende Option anzeigen und Sie Stellen erhalten können, vervollständigen Sie bitte Ihr Profil.",
  },
  1003: {
    headline: "Wie sieht Ihre berufliche Ausbildung aus?",
    text:
      "Halten Sie hierzu Ihre Gesellenbrief und Zertifikate bereit oder laden Sie diese später ganz einfach in Ihrem Profil hoch.",
  },
  1004: {
    headline: "Wer ist Ihr aktueller oder letzter Arbeitgeber?",
    text:
      "Durch diese Information werden die zur Verfügung stehenden Stellen passend auf Sie zugeschnitten.",
  },
  3002: {
    headline: "Noch ein paar Schritte",
    text:
      "Damit wir für Sie die passende Arbeitskraft finden, fehlen noch ein paar Daten. ",
  },
  3003: {
    headline: "Schneller Stellen anlegen",
    text:
      "Passen Sie die Arbeitszeitmodelle hier oder später im Profil global für Ihre Firma an und sparen Sie Zeit beim Anlegen von Stellen.",
  },
  4001: {
    headline: "Wann müssen Sie eine Lücke besetzen?",
  },
  4002: {
    headline: "Welche Vorraussetzung sollte die Arbeitskraft mitbringen?",
  },
  4003: {
    headline: "Stellen Sie notwendige Dokumente bereit. (optional)",
  },
};
const fieldGroupIntrosCaresome: FieldGroupIntroData = {
  1002: {
    headline: "Noch ein paar Schritte",
    text:
      "Damit wir Sie als passende Option anzeigen und Sie Stellen erhalten können, vervollständigen Sie bitte Ihr Profil.",
  },
  1003: {
    headline: "Wie sieht Ihre berufliche Ausbildung aus?",
    text:
      "Halten Sie hierzu Ihre Unterlagen und Zertifikate bereit oder laden Sie diese später ganz einfach in Ihrem Profil hoch.",
  },
  1004: {
    headline: "Wer ist Ihr aktueller oder letzter Arbeitgeber?",
    text:
      "Durch diese Information werden die zur Verfügung stehenden Stellen passend auf Sie zugeschnitten.",
  },
  3002: {
    headline: "Noch ein paar Schritte",
    text:
      "Damit wir für Sie die passende Pflegekraft finden, fehlen noch ein paar Daten.",
  },
  4001: {
    headline: "Wann müssen Sie eine Lücke besetzen?",
  },
  4002: {
    headline: "Welche Vorraussetzung sollte die Pflegekraft mitbringen?",
  },
  4003: {
    headline: "Stellen Sie notwendige Dokumente bereit. (optional)",
  },
};

const dataForTenants = {
  mondi: fieldGroupIntrosMondi,
  caresome: fieldGroupIntrosCaresome,
};

export const fieldGroupIntroData: FieldGroupIntroData = Object.prototype.hasOwnProperty.call(
  dataForTenants,
  activeTenant
)
  ? dataForTenants[activeTenant]
  : fieldGroupIntrosMondi;

/**
 * Used to display location fields in the right order
 */
export const locationProps: string[] = [
  nameof<ValidationLocation>((o) => o.street),
  nameof<ValidationLocation>((o) => o.street_number),
  nameof<ValidationLocation>((o) => o.postal_code),
  nameof<ValidationLocation>((o) => o.city),
];

export const rangeProps: string[] = [
  nameof<ValueValidationRange>((o) => o.minValue),
  nameof<ValueValidationRange>((o) => o.maxValue),
];

export type LocationProp = typeof locationProps[number];
export type RangeProp = typeof rangeProps[number];
export type TCLocation = Record<LocationProp, string>;

type PropsWithLabels = LocationProp | RangeProp;

export const labelsForIdentifiers: {
  [key in PropsWithLabels]: string;
} = {
  street: "Straße",
  street_number: "HausNr.",
  postal_code: "PLZ",
  city: "Stadt",
  minValue: "Beginn",
  maxValue: "Ende",
};
