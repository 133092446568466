import React from "react";
import { DisplayConfigurationValueDisplayFileFieldDisplayStyleEnum } from "../../../../../generated/api-service";
import { TCDisplayFieldProps } from "../../../../../interfaces/dynamicFieldInterfaces";
import { TCDisplayFieldComplexFileEditable } from "./TCDisplayFieldComplexFileEditable/TCDisplayFieldComplexFileEditable";
import { TCDisplayFieldComplexFileLabeledValue } from "./TCDisplayFieldComplexFileLabeledValue/TCDisplayFieldComplexFileLabeledValue";

export const TCDisplayFieldComplexFile: React.FunctionComponent<TCDisplayFieldProps> = (
  props
) => {
  const displayStyle =
    props.displayConfiguration.valueDisplay?.fileFieldDisplayStyle;

  if (!props.initialFiles || props.initialFiles.length === 0) {
    return null;
  }

  return displayStyle ===
    DisplayConfigurationValueDisplayFileFieldDisplayStyleEnum.ListView ? (
    <TCDisplayFieldComplexFileEditable {...props} />
  ) : (
    <TCDisplayFieldComplexFileLabeledValue {...props} />
  );
};
