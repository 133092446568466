import { createStyles, makeStyles } from "@material-ui/core";
import { CustomMuiTheme } from "../../interfaces/CustomMuiTheme";

export const useTCStaticContentPageStyles = makeStyles(
  (theme: CustomMuiTheme) =>
    createStyles({
      h2StaticPage: { textAlign: "left", marginTop: theme.spacing(3) },
      h3StaticPage: { textAlign: "left", marginTop: theme.spacing(3) },
      textParagraph: { marginTop: theme.spacing(3) },
    })
);
