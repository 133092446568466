import { Avatar, Typography } from "@material-ui/core";
import React, { useCallback } from "react";
import { TCConversationUserCellProps } from "./TCConversationUserCellProps";
import { useTCConversationUserCellStyles } from "./TCConversationUserCellStyles";
import { useTCConversationUserCellViewModel } from "./TCConversationUserCellViewModel";

export const TCConversationUserCell: React.FunctionComponent<TCConversationUserCellProps> = (
  props
) => {
  const viewModel = useTCConversationUserCellViewModel(props.conversationUser);
  const classes = useTCConversationUserCellStyles({
    hasUnreadMessages: viewModel.hasUnreadMessages,
  });

  const { conversationUser, onClick } = props;

  const onConversationUserClick = useCallback(() => onClick(conversationUser), [
    conversationUser,
    onClick,
  ]);

  return (
    <div className={classes.cellRoot} onClick={onConversationUserClick}>
      <Avatar className={classes.avatar} src={viewModel.conversationImage} />

      <div className={classes.detailsContainer}>
        <Typography className={classes.title} variant="subtitle1">
          {viewModel.conversationTitle}
        </Typography>

        <Typography variant="caption" className={classes.timestamp}>
          {viewModel.lastMessageTimestamp}
        </Typography>

        <Typography className={classes.messageBody} variant="body2">
          {viewModel.lastMessageBody}
        </Typography>
      </div>
    </div>
  );
};
