import { ReactComponent as PlusIcon } from "@iconset/add.alt.svg";
import { ReactComponent as HouseIcon } from "@iconset/home.svg";
import { ReactComponent as LocationIcon } from "@iconset/location.alt.svg";
import { ReactComponent as PersonIcon } from "@iconset/profile.svg";
import { ReactComponent as ClockIcon } from "@iconset/time.svg";
import { Icon } from "@material-ui/core";
import clsx from "clsx";
import React, { ReactElement } from "react";
import { DisplayConfigurationValueDisplayShowIconBeforeValueEnum } from "../../../../generated/api-service";
import { TCIconBeforeValueProps } from "./TCIconBeforeValueProps";
import { useTCIconBeforeValueStyles } from "./TCIconBeforeValueStyles";

const getIcon = (
  icon: DisplayConfigurationValueDisplayShowIconBeforeValueEnum
) => {
  const iconMap: {
    [key in DisplayConfigurationValueDisplayShowIconBeforeValueEnum]: ReactElement;
  } = {
    [DisplayConfigurationValueDisplayShowIconBeforeValueEnum.Clock]: (
      <ClockIcon />
    ),
    [DisplayConfigurationValueDisplayShowIconBeforeValueEnum.House]: (
      <HouseIcon />
    ),
    [DisplayConfigurationValueDisplayShowIconBeforeValueEnum.Location]: (
      <LocationIcon />
    ),
    [DisplayConfigurationValueDisplayShowIconBeforeValueEnum.Person]: (
      <PersonIcon />
    ),
    [DisplayConfigurationValueDisplayShowIconBeforeValueEnum.Plus]: (
      <PlusIcon />
    ),
  };

  return iconMap[icon];
};

export const TCIconBeforeValue: React.FunctionComponent<TCIconBeforeValueProps> = (
  props
) => {
  const classes = useTCIconBeforeValueStyles();
  const icon = props.displayConfiguration.valueDisplay?.showIconBeforeValue;

  if (!icon) {
    return null;
  }

  return (
    <Icon
      {...props.iconProps}
      className={clsx(props.iconProps?.className, classes.icon)}
    >
      {getIcon(icon)}
    </Icon>
  );
};
