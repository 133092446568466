export enum RequestStatus {
  "Open" = "open",
  "Pending" = "pending",
  "Errored" = "errored",
  "Success" = "success",
}

export const requestIsFinished = (
  status: RequestStatus
): status is RequestStatus.Errored | RequestStatus.Success => status === RequestStatus.Errored || status === RequestStatus.Success;
