import { createStyles, makeStyles } from "@material-ui/core";

interface StyleProps {
  detailsOpened: boolean | undefined;
}

export const useTCListNavigationDetailsViewStyles = makeStyles(() =>
  createStyles({
    detailsViewContainer: (props: StyleProps) => ({
      transition: "0.2s ease-in-out",
      width: "100%",
      height: "100%",
      position: "absolute",
      opacity: props.detailsOpened ? 1 : 0,
      pointerEvents: props.detailsOpened ? "all" : "none",
      left: props.detailsOpened ? "0" : "30%",
    }),
  })
);
