import { FormikContextType } from "formik";
import { FileObject } from "material-ui-dropzone";
import { useCallback, useRef, useState } from "react";
import {
  DisplayConfigurationValueEditingFileUploadRenderingTypeEnum,
  TcDocument,
  ValidationFile,
  ValueValidationFile,
} from "../../../../../generated/api-service";
import { fieldValuesFromFormikContext } from "../../../../../helpers/forms/fieldValuesFromFormikContext";
import { updateFormikValue } from "../../../../../helpers/forms/updateFormikValue";
import { TCFieldComponentProps } from "../../../../../interfaces/dynamicFieldInterfaces";
import {
  TCFormikFieldData,
  TCFormikValues,
} from "../../../../../interfaces/formikInterfaces";

interface TCFieldFileViewModel {
  formikFieldData: TCFormikFieldData;
  onChangeOfUploadedFiles: (files: FileObject[]) => void;
  onListFileDelete: (index: number) => void;
  onDocumentDelete: (document: TcDocument) => void;
  dropZoneFiles: FileObject[];
  showInDialog: boolean;
  showUploadDialog: () => void;
  hideUploadDialog: () => void;
  isUploadDialogVisible: boolean;
}

export const useTCFieldFileViewModel = (
  props: TCFieldComponentProps,
  formikContext: FormikContextType<TCFormikValues>
): TCFieldFileViewModel | null => {
  const {
    formikIdentifier,
    fieldValue,
    fieldData,
  } = fieldValuesFromFormikContext<ValueValidationFile>(
    formikContext,
    props.field
  );

  const [dropZoneFiles, setDropzoneFiles] = useState<FileObject[]>([]);
  const [isUploadDialogVisible, setIsUploadDialogVisible] = useState(false);

  // Show in dialog unless "dropzone" is set.
  const showInDialog =
    props.displayConfiguration.valueEditing?.fileUploadRenderingType !==
    DisplayConfigurationValueEditingFileUploadRenderingTypeEnum.Dropzone;

  const validationSchema = props.field.fieldType
    .validationSchema as ValidationFile;

  const saveFilesToFormik = useCallback(
    (newFiles: File[]): void => {
      updateFormikValue(formikContext, formikIdentifier, {
        ...fieldData,
        newFiles,
        value: { needsValidation: validationSchema.needsValidation },
      });
    },
    [
      formikIdentifier,
      fieldData,
      validationSchema.needsValidation,
      formikContext,
    ]
  );

  const onListFileDelete = useCallback(
    (index: number) => {
      const indexToUser = index - 1;
      setDropzoneFiles(dropZoneFiles.filter((f, idx) => idx !== indexToUser));
    },
    [dropZoneFiles]
  );

  const onChangeOfUploadedFiles = useCallback(
    (newDropZoneFiles: FileObject[]) => {
      setDropzoneFiles(newDropZoneFiles);
      saveFilesToFormik(newDropZoneFiles.map((fileObject) => fileObject.file));
    },
    [saveFilesToFormik]
  );

  const onDocumentDelete = useCallback(
    (document: TcDocument) => {
      if (fieldValue && document) {
        updateFormikValue(formikContext, formikIdentifier, {
          ...fieldData,
          initialFiles: fieldData.initialFiles?.filter(
            (documentInFormik) => documentInFormik.id !== document.id
          ),
        });
      }
    },
    [formikIdentifier, fieldData, fieldValue, formikContext]
  );

  const showUploadDialog = useRef(() => {
    setIsUploadDialogVisible(true);
  }).current;

  const hideUploadDialog = useRef(() => {
    setIsUploadDialogVisible(false);
  }).current;

  return {
    onChangeOfUploadedFiles,
    onListFileDelete,
    formikFieldData: fieldData,
    onDocumentDelete,
    dropZoneFiles,
    showInDialog,
    showUploadDialog,
    hideUploadDialog,
    isUploadDialogVisible,
  };
};
