import { createStyles, makeStyles } from "@material-ui/core";
import { CustomMuiTheme } from "../../../interfaces/CustomMuiTheme";

export const useTCProfileButtonStyles = makeStyles((theme: CustomMuiTheme) => {
  const borderWidth = "1";
  const border = `${borderWidth}px solid ${theme.palette.grey[400]}`;
  return createStyles({
    avatarBorder: {
      border: `1px solid ${theme.palette.secondary.light}`,
    },
    button: {
      padding: `0 ${theme.spacing(3)}px`,
      borderLeft: border,
      borderRight: border,
      "&:after": {
        content: "''",
        display: "block",
        position: "absolute",
        left: `-${borderWidth}px`,
        right: `-${borderWidth}px`,
        bottom: 0,
        height: "3px",
      },
      [theme.breakpoints.down("sm")]: {
        border: "none",
        padding: 0,
        marginLeft: theme.spacing(2),
      },
    },
    label: {
      color: theme.palette.grey[900],
      paddingLeft: `${theme.spacing(1)}px`,
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
  });
});
