// tslint:disable
/**
 * TaMoC API V1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface ValidationText
 */
export interface ValidationText {
    /**
     * @type {string}
     * @memberof ValidationText
     */
    type?: ValidationTextTypeEnum;
    /**
     * @type {number}
     * @memberof ValidationText
     */
    minLength?: number;
    /**
     * @type {number}
     * @memberof ValidationText
     */
    maxLength?: number;
}

/**
 * @export
 * @enum {string}
 */
export enum ValidationTextTypeEnum {
    String = 'string'
}

