import { nanoid } from "nanoid";
import React, { ReactElement } from "react";
import { TCLabeledFieldValue } from "../../../components/dynamicFields/displayFields/TCLabeledFieldValue/TCLabeledFieldValue";
import { Field } from "../../../generated/api-service";
import { TCFieldProps } from "../../../interfaces/dynamicFieldInterfaces";

const displayArrayAsSingleValueJoinedByKey = (
  field: Field,
  input: [string, unknown][],
  joinBySign: string,
  props: TCFieldProps
): ReactElement => (
    <TCLabeledFieldValue
      key={field.id}
      value={input.join(joinBySign)}
      label={field.label}
      {...props}
    />
  );

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const valueFromArray = (input, props): ReactElement => {
  const joinBySign = props.displayConfiguration?.valueDisplay?.joinBySign;

  if (joinBySign) {
    return displayArrayAsSingleValueJoinedByKey(
      props.field,
      input,
      joinBySign,
      props
    );
  }

  const listItem =
    input.length > 1 ? (
      <ul>
        {input.map((element) => (
          <li key={nanoid()}>{element}</li>
        ))}
      </ul>
    ) : (
      input[0] || ""
    );

  return (
    <TCLabeledFieldValue
      value={listItem}
      label={props.field.label}
      {...props}
    />
  );
};
