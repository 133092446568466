import { ConversationUser } from "../generated/api-service";

export const unreadConversationsCount = (
  conversationUsers: ConversationUser[]
): number =>
  conversationUsers.reduce((sum, conversationUser) => {
    if (!conversationUser.unreadMessageUsers) {
      return sum;
    }

    if (conversationUser.unreadMessageUsers.length > 0) {
      return sum + 1;
    }

    return sum;
  }, 0);
