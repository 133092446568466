import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import React from "react";
import { TCAcceptButton } from "../../button/TCAcceptButton/TCAcceptButton";
import { TCCloseButton } from "../../button/TCCloseButton";
import { TCDeclineButton } from "../../button/TCDeclineButton/TCDeclineButton";
import { TCConfirmationDialogProps } from "./TCConfirmationDialogProps";

export const TCConfirmationDialog: React.FunctionComponent<TCConfirmationDialogProps> = (
  props
) => (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle disableTypography={true} id="alert-dialog-title">
        <TCCloseButton onClick={props.handleClose} />

        <Typography variant="h1" component="h2">
          {props.confirmationTitle}
        </Typography>
      </DialogTitle>

      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {props.confirmationText}
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <TCDeclineButton onClick={props.handleClose} useIcon={true} />

        <TCAcceptButton onClick={props.acceptAction} useIcon={true} />
      </DialogActions>
    </Dialog>
  );
