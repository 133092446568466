import { nanoid } from "nanoid";
import React, { Fragment, ReactElement } from "react";
import { TCLabeledFieldValue } from "../../../components/dynamicFields/displayFields/TCLabeledFieldValue/TCLabeledFieldValue";
import { DisplayConfiguration, Field } from "../../../generated/api-service";
import { TCFieldProps } from "../../../interfaces/dynamicFieldInterfaces";
import { labelsForIdentifiers } from "../../staticData";
import { valueFromPrimitive } from "./valueFromPrimitive";

const displayObjectAsSingleValueJoinedByKey = (
  field: Field,
  input: [string, unknown][],
  joinBySign: string,
  props: TCFieldProps
): ReactElement => {
  const returnValues = input.map(([, value]) => valueFromPrimitive(value as string | boolean | number)[0]);

  const returnLabels = input.map(([property]) => labelsForIdentifiers[property]);

  return (
    <TCLabeledFieldValue
      key={field.id}
      value={returnValues.join(joinBySign)}
      label={returnLabels.join(joinBySign)}
      {...props}
    />
  );
};

const filterInputByDisplayConfiguration = (
  displayConfiguration: DisplayConfiguration,
  input: [string, unknown][]
): [string, unknown][] => {
  const selectValueKeys = displayConfiguration.valueDisplay?.selectValueKeys;

  return selectValueKeys
    ? input.filter(([property]) => selectValueKeys.includes(property))
    : input;
};

export const valueFromObject = (
  field: Field,
  input: [string, unknown][],
  props: TCFieldProps
): ReactElement | string => {
  const joinBySign = props.displayConfiguration?.valueDisplay?.joinBySign;

  if (joinBySign) {
    return displayObjectAsSingleValueJoinedByKey(
      field,
      input,
      joinBySign,
      props
    );
  }

  const filteredInput = filterInputByDisplayConfiguration(
    props.displayConfiguration,
    input
  );

  return (
    <Fragment>
      {filteredInput.map(([property, value]) => {
        const label = labelsForIdentifiers[property];

        if (!label) {
          return null;
        }
        const parsedValue = valueFromPrimitive(
          value as string | boolean | number
        )[0];
        return (
          <TCLabeledFieldValue
            key={nanoid()}
            {...{ value: parsedValue, label }}
            {...props}
          />
        );
      })}
    </Fragment>
  );
};
