// tslint:disable
/**
 * TaMoC API V1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs';
import {
    EJobStatus, FilesMeta,
    Job,
    JobPostJob,
    JobPutJob
} from '../models';
import { BaseAPI, encodeURI, HttpHeaders, HttpQuery, OperationOpts, RawAjaxResponse, throwIfNullOrUndefined } from '../runtime';

export interface CreateJobRequest {
    accessToken: string;
    client: string;
    uid: string;
    job: JobPostJob;
    files?: Array<Blob>;
    filesMeta?: FilesMeta;
}

export interface DeleteJobRequest {
    accessToken: string;
    client: string;
    uid: string;
    id: number;
}

export interface GetJobRequest {
    accessToken: string;
    client: string;
    uid: string;
    sortBy?: GetJobSortByEnum;
    id?: number | string;
    jobOwnerId?: number | string;
    createdAt?: string;
    updatedAt?: string;
    jobStatus?: EJobStatus;
}

export interface GetJobByIdRequest {
    accessToken: string;
    client: string;
    uid: string;
    id: number;
}

export interface PatchJobRequest {
    accessToken: string;
    client: string;
    uid: string;
    id: number;
    job: JobPutJob;
    files?: Array<Blob>;
    filesMeta?: FilesMeta;
}

export interface UpdateJobRequest {
    accessToken: string;
    client: string;
    uid: string;
    id: number;
    job: JobPutJob;
    files?: Array<Blob>;
    filesMeta?: FilesMeta;
}

/**
 * no description
 */
export class JobApi extends BaseAPI {

    /**
     * Create Job
     */
    createJob({ accessToken, client, uid, job, files, filesMeta }: CreateJobRequest): Observable<Job>
    createJob({ accessToken, client, uid, job, files, filesMeta }: CreateJobRequest, opts?: OperationOpts): Observable<RawAjaxResponse<Job>>
    createJob({ accessToken, client, uid, job, files, filesMeta }: CreateJobRequest, opts?: OperationOpts): Observable<Job | RawAjaxResponse<Job>> {
        throwIfNullOrUndefined(accessToken, 'accessToken', 'createJob');
        throwIfNullOrUndefined(client, 'client', 'createJob');
        throwIfNullOrUndefined(uid, 'uid', 'createJob');
        throwIfNullOrUndefined(job, 'job', 'createJob');

        const headers: HttpHeaders = {
            ...(accessToken != null ? { 'access-token': String(accessToken) } : undefined),
            ...(client != null ? { 'client': String(client) } : undefined),
            ...(uid != null ? { 'uid': String(uid) } : undefined),
        };

        const formData = new FormData();
        if (job !== undefined) {
          let formElement: string | Blob;
          if (typeof job === "object") {
            if ((job as any) instanceof Blob) {
              formElement = (job as any) as Blob;
            } else {
              formElement = JSON.stringify(job);
            }
          } else {
            formElement = job;
          }

          formData.append('job', formElement); }
        if (files !== undefined) {
              if (Array.isArray(files)) {
                for (let file of files) {
                  formData.append('files[]', file);
                }
              } else {
                formData.append('files', files);
              }
        }

        if (filesMeta !== undefined) {
          let formElement: string | Blob;
          if (typeof filesMeta === "object") {
            if ((filesMeta as any) instanceof Blob) {
              formElement = (filesMeta as any) as Blob;
            } else {
              formElement = JSON.stringify(filesMeta);
            }
          } else {
            formElement = filesMeta;
          }

          formData.append('filesMeta', formElement); }

        return this.request<Job>({
            url: '/api/v1/jobs/',
            method: 'POST',
            headers,
            body: formData,
        }, opts?.responseOpts);
    };

    /**
     * Deletes a Job
     */
    deleteJob({ accessToken, client, uid, id }: DeleteJobRequest): Observable<void>
    deleteJob({ accessToken, client, uid, id }: DeleteJobRequest, opts?: OperationOpts): Observable<void | RawAjaxResponse<void>>
    deleteJob({ accessToken, client, uid, id }: DeleteJobRequest, opts?: OperationOpts): Observable<void | RawAjaxResponse<void>> {
        throwIfNullOrUndefined(accessToken, 'accessToken', 'deleteJob');
        throwIfNullOrUndefined(client, 'client', 'deleteJob');
        throwIfNullOrUndefined(uid, 'uid', 'deleteJob');
        throwIfNullOrUndefined(id, 'id', 'deleteJob');

        const headers: HttpHeaders = {
            ...(accessToken != null ? { 'access-token': String(accessToken) } : undefined),
            ...(client != null ? { 'client': String(client) } : undefined),
            ...(uid != null ? { 'uid': String(uid) } : undefined),
        };

        return this.request<void>({
            url: '/api/v1/jobs/{id}'.replace('{id}', encodeURI(id)),
            method: 'DELETE',
            headers,
        }, opts?.responseOpts);
    };

    /**
     * Retrieve a Job list
     */
    getJob({ accessToken, client, uid, sortBy, id, jobOwnerId, createdAt, updatedAt, jobStatus }: GetJobRequest): Observable<Array<Job>>
    getJob({ accessToken, client, uid, sortBy, id, jobOwnerId, createdAt, updatedAt, jobStatus }: GetJobRequest, opts?: OperationOpts): Observable<RawAjaxResponse<Array<Job>>>
    getJob({ accessToken, client, uid, sortBy, id, jobOwnerId, createdAt, updatedAt, jobStatus }: GetJobRequest, opts?: OperationOpts): Observable<Array<Job> | RawAjaxResponse<Array<Job>>> {
        throwIfNullOrUndefined(accessToken, 'accessToken', 'getJob');
        throwIfNullOrUndefined(client, 'client', 'getJob');
        throwIfNullOrUndefined(uid, 'uid', 'getJob');

        const headers: HttpHeaders = {
            ...(accessToken != null ? { 'access-token': String(accessToken) } : undefined),
            ...(client != null ? { 'client': String(client) } : undefined),
            ...(uid != null ? { 'uid': String(uid) } : undefined),
        };

        const query: HttpQuery = {};

        if (sortBy != null) { query['sort_by'] = sortBy; }
        if (id != null) { query['id'] = id; }
        if (jobOwnerId != null) { query['job_owner_id'] = jobOwnerId; }
        if (createdAt != null) { query['created_at'] = (createdAt as any).toISOString(); }
        if (updatedAt != null) { query['updated_at'] = (updatedAt as any).toISOString(); }
        if (jobStatus != null) { query['job_status'] = jobStatus; }

        return this.request<Array<Job>>({
            url: '/api/v1/jobs/',
            method: 'GET',
            headers,
            query,
        }, opts?.responseOpts);
    };

    /**
     * Retrieves a Job
     */
    getJobById({ accessToken, client, uid, id }: GetJobByIdRequest): Observable<Job>
    getJobById({ accessToken, client, uid, id }: GetJobByIdRequest, opts?: OperationOpts): Observable<RawAjaxResponse<Job>>
    getJobById({ accessToken, client, uid, id }: GetJobByIdRequest, opts?: OperationOpts): Observable<Job | RawAjaxResponse<Job>> {
        throwIfNullOrUndefined(accessToken, 'accessToken', 'getJobById');
        throwIfNullOrUndefined(client, 'client', 'getJobById');
        throwIfNullOrUndefined(uid, 'uid', 'getJobById');
        throwIfNullOrUndefined(id, 'id', 'getJobById');

        const headers: HttpHeaders = {
            ...(accessToken != null ? { 'access-token': String(accessToken) } : undefined),
            ...(client != null ? { 'client': String(client) } : undefined),
            ...(uid != null ? { 'uid': String(uid) } : undefined),
        };

        return this.request<Job>({
            url: '/api/v1/jobs/{id}'.replace('{id}', encodeURI(id)),
            method: 'GET',
            headers,
        }, opts?.responseOpts);
    };

    /**
     * Patch a Job
     */
    patchJob({ accessToken, client, uid, id, job, files, filesMeta }: PatchJobRequest): Observable<Job>
    patchJob({ accessToken, client, uid, id, job, files, filesMeta }: PatchJobRequest, opts?: OperationOpts): Observable<RawAjaxResponse<Job>>
    patchJob({ accessToken, client, uid, id, job, files, filesMeta }: PatchJobRequest, opts?: OperationOpts): Observable<Job | RawAjaxResponse<Job>> {
        throwIfNullOrUndefined(accessToken, 'accessToken', 'patchJob');
        throwIfNullOrUndefined(client, 'client', 'patchJob');
        throwIfNullOrUndefined(uid, 'uid', 'patchJob');
        throwIfNullOrUndefined(id, 'id', 'patchJob');
        throwIfNullOrUndefined(job, 'job', 'patchJob');

        const headers: HttpHeaders = {
            ...(accessToken != null ? { 'access-token': String(accessToken) } : undefined),
            ...(client != null ? { 'client': String(client) } : undefined),
            ...(uid != null ? { 'uid': String(uid) } : undefined),
        };

        const formData = new FormData();
        if (job !== undefined) {
          let formElement: string | Blob;
          if (typeof job === "object") {
            if ((job as any) instanceof Blob) {
              formElement = (job as any) as Blob;
            } else {
              formElement = JSON.stringify(job);
            }
          } else {
            formElement = job;
          }

          formData.append('job', formElement); }
        if (files !== undefined) {
              if (Array.isArray(files)) {
                for (let file of files) {
                  formData.append('files[]', file);
                }
              } else {
                formData.append('files', files);
              }
        }

        if (filesMeta !== undefined) {
          let formElement: string | Blob;
          if (typeof filesMeta === "object") {
            if ((filesMeta as any) instanceof Blob) {
              formElement = (filesMeta as any) as Blob;
            } else {
              formElement = JSON.stringify(filesMeta);
            }
          } else {
            formElement = filesMeta;
          }

          formData.append('filesMeta', formElement); }

        return this.request<Job>({
            url: '/api/v1/jobs/{id}'.replace('{id}', encodeURI(id)),
            method: 'PATCH',
            headers,
            body: formData,
        }, opts?.responseOpts);
    };

    /**
     * Update a Job
     */
    updateJob({ accessToken, client, uid, id, job, files, filesMeta }: UpdateJobRequest): Observable<Job>
    updateJob({ accessToken, client, uid, id, job, files, filesMeta }: UpdateJobRequest, opts?: OperationOpts): Observable<RawAjaxResponse<Job>>
    updateJob({ accessToken, client, uid, id, job, files, filesMeta }: UpdateJobRequest, opts?: OperationOpts): Observable<Job | RawAjaxResponse<Job>> {
        throwIfNullOrUndefined(accessToken, 'accessToken', 'updateJob');
        throwIfNullOrUndefined(client, 'client', 'updateJob');
        throwIfNullOrUndefined(uid, 'uid', 'updateJob');
        throwIfNullOrUndefined(id, 'id', 'updateJob');
        throwIfNullOrUndefined(job, 'job', 'updateJob');

        const headers: HttpHeaders = {
            ...(accessToken != null ? { 'access-token': String(accessToken) } : undefined),
            ...(client != null ? { 'client': String(client) } : undefined),
            ...(uid != null ? { 'uid': String(uid) } : undefined),
        };

        const formData = new FormData();
        if (job !== undefined) {
          let formElement: string | Blob;
          if (typeof job === "object") {
            if ((job as any) instanceof Blob) {
              formElement = (job as any) as Blob;
            } else {
              formElement = JSON.stringify(job);
            }
          } else {
            formElement = job;
          }

          formData.append('job', formElement); }
        if (files !== undefined) {
              if (Array.isArray(files)) {
                for (let file of files) {
                  formData.append('files[]', file);
                }
              } else {
                formData.append('files', files);
              }
        }

        if (filesMeta !== undefined) {
          let formElement: string | Blob;
          if (typeof filesMeta === "object") {
            if ((filesMeta as any) instanceof Blob) {
              formElement = (filesMeta as any) as Blob;
            } else {
              formElement = JSON.stringify(filesMeta);
            }
          } else {
            formElement = filesMeta;
          }

          formData.append('filesMeta', formElement); }

        return this.request<Job>({
            url: '/api/v1/jobs/{id}'.replace('{id}', encodeURI(id)),
            method: 'PUT',
            headers,
            body: formData,
        }, opts?.responseOpts);
    };

}

/**
 * @export
 * @enum {string}
 */
export enum GetJobSortByEnum {
    IdAsc = 'id_asc',
    IdDesc = 'id_desc',
    JobOwnerIdAsc = 'job_owner_id_asc',
    JobOwnerIdDesc = 'job_owner_id_desc',
    CreatedAtAsc = 'created_at_asc',
    CreatedAtDesc = 'created_at_desc',
    UpdatedAtAsc = 'updated_at_asc',
    UpdatedAtDesc = 'updated_at_desc',
    JobStatusAsc = 'job_status_asc',
    JobStatusDesc = 'job_status_desc'
}
