import React, { ReactElement } from "react";
import { FieldValueValuable } from "../../../../../generated/api-service";
import { valueFromComplex } from "../../../../../helpers/dynamicFields/values/valueFromComplex";
import {
  TCFieldComponent,
  TCFieldProps,
} from "../../../../../interfaces/dynamicFieldInterfaces";
import { AnyObject } from "../../../../../interfaces/typeAliases";
import { TCLabeledFieldValue } from "../../TCLabeledFieldValue/TCLabeledFieldValue";

const countFromValue = (value) => {
  if (Array.isArray(value)) {
    return value.length;
  }

  return 1;
};

const getValueToDisplay = (
  value: FieldValueValuable["value"],
  props: TCFieldProps
): [string | ReactElement, boolean] => {
  const showCountInsteadOfValue =
    props.displayConfiguration?.valueDisplay?.showCountInsteadOfValue;

  if (showCountInsteadOfValue) {
    return [countFromValue(value).toString(), true];
  }

  return valueFromComplex(props.field, value as string[] | AnyObject, props);
};

export const TCDisplayFieldComplex: TCFieldComponent = (props) => {
  const value = props.initialValue || "";

  const valueToDisplay = getValueToDisplay(value, props);
  return React.isValidElement(valueToDisplay[0]) ? (
    valueToDisplay[0]
  ) : (
    <TCLabeledFieldValue
      value={valueToDisplay[0]}
      label={valueToDisplay[1] ? props.field.label : undefined}
      {...props}
    />
  );
};
