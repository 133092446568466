import { Observable } from "rxjs";
import { fieldValueApi } from "../../dataHandling/autogeneratedApiServices";
import { FieldValue, GetFieldValueRequest } from "../../generated/api-service";
import { WithoutAuthentication } from "../../interfaces/authentication";
import { ObservableHookResult } from "../../interfaces/observableHook";
import { useObservableWithAuthentication } from "./useObservable/useObservableWithAuthentication";

export const useFieldValues = (): ObservableHookResult<
  WithoutAuthentication<GetFieldValueRequest>,
  FieldValue[]
> =>
  useObservableWithAuthentication(
    fieldValueApi.getFieldValue.bind(fieldValueApi) as (
      parameter
    ) => Observable<FieldValue[]>
  );
