import React, { useContext } from "react";
import { DebuggingContext } from "../../../../appContext";
import { getFieldComponentForDisplayMode } from "../../../../helpers/dynamicFields/getFieldComponentForDisplayMode";
import {
  DisplayMode,
  TCDisplayFieldProps,
} from "../../../../interfaces/dynamicFieldInterfaces";
import { TCFieldDebugger } from "../../../debug/TCFieldDebugger/TCFieldDebugger";
import { TCNoopComponent } from "../../../placeholders/noop/noopComponent";

/**
 * Entry point for rendering any dynamic field in DisplayMode.Display
 */
export const TCDisplayField: React.FunctionComponent<TCDisplayFieldProps> = (
  props
) => {
  const isDebugging = useContext(DebuggingContext);
  // For JSX to render this properly, tag variables must be uppercase!

  const DataTypeField = getFieldComponentForDisplayMode(
    props.field,
    DisplayMode.Display
  );

  const initialValue = props.fieldValueMap
    ? props.fieldValueMap[props.field.id]
    : undefined;
  const propsWithValue =
    initialValue && initialValue.valuable
      ? {
          ...props,
          initialValue: initialValue.valuable.value,
          initialFiles: initialValue.documents || [],
        }
      : props;
  const Wrapper = isDebugging ? TCFieldDebugger : TCNoopComponent;

  if (!DataTypeField) {
    return null;
  }

  return (
    <Wrapper {...props} displayMode={DisplayMode.Display}>
      <DataTypeField {...propsWithValue} displayMode={DisplayMode.Display} />
    </Wrapper>
  );
};
