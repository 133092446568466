import { Box } from "@material-ui/core";
import React, { Fragment, useMemo } from "react";
import { TCConfirmationDialog } from "../../../../../dialogs/TCConfirmationDialog/ConfirmationDialog";
import { TCDialogDeleteDocument } from "../../../../../dialogs/TCDialogDeleteDocument/TCDialogDeleteDocument";
import { TCDocumentBlobList } from "../../../../../lists/TCDocumentBlobList/TCDocumentBlobList";
import { TCEditFieldFileCurrentStateProps } from "./TCEditFieldFileCurrentStateProps";
import { useTCEditFieldFileCurrentStateStyles } from "./TCEditFieldFileCurrentStateStyles";
import { useTCEditFieldFileCurrentStateViewModel } from "./TCEditFieldFileCurrentStateViewModel";

/**
 * Show the current state of the uploaded files.
 *
 * Files that are already stored in the system are represented as documents.
 * They persist no matter what.
 *
 * Files that have been uploaded during the lifetime of the parent form are stored as blobs.
 * They get saved when the user saves the whole form without error.
 */
export const TCEditFieldFileCurrentState: React.FunctionComponent<TCEditFieldFileCurrentStateProps> = (
  props
) => {
  const { documents, files } = props;
  const classes = useTCEditFieldFileCurrentStateStyles();
  const viewModel = useTCEditFieldFileCurrentStateViewModel(props);

  const documentsToUse = useMemo(() => documents || [], [documents]);
  const filesToUse = useMemo(() => files || [], [files]);

  const linkWrapperProps = useMemo(() => ({
      className: classes.linkWrapper,
    }), [classes]);

  return props.documents || props.files ? (
    <Fragment>
      {viewModel.documentToDelete && (
        <TCDialogDeleteDocument
          document={viewModel.documentToDelete}
          dialogOpen={viewModel.showDocumentDeleteDialog}
          onClose={viewModel.onCloseDeleteDocument}
          onAfterDelete={viewModel.onDeleteDocument}
        />
      )}

      {viewModel.uploadedFileIndexToDelete && (
        <TCConfirmationDialog
          confirmationTitle={"Datei löschen"}
          confirmationText={
            "Wollen Sie die Datei wirklich löschen? Dieser Vorgang kann nicht rückgängig gemacht werden."
          }
          open={viewModel.showUploadedBlobDeleteDialog}
          handleClose={viewModel.onCloseDeleteBlob}
          acceptAction={viewModel.onDeleteBlob}
        />
      )}

      {(props.documents?.length > 0 || props.files?.length > 0) && (
        <Box className={classes.fileListWrapper}>
          <Box className={classes.fileListLabel}>{props.field.label}</Box>

          <TCDocumentBlobList
            linkWrapperProps={linkWrapperProps}
            documents={documentsToUse}
            blobs={filesToUse}
            isUsingDeleteIcon={true}
            onDocumentDelete={viewModel.showDocumentDelete}
            onBlobDelete={viewModel.showUploadedBlobDelete}
          />
        </Box>
      )}
    </Fragment>
  ) : null;
};
