import { oneOfTheseStrings } from "./helpers/oneOfTheseStrings";

const possibleEnvironments = ["development", "production"] as const;

function requiredEnvValue(key: string): string | never {
  const value = process.env[key];

  if (!value) {
    throw Error(`Fatal: Could not find process.env.${key}`);
  }
  return value;
}

const buildApiPath = (): string => {
  const hostName = requiredEnvValue("REACT_APP_API_HOST");
  const apiPort = requiredEnvValue("REACT_APP_API_PORT_EXTERNAL");
  const apiPath = requiredEnvValue("REACT_APP_API_PATH");

  let apiHost = hostName;

  const excludedPorts = ["80", "443"];

  if (apiPort && !excludedPorts.includes(apiPort)) {
    apiHost = `${apiHost}:${apiPort}`;
  }

  if (apiPath) {
    apiHost += apiPath;
  }

  return apiHost;
};

const buildCableUrl = (): string => {
  const apiHost = buildApiPath();
  const apiUsesHttps = requiredEnvValue("REACT_APP_API_USES_HTTPS");
  const cableProtocol = apiUsesHttps === "true" ? "wss" : "ws";

  return `${cableProtocol}://${apiHost}/cable`;
};

const buildClientPath = (): string => requiredEnvValue("REACT_APP_TAMOC_HOST");

export const dbDateFormat = "YYYY-MM-DD";

export const activeTenant = requiredEnvValue("REACT_APP_TENANT");

export const apiHost = buildApiPath();
export const clientHost = buildClientPath();
export const cableURL = buildCableUrl();

export const dbTimeFormat = "HH:mm";

export const environment = oneOfTheseStrings(
  requiredEnvValue("REACT_APP_ENV"),
  possibleEnvironments
);

export const shouldPerformComplexTypeChecks = environment === "development";
