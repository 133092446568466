import { ButtonBase, Tooltip } from "@material-ui/core";
import clsx from "clsx";
import React, { useRef } from "react";
import { TCAvatar } from "../../tamocComponents/TCAvatar/TCAvatar";
import {
  AvatarSize,
  TCAvatarModes,
} from "../../tamocComponents/TCAvatar/TCAvatarProps";
import { TCProfileButtonProps } from "./TCProfileButtonProps";
import { useTCProfileButtonStyles } from "./TCProfileButtonStyles";

export const TCProfileButton: React.FunctionComponent<TCProfileButtonProps> = (
  props
) => {
  const anchorEl = useRef(null);
  const classes = useTCProfileButtonStyles();

  return (
    <Tooltip arrow={true} title="Profil bearbeiten">
      <ButtonBase
        ref={anchorEl}
        className={clsx(classes.button, props.className)}
        onClick={props.onClick}
      >
        <TCAvatar
          user={props.user}
          size={AvatarSize.Medium}
          avatarMode={TCAvatarModes.Display}
          className={classes.avatarBorder}
        />

        <span className={classes.label}>{props.name}</span>
      </ButtonBase>
    </Tooltip>
  );
};
