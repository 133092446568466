import { useEffect, useMemo, useState } from "react";
import {
  ITCListNavigationContext,
  TCListNavigationProps,
} from "./TCListNavigationProps";

export const useTCListNavigationViewModel = (
  props: TCListNavigationProps
): { detailsOpen: boolean; providerValue: ITCListNavigationContext } => {
  const [detailsOpen, setDetailsOpen] = useState(false);

  const { listHeader, onBackAction } = props;

  const providerValue: ITCListNavigationContext = useMemo(
    () => ({
      detailsOpen,
      openDetails: () => setDetailsOpen(true),
      closeDetails: () => {
        onBackAction?.();
        setDetailsOpen(false);
      },
      hasHeader: Boolean(listHeader),
    }),
    [detailsOpen, listHeader, onBackAction]
  );

  useEffect(() => {
    if (props.selectedItem) {
      setTimeout(() => {
        setDetailsOpen(true);
      }, 400);
    }
  }, [props.selectedItem]);

  return { detailsOpen, providerValue };
};
