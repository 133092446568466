import { isEqual } from "lodash";
import { FileObject } from "material-ui-dropzone";
import { useSnackbar } from "notistack";
import { CSSProperties, useContext, useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { AnimatedValue, useSpring } from "react-spring";
import { Subscription } from "rxjs";
import { UserContext } from "../../../appContext";
import { userApi } from "../../../dataHandling/autogeneratedApiServices";
import { User } from "../../../generated/api-service";
import { useAuthenticationParameter } from "../../../hooks/tamocApiHooks/authentication/useAuthenticationParameter";
import { TCAvatarProps } from "./TCAvatarProps";

interface TCAvatarViewModel {
  flashAnimate: AnimatedValue<Pick<CSSProperties, "opacity">>;
  avatarImageSrc: string;
  isMobile: boolean;
  updateAvatarOfUser: (files: FileObject[]) => void;
  isLoading: boolean;
}

export const useTCAvatarViewModel = (
  props: TCAvatarProps
): TCAvatarViewModel => {
  const [isFlashing, setFlashing] = useState<boolean>(false);
  const [
    updateAvatarSubscription,
    setUpdateAvatarSubscription,
  ] = useState<Subscription>();

  const { enqueueSnackbar } = useSnackbar();

  const [loggedInUser, setLoggedInUser] = useContext(UserContext);
  const [avatarForUser, setAvatarForUser] = useState<User>();
  const authenticationParameter = useAuthenticationParameter();

  const isSelf = loggedInUser?.id === props.user.id;

  const updateAvatarOfUser = async (files: FileObject[]) => {
    if (!avatarForUser) {
      return;
    }

    setUpdateAvatarSubscription(
      userApi
        .updateAvatar({
          id: avatarForUser.id,
          file: files[0].file,
          userId: avatarForUser.id.toString(),
          name: `${avatarForUser.email}-avatar`,
          ...authenticationParameter,
        })
        .subscribe({
          next(data) {
            enqueueSnackbar("Profilbild aktualisiert", {
              variant: "success",
            });

            setAvatarForUser(data);

            if (isSelf) {
              setLoggedInUser(data);
            }

            updateAvatarSubscription?.unsubscribe();
          },
          error(err) {
            enqueueSnackbar(`Something went wrong: ${err.message}`, {
              variant: "error",
            });

            updateAvatarSubscription?.unsubscribe();
          },
        })
    );
  };

  useEffect(() => {
    if (isMobile) {
      setFlashing(true);
    }

    if (isSelf && !isEqual(avatarForUser, loggedInUser)) {
      setAvatarForUser(loggedInUser);
    } else if (!isSelf && !isEqual(avatarForUser, props.user)) {
      setAvatarForUser(props.user);
    }

    return () => {
      updateAvatarSubscription?.unsubscribe();
    };
  }, [
    updateAvatarSubscription,
    avatarForUser,
    isSelf,
    loggedInUser,
    props.user,
  ]);

  const flashAnimate = useSpring({
    opacity: isFlashing ? 0 : 1,
    delay: isFlashing ? 400 : 0,
    config: { tension: 500 },
  });

  const defaultAvatarSrc = "/images/default-avatar.png";
  const avatarImageSrc = avatarForUser?.image || defaultAvatarSrc;

  return {
    flashAnimate,
    avatarImageSrc,
    isMobile,
    updateAvatarOfUser,
    isLoading: updateAvatarSubscription?.closed === false,
  };
};
