import { createStyles, makeStyles } from "@material-ui/core";
import { CustomMuiTheme } from "../../../../../interfaces/CustomMuiTheme";

export const useTCEditFieldLocationStyles = makeStyles(
  (theme: CustomMuiTheme) =>
    createStyles({
      inputWrap: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        flexWrap: "wrap",
      },
      street: {
        flexBasis: "70%",
      },
      street_number: {
        flexBasis: "28%",
      },
      postal_code: {
        marginTop: theme.spacing(1),
        flexBasis: "49%",
      },
      city: {
        marginTop: theme.spacing(1),
        flexBasis: (props: { cityOnly: boolean }) =>
          props.cityOnly ? "100%" : "49%",
      },
    })
);
