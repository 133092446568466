import { Observable } from "rxjs";
import { TCEditFieldGroupsDialogProps } from "../../components/dialogs/TCEditFieldGroupsDialog/TCEditFieldGroupsDialogProps";
import {
  EParentableType,
  Job,
  JobPutJob,
  JobUser,
  JobUserPutJobUser,
  User,
  UserPutUser,
} from "../../generated/api-service";
import { InformationFromFormikValue } from "../../helpers/forms/extractInformationFromFormikValues";
import { formikValuesToFieldValueBulkList } from "../../helpers/forms/formikValuesToFieldValueBulkList";
import { AuthenticationParameter } from "../../interfaces/authentication";
import { jobApi, jobUserApi, userApi } from "../autogeneratedApiServices";

export type UpdateService = (
  authenticationParameter: AuthenticationParameter,
  parentableId: string,
  dataToSave: InformationFromFormikValue,
  // Pretty dirty, since this will only work (and actually be parsed)
  //  by the user service. It's that time of the project schedule again …
  additionalProps?: UserPutUser | JobPutJob | JobUserPutJobUser
) => Observable<User | Job | JobUser>;

export const fieldValueBulkUpdateServices: Record<
  TCEditFieldGroupsDialogProps["parentableType"],
  UpdateService
> = {
  [EParentableType.Job]: (
    authenticationParameter: AuthenticationParameter,
    parentableId: string,
    dataToSave: InformationFromFormikValue,
    additionalProps?: JobPutJob
  ) =>
    jobApi.updateJob({
      id: parseInt(parentableId, 10),
      job: {
        fieldValues: formikValuesToFieldValueBulkList(dataToSave.fieldsToSave),
        ...additionalProps,
      },
      files: dataToSave?.files,
      filesMeta: dataToSave?.filesMeta,
      ...authenticationParameter,
    }),
  [EParentableType.JobUser]: (
    authenticationParameter: AuthenticationParameter,
    parentableId: string,
    dataToSave: InformationFromFormikValue,
    additionalProps?: JobUserPutJobUser
  ) =>
    jobUserApi.updateJobUser({
      id: parseInt(parentableId, 10),
      jobUser: {
        fieldValues: formikValuesToFieldValueBulkList(dataToSave.fieldsToSave),
        ...additionalProps,
      },
      files: dataToSave?.files,
      filesMeta: dataToSave?.filesMeta,
      ...authenticationParameter,
    }),
  [EParentableType.User]: (
    authenticationParameter: AuthenticationParameter,
    parentableId: string,
    dataToSave: InformationFromFormikValue,
    additionalProps?: UserPutUser
  ) => userApi.patchUser({
      id: parseInt(parentableId, 10),
      user: {
        fieldValues: formikValuesToFieldValueBulkList(dataToSave.fieldsToSave),
        ...additionalProps,
      },
      files: dataToSave?.files,
      filesMeta: dataToSave?.filesMeta,
      ...authenticationParameter,
    }),
};
