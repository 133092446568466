import {
  Dialog,
  DialogContent,
  DialogProps,
  DialogTitle,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { merge } from "lodash";
import React, { useCallback } from "react";
import { randomId } from "../../../helpers/randomId";
import { TCCloseButton } from "../../button/TCCloseButton";
import { TCDialogProps } from "./TCDialogProps";
import { useTCDialogStyles } from "./TCDialogStyles";
import { Transition } from "./Transition/Transition";

const defaultDialogProps: Partial<DialogProps> = {
  keepMounted: false,
  fullWidth: true,
  maxWidth: "lg" as const,
  scroll: "paper",
};

/** Generic TC Dialog for displaying something in a modal */
export const TCDialog: React.FunctionComponent<TCDialogProps> = (props) => {
  const isNarrow = useMediaQuery("(max-height:1200px)");
  const classes = useTCDialogStyles({
    narrowScreen: isNarrow,
  });
  const id = randomId();
  const dialogProps: DialogProps = merge(
    {},
    defaultDialogProps,
    props.elementProps?.dialog,
    {
      onClose: props.onClose,
      open: props.open,
      "aria-labelledby": id,
      classes: {
        paper: classes.dialog,
        root: classes.dialogRoot,
      },
    }
  );

  const { onClose } = dialogProps;
  const onCloseHandler = useCallback(
    (event) =>
      (onClose as NonNullable<DialogProps["onClose"]>)(event, "backdropClick"),
    [onClose]
  );

  const buttonCloseHandler = onClose ? onCloseHandler : undefined;

  return (
    <Dialog TransitionComponent={Transition} {...dialogProps}>
      <DialogTitle
        disableTypography={true}
        id={id}
        className={classes.dialogTitle}
      >
        <Typography variant="h1">{props.title}</Typography>

        {props.headerElement && props.headerElement}
      </DialogTitle>

      {buttonCloseHandler && (
        <TCCloseButton
          className={classes.closeButton}
          onClick={buttonCloseHandler}
        />
      )}

      <DialogContent ref={props.contentRef} className={classes.dalogContent}>
        {props.children}
      </DialogContent>
    </Dialog>
  );
};
