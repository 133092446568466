import { isEmpty } from "lodash";
import { FieldValueValuable } from "../../../generated/api-service";

export const fieldIsEmpty = (
  fieldValue: FieldValueValuable["value"] | undefined
): boolean => {
  if (!fieldValue) {
    return true;
  }

  // Objects needs to be checked separately, since the the simple check from
  //  above will not catch them ([] and {} both are truthy!)
  return typeof fieldValue === "object" && isEmpty(fieldValue);
};
