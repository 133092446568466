import { ProviderContext, SnackbarProvider } from "notistack";
import React, { createRef, ReactElement } from "react";
import { TCIconButton } from "../components/icons/TCIcon/TCIconButton/TCIconButton";

interface TCSnackbarProviderProps {
  children: ReactElement;
}

export const TCSnackbarProvider: React.FunctionComponent<TCSnackbarProviderProps> = (
  props: TCSnackbarProviderProps
) => {
  const notistackRef = createRef<ProviderContext>();
  const onClickDismiss = (key) => {
    notistackRef?.current?.closeSnackbar(key);
  };
  return (
    <SnackbarProvider
      ref={notistackRef}
      maxSnack={3}
      action={(key) => (
        <TCIconButton iconName="clear" onClick={() => onClickDismiss(key)} />
      )}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
    >
      {props.children}
    </SnackbarProvider>
  );
};
