import { createStyles, fade, makeStyles } from "@material-ui/core";
import { CustomMuiTheme } from "../../../interfaces/CustomMuiTheme";
import { TCStepButtonProps } from "./TCStepButtonProps";

export const useTCStepButtonStyles = makeStyles((theme: CustomMuiTheme) => {
  const borderColor = (props: TCStepButtonProps) =>
    props.active || (!props.onlyOneActive && props.completed)
      ? theme.palette.primary.main
      : fade(theme.palette.primary.main, 0.25);
  return createStyles({
    stepButtonRoot: {
      width: "150px",
      minHeight: "80px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: "1.5rem",
      borderBottom: (props: TCStepButtonProps) =>
        `5px solid ${borderColor(props)}`,
      [theme.breakpoints.down("sm")]: {
        padding: "0.7rem",
      },
    },
    counterLabel: {
      display: "none",
      [theme.breakpoints.down("sm")]: {
        display: "block",
      },
    },
    checkIcon: {
      color: theme.palette.success.main,
    },
  });
});
