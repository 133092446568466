import { useEffect, useMemo } from "react";
import {
  EParentableType,
  ValueValidationTimeSlots,
} from "../../../../../../../generated/api-service";
import { useFieldValues } from "../../../../../../../hooks/tamocApiHooks/useFieldValues";
import { useUser } from "../../../../../../../hooks/useUser";

export const useReferencedTimeSlotsValue = (
  referencedFieldId: number | undefined
): ValueValidationTimeSlots | undefined => {
  const user = useUser();
  const service = useFieldValues();
  const { call, result } = service;

  useEffect(() => {
    if (!referencedFieldId || !user) {
      return;
    }
    call({
      fieldId: referencedFieldId.toString(),
      parentableType: EParentableType.User,
      parentableId: user.id,
    });
  }, [referencedFieldId, call, user]);

  return useMemo(
    () => result?.[0]?.valuable.value as ValueValidationTimeSlots,
    [result]
  );
};
