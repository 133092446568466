import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import moment from "moment";
import { dbTimeFormat } from "../../Consts";

/**
 * Convert a time string, as stored in our database, to a moment object.
 */
export const momentFromDbTime = (
  dbTimeString: string | undefined
): MaterialUiPickersDate =>
  dbTimeString ? moment(dbTimeString, dbTimeFormat) : null;
