import { BoxProps } from "@material-ui/core";
import { ReactElement } from "react";

export enum InfoBlockTyp {
  Error = "error",
  Success = "success",
  Info = "info",
  Waiting = "waiting",
}

export interface TCInfoBlockProps {
  infoBlockType: InfoBlockTyp;
  children: ReactElement;
  wrapperBoxProps?: BoxProps;
}
