import { useRef } from "react";
import { conversationUsersStore } from "../../../../dataHandling/stores/conversationUsersStore";
import {
  ConversationUser,
  ECableActions,
  ECableChannels,
} from "../../../../generated/api-service";
import { useCable } from "../../../../hooks/useCable";
import { ActiveCableMessage } from "../../../../interfaces/activeCable";

interface ConversationCable extends ActiveCableMessage {
  conversationUser: ConversationUser;
}

export const useTCMessageCable = (userId: number | undefined): void => {
  const onReceived = useRef((data: ConversationCable) => {
    const { action, conversationUser } = data;

    switch (action) {
      case ECableActions.ConversationsChannelUpdate:
        conversationUsersStore.updateConversationUser(conversationUser);
        break;
      default:
        break;
    }
  }).current;

  useCable<ConversationCable>({
    channel: ECableChannels.ConversationsChannel,
    room: userId,
    onReceived,
  });
};
