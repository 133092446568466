import { createStyles, makeStyles } from "@material-ui/core";
import { CustomMuiTheme } from "../../../../interfaces/CustomMuiTheme";

export const useTCLabeledFieldValueStyles = makeStyles(
  (theme: CustomMuiTheme) =>
    createStyles({
      typographyTCLabeledFieldValueStyles: {
        justifySelf: "center",
      },
      iconAndFieldWrapper: {
        display: "flex",
        flexDirection: "row",
      },
      labelAndValueWrapper: {
        display: "flex",
        flexDirection: "column",
        "& ul": {
          margin: theme.spacing(0),
          listStyleType: "none",
          paddingLeft: theme.spacing(0),
        },
      },
    })
);
