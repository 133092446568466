import React, { memo, useMemo } from "react";
import { TCMessageList } from "./messages/TCMessageList/TCMessageList";
import TCChatInput from "./TCChatInput/TCChatInput";
import { TCChatProps } from "./TCChatProps";
import { useTCChatStyles } from "./TCChatStyles";

export const TCChat: React.FunctionComponent<TCChatProps> = memo(
  (props) => {
    const classes = useTCChatStyles();

    const messages = useMemo(() => props.conversation.messages ?? [], [props.conversation.messages]);

    if (!props.conversation.id) {
      return null;
    }

    return (
      <div className={classes.chatContainer}>
        <TCMessageList
          conversationId={props.conversation.id}
          messages={messages}
        />

        <TCChatInput conversationId={props.conversation.id} />
      </div>
    );
  },
  (prevProps, nextProps) => prevProps.conversation.id === nextProps.conversation.id
);
