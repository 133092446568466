import { merge } from "lodash";
import { Field, FieldGroup } from "../../generated/api-service";

/**
 * Merges each field's display configuration with the display configuration
 * given in the field_field_group and the field_type.
 */
export function mergeFieldDisplayConfig(group: FieldGroup): void {
  const fields = group.fields || ([] as Field[]);
  fields.forEach((field) => {
    const configFromGroup = (group.fieldFieldGroups || []).find(
      (fieldFieldGroup) => fieldFieldGroup.fieldId === field.id
    )?.displayConfiguration;
    const mergedDisplayConfig = merge(
      {},
      field.fieldType?.displayConfiguration || {},
      field.displayConfiguration,
      configFromGroup
    );
    // We want it that way.
    // eslint-disable-next-line no-param-reassign
    field.displayConfiguration = mergedDisplayConfig;
  });
}
