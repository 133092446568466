// tslint:disable
/**
 * TaMoC API V1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum EComplexType {
    File = 'file',
    FieldReference = 'field_reference',
    Location = 'location',
    Range = 'range',
    ValueList = 'value_list',
    ValueMap = 'value_map',
    VideoIdent = 'video_ident',
    PasswordNew = 'password_new',
    PasswordUpdate = 'password_update',
    Payment = 'payment',
    TimeSlots = 'time_slots'
}

