import React from "react";
import { TCHigherOrderComponent } from "./globalHigherOrderComponents";
import { MainPage } from "./pages/MainPage/MainPage";
import { TCRoutes } from "./routes/TCRoutes";

const App: React.FC = () => (
    <div className="App" style={{ height: "100%", width: "100%" }}>
      <TCHigherOrderComponent>
        <MainPage>
          <TCRoutes />
        </MainPage>
      </TCHigherOrderComponent>
    </div>
  );

export default App;
