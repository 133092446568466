import { Box, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import { isString } from "lodash";
import React from "react";
import TCFooter from "../../basicPageComponents/TCFooter/TCFooter";
import { TCPageLayoutProps } from "./TCPageLayoutProps";
import { useTCPageLayoutStyles } from "./TCPageLayoutStyles";

const TCPageLayout = ({ title, children }: TCPageLayoutProps): JSX.Element => {
  const classes = useTCPageLayoutStyles();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Box className={classes.pageWrapper}>
      <Box className={classes.pageContent}>
        {isString(title) && (
          <Typography align="left" variant="h1">
            {title}
          </Typography>
        )}

        {!isString(title) && title}

        {children}
      </Box>

      {isDesktop && <TCFooter />}
    </Box>
  );
};

export default TCPageLayout;
