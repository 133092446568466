import { Observable } from "rxjs";
import { conversationUserApi } from "../../dataHandling/autogeneratedApiServices";
import {
  ConversationUser,
  GetConversationUserRequest,
} from "../../generated/api-service";
import { WithoutAuthentication } from "../../interfaces/authentication";
import { ObservableHookResult } from "../../interfaces/observableHook";
import { useObservableWithAuthentication } from "./useObservable/useObservableWithAuthentication";

export const useGetConversationUsers = (): ObservableHookResult<
  WithoutAuthentication<GetConversationUserRequest>,
  ConversationUser[]
> =>
  useObservableWithAuthentication(
    conversationUserApi.getConversationUser.bind(conversationUserApi) as (
      parameter
    ) => Observable<ConversationUser[]>
  );
