import { Avatar, Box, CircularProgress, Grid } from "@material-ui/core";
import { AddAPhotoOutlined } from "@material-ui/icons";
import clsx from "clsx";
import { isNumber, merge } from "lodash";
import { DropzoneAreaBase } from "material-ui-dropzone";
import React, { useState } from "react";
import { animated } from "react-spring";
import { AvatarSize, TCAvatarModes, TCAvatarProps } from "./TCAvatarProps";
import { avatarSizeMap, useTCAvatarStyles } from "./TCAvatarStyles";
import { useTCAvatarViewModel } from "./TCAvatarViewModel";

const defaults: Partial<TCAvatarProps> = {
  size: AvatarSize.Default,
};

export const TCAvatar: React.FunctionComponent<TCAvatarProps> = (
  passedProps
) => {
  const props = merge({}, defaults, passedProps);

  const [isFocus, setFocus] = useState<boolean>(false);

  const viewModel = useTCAvatarViewModel(props);

  const classes = useTCAvatarStyles({
    size: isNumber(props.size) ? props.size : avatarSizeMap[props.size],
    isMobile: viewModel.isMobile,
    isFocus,
  });

  const AnimatedDiv = viewModel.isMobile ? animated.div : Grid;

  return (
    <Box {...props.boxProps} className={classes.root}>
      {viewModel.avatarImageSrc ? (
        <Avatar
          src={viewModel.avatarImageSrc}
          className={clsx(
            classes.avatar,
            props.className,
            props.classes?.avatar
          )}
          {...props.avatarProps}
        />
      ) : (
        <Box className={classes.emptyAvatar} />
      )}

      {!viewModel.isLoading && props.avatarMode === TCAvatarModes.Edit && (
        <AnimatedDiv
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
          className={classes.animatedDiv}
          style={viewModel.flashAnimate}
        >
          <DropzoneAreaBase
            fileObjects={[]}
            showPreviews={true}
            showAlerts={false}
            acceptedFiles={["image/*"]}
            dropzoneParagraphClass={classes.dropZoneParagraph}
            dropzoneClass={classes.dropZone}
            showPreviewsInDropzone={false}
            onAdd={viewModel.updateAvatarOfUser}
            onDrop={() => {}}
            // This seems to be a typing bug in the material-ui-dropzone package:
            // https://github.com/Yuvaleros/material-ui-dropzone/issues/267
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            Icon={AddAPhotoOutlined}
          />
        </AnimatedDiv>
      )}

      {viewModel.isLoading && (
        <Grid
          container={true}
          className={classes.loading}
          justify="center"
          alignItems="center"
        >
          <CircularProgress
            classes={{
              circle: classes.circle,
            }}
          />
        </Grid>
      )}
    </Box>
  );
};
