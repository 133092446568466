/* eslint-disable import/no-cycle */
import { ButtonBase } from "@material-ui/core";
import React, { Fragment, useContext } from "react";
import { TCListNavigationContext } from "../TCListNavigationContext";
import { TCListNavigationListProps } from "./TCListNavigationListProps";

export const TCListNavigationList: React.FunctionComponent<TCListNavigationListProps> = (
  props
) => {
  const context = useContext(TCListNavigationContext);

  return (
    <Fragment>
      {React.Children.map(props.children, (child) => (
        <ButtonBase style={{ width: "100%" }} onClick={context?.openDetails}>
          {child}
        </ButtonBase>
      ))}
    </Fragment>
  );
};
