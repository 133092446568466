import { createStyles, makeStyles } from "@material-ui/core";
import { CustomMuiTheme } from "../../../../interfaces/CustomMuiTheme";

export const useTCIconBeforeValueStyles = makeStyles((theme: CustomMuiTheme) =>
  createStyles({
    icon: {
      marginRight: theme.spacing(0.6),
    },
  })
);
