import { useEffect, useState } from "react";
import { User } from "../../../../generated/api-service";
import { extractParticipants } from "../../../../helpers/conversationUser";
import { useUser } from "../../../../hooks/useUser";
import { TCHeaderAvatarIconsProps } from "./TCHeaderAvatarIconsProps";

export const useTCHeaderAvatarIconsViewModel = (
  props: TCHeaderAvatarIconsProps
): {
  user: User;
  title: string;
} => {
  const currentUser = useUser();

  const [participants, setParticipants] = useState<User[]>([]);

  useEffect(() => {
    if (!currentUser || !props.conversationUser.conversation) {
      return;
    }
    const extractedParticipants = extractParticipants(
      props.conversationUser.conversation,
      currentUser
    );
    setParticipants(extractedParticipants);
  }, [props.conversationUser, currentUser]);

  return {
    user: participants?.[0],
    title: `${participants?.[0]?.firstName} ${participants?.[0]?.lastName}`,
  };
};
