export function onlyUnique<T>(value: T, index: number, self: T[]): boolean {
  return self.indexOf(value) === index;
}

export function arrayWithSequentialNumbers(until: number): number[] {
  return Array.from(Array(until).keys());
}

export function noNullOrUndefined<T>(
  value: T | null | undefined
): value is NonNullable<T> {
  return value !== null && value !== undefined;
}

export function arrayFromNodeList<T extends Node>(input: NodeListOf<T>): T[] {
  return Array.prototype.slice.call(input);
}
