// tslint:disable
/**
 * TaMoC API V1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface ValidationInt
 */
export interface ValidationInt {
    /**
     * @type {string}
     * @memberof ValidationInt
     */
    type?: ValidationIntTypeEnum;
    /**
     * @type {number}
     * @memberof ValidationInt
     */
    minimum?: number;
    /**
     * @type {number}
     * @memberof ValidationInt
     */
    maximum?: number;
}

/**
 * @export
 * @enum {string}
 */
export enum ValidationIntTypeEnum {
    Integer = 'integer'
}

