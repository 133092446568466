import { createStyles, fade, makeStyles } from "@material-ui/core";
import { forceHeight } from "../../../helpers/styles/forceHeight";
import { forceWidth } from "../../../helpers/styles/forceWidth";
import { CustomMuiTheme } from "../../../interfaces/CustomMuiTheme";

export const useTCSidebarStyles = makeStyles((theme: CustomMuiTheme) =>
  createStyles({
    drawer: {
      flexShrink: 0,
      overflowY: "auto" as const,
      [theme.breakpoints.up("md")]: {
        ...forceWidth(theme.tcCustom.layout.sidebarWidth),
      },
    },
    drawerPaper: {
      backgroundColor: theme.palette.primary.dark,
      paddingTop: `${theme.tcCustom.layout.headerHeight * 1.5}px`,
      zIndex: 1000,
      border: "none",
      [theme.breakpoints.up("md")]: {
        ...forceWidth(theme.tcCustom.layout.sidebarWidth),
      },
      [theme.breakpoints.down("sm")]: {
        boxShadow: theme.shadows[3],
        backgroundColor: theme.palette.grey[50],
        paddingTop: 0,
        "& ul": {
          padding: 0,
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
        },
      },
    },
    sidebarListItem: {
      [theme.breakpoints.down("sm")]: {
        width: "auto",
      },
    },
    link: {
      borderLeft: "2px solid transparent",
      padding: theme.spacing(2.5),
      width: "100%",
      display: "flex",
      lineHeight: 1,
      "&, &:link, &:visited, &:hover, &:active": {
        textDecoration: "none",
      },
      textDecoration: "none",
      [theme.breakpoints.down("sm")]: {
        borderLeft: "none",
        borderTop: "4px solid transparent",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        padding: `0 ${theme.spacing(1.5)}px`,
        ...forceHeight(theme.tcCustom.layout.mobileBottomNavHeight),
      },
    },
    activeLink: {
      borderColor: theme.palette.primary.light,
      backgroundColor: fade(theme.palette.grey[50], 0.21),
      [theme.breakpoints.down("sm")]: {
        borderColor: theme.palette.primary.main,
        "& svg, & .link-label": {
          color: theme.palette.primary.main,
        },
      },
    },
    linkLabel: {
      color: theme.palette.secondary.light,
    },
    iconWrap: {
      minWidth: 0,
      [theme.breakpoints.down("sm")]: {
        marginBottom: theme.spacing(0.5),
      },
    },
    iconFill: {
      "& path": {
        fill: theme.palette.primary.light,
        [theme.breakpoints.down("sm")]: {
          fill: theme.palette.secondary.main,
        },
      },
    },
    iconStroke: {
      "& path": {
        stroke: theme.palette.primary.light,
        [theme.breakpoints.down("sm")]: {
          stroke: theme.palette.secondary.main,
        },
      },
    },
  })
);
