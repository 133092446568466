import React from "react";
import { TCFieldComponent } from "../../../../../interfaces/dynamicFieldInterfaces";
import { TCEditFieldTimeSlotsPatterns } from "./TCEditFieldTimeSlotsPatterns/TCEditFieldTimeSlotsPatterns/TCEditFieldTimeSlotsPatterns";

export const TCEditFieldTimeSlots: TCFieldComponent = (props) => 
  // Right now, TCEditFieldTimeSlotsPatterns is tailored towards managing
  //  working shift time slots. It can be easily extended though to
  //  reflect other use cases of time slot patterns.
   <TCEditFieldTimeSlotsPatterns {...props} />
;
