import { PaletteOptions } from "@material-ui/core/styles/createPalette";

export const palette: PaletteOptions = {
  primary: {
    main: "#783af3",
    light: "#dac1ff",
    dark: "#3e17a7",
  },
  secondary: {
    main: "#8296b9",
    light: "#cbd1e9",
    dark: "#60708c",
  },
  background: {
    paper: "#ffffff",
    default: "#fafbff",
  },
  grey: {
    "900": "#001473",
    "400": "#f0f3fa",
    "200": "#fafbff",
    "50": "#ffffff",
  },
  success: {
    main: "#5fb432",
    light: "#f3fae8",
  },
  warning: {
    main: "#ffc846",
    light: "#fff6e0",
  },
  error: {
    main: "#ffa18e",
    light: "#fff0ed",
  },
};
