import { TextFieldProps } from "@material-ui/core";
import { useCallback } from "react";
import { fieldIsRequired } from "../../helpers/forms/fieldIsRequired";
import { fieldValuesFromFormikContext } from "../../helpers/forms/fieldValuesFromFormikContext";
import { updateFormikValue } from "../../helpers/forms/updateFormikValue";
import {
  hasFieldAndFieldGroup,
  TCFieldProps,
} from "../../interfaces/dynamicFieldInterfaces";
import { useTCFormikContext } from "../useTCFormikContext";

interface GetFieldPropsForNumber {
  dataTypeFieldProps: TCFieldProps;
  fieldIntValue: number;
  onChange: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
}
export const useGetFieldPropsForNumber = (
  props: GetFieldPropsForNumber
): TextFieldProps => {
  const formikContext = useTCFormikContext();

  const {
    formikIdentifier,
    fieldData,
    fieldValue,
  } = fieldValuesFromFormikContext<number>(
    formikContext,
    props.dataTypeFieldProps.field
  );

  const onBlur = useCallback(() => {
    if (fieldValue !== props.fieldIntValue) {
      formikContext.setFieldTouched(formikIdentifier, true);
      updateFormikValue(formikContext, formikIdentifier, {
        ...fieldData,
        value: props.fieldIntValue,
      });
    }
  }, [
    formikContext,
    formikIdentifier,
    props.fieldIntValue,
    fieldValue,
    fieldData,
  ]);

  if (!hasFieldAndFieldGroup(props.dataTypeFieldProps)) {
    return {};
  }

  return {
    label: props.dataTypeFieldProps.field.label,
    required: fieldIsRequired(props.dataTypeFieldProps),
    inputProps: {
      type: "number",
    },
    value: props.fieldIntValue,
    InputProps: {
      onBlur,
      onChange: props.onChange,
    },
  };
};
