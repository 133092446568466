import { createStyles, makeStyles } from "@material-ui/core";
import { omit } from "lodash";
import { inputBasicStyles } from "../../../../../helpers/styles/inputStyles";
import { CustomMuiTheme } from "../../../../../interfaces/CustomMuiTheme";

export const useTCEditFieldValueListStyles = makeStyles(
  (theme: CustomMuiTheme) =>
    createStyles({
      dropdownRoot: {
        border: "none",
      },
      dropdown: {
        ...omit(inputBasicStyles(theme), "paddingTop", "paddingBottom"),
        padding: "0",
      },
    })
);
