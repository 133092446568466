import { useCallback, useContext, useEffect } from "react";
import { UserContext } from "../appContext";
import { EOnboardingStatus, User } from "../generated/api-service";
import { userNeedsOnboarding } from "../helpers/userNeedsOnboarding";
import { useUpdateUser } from "./tamocApiHooks/userHooks";
import { useErrorHandler } from "./useErrorHandler";
import { useTCSnackbars } from "./useTCSnackbars";

/**
 * To be used for quickly changing the onboarding status of the current
 *  user during development.
 */
export const useToggleOnboardingStatus = (): {
  toggleOnboardingStatus: () => void;
  userIsOnboarded: boolean;
} => {
  const [user, updateUser] = useContext(UserContext);
  const { successSnackbar } = useTCSnackbars();
  const errorCallback = useErrorHandler();

  const successCallback = useCallback(
    (newUser: User | undefined) => {
      successSnackbar(
        `Onboarding status changed to ${newUser?.onboardingStatus || "unknown"}`
      );
      updateUser(newUser);
    },
    [successSnackbar, updateUser]
  );

  const { call, result, error } = useUpdateUser();
  useEffect(() => {
    if (error) {
      errorCallback(error);
    }
  }, [error, errorCallback]);

  useEffect(() => {
    if (result) {
      successCallback(result);
    }
  }, [result, successCallback]);

  const newStatusWouldBe =
    user && userNeedsOnboarding(user)
      ? EOnboardingStatus.Complete
      : EOnboardingStatus.Open;

  const toggleOnboardingStatus = useCallback(() => {
    if (!user?.id) {
      return;
    }
    call({
      id: user?.id,
      user: {
        onboardingStatus: newStatusWouldBe,
      },
    });
  }, [user?.id, call, newStatusWouldBe]);

  return {
    toggleOnboardingStatus,
    userIsOnboarded: !!user && !userNeedsOnboarding(user),
  };
};
