import { FormikContextType } from "formik";
import { FieldValue } from "../../generated/api-service";
import {
  FieldValuableMap,
  FieldValueMap,
} from "../../interfaces/dynamicFieldInterfaces";
import { TCFormikValues } from "../../interfaces/formikInterfaces";

export const valuableMapFromFormikContext = (
  formikContext: FormikContextType<TCFormikValues> | undefined
): FieldValuableMap | undefined => {
  if (!formikContext) {
    return undefined;
  }

  const valuableMap: FieldValuableMap = Object.values(
    formikContext.values
  ).reduce(
    (res, formikValue) =>
      formikValue.field
        ? {
            ...res,
            [formikValue.field?.id]: formikValue.value,
          }
        : res,
    {}
  );

  return valuableMap;
};

export const valuableMapFromValueMap = (
  fieldValueMap: FieldValueMap | undefined
): FieldValuableMap | undefined => {
  if (!fieldValueMap) {
    return undefined;
  }

  return Object.values(fieldValueMap).reduce(
    (res: FieldValuableMap, fieldValue: FieldValue) => ({
      ...res,
      [(fieldValue as FieldValue).fieldId]: (fieldValue as FieldValue).valuable
        .value,
    }),
    {}
  );
};
