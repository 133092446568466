import { Grid, GridProps } from "@material-ui/core";
import clsx from "clsx";
import { merge } from "lodash";
import React, { Fragment, ReactElement } from "react";
import {
  DisplayConfiguration,
  DisplayConfigurationValueDisplayFileFieldDisplayStyleEnum,
  EComplexType,
  Field,
} from "../../../../generated/api-service";
import { mergeDisplayConfigurationForField } from "../../../../helpers/dynamicFields/displayConfiguration/mergeDisplayConfigurationForField";
import { dynamicFieldClasses } from "../../../../helpers/dynamicFields/dynamicFieldClasses";
import { fieldShouldBeRendered } from "../../../../helpers/dynamicFields/fieldShouldBeRendered/fieldShouldBeRendered";
import { addClassNameToProps } from "../../../../helpers/forComponents/addClassNameToProps";
import { TCField } from "../../TCField/TCField";
import { TCFieldGroupRenderedFieldsProps } from "./TCFieldGroupRenderedFieldsProps";
import { useTCFieldGroupRenderedFieldsStyles } from "./TCFieldGroupRenderedFieldsStyles";
import { useTCFieldGroupRenderedFieldsViewModel } from "./TCFieldGroupRenderedFieldsViewModel";

const defaultGridProps: GridProps = {
  xs: 6,
  item: true,
};

export const TCFieldGroupRenderedFields: React.FunctionComponent<TCFieldGroupRenderedFieldsProps> = (
  props
): ReactElement | null => {
  const viewModel = useTCFieldGroupRenderedFieldsViewModel(props);
  const classes = useTCFieldGroupRenderedFieldsStyles();

  const gridProps = merge({}, defaultGridProps, props.fieldGroupGridItemProps);

  const getRenderedField = (
    field: Field,
    displayConfiguration: DisplayConfiguration
  ) => (
    <TCField
      displayConfiguration={displayConfiguration}
      key={`${field.id}_${field.name}`}
      field={field}
      {...props}
      fieldDisplayWrapperProps={addClassNameToProps(
        props.fieldDisplayWrapperProps,
        classes.tcFieldGroupFieldWrapper
      )}
      fieldLabelDisplayProps={addClassNameToProps(
        props.fieldLabelDisplayProps,
        classes.tcFieldGroupFieldLabel
      )}
      fieldValueDisplayProps={addClassNameToProps(
        props.fieldValueDisplayProps,
        classes.tcFieldGroupFieldValue
      )}
    />
  );

  return (
    <Fragment>
      {props.fields.map((field) => {
        const displayConfiguration = mergeDisplayConfigurationForField(
          field,
          props.fieldGroup
        );

        if (
          !fieldShouldBeRendered(
            field,
            displayConfiguration,
            props.displayMode,
            viewModel.valuableMap
          )
        ) {
          return null;
        }

        const fieldToRender = getRenderedField(field, displayConfiguration);

        if (
          field.fieldType.complexType?.complexType === EComplexType.File &&
          displayConfiguration.valueDisplay?.fileFieldDisplayStyle ===
            DisplayConfigurationValueDisplayFileFieldDisplayStyleEnum.ListView
        ) {
          return fieldToRender;
        }

        return (
          <Grid
            {...addClassNameToProps(
              gridProps,
              clsx(classes.fieldGroupGridItem, ...dynamicFieldClasses(field))
            )}
            key={`${field.id}_${props.fieldGroup.id}`}
          >
            {fieldToRender}
          </Grid>
        );
      })}
    </Fragment>
  );
};
