import React, { FunctionComponent } from "react";
import {
  DisplayConfigurationValueEditingValueListRenderingTypeEnum,
  ValidationValueList,
} from "../../../../../generated/api-service";
import { constructFormikFieldIdentifier } from "../../../../../helpers/dynamicFields/fieldName";
import { LoggingSeverity, tclog } from "../../../../../helpers/tclog";
import { useTCFormikContext } from "../../../../../hooks/useTCFormikContext";
import {
  TCFieldComponent,
  ValueListComponentProps,
} from "../../../../../interfaces/dynamicFieldInterfaces";
import { TCEditFieldDropdown } from "./TCEditFieldDropdown";
import { TCEditFieldMultiSelect } from "./TCEditFieldMultiselect";
import { TCEditFieldRadioGroup } from "./TCEditFieldRadioGroup";

/**
 * Determine which component should render
 */
function getComponentToRender(
  type: DisplayConfigurationValueEditingValueListRenderingTypeEnum,
  allowMultipleValues: boolean,
  fieldName: string
): FunctionComponent<ValueListComponentProps> {
  switch (type) {
    case DisplayConfigurationValueEditingValueListRenderingTypeEnum.Dropdown:
      return allowMultipleValues ? TCEditFieldMultiSelect : TCEditFieldDropdown;
    case DisplayConfigurationValueEditingValueListRenderingTypeEnum.Radio:
      if (allowMultipleValues) {
        tclog(
          `Selected '${type}' as valueListRenderingType for field ${fieldName}.` +
            `Since the field allows multiple values, a multi-select is displayed instead.`,
          LoggingSeverity.Warning
        );
      }
      return allowMultipleValues
        ? TCEditFieldMultiSelect
        : TCEditFieldRadioGroup;
    default:
      throw Error(
        `Tried to display a value list of type '${type}', but it it not mapped.`
      );
  }
}

const componentTypeFromLength: (
  length: number
) => DisplayConfigurationValueEditingValueListRenderingTypeEnum = (length) =>
  length > 3
    ? DisplayConfigurationValueEditingValueListRenderingTypeEnum.Dropdown
    : DisplayConfigurationValueEditingValueListRenderingTypeEnum.Radio;

export const TCEditFieldValueList: TCFieldComponent = (props) => {
  const validationSchema = props.field.fieldType
    .validationSchema as ValidationValueList;
  const allowMultipleValues = !!(
    validationSchema.max_items && validationSchema.max_items > 1
  );
  const formikContext = useTCFormikContext();

  // eslint-disable-next-line
  // @ts-ignore
  const options = (validationSchema.items.enum || []) as string[];

  const type: DisplayConfigurationValueEditingValueListRenderingTypeEnum =
    props.displayConfiguration.valueEditing?.valueListRenderingType ||
    componentTypeFromLength(options.length);

  const fieldName = constructFormikFieldIdentifier(props.field);

  const ComponentToRender = getComponentToRender(
    type,
    allowMultipleValues,
    props.field.name
  );

  return (
    <ComponentToRender {...props} {...{ options, fieldName, formikContext }} />
  );
};
