import { Typography } from "@material-ui/core";
import React from "react";
import {
  EComplexType,
  ValidationFieldReference,
  ValidationFieldReferenceDisplayAsEnum,
} from "../../../../../generated/api-service";
import { getReferencedField } from "../../../../../helpers/dynamicFields/getReferencedField";
import { TCFieldComponent } from "../../../../../interfaces/dynamicFieldInterfaces";
import { TCEditFieldReferenceTimeSlots } from "./TCEditFieldReferenceTimeSlots/TCEditFieldReferenceTimeSlots/TCEditFieldReferenceTimeSlots";
import { TCEditFieldReferenceTimeSlotsSingleSlot } from "./TCEditFieldReferenceTimeSlots/TCEditFieldReferenceTimeSlotsSingleSlot/TCEditFieldReferenceTimeSlotsSingleSlot";

export const TCEditFieldReference: TCFieldComponent = (props) => {
  const { field } = props;
  const referencedField = getReferencedField(field);
  const { displayAs } = field.fieldType
    .validationSchema as ValidationFieldReference;

  switch (referencedField.fieldType.complexType?.complexType) {
    case EComplexType.TimeSlots:
      return displayAs === ValidationFieldReferenceDisplayAsEnum.Dropdown ? (
        <TCEditFieldReferenceTimeSlots {...props} />
      ) : (
        <TCEditFieldReferenceTimeSlotsSingleSlot {...props} />
      );
    default:
      return (
        <Typography variant="h1">
          TCEditFieldReference can currently not display this kind of reference.
        </Typography>
      );
  }
};
