import { FormikContextType } from "formik";
import {
  TCFormikFieldData,
  TCFormikValues,
} from "../../interfaces/formikInterfaces";

// this function basically exist to provide proper typing when updating a field in the formik context
export const updateFormikValue = (
  formikContext: FormikContextType<TCFormikValues>,
  formikIdentifier: string,
  newValue: TCFormikFieldData
): void => {
  formikContext.setFieldValue(formikIdentifier, newValue);
};
