import { Link } from "@material-ui/core";
import clsx from "clsx";
import { omit } from "lodash";
import React, { useCallback } from "react";
import { useNavigate } from "../../../hooks/routing/useNavigate";
import { TCLinkProps } from "./TCLinkProps";
import { useTCLinkStyles } from "./TCLinkStyles";

export const TCLink = (props: TCLinkProps): JSX.Element => {
  const classes = useTCLinkStyles();
  const navigate = useNavigate();

  const onClick = useCallback(
    (event) => {
      event.preventDefault();
      navigate(props.appRoute, props.itemId);
    },
    [navigate, props.appRoute, props.itemId]
  );

  return (
    <Link
      {...omit(props, "className", "link", "itemId", "appRoute")}
      className={clsx(classes.tcLink, props.className)}
      onClick={onClick}
    >
      {props.children}
    </Link>
  );
};
