import { createStyles, makeStyles } from "@material-ui/core";
import { CustomMuiTheme } from "../../interfaces/CustomMuiTheme";

export const useMainPageStyles = makeStyles((theme: CustomMuiTheme) =>
  createStyles({
    homePageWrapper: {
      height: "100vh",
      width: "100vw",
      overflow: "hidden",
    },
    pageContentWrapper: {
      height: "100%",
      padding: 0,
      background: theme.palette.background.default,
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
      },
    },
    main: {
      flexGrow: 1,
      height: `calc(100vh - ${theme.tcCustom.layout.headerHeight}px)`,
      [theme.breakpoints.down("sm")]: {
        flexGrow: 0,
        width: "100vw",
        padding: theme.spacing(2),
        order: 0,
        height: `calc(100vh - ${theme.tcCustom.layout.headerHeight}px - ${theme.tcCustom.layout.mobileBottomNavHeight}px)`,
      },
    },
  })
);
