import {
  EParentableType,
  Job,
  JobPutJob,
  JobUser,
  JobUserPutJobUser,
  User,
  UserPutUser,
} from "../../../../generated/api-service";
import { CustomFormikSubmitHandler } from "../../../../interfaces/typeAliases";

export enum FormikSaveHandler {
  Custom = "custom",
  InComponent = "inComponent",
}

interface BulkUpdateInformationSnackbarMessages {
  error?: string;
  success?: string;
}

export interface BulkUpdateInformation {
  parentableType: EParentableType;
  parentableId: number;
  additionalProps: UserPutUser | JobPutJob | JobUserPutJobUser;
  onAfterSave?: (updatedObject: Job | User | JobUser) => void;
  onError?: (error) => void;
  snackbarMessages?: BulkUpdateInformationSnackbarMessages;
}

interface TCFormikForPurposeContextBaseProps {
  saveHandler: FormikSaveHandler;
  noFormValidate?: boolean;
  onSave?: CustomFormikSubmitHandler;
  bulkUpdateInformation?: BulkUpdateInformation;
}

interface TCFormikForPurposeContextPropsCustomSave
  extends TCFormikForPurposeContextBaseProps {
  saveHandler: FormikSaveHandler.Custom;
  onSave: CustomFormikSubmitHandler;
}

interface TCFormikForPurposeContextPropsWithoutOnSave
  extends TCFormikForPurposeContextBaseProps {
  saveHandler: FormikSaveHandler.InComponent;
  bulkUpdateInformation: BulkUpdateInformation;
}

export type TCFormikBulkUpdateForPurposeContextProps =
  | TCFormikForPurposeContextPropsCustomSave
  | TCFormikForPurposeContextPropsWithoutOnSave;
