import { TextField } from "@material-ui/core";
import { AutocompleteProps } from "@material-ui/lab/Autocomplete/Autocomplete";
import React, { useCallback } from "react";
import { getFieldLabel } from "../helpers/dynamicFields/getFieldLabel";
import { getFieldPlaceholder } from "../helpers/dynamicFields/getFieldPlaceholder";
import { fieldIsRequired } from "../helpers/forms/fieldIsRequired";
import { simpleDeepEquals } from "../helpers/simpleDeepEquals";
import { PropsWithFieldAndFieldGroup } from "../interfaces/dynamicFieldInterfaces";

type ExposedProps<OptionType> = Pick<
  AutocompleteProps<OptionType, undefined, undefined, undefined>,
  "renderInput" | "getOptionLabel" | "getOptionSelected"
>;

type SomethingWithLabel = { label: string };

const getLabel = <T extends SomethingWithLabel>(objectWithLabel: T): string =>
  objectWithLabel.label;

/**
 * Return certain props of the Autocomplete MUI component for a given field component.
 * This is pretty static right now, but could be easily extended for broader usage.
 */
export const useAutocompletePropsForField = <
  OptionType extends SomethingWithLabel
>(
  props: PropsWithFieldAndFieldGroup
): ExposedProps<OptionType> => {
  const placeholder = getFieldPlaceholder(props.field);
  const label = getFieldLabel(props.field);
  const required = fieldIsRequired(props);

  const renderInput = useCallback(
    (params) => (
      <TextField
        {...params}
        placeholder={placeholder}
        label={label}
        variant="outlined"
        required={required}
      />
    ),
    [label, placeholder, required]
  );

  return {
    renderInput,
    getOptionLabel: getLabel,
    getOptionSelected: simpleDeepEquals,
  };
};
