import { createStyles, makeStyles } from "@material-ui/core";
import { CustomMuiTheme } from "../../../../../interfaces/CustomMuiTheme";

export const useTCBlobListItemStyles = makeStyles((theme: CustomMuiTheme) =>
  createStyles({
    linkWrapper: {
      display: "flex",
      justifyContent: "space-between",
    },
    deleteIcon: {
      marginLeft: theme.spacing(3),
      cursor: "pointer",
    },
    fileNameButton: {
      textAlign: "left",
    },
  })
);
