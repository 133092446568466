// tslint:disable
/**
 * TaMoC API V1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface DisplayConfigurationValueDisplay
 */
export interface DisplayConfigurationValueDisplay {
    /**
     * Number of decimals behind the comma
     * @type {number}
     * @memberof DisplayConfigurationValueDisplay
     */
    numberOfDecimals?: number;
    /**
     * Display an icon before the field value
     * @type {string}
     * @memberof DisplayConfigurationValueDisplay
     */
    showIconBeforeValue?: DisplayConfigurationValueDisplayShowIconBeforeValueEnum;
    /**
     * Define keys of the validation schema that should be displayed. For example only show the city of a location field by using [city] as a value.
     * @type {Array<string>}
     * @memberof DisplayConfigurationValueDisplay
     */
    selectValueKeys?: Array<string>;
    /**
     * Define a sign (e.g. \'-\') that joins multiple values of a range or a location.
     * @type {string}
     * @memberof DisplayConfigurationValueDisplay
     */
    joinBySign?: string;
    /**
     * Instead of the values a count of values is displayed. Only makes sense with valuelist items.
     * @type {boolean}
     * @memberof DisplayConfigurationValueDisplay
     */
    showCountInsteadOfValue?: boolean;
    /**
     * How should a file filed be displayed? Normally as a label and a list of the files or as an editable list without label.
     * @type {string}
     * @memberof DisplayConfigurationValueDisplay
     */
    fileFieldDisplayStyle?: DisplayConfigurationValueDisplayFileFieldDisplayStyleEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum DisplayConfigurationValueDisplayShowIconBeforeValueEnum {
    House = 'house',
    Location = 'location',
    Clock = 'clock',
    Person = 'person',
    Plus = 'plus'
}
/**
 * @export
 * @enum {string}
 */
export enum DisplayConfigurationValueDisplayFileFieldDisplayStyleEnum {
    ListView = 'listView',
    LabelAndValue = 'labelAndValue'
}

