// tslint:disable
/**
 * TaMoC API V1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs';
import {
    Conversation,
    ConversationPost,
    ConversationUser, Message,
    MessagePost
} from '../models';
import { BaseAPI, encodeURI, HttpHeaders, HttpQuery, OperationOpts, RawAjaxResponse, throwIfNullOrUndefined } from '../runtime';

export interface CreateConversationRequest {
    accessToken: string;
    client: string;
    uid: string;
    conversationPost?: ConversationPost;
}

export interface GetConversationRequest {
    accessToken: string;
    client: string;
    uid: string;
    sortBy?: GetConversationSortByEnum;
    id?: string;
    conversationableType?: string;
    conversationableId?: number | string;
    createdAt?: string;
    updatedAt?: string;
}

export interface GetConversationByIdRequest {
    accessToken: string;
    client: string;
    uid: string;
    id: number;
}

export interface GetConversationWithUsersRequest {
    accessToken: string;
    client: string;
    uid: string;
    userIds: string;
}

export interface MarkMessageAsReadRequest {
    accessToken: string;
    client: string;
    uid: string;
    conversationId: string;
}

export interface PostMessageToConversationRequest {
    accessToken: string;
    client: string;
    uid: string;
    conversationId: string;
    messagePost?: MessagePost;
}

/**
 * no description
 */
export class ConversationApi extends BaseAPI {

    /**
     * Create Conversation
     */
    createConversation({ accessToken, client, uid, conversationPost }: CreateConversationRequest): Observable<Conversation>
    createConversation({ accessToken, client, uid, conversationPost }: CreateConversationRequest, opts?: OperationOpts): Observable<RawAjaxResponse<Conversation>>
    createConversation({ accessToken, client, uid, conversationPost }: CreateConversationRequest, opts?: OperationOpts): Observable<Conversation | RawAjaxResponse<Conversation>> {
        throwIfNullOrUndefined(accessToken, 'accessToken', 'createConversation');
        throwIfNullOrUndefined(client, 'client', 'createConversation');
        throwIfNullOrUndefined(uid, 'uid', 'createConversation');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(accessToken != null ? { 'access-token': String(accessToken) } : undefined),
            ...(client != null ? { 'client': String(client) } : undefined),
            ...(uid != null ? { 'uid': String(uid) } : undefined),
        };

        return this.request<Conversation>({
            url: '/api/v1/conversations/',
            method: 'POST',
            headers,
            body: conversationPost,
        }, opts?.responseOpts);
    };

    /**
     * Retrieve a Conversation list
     */
    getConversation({ accessToken, client, uid, sortBy, id, conversationableType, conversationableId, createdAt, updatedAt }: GetConversationRequest): Observable<Array<Conversation>>
    getConversation({ accessToken, client, uid, sortBy, id, conversationableType, conversationableId, createdAt, updatedAt }: GetConversationRequest, opts?: OperationOpts): Observable<RawAjaxResponse<Array<Conversation>>>
    getConversation({ accessToken, client, uid, sortBy, id, conversationableType, conversationableId, createdAt, updatedAt }: GetConversationRequest, opts?: OperationOpts): Observable<Array<Conversation> | RawAjaxResponse<Array<Conversation>>> {
        throwIfNullOrUndefined(accessToken, 'accessToken', 'getConversation');
        throwIfNullOrUndefined(client, 'client', 'getConversation');
        throwIfNullOrUndefined(uid, 'uid', 'getConversation');

        const headers: HttpHeaders = {
            ...(accessToken != null ? { 'access-token': String(accessToken) } : undefined),
            ...(client != null ? { 'client': String(client) } : undefined),
            ...(uid != null ? { 'uid': String(uid) } : undefined),
        };

        const query: HttpQuery = {};

        if (sortBy != null) { query['sort_by'] = sortBy; }
        if (id != null) { query['id'] = id; }
        if (conversationableType != null) { query['conversationable_type'] = conversationableType; }
        if (conversationableId != null) { query['conversationable_id'] = conversationableId; }
        if (createdAt != null) { query['created_at'] = (createdAt as any).toISOString(); }
        if (updatedAt != null) { query['updated_at'] = (updatedAt as any).toISOString(); }

        return this.request<Array<Conversation>>({
            url: '/api/v1/conversations/',
            method: 'GET',
            headers,
            query,
        }, opts?.responseOpts);
    };

    /**
     * Retrieves a Conversation
     */
    getConversationById({ accessToken, client, uid, id }: GetConversationByIdRequest): Observable<Conversation>
    getConversationById({ accessToken, client, uid, id }: GetConversationByIdRequest, opts?: OperationOpts): Observable<RawAjaxResponse<Conversation>>
    getConversationById({ accessToken, client, uid, id }: GetConversationByIdRequest, opts?: OperationOpts): Observable<Conversation | RawAjaxResponse<Conversation>> {
        throwIfNullOrUndefined(accessToken, 'accessToken', 'getConversationById');
        throwIfNullOrUndefined(client, 'client', 'getConversationById');
        throwIfNullOrUndefined(uid, 'uid', 'getConversationById');
        throwIfNullOrUndefined(id, 'id', 'getConversationById');

        const headers: HttpHeaders = {
            ...(accessToken != null ? { 'access-token': String(accessToken) } : undefined),
            ...(client != null ? { 'client': String(client) } : undefined),
            ...(uid != null ? { 'uid': String(uid) } : undefined),
        };

        return this.request<Conversation>({
            url: '/api/v1/conversations/{id}'.replace('{id}', encodeURI(id)),
            method: 'GET',
            headers,
        }, opts?.responseOpts);
    };

    /**
     * Retrieves a Conversation with users
     */
    getConversationWithUsers({ accessToken, client, uid, userIds }: GetConversationWithUsersRequest): Observable<Conversation>
    getConversationWithUsers({ accessToken, client, uid, userIds }: GetConversationWithUsersRequest, opts?: OperationOpts): Observable<RawAjaxResponse<Conversation>>
    getConversationWithUsers({ accessToken, client, uid, userIds }: GetConversationWithUsersRequest, opts?: OperationOpts): Observable<Conversation | RawAjaxResponse<Conversation>> {
        throwIfNullOrUndefined(accessToken, 'accessToken', 'getConversationWithUsers');
        throwIfNullOrUndefined(client, 'client', 'getConversationWithUsers');
        throwIfNullOrUndefined(uid, 'uid', 'getConversationWithUsers');
        throwIfNullOrUndefined(userIds, 'userIds', 'getConversationWithUsers');

        const headers: HttpHeaders = {
            ...(accessToken != null ? { 'access-token': String(accessToken) } : undefined),
            ...(client != null ? { 'client': String(client) } : undefined),
            ...(uid != null ? { 'uid': String(uid) } : undefined),
        };

        return this.request<Conversation>({
            url: '/api/v1/conversations/with_users?user_ids={user_ids}'.replace('{user_ids}', encodeURI(userIds)),
            method: 'GET',
            headers,
        }, opts?.responseOpts);
    };

    /**
     * Mark message as read
     */
    markMessageAsRead({ accessToken, client, uid, conversationId }: MarkMessageAsReadRequest): Observable<ConversationUser>
    markMessageAsRead({ accessToken, client, uid, conversationId }: MarkMessageAsReadRequest, opts?: OperationOpts): Observable<RawAjaxResponse<ConversationUser>>
    markMessageAsRead({ accessToken, client, uid, conversationId }: MarkMessageAsReadRequest, opts?: OperationOpts): Observable<ConversationUser | RawAjaxResponse<ConversationUser>> {
        throwIfNullOrUndefined(accessToken, 'accessToken', 'markMessageAsRead');
        throwIfNullOrUndefined(client, 'client', 'markMessageAsRead');
        throwIfNullOrUndefined(uid, 'uid', 'markMessageAsRead');
        throwIfNullOrUndefined(conversationId, 'conversationId', 'markMessageAsRead');

        const headers: HttpHeaders = {
            ...(accessToken != null ? { 'access-token': String(accessToken) } : undefined),
            ...(client != null ? { 'client': String(client) } : undefined),
            ...(uid != null ? { 'uid': String(uid) } : undefined),
        };

        return this.request<ConversationUser>({
            url: '/api/v1/conversations/{conversation_id}/messages/mark_as_read'.replace('{conversation_id}', encodeURI(conversationId)),
            method: 'PUT',
            headers,
        }, opts?.responseOpts);
    };

    /**
     * Post message to conversation
     */
    postMessageToConversation({ accessToken, client, uid, conversationId, messagePost }: PostMessageToConversationRequest): Observable<Message>
    postMessageToConversation({ accessToken, client, uid, conversationId, messagePost }: PostMessageToConversationRequest, opts?: OperationOpts): Observable<RawAjaxResponse<Message>>
    postMessageToConversation({ accessToken, client, uid, conversationId, messagePost }: PostMessageToConversationRequest, opts?: OperationOpts): Observable<Message | RawAjaxResponse<Message>> {
        throwIfNullOrUndefined(accessToken, 'accessToken', 'postMessageToConversation');
        throwIfNullOrUndefined(client, 'client', 'postMessageToConversation');
        throwIfNullOrUndefined(uid, 'uid', 'postMessageToConversation');
        throwIfNullOrUndefined(conversationId, 'conversationId', 'postMessageToConversation');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(accessToken != null ? { 'access-token': String(accessToken) } : undefined),
            ...(client != null ? { 'client': String(client) } : undefined),
            ...(uid != null ? { 'uid': String(uid) } : undefined),
        };

        return this.request<Message>({
            url: '/api/v1/conversations/{conversation_id}/messages'.replace('{conversation_id}', encodeURI(conversationId)),
            method: 'POST',
            headers,
            body: messagePost,
        }, opts?.responseOpts);
    };

}

/**
 * @export
 * @enum {string}
 */
export enum GetConversationSortByEnum {
    IdAsc = 'id_asc',
    IdDesc = 'id_desc',
    ConversationableTypeAsc = 'conversationable_type_asc',
    ConversationableTypeDesc = 'conversationable_type_desc',
    ConversationableIdAsc = 'conversationable_id_asc',
    ConversationableIdDesc = 'conversationable_id_desc',
    CreatedAtAsc = 'created_at_asc',
    CreatedAtDesc = 'created_at_desc',
    UpdatedAtAsc = 'updated_at_asc',
    UpdatedAtDesc = 'updated_at_desc'
}
