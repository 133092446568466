import { createStyles, makeStyles } from "@material-ui/core";
import { CustomMuiTheme } from "../../../interfaces/CustomMuiTheme";

export const useTCTenantLogoStyles = makeStyles((theme: CustomMuiTheme) =>
  createStyles({
    linkElement: {
      padding: `${theme.spacing(3)}px`,
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "flex-start",
      [theme.breakpoints.down("sm")]: {
        paddingLeft: 0,
      },
      width: "100%",
    },
    image: {
      height: "auto",
      maxHeight: "100%",
      maxWidth: "100%",
    },
  })
);
