import useSWR from "swr";
import { fieldApi } from "../../dataHandling/autogeneratedApiServices";
import { Field, GetFieldRequest } from "../../generated/api-service";
import { getUniqueSwrKeyFromParameter } from "../../helpers/getUniqueSwrKeyFromParameter";
import { WithoutAuthentication } from "../../interfaces/authentication";
import { BasicSwrInterface } from "../../interfaces/basicSwrInterface";
import { useParameterWithAuthentication } from "./authentication/useParameterWithAuthentication";

export const useFields = (
  filterParameters: WithoutAuthentication<GetFieldRequest>
): BasicSwrInterface<Field[]> => {
  const parameters = useParameterWithAuthentication(filterParameters);
  const fetcher = () => fieldApi.getField(parameters).toPromise();

  const key = getUniqueSwrKeyFromParameter(["fieldApi", "getField"], {
    ...parameters,
  });

  const { data, error } = useSWR(key, fetcher);

  return {
    result: data,
    isLoading: !error && !data,
    isError: error,
  };
};
