import { Field } from "../../../generated/api-service";
import { TCFieldGroupProps } from "./TCFieldGroupProps";

interface TCFieldGroupViewModel {
  titleFields: Field[] | undefined;
  subTitleFields: Field[] | undefined;
  restOfFields: Field[];
}

export const useTCFieldGroupViewModel = (
  props: TCFieldGroupProps
): TCFieldGroupViewModel => {
  const fields = props.fieldGroup.fields || [];

  const titleFields = props.titleUntilFieldIndex
    ? fields.concat([]).slice(0, props.titleUntilFieldIndex)
    : undefined;

  const subTitleFields = props.subTitleUntilFieldIndex
    ? fields
        .concat([])
        .slice(props.titleUntilFieldIndex, props.subTitleUntilFieldIndex)
    : undefined;

  const restOfFields = fields.concat([]).slice(props.subTitleUntilFieldIndex);

  return { titleFields, subTitleFields, restOfFields };
};
