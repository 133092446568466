import { FunctionComponent, ReactElement } from "react";

export enum TCTabDialogStepperType {
  "Progressive" = "progessive",
  "Single" = "single",
}

export interface TCTabDialogTab {
  title: string;
  element: ReactElement;
}

export interface TCTabDialogProps {
  stepsNotClickable?: boolean;
  title: string;
  open: boolean;
  stepperType?: TCTabDialogStepperType;
  renderWelcomeScreen?: (
    goForward: () => void,
    goBackward: () => void
  ) => ReactElement;
  children?: undefined;
  onClose?: () => void;
  // https://reactjs.org/docs/render-props.html
  render: (goForward: () => void, goBackward: () => void) => TCTabDialogTab[];
  contentWrapper?: FunctionComponent;
}
