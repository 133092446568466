import { createStyles, makeStyles } from "@material-ui/core";

export const useTCLinkStyles = makeStyles(() =>
  createStyles({
    tcLink: {
      cursor: "pointer",
      textDecoration: "none",
      "&:hover": {
        textDecoration: "none",
      },
    },
  })
);
