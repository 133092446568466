import { createStyles, makeStyles } from "@material-ui/core";
import { CustomMuiTheme } from "../../../../../../interfaces/CustomMuiTheme";

export const useTCEditFieldFileDropzoneStyles = makeStyles(
  (theme: CustomMuiTheme) =>
    createStyles({
      allowedMimeTypes: {
        textAlign: "left",
        margin: theme.spacing(1, 0),
        color: theme.palette.secondary.main,
      },
    })
);
