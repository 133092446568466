import React, { useContext } from "react";
import { DebuggingContext } from "../../../../appContext";
import { getFieldComponentForDisplayMode } from "../../../../helpers/dynamicFields/getFieldComponentForDisplayMode";
import {
  DisplayMode,
  TCEditFieldProps,
} from "../../../../interfaces/dynamicFieldInterfaces";
import { TCFieldDebugger } from "../../../debug/TCFieldDebugger/TCFieldDebugger";
import { TCNoopComponent } from "../../../placeholders/noop/noopComponent";
import { useTCEditFieldStyles } from "./TCEditFieldStyles";

/**
 * Entry point for rendering any dynamic field in DisplayMode.Display
 */
export const TCEditField: React.FunctionComponent<TCEditFieldProps> = (
  props
) => {
  const isDebugging = useContext(DebuggingContext);
  const classes = useTCEditFieldStyles();

  const DataTypeField = getFieldComponentForDisplayMode(
    props.field,
    DisplayMode.Input
  );

  if (!DataTypeField) {
    return null;
  }

  const initialValue = props.fieldValueMap
    ? props.fieldValueMap[props.field.id]
    : undefined;

  const propsWithValue =
    initialValue && initialValue.valuable
      ? {
          ...props,
          initialValue: initialValue.valuable.value,
        }
      : props;

  const Wrapper = isDebugging ? TCFieldDebugger : TCNoopComponent;

  return (
    <Wrapper {...props} displayMode={DisplayMode.Input}>
      <div className={classes.wrapper}>
        <DataTypeField {...propsWithValue} displayMode={DisplayMode.Input} />
      </div>
    </Wrapper>
  );
};
