import { createStyles, makeStyles } from "@material-ui/core";
import { CustomMuiTheme } from "../../../../interfaces/CustomMuiTheme";

export const useTCMessageCellStyles = makeStyles((theme: CustomMuiTheme) =>
  createStyles({
    time: (props: { isMessageFromCurrentUser: boolean }) => ({
      color: theme.palette.secondary.main,
      fontSize: 11,
      alignSelf: props.isMessageFromCurrentUser ? "flex-end" : "flex-start",
    }),
    messageBody: {
      color: theme.palette.grey[900],
    },
    messageCell: (props) => ({
      padding: "8px 33px 8px 24px",
      marginBottom: 5,
      whiteSpace: "break-spaces",
      width: "auto",
      maxWidth: 620,
      textAlign: "left",
      flexShrink: 0,
      overflowWrap: "anywhere",
      borderRadius: 12,
      alignSelf: props.isMessageFromCurrentUser ? "flex-end" : "flex-start",
      borderTopRightRadius: props.isMessageFromCurrentUser ? 2 : 12,
      borderTopLeftRadius: props.isMessageFromCurrentUser ? 12 : 2,
      backgroundColor: props.isMessageFromCurrentUser
        ? theme.palette.primary.light
        : theme.palette.grey[400],
    }),
  })
);
