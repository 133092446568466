import { Box, Button } from "@material-ui/core";
import React, { FunctionComponent, ReactElement, useCallback } from "react";
import { mergeDefaultPropsAndClass } from "../../../helpers/forComponents/mergeDefaultPropsAndClass";
import { useTCFormikContext } from "../../../hooks/useTCFormikContext";
import { TCPrimaryButton } from "../TCPrimaryButton";
import { TCSaveOrDismissButtonsProps } from "./TCSaveOrDismissButtonsProps";
import { useTCSaveOrDismissButtonsStyles } from "./TCSaveOrDismissButtonsStyles";

export const TCSaveOrDismissButtons: FunctionComponent<TCSaveOrDismissButtonsProps> = (
  props
): ReactElement => {
  const formikContext = useTCFormikContext();
  const classes = useTCSaveOrDismissButtonsStyles({
    hasChanges: formikContext.dirty,
  });

  const onClick = useCallback(() => {
    formikContext.resetForm();
  }, [formikContext]);

  return (
    <Box
      {...mergeDefaultPropsAndClass(
        {},
        props.boxProps,
        classes.saveOrDismissButtonGroup
      )}
    >
      <Button
        variant="outlined"
        color="secondary"
        size="large"
        onClick={onClick}
      >
        verwerfen
      </Button>

      <TCPrimaryButton isLoading={formikContext.isSubmitting} type="submit">
        speichern
      </TCPrimaryButton>
    </Box>
  );
};
