import { noop } from "lodash";
import React, { ReactElement } from "react";
import { EParentableType, EPurpose } from "../../generated/api-service";
import { TCEditFieldGroupsDialog } from "../dialogs/TCEditFieldGroupsDialog/TCEditFieldGroupsDialog";
import { TCPurposeProvider } from "../dynamicFields/purpose/TCPurposeProvider/TCPurposeProvider";

const Playground = (): ReactElement | null => (
    <TCPurposeProvider purpose={EPurpose.JobCreation} jobId={8}>
      <TCEditFieldGroupsDialog
        open={true}
        onClose={noop}
        title={"Job"}
        parentableId={8}
        parentableType={EParentableType.Job}
      />
    </TCPurposeProvider>
  );

export default Playground;
