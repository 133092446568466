// tslint:disable
/**
 * TaMoC API V1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    DisplayConfigurationValueEditingTimeSlots
} from './';

/**
 * @export
 * @interface DisplayConfigurationValueEditing
 */
export interface DisplayConfigurationValueEditing {
    /**
     * Suitable for text input fields.
     * @type {boolean}
     * @memberof DisplayConfigurationValueEditing
     */
    isMultiLine?: boolean;
    /**
     * @type {string | Array<string | number | object>}
     * @memberof DisplayConfigurationValueEditing
     */
    defaultValue?: string | Array<string | number | object>;
    /**
     * The value_list complex type can be rendered using one of these components.
     * @type {string}
     * @memberof DisplayConfigurationValueEditing
     */
    valueListRenderingType?: DisplayConfigurationValueEditingValueListRenderingTypeEnum;
    /**
     * @type {DisplayConfigurationValueEditingTimeSlots}
     * @memberof DisplayConfigurationValueEditing
     */
    timeSlots?: DisplayConfigurationValueEditingTimeSlots;
    /**
     * The range complex type can be rendered using one of these input types.
     * @type {string}
     * @memberof DisplayConfigurationValueEditing
     */
    rangeRenderingType?: DisplayConfigurationValueEditingRangeRenderingTypeEnum;
    /**
     * Placeholder for the input.
     * @type {string}
     * @memberof DisplayConfigurationValueEditing
     */
    placeholderValue?: string;
    /**
     * Render file upload as a button triggering an overlay, or directly output the drop zone. Defaults to dialog.
     * @type {string}
     * @memberof DisplayConfigurationValueEditing
     */
    fileUploadRenderingType?: DisplayConfigurationValueEditingFileUploadRenderingTypeEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum DisplayConfigurationValueEditingValueListRenderingTypeEnum {
    Dropdown = 'dropdown',
    Radio = 'radio'
}
/**
 * @export
 * @enum {string}
 */
export enum DisplayConfigurationValueEditingRangeRenderingTypeEnum {
    Text = 'text',
    Date = 'date',
    Time = 'time'
}
/**
 * @export
 * @enum {string}
 */
export enum DisplayConfigurationValueEditingFileUploadRenderingTypeEnum {
    Dialog = 'dialog',
    Dropzone = 'dropzone'
}

