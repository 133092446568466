import { FilesMeta } from "../../generated/api-service";
import { TCFormikValues } from "../../interfaces/formikInterfaces";
import { FormikSubmitValues } from "../../interfaces/typeAliases";
import { formikValuesToFileUploadData } from "./formikValuesToFileUploadData";
import { getChangedFormFields } from "./getChangedFormFields";

export interface InformationFromFormikValue {
  files: Blob[] | undefined;
  filesMeta: FilesMeta | undefined;
  fieldsToSave: TCFormikValues;
}

export const extractInformationFromFormikSubmitValues = (
  values: FormikSubmitValues
): InformationFromFormikValue => {
  const fieldsToSave = getChangedFormFields(values);
  const uploads = formikValuesToFileUploadData(fieldsToSave);

  return {
    fieldsToSave,
    files: uploads?.files,
    filesMeta: uploads?.filesMeta,
  };
};
