import { Grid } from "@material-ui/core";
import React, { ReactElement, useContext, useRef } from "react";
import { UserContext } from "../../appContext";
import { TCAppBar } from "../../components/basicPageComponents/TCAppBar/TCAppBar";
import { TCSidebar } from "../../components/basicPageComponents/TCSidebar/TCSidebar";
import { useTCMessageCable } from "../../components/chat/messages/TCMessageDrawer/TCMessageCable";
import { TCMessageDrawer } from "../../components/chat/messages/TCMessageDrawer/TCMessageDrawer";
import { TCOnboardingWrapper } from "../../components/dialogs/TCOnboardingDialog/TCOnboardingWrapper";
import { TCNoopComponent } from "../../components/placeholders/noop/noopComponent";
import userNotLoggedIn from "../../helpers/userLoggedIn";
import { useMainPageStyles } from "./MainPageStyles";

interface HomePageProps {
  children: ReactElement;
}

export const MainPage = ({ children }: HomePageProps): ReactElement | null => {
  const [user, setUser] = useContext(UserContext);
  const classes = useMainPageStyles();

  useTCMessageCable(user?.id);

  const logout = useRef(() => setUser(undefined)).current;

  if (userNotLoggedIn(user)) {
    return <TCNoopComponent>{children}</TCNoopComponent>;
  }

  return (
    <Grid
      container={true}
      direction="column"
      wrap="nowrap"
      className={classes.homePageWrapper}
    >
      <TCAppBar user={user} onLogoutClick={logout} />

      <TCMessageDrawer />

      <Grid
        container={true}
        wrap="nowrap"
        className={classes.pageContentWrapper}
      >
        <TCSidebar />

        <main className={classes.main}>
          <TCOnboardingWrapper user={user} />

          {children}
        </main>
      </Grid>
    </Grid>
  );
};
