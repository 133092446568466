import { Dispatch, SetStateAction } from "react";
import { BehaviorSubject, Subscription } from "rxjs";
import { ConversationUser } from "../../generated/api-service";
import { unreadConversationsCount } from "../../helpers/conversationUserHelper";

interface IState {
  conversationUsers: ConversationUser[];
  isPanelOpen: boolean;
  currentConversationUser?: ConversationUser;
  unreadConversationsCount: number;
}

const initialState: IState = {
  conversationUsers: [],
  isPanelOpen: false,
  currentConversationUser: undefined,
  unreadConversationsCount: 0,
};

let state = initialState;
const subject = new BehaviorSubject(state);

export const conversationUsersStore = {
  subscribe: (setState: Dispatch<SetStateAction<IState>>): Subscription =>
    subject.subscribe(setState),

  setConversationUsers: (conversationUsers: ConversationUser[]): void => {
    state = {
      ...state,
      conversationUsers,
      unreadConversationsCount: unreadConversationsCount(conversationUsers),
    };
    subject.next(state);
  },

  setConversationUserPanelOpen: (isPanelOpen: boolean): void => {
    state = {
      ...state,
      isPanelOpen,
      currentConversationUser: undefined,
    };
    subject.next(state);
  },

  setCurrentConversationUser: (conversationUser: ConversationUser): void => {
    state = {
      ...state,
      isPanelOpen: true,
      currentConversationUser: conversationUser,
    };
    subject.next(state);
  },

  unsetCurrentConversationUser: (): void => {
    state = {
      ...state,
      currentConversationUser: undefined,
    };
    subject.next(state);
  },

  addConversationUser: (conversationUser: ConversationUser): void => {
    const immutableConversationUsers = [
      conversationUser,
      ...state.conversationUsers,
    ];
    state = {
      ...state,
      unreadConversationsCount: unreadConversationsCount(
        immutableConversationUsers
      ),
      conversationUsers: immutableConversationUsers,
    };
    subject.next(state);
  },

  updateConversationUser: (conversationUser: ConversationUser): void => {
    const isNewConversation = !state.conversationUsers
      .map((_conversationUser) => _conversationUser.conversationId)
      .includes(conversationUser.conversationId);

    const conversationUsers = isNewConversation
      ? [conversationUser, ...state.conversationUsers]
      : state.conversationUsers.map((_conversationUser) =>
          _conversationUser.id === conversationUser.id
            ? conversationUser
            : _conversationUser
        );

    state = {
      ...state,
      unreadConversationsCount: unreadConversationsCount(conversationUsers),
      conversationUsers,
    };
    subject.next(state);
  },

  initialState,
};
