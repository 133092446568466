// tslint:disable
/**
 * TaMoC API V1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum EFieldTypes {
    SimpleString = 'Simple String',
    EMailAddress = 'E-Mail Address',
    Street = 'Street',
    SimpleNumber = 'Simple Number',
    PostalCode = 'Postal Code',
    PhoneNumber = 'Phone Number',
    Nationality = 'nationality',
    WorkPermit = 'work permit',
    Certificates = 'certificates',
    PrivacyNoticeCheckbox = 'privacy_notice_checkbox',
    Job = 'job',
    Specialisation = 'specialisation',
    Languages = 'languages',
    CurrentPosition = 'current_position',
    CurrentEmployer = 'current_employer',
    TravelingDistance = 'traveling_distance',
    ReadyToSpendNightAway = 'ready_to_spend_night_away',
    Date = 'date',
    LocationWithoutCountry = 'location_without_country',
    LocationCityOnly = 'location_city_only',
    SalaryGroup = 'salary_group',
    Password = 'password',
    Timespan = 'timespan',
    Education = 'education',
    Salutation = 'salutation',
    FurtherEducation = 'further_education',
    DrivingLicenseType = 'driving_license_type',
    JobWorkingClothesProvided = 'job_working_clothes_provided',
    WorkingShiftsPattern = 'working_shifts_pattern',
    JobCancellationReason = 'job_cancellation_reason',
    PasswordUpdate = 'password_update',
    Decimal = 'decimal',
    Currency = 'currency',
    CurrencyPerHour = 'currency per hour',
    JobTimingTypeToggle = 'job_timing_type_toggle',
    ReferenceFieldDropdown = 'reference_field_dropdown',
    ReferenceFieldDropdownComplex = 'reference_field_dropdown_complex',
    Datespan = 'datespan',
    JobSingleDateSelectWidget = 'job_single_date_select_widget',
    JobWorkingClothes = 'job_working_clothes'
}

