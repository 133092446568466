import clsx from "clsx";
import React, { Fragment } from "react";
import { dynamicFieldClasses } from "../../../helpers/dynamicFields/dynamicFieldClasses";
import {
  DisplayMode,
  TCFieldProps,
} from "../../../interfaces/dynamicFieldInterfaces";
import { TCDisplayField } from "../displayFields/TCDisplayField/TCDisplayField";
import { TCEditField } from "../editFields/TCEditField/TCEditField";

export const TCField: React.FunctionComponent<TCFieldProps> = (props) => {
  const propsWithClasses = {
    ...props,
    className: clsx(props.className, ...dynamicFieldClasses(props.field)),
  };

  return (
    <Fragment>
      {props.displayMode === DisplayMode.Display && (
        <TCDisplayField {...propsWithClasses} />
      )}

      {props.displayMode === DisplayMode.Input && (
        <TCEditField {...propsWithClasses} />
      )}
    </Fragment>
  );
};
