import { Form, Formik } from "formik";
import { merge } from "lodash";
import React from "react";
import { TCFormikBulkUpdateForPurposeContextProps } from "./TCFormikBulkUpdateForPurposeContextProps";
import { useTCFormikBulkUpdateForPurposeContextViewModel } from "./TCFormikBulkUpdateForPurposeContextViewModel";

const defaultProps: Partial<TCFormikBulkUpdateForPurposeContextProps> = {
  noFormValidate: false,
};

/**
 * Component for initializing a Formik form.
 *
 * Takes the initial values from the PurposeContext and
 * handles the saving of the data via a bulk update.
 * Also handles the display of the snackbar with the error messages
 */
const TCFormikBulkUpdateForPurposeContext: React.FunctionComponent<TCFormikBulkUpdateForPurposeContextProps> = (
  passedProps
) => {
  const props = merge({}, defaultProps, passedProps);
  const viewModel = useTCFormikBulkUpdateForPurposeContextViewModel(props);

  return (
    <Formik
      enableReinitialize={true}
      initialValues={viewModel.initialValues}
      onSubmit={viewModel.submitHandler}
    >
      {() => <Form noValidate={props.noFormValidate}>{props.children}</Form>}
    </Formik>
  );
};

export default TCFormikBulkUpdateForPurposeContext;
