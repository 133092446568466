import {
  CustomMuiTheme,
  TCAuthPageMuiProps,
} from "../../../interfaces/CustomMuiTheme";
import { RecursivePartial } from "../../../interfaces/typeAliases";
import logoSvg from "../logo.svg";
import mobileLogoSvg from "../mondi-logo-mobil.svg";
import backgroundImageUrl from "../registrierung-asset-mondi.png";
import welcomeSvg from "../welcomeMondi.svg";

const loginScreen: TCAuthPageMuiProps = {
  headlineLeft: "Willkommen",
  heroSectionProps: {
    headline:
      "Ihr Zugang zu mehreren tausend Mitarbeitern aus der Industrie und Logistik",
    description:
      "Mit unserer branchenspezifischen Matching-Plattform finden Sie mit einem Klick die passenden und verfügbaren Arbeitskräfte.",
    backgroundImageUrl,
  },
};

export const tcCustom: RecursivePartial<CustomMuiTheme["tcCustom"]> = {
  welcomeSvg,
  logoSvg,
  loginScreen,
  contractSigning: {
    helloSignClientId: "1ebd7fbaeca29ba92fff05f7b63159ef",
    useContractSigning: true,
  },
  registerScreen: {
    worker: {
      ...loginScreen,
      headlineLeft: "Erhalten Sie vollautomatisch passende Stellen.",
    },
    employer: {
      ...loginScreen,
      headlineLeft: "Finden Sie jetzt unkompliziert passende Mitarbeiter",
    },
  },
  mobileLogoSvg,
  workerName: "Arbeitskraft",
  employerName: "Unternehmen",
  layout: {
    authSectionMaxWidth: 650,
    messagesDrawerMaxWidth: 421,
  },
};
