import { Theme } from "@material-ui/core";
import { CSSProperties } from "@material-ui/core/styles/withStyles";

export const inputBasicStyles = (theme: Theme): CSSProperties => {
  const padding = theme.spacing(1.4);
  const backgroundColor = theme.palette.grey[200];
  return {
    paddingTop: padding,
    paddingBottom: padding,
    backgroundColor,
    borderRadius: 0,
    border: "0",
    borderColor: theme.palette.secondary.main,
    "&:hover": {
      backgroundColor,
    },
    "&:focus": {
      color: theme.palette.grey[900],
    },
    "&:after": {
      display: "none",
    },
  };
};
