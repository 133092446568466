import {
  AllFields,
  EParentableType,
  ERole,
  EUserVerificationStatus,
  User,
} from "../generated/api-service";
import { useTCFieldWithoutContext } from "../hooks/dynamicFields/useFieldWithoutContext/useFieldWithoutContext";
import { UserKeyAttributes } from "../interfaces/dynamicFieldInterfaces";
import { mapFieldValues } from "./fieldValueFromMap";

export function userHasRole(user: User, role: ERole): boolean {
  if (!user.userRoles) {
    return false;
  }
  return user.userRoles.some((thisRole) => thisRole.role === role);
}

export function userIsWorker(user: User): boolean {
  return userHasRole(user, ERole.Worker);
}

export function userIsAdmin(user: User): boolean {
  return userHasRole(user, ERole.Admin);
}

export function userIsEmployer(user: User): boolean {
  return userHasRole(user, ERole.Employer);
}

export function userIsNotVerified(user: User): boolean {
  return user.verificationStatus === EUserVerificationStatus.NotYetVerified;
}

export function userIsVerified(user: User): boolean {
  return user.verificationStatus === EUserVerificationStatus.Verified;
}

export function userRoles(user: User): ERole[] {
  return user.userRoles?.map((role) => role.role) || [];
}

export const userName = (user: User): string => {
  const name: string[] = [];

  if (user.firstName) {
    name.push(user.firstName);
  }

  if (user.lastName) {
    name.push(user.lastName);
  }

  return name.join(" ");
};

export const useUserKeyAttributes = (
  userId: number
): Partial<UserKeyAttributes> => {
  const fieldsWithoutContext = useTCFieldWithoutContext({
    fieldNames: [
      AllFields.FirstName,
      AllFields.LastName,
      AllFields.EmailAddress,
    ],
    parentableId: userId,
    parentableType: EParentableType.User,
  });

  if (fieldsWithoutContext === null) {
    return {};
  }

  return mapFieldValues<UserKeyAttributes>(fieldsWithoutContext);
};
