import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { dbTimeFormat } from "../../Consts";

/**
 * Convert a moment object to our internal db time format.
 */
export const dbTimeFromMoment = (
  momentObject: MaterialUiPickersDate
): string | undefined =>
  momentObject ? momentObject.format(dbTimeFormat) : undefined;
