import { Grid, GridProps } from "@material-ui/core";
import React, { Fragment } from "react";
import { mergeDefaultPropsAndClass } from "../../../helpers/forComponents/mergeDefaultPropsAndClass";
import { useUniqueId } from "../../../hooks/useUniqueId";
import { useTCDialogStepStyles } from "../TCDialogStep/TCDialogStepStyles";
import { TCDialogStepGridProps } from "./TCDialogStepGridProps";

const gridDefaults: GridProps = {
  item: true,
  xs: true,
};
const gridDefaultsLeft: GridProps = {
  ...gridDefaults,
  xs: 12,
  sm: 5,
};
const gridDefaultsRight: GridProps = {
  ...gridDefaults,
};

export const TCDialogStepGrid: React.FunctionComponent<TCDialogStepGridProps> = (
  props
) => {
  const firstGridId = useUniqueId();
  const secondGridId = useUniqueId();
  const classes = useTCDialogStepStyles();

  return (
    <Fragment>
      <Grid
        key={firstGridId}
        {...mergeDefaultPropsAndClass(
          gridDefaultsLeft,
          props.gridPropsLeft,
          classes.infoSection
        )}
      >
        {props.contentLeft}
      </Grid>

      <Grid
        key={secondGridId}
        {...mergeDefaultPropsAndClass(
          gridDefaultsRight,
          props.gridPropsLeft,
          classes.inputSection
        )}
      >
        {props.contentRight}
      </Grid>
    </Fragment>
  );
};
