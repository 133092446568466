import { createStyles, makeStyles } from "@material-ui/core";
import { CustomMuiTheme } from "../../../../interfaces/CustomMuiTheme";

export const useTCFieldGroupRenderedFieldsStyles = makeStyles(
  (theme: CustomMuiTheme) =>
    createStyles({
      tcFieldGroupFieldWrapper: { marginRight: theme.spacing(3) },
      tcFieldGroupFieldLabel: {
        color: theme.palette.secondary.main,
        fontSize: theme.typography.fontSize * 0.8,
        marginBottom: theme.spacing(0.5),
      },
      tcFieldGroupFieldValue: {
        color: theme.palette.grey[900],
        fontSize: theme.typography.fontSize * 0.9,
        marginBottom: theme.spacing(2),
      },
      fieldGroupGridItem: {
        marginBottom: theme.spacing(1),
      },
    })
);
