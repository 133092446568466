export enum TCAppRoutes {
  "Admin" = "admin",
  "Dashboard" = "dashboard",
  "Home" = "home",
  "Job" = "job",
  "JobUser" = "job_user",
  "Login" = "login",
  "PasswordRecovery" = "password_recovery",
  "Playground" = "playground",
  "Projects" = "projects",
  "Registration" = "registration",
  "Logout" = "logout",
  "Signing" = "signin",
  "User" = "user",
  "UserValidation" = "user_validation",
  "Contracts" = "contracts",
  "DataProtection" = "data_protection",
  "Imprint" = "imprint",
}
