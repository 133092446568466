import React from "react";
import { useDeleteDocument } from "../../../hooks/tamocApiHooks/documentHooks";
import { TCConfirmationDialog } from "../TCConfirmationDialog/ConfirmationDialog";
import { TCDialogDeleteDocumentProps } from "./TCDialogDeleteDocumentProps";

export const TCDialogDeleteDocument: React.FunctionComponent<TCDialogDeleteDocumentProps> = (
  props
) => {
  const deleteDocument = useDeleteDocument();

  return (
    <TCConfirmationDialog
      open={props.dialogOpen}
      confirmationTitle={"Datei löschen"}
      confirmationText={`Wollen Sie die Datei ${props.document.metaInformation.name} wirklich löschen? Dieser Vorgang kann nicht rückgängig gemacht werden.`}
      handleClose={props.onClose}
      acceptAction={() => {
        if (props.document) {
          deleteDocument.call({ id: props.document.id });
        }

        props.onAfterDelete(props.document);
      }}
    />
  );
};
