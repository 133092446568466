import React, { Fragment } from "react";
import { mergeClassNameIntoDisplayProp } from "../../../../helpers/displayProps";
import { mergeDisplayConfigurationForField } from "../../../../helpers/dynamicFields/displayConfiguration/mergeDisplayConfigurationForField";
import { TCField } from "../../TCField/TCField";
import { TCFieldGroupHeaderStyle } from "../TCFieldGroupProps";
import { TCFieldGroupHeaderProps } from "./TCFieldGroupHeaderProps";
import { useTCFieldGroupHeaderStyles } from "./TCFieldGroupHeaderStyles";

export const TCFieldGroupHeader: React.FunctionComponent<TCFieldGroupHeaderProps> = (
  props
) => {
  const classes = useTCFieldGroupHeaderStyles();

  return (
    <Fragment>
      {props.headerStyle === TCFieldGroupHeaderStyle.TitleOnly && (
        <TCField
          displayConfiguration={mergeDisplayConfigurationForField(
            props.titleField,
            props.fieldGroup
          )}
          field={props.titleField}
          {...props}
          fieldValueDisplayProps={mergeClassNameIntoDisplayProp(
            props.titleFieldValueRenderingProps,
            classes.fieldGroupTitle
          )}
          fieldIconBeforeValueProps={{
            className: classes.fieldGroupTitleIconBefore,
          }}
        />
      )}

      {props.headerStyle === TCFieldGroupHeaderStyle.Complex && (
        <div className={classes.fieldGroupHeader}>
          {props.preTitleAndSubtitleElement && (
            <div className={classes.fieldGroupPreTitleAndSubtitleElement}>
              {props.preTitleAndSubtitleElement}
            </div>
          )}

          <div className={classes.fieldGroupTitleAndSubtitle}>
            <div className={classes.fieldGroupTitle}>
              {props.titleFields.map((field) => (
                  <TCField
                    displayConfiguration={mergeDisplayConfigurationForField(
                      field,
                      props.fieldGroup
                    )}
                    key={`${field.id}_${props.fieldGroup.id}`}
                    field={field}
                    {...props}
                    fieldValueDisplayProps={mergeClassNameIntoDisplayProp(
                      props.titleFieldValueRenderingProps,
                      classes.fieldGroupTitle
                    )}
                    fieldIconBeforeValueProps={{
                      className: classes.fieldGroupTitleIconBefore,
                    }}
                  />
                ))}
            </div>

            <div className={classes.fieldGroupSubTitle}>
              {(props.subTitleFields || []).map((field) => (
                  <TCField
                    displayConfiguration={mergeDisplayConfigurationForField(
                      field,
                      props.fieldGroup
                    )}
                    key={`${field.id}_${props.fieldGroup.id}`}
                    field={field}
                    {...props}
                    fieldValueDisplayProps={{
                      className: classes.fieldGroupSubTitleValue,
                    }}
                    fieldIconBeforeValueProps={{
                      className: classes.fieldGroupSubTitleIconBefore,
                    }}
                  />
                ))}
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};
