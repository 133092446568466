import { fade, makeStyles } from "@material-ui/core";
import { CustomMuiTheme } from "../../../interfaces/CustomMuiTheme";
import { AvatarSize } from "./TCAvatarProps";

export const avatarSizeMap: {
  [key in AvatarSize]: number;
} = {
  [AvatarSize.Default]: 44,
  [AvatarSize.Big]: 60,
  [AvatarSize.Small]: 28,
  [AvatarSize.ExtraSmall]: 24,
  [AvatarSize.Medium]: 36,
  [AvatarSize.Large]: 84,
  [AvatarSize.ExtraLarge]: 90,
};

interface StyleProps {
  size: number;
  isMobile: boolean;
  isFocus: boolean;
}

export const useTCAvatarStyles = makeStyles((theme: CustomMuiTheme) => ({
  emptyAvatar: (props: StyleProps) => ({
    height: props.size,
    width: props.size,
    borderRadius: "50%",
    flexShrink: 0,
    border: `2px dashed ${theme.palette.primary.main}`,
    backgroundColor: theme.palette.grey[200],
  }),
  avatar: (props) => ({
    height: props.size,
    width: props.size,
  }),
  root: (props) => ({
    backgroundColor: theme.palette.grey[200],
    height: props.size,
    width: props.size,
    borderRadius: "50%",
    flexShrink: 0,
    position: "relative" as const,
  }),
  animatedDiv: (props) => ({
    opacity: props.isFocus ? 1 : 0,
    transition: "0.2s opacity ease-in-out",
    "&:hover, &:focus": {
      opacity: 1,
    },
  }),
  dropZoneParagraph: {
    display: "none",
  },
  dropZone: (props) => ({
    position: "absolute" as const,
    border: `2px dashed ${theme.palette.primary.main}`,
    top: 0,
    left: 0,
    zIndex: 10,
    width: "100%",
    height: "100%",
    minHeight: 90,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: fade(theme.palette.grey[50], 0.6),
    borderRadius: "50%",
    opacity: 1,
    "& > span": {
      margin: "0 !important",
      fontSize: `${props.size * 0.4}px !important`,
    },
    "& svg": {
      width: "1.5em",
      height: "1.5em",
      color: theme.palette.primary.main,
    },
  }),

  loading: {
    position: "absolute" as const,
    top: 0,
    left: 0,
    zIndex: 10,
    width: "100%",
    height: "100%",
    backgroundColor: fade(theme.palette.grey[900], 0.3),
    borderRadius: "50%",
  },
  circle: {
    color: theme.palette.grey[50],
  },
}));
