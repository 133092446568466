/* eslint-disable react/jsx-newline */
import { Box, Typography } from "@material-ui/core";
import React from "react";
import TCPageLayout from "../../../components/layout/TCPageLayout/TCPageLayout";
import { path, urlForRoute } from "../../../helpers/routingHelper";
import { useCustomMuiTheme } from "../../../hooks/useCustomMuiTheme";
import { TCAppRoutes } from "../../../interfaces/TCAppRoutes";
import { useTCStaticContentPageStyles } from "../TCStaticContentPageStyles";

const TCImprintPage = (): JSX.Element => {
  const classes = useTCStaticContentPageStyles();
  const theme = useCustomMuiTheme();

  return (
    <TCPageLayout title="Impressum">
      <Box>
        <Typography component="p" className={classes.textParagraph}>
          {theme.tcCustom.tenantInformation.company.name}

          <br />

          {`${theme.tcCustom.tenantInformation.address.street} ${theme.tcCustom.tenantInformation.address.streetNumber}`}

          <br />

          {`${theme.tcCustom.tenantInformation.address.postalCode} ${theme.tcCustom.tenantInformation.address.city}`}

          <br />
        </Typography>
        <Typography component="p" className={classes.textParagraph}>
          {`Telefon: ${theme.tcCustom.tenantInformation.contact.telephone}`}
          <br />
          {`Fax: ${theme.tcCustom.tenantInformation.contact.fax}`}
        </Typography>
        <Typography component="p" className={classes.textParagraph}>
          ‍
          <a href={theme.tcCustom.tenantInformation.contact.email}>
            {theme.tcCustom.tenantInformation.contact.email}
          </a>
          <br />
          <a href="https://www.suregmbh.de" target="_blank" rel="noreferrer">
            www.suregmbh.de
          </a>
          ‍ ‍
        </Typography>
        <Typography variant="h3" className={classes.h3StaticPage}>
          {theme.tcCustom.tenantInformation.managingDirector.gender === "female"
            ? "Geschäftsführerin"
            : "Geschäftsführer"}
        </Typography>
        <Typography component="p" className={classes.textParagraph}>
          {`${theme.tcCustom.tenantInformation.managingDirector.firstName} ${theme.tcCustom.tenantInformation.managingDirector.lastName}`}
        </Typography>
        <Typography component="p" className={classes.textParagraph}>
          {`${theme.tcCustom.tenantInformation.company.commercialRegisterIdentifier} ${theme.tcCustom.tenantInformation.company.commercialRegisterPlace}`}
          <br />
          {`Sitz der Gesellschaft: ${theme.tcCustom.tenantInformation.company.seatCity}`}
          <br />
          {`Umsatzsteuer-Identifikationsnummer gemäß § 27a UStG: ${theme.tcCustom.tenantInformation.company.ustId}`}
        </Typography>
        <Typography variant="h3" className={classes.h3StaticPage}>
          {"Inhaltlich Verantwortliche gemäß § 18 Abs. 2 MStV:"}
        </Typography>
        <Typography component="p" className={classes.textParagraph}>
          {`${theme.tcCustom.tenantInformation.responsibleForContent.firstName} ${theme.tcCustom.tenantInformation.responsibleForContent.lastName}, ${theme.tcCustom.tenantInformation.responsibleForContent.address.street} ${theme.tcCustom.tenantInformation.responsibleForContent.address.streetNumber}, ${theme.tcCustom.tenantInformation.responsibleForContent.address.postalCode} ${theme.tcCustom.tenantInformation.responsibleForContent.address.city}`}
        </Typography>
        ‍ ‍
        <Typography variant="h2" className={classes.h2StaticPage}>
          Zulassungs-/Aufsichtsbehörden
        </Typography>
        <Typography component="p" className={classes.textParagraph}>
          {
            "Durch Erlass hat der Präsident der Bundesagentur für Arbeit verschiedenen Regionaldirektionen und Agenturen für Arbeit die Durchführung des Arbeitnehmerüberlassungsgesetzes übertragen."
          }
        </Typography>
        <Typography component="p" className={classes.textParagraph}>
          {`Die Erlaubnis zur Arbeitnehmerüberlassung, für die ${theme.tcCustom.tenantInformation.company.name} wurde erteilt, durch die Agentur für Arbeit, 90300 Nürnberg`}
        </Typography>
        <Typography component="p" className={classes.textParagraph}>
          T: +49 911 – 529 4343 | F: +49 911 – 529 400 4343 | E:
          <a href="mailto:nuernberg.091-anue@arbeitsagentur.de">
            nuernberg.091-anue@arbeitsagentur.de
          </a>
        </Typography>
        <Typography variant="h2" className={classes.h2StaticPage}>
          ‍Verbraucherstreitschlichtung
        </Typography>
        <Typography component="p">
          {`Die ${theme.tcCustom.tenantInformation.company.name} ist zur Teilnahme an einem Streitbeilegungsverfahren vor einer Universalschlichtungsstelle weder verpflichtet noch bereit. ‍`}
        </Typography>
        <Typography variant="h2" className={classes.h2StaticPage}>
          Datenschutzhinweis
        </Typography>
        <Typography component="p" className={classes.textParagraph}>
          {
            "Sofern innerhalb des Internetangebotes die Möglichkeit zur Eingabe persönlicher oder geschäftlicher Daten (Emailadressen, Namen, Anschriften) besteht, so erfolgt die Preisgabe dieser Daten seitens des Nutzers auf ausdrücklich freiwilliger Basis. Die Inanspruchnahme aller angebotenen Dienste ist – soweit technisch möglich und zumutbar – auch ohne Angabe solcher Daten bzw. unter Angabe anonymisierter Daten oder eines Pseudonyms gestattet. Weitere Informationen zum Datenschutz entnehmen Sie bitte unserer Datenschutzerklärung. Diese sind hier abrufbar:"
          }
          <a href={path(TCAppRoutes.DataProtection)}>
            {urlForRoute(TCAppRoutes.DataProtection)}
          </a>
        </Typography>
        ‍
        <Typography variant="h2" className={classes.h2StaticPage}>
          Sonstige Hinweise
        </Typography>
        <Typography component="p" className={classes.textParagraph}>
          {
            "Aus Gründen der besseren Lesbarkeit wurde in den Texten grundsätzlich auf die gleichzeitige Verwendung männlicher und weiblicher Sprachformen verzichtet. Die männliche Form schließt die weibliche mit ein. ‍"
          }
        </Typography>
        <Typography variant="h3" className={classes.h3StaticPage}>
          Nutzungsbedingungen
        </Typography>
        <Typography component="p" className={classes.textParagraph}>
          {`Die Nutzung dieser von der ${theme.tcCustom.tenantInformation.company.name} angebotenen Webseite wird ausschließlich zu den folgenden Bedingungen gestattet. Diese „Allgemeine Nutzungsbedingungen“ können im Einzelfall durch weitere Bedingungen, z.B. für den Erwerb von Produkten und/oder Dienstleistungen, ergänzt, modifiziert oder ersetzt werden. Mit Log-in, oder, falls ein gesonderter Log-in nicht erforderlich sein sollte, durch Aufnahme der Nutzung wird die Geltung dieser Nutzungsbedingungen in ihrer jeweiligen Fassung akzeptiert.`}
        </Typography>
        ‍
        <Typography variant="h3" className={classes.h3StaticPage}>
          Nutzungsbedingungsannahme
        </Typography>
        <Typography component="p" className={classes.textParagraph}>
          {
            "Durch Aufnahme der Nutzung wird die Geltung dieser Nutzungsbedingungen in ihrer jeweiligen Fassung akzeptiert."
          }
        </Typography>
        <Typography variant="h3" className={classes.h3StaticPage}>
          Nutzerpflichten
        </Typography>
        <Typography component="p" className={classes.textParagraph}>
          {
            "Ihnen als Nutzer ist es bei der Nutzung unserer Webseite untersagt, dritten Personen, insbesondere"
          }
        </Typography>
        <ul>
          <li>
            {
              "minderjährigen Schaden zuzufügen oder deren Persönlichkeitsrechte zu verletzen"
            }
          </li>

          <li>
            {"mit dem Nutzungsverhalten gegen die guten Sitten zu Verstoßen"}
          </li>

          <li>
            {
              "gewerbliche Schutz- und Urheberrechte oder sonstige Eigentumsrechte zu verletzen"
            }
          </li>

          <li>
            {
              "Inhalte mit Viren, sog. Trojanischen Pferden oder sonstige Programmierungen die Software beschädigen können zu übermitteln"
            }
          </li>

          <li>
            {
              "Hyperlinks oder Inhalte einzugeben, zu speichern oder unbefugt zuzusenden, insbesondere wenn diese Hyperlinks oder Inhalte gegen Geheimhaltungsverpflichtungen verstoßen oder diese rechtswidrig sind"
            }
          </li>

          <li>
            {
              "Werbung oder unaufgeforderte E-Mails (sogenannten „Spam“) oder unzutreffende Warnungen vor Viren,Fehlfunktionen und dergleichen zu verbreiten"
            }
          </li>

          <li>
            {
              "zur Teilnahme an Gewinnspielen, Schneeballsystemen, Kettenbriefen, Pyramidenspielen oder vergleichbaren Aktionen aufzufordern."
            }
          </li>
        </ul>
        <Typography component="p" className={classes.textParagraph}>
          {
            "Die Nutzung der im Rahmen des Impressums oder vergleichbarer Angaben veröffentlichten Kontaktdaten wie Postanschriften, Telefon- und Faxnummern sowie E-Mail-Adressen durch Dritte zur Übersendung von nicht ausdrücklich angeforderten Informationen ist nicht gestattet."
          }
        </Typography>
        <Typography component="p" className={classes.textParagraph}>
          {`Rechtliche Schritte gegen die Versender von sogenannten Spam-Mails, bei Verstößen gegen dieses Verbot, ist ausdrücklich der ${theme.tcCustom.tenantInformation.company.name} vorbehalten.`}
        </Typography>
        <Typography component="p" className={classes.textParagraph}>
          {`Die ${theme.tcCustom.tenantInformation.company.name} darf den Zugang zu den angebotenen Informationen und der Webseite jederzeit sperren, insbesondere wenn Nutzer gegen Ihre Pflichten aus diesen Bedingungen Verstößen.`}
        </Typography>
        ‍
        <Typography variant="h3" className={classes.h3StaticPage}>
          Registrierung
        </Typography>
        <Typography component="p" className={classes.textParagraph}>
          {`Einige Seiten dieser Webseite können passwortgeschützt sein. Der Zugang zu diesen Seiten ist nur registrierten Nutzern möglich. Auf eine Registrierung des Nutzers durch die ${theme.tcCustom.tenantInformation.company.name} besteht kein Anspruch.`}
        </Typography>
        <Typography component="p" className={classes.textParagraph}>
          {`Die ${theme.tcCustom.tenantInformation.company.name} ist jederzeit berechtigt, die Zugangsberechtigung durch Sperrung der Zugangsdaten zu widerrufen, ohne dass es der Angabe von Gründen bedarf, dies insbesondere wenn der Nutzer`}
        </Typography>
        <ul>
          <li>{"bei der Registrierung falsche Angaben gemacht hat"} </li>

          <li>{"gegen diese Nutzungsbedingungen verstoßen hat"} </li>

          <li>
            {
              "gegen seine Sorgfaltspflichten im Umgang mit den Zugangsdaten verstoßen hat, gegen anwendbares Recht bei"
            }
          </li>

          <li>{"der Nutzung dieser Webseite verstoßen hat"} </li>

          <li>
            {
              "wenn der Nutzer diese Webseite über einen längeren Zeitraum hinweg nicht genutzt hat."
            }
          </li>
        </ul>
        <Typography component="p" className={classes.textParagraph}>
          {`Ist eine Registrierung vorgesehen ist der Nutzer verpflichtet, bei der Registrierung seiner natürlichen Person wahrheitsgemäße Angaben zu machen und bei etwaigen späteren Änderungen, diese der ${theme.tcCustom.tenantInformation.company.name} unverzüglich mitzuteilen. ‍`}
        </Typography>
        <Typography variant="h2" className={classes.h2StaticPage}>
          Disclaimer / Haftungsausschluss
        </Typography>
        <Typography variant="h3" className={classes.h3StaticPage}>
          {"Inhalte dieser Webseite(n)"}
        </Typography>
        <Typography component="p" className={classes.textParagraph}>
          {`Die kostenlosen und frei zugänglichen Inhalte dieser Webseite wurden mit größtmöglicher Sorgfalt erstellt. Wir weisen jedoch ausdrücklich darauf hin, dass wir keine Gewähr oder sonstige Verantwortung für die Richtigkeit, Aktualität oder Vollständigkeit der bereitgestellten Informationen unserer Webseite übernehmen. Die ${theme.tcCustom.tenantInformation.company.name} behält sich Änderungen der Informationen auf der bereitgestellten Webseite ohne vorherige Ankündigung ausdrücklich vor.`}
        </Typography>
        <Typography component="p" className={classes.textParagraph}>
          {`Wir sind berechtigt, jederzeit den Betrieb der Webseite ganz oder teilweise einzustellen. Aufgrund der Beschaffenheit des Internets und von Computersystemen, übernimmt die ${theme.tcCustom.tenantInformation.company.name} keine Gewähr für die ununterbrochene Verfügbarkeit der angebotenen oder bereitgestellten Webseite.`}
        </Typography>
        <Typography component="p" className={classes.textParagraph}>
          {`Durch Aufruf dieser kostenlosen und zugänglichen Inhalte, kommt mangels eines entsprechenden Rechtsbindungswillens unsererseits keinerlei Vertragsverhältnisses zwischen Ihnen als Nutzer der Webseite(n) und der ${theme.tcCustom.tenantInformation.company.name} zustande. ‍`}
        </Typography>
        <Typography variant="h3" className={classes.h3StaticPage}>
          Links und Verweise
        </Typography>
        <Typography component="p" className={classes.textParagraph}>
          {`Die zur ${theme.tcCustom.tenantInformation.company.name} gehörende Webseite kann Links zu externen Webseiten enthalten. Da diese Webseiten nicht dem Einfluss der ${theme.tcCustom.tenantInformation.company.name} unterliegen, können wir keinerlei Verantwortung für deren Inhalte und Datenschutzpolitik übernehmen.`}
        </Typography>
        <Typography component="p" className={classes.textParagraph}>
          {
            "Bei direkten oder indirekten Verweisen auf fremde Webseiten (“Hyperlinks”), die außerhalb unserer Verantwortung als Anbieter liegen, würde eine dementsprechende Haftungsverpflichtung ausschließlich in dem Fall in Kraft treten, in dem wir von den Inhalten Kenntnis hatten und es uns technisch möglich und zumutbar gewesen wäre, die Nutzung im Falle rechtswidriger Inhalte zu verhindern."
          }
        </Typography>
        <Typography component="p" className={classes.textParagraph}>
          {`Die ${theme.tcCustom.tenantInformation.company.name} erklärt hiermit ausdrücklich, dass zum Zeitpunkt der Linksetzung keine illegalen Inhalte, auf den zu verlinkten Seite(n) erkennbar waren. Auf die aktuelle und zukünftige Gestaltung, Inhalte oder die Urheberschaft der verlinkten oder verknüpften Seite(n) hat die ${theme.tcCustom.tenantInformation.company.name} als Anbieter keinerlei Einfluss.`}
        </Typography>
        <Typography component="p" className={classes.textParagraph}>
          {`Die ${theme.tcCustom.tenantInformation.company.name} distanziert sich ausdrücklich von allen Inhalten und aller verlinkten oder verknüpften Seiten, die nach der Linksetzung auf unseren Webseiten verändert wurden. Diese Feststellung gilt für alle innerhalb des eigenen Internetangebotes gesetzten Links und Verweise sowie für Fremdeinträge in von uns eingerichteten Gästebüchern, Diskussionsforen, Verzeichnissen, Mailinglisten und allen anderen Formen von Datenbanken, auf deren Inhalt externe Schreibzugriffe möglich sind.`}
        </Typography>
        <Typography component="p" className={classes.textParagraph}>
          {
            "Für illegale, fehlerhafte oder unvollständige Inhalte und insbesondere für Schäden, die aus der Nutzung oder Nichtnutzung solcherart dargebotener Informationen entstehen, haftet grundsätzlich der Anbieter der jeweiligen Seite, auf welche verwiesen wurde, nicht derjenige der über Links auf die jeweilige Veröffentlichung lediglich verweist."
          }
        </Typography>
        <Typography component="p" className={classes.textParagraph}>
          {`In keinem Fall kann die ${theme.tcCustom.tenantInformation.company.name} für etwaige Schäden irgendwelcher Art verantwortlich gemacht werden, die durch die Benutzung oder im Zusammenhang mit der Benutzung der hier bereitgestellten Informationen entstehen, seien es direkte oder indirekte Schäden bzw. Folgeschäden einschließlich entgangenen Gewinns, oder Schäden, die aus dem Verlust von Daten entstehen.`}
        </Typography>
        ‍
        <Typography variant="h3" className={classes.h3StaticPage}>
          Urheber- und Kennzeichnungsrecht
        </Typography>
        <Typography component="p" className={classes.textParagraph}>
          {`Die Inhalte unserer Webseite einschließlich der Gestaltung sind urheberrechtlich geschützt. Sie dürfen weder in Teilen noch vollständig ohne vorherige schriftliche Zustimmung der ${theme.tcCustom.tenantInformation.company.name} in jeder denkbaren Art vervielfältigt, verbreitet, reproduziert oder verarbeitet werden, es sei denn dies ist im Einzelfall ausdrücklich angegeben. Dieses gilt ausdrücklich auch für Inhalte Dritter Autoren.`}
        </Typography>
        <Typography component="p" className={classes.textParagraph}>
          {`Eine Veröffentlichung auf unseren Webseiten oder in sonstigen Diensten des Internet bedeutet keine Einverständniserklärung für eine anderweitige Nutzung durch Dritte. Jede vom deutschen Urheberrecht nicht zugelassene Verwertung, bedarf der vorherigen schriftlichen Zustimmung der ${theme.tcCustom.tenantInformation.company.name}. Eine Vervielfältigung oder Verwendung solcher Grafiken, Tondokumente, Videosequenzen und Texte in anderen elektronischen oder gedruckten Publikationen ist ohne die ausdrückliche Zustimmung nicht gestattet. Das Copyright für veröffentlichte und nicht selbst erstellten Inhalte bleibt allein dem jeweiligen Autor vorbehalten.`}
        </Typography>
        <Typography component="p" className={classes.textParagraph}>
          {
            "Soweit Inhalte auf unseren Seiten nicht von uns als Anbieter erstellt wurden, werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie dennoch auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir solche Inhalte umgehend entfernen."
          }
        </Typography>
        ‍
        <Typography variant="h3" className={classes.h3StaticPage}>
          Marken- und Warenzeichen
        </Typography>
        <Typography component="p" className={classes.textParagraph}>
          {
            "Die innerhalb unserer Webseite genannten Marken- und Warenzeichen unterliegen den Bestimmungen des jeweils gültigen Kennzeichnungsrechtes und den Besitzrechten des jeweiligen Eigentümers."
          }
        </Typography>
        ‍
        <Typography variant="h3" className={classes.h3StaticPage}>
          Bildrechte Dritter
        </Typography>
        <Typography component="p" className={classes.textParagraph}>
          {`Die auf unseren Internetseiten veröffentlichten Bilder entstammen Fotoarchiven. Die jeweiligen Bildrechte liegen soweit nicht anders angegeben bei ${theme.tcCustom.tenantInformation.company.name}.`}
        </Typography>
        ‍
        <Typography variant="h3" className={classes.h3StaticPage}>
          Rechtswahl
        </Typography>
        <Typography component="p" className={classes.textParagraph}>
          {
            "Es gilt deutsches Recht. Bei Verbrauchern gilt diese Rechtswahl nur so weit hierdurch der durch zwingende Bestimmungen des Rechts des Staates des gewöhnlichen Aufenthaltes des Verbrauchers, gewährte Schutz nicht entzogen wird (Günstigkeitsprinzip)."
          }
        </Typography>
        ‍
        <Typography variant="h3" className={classes.h3StaticPage}>
          Rechtswirksamkeit des Haftungsausschlusses
        </Typography>
        <Typography component="p" className={classes.textParagraph}>
          {
            "Dieser Haftungsausschluss ist als Teil des Internetangebotes zu betrachten von dem aus auf unsere Webseite(n) verwiesen wurde. Sofern Teile oder einzelne Formulierungen dieses Textes der geltenden Rechtslage nicht, nicht mehr oder nicht vollständig entsprechen sollte, bleiben die übrigen Teile des Textes in ihrem Inhalt und ihrer Gültigkeit davon unberührt."
          }
        </Typography>
        ‍
        <Typography component="p" className={classes.textParagraph}>
          {`Dieses Impressum wurde durch die ${theme.tcCustom.tenantInformation.dataProtectionOfficer.company} erstellt.`}
        </Typography>
        <Typography component="p" className={classes.textParagraph}>
          Letzte Aktualisierung 04/2021
        </Typography>
      </Box>
    </TCPageLayout>
  );
};

export default TCImprintPage;
