import { Box, Button, IconButton } from "@material-ui/core";
import { ReactComponent as DeleteForeverIcon } from "@iconset/trashweis.svg";
import clsx from "clsx";
import { omit } from "lodash";
import { nanoid } from "nanoid";
import React, { Fragment, useMemo } from "react";
import TCDisplayDocument from "../../../../dialogs/TCDisplayDocument/TCDisplayDocument";
import { TCMenuThreeDot } from "../../../../menu/TCMenuThreeDot/TCMenuThreeDot";
import { TCDocumentListItemProps } from "./TCDocumentListItemProps";
import { useTCDocumentListItemStyles } from "./TCDocumentListItemStyles";
import { useTCDocumentListItemViewModel } from "./TCDocumentListItemViewModel";

const TCDocumentListItem: React.FunctionComponent<TCDocumentListItemProps> = (
  props
) => {
  const viewModel = useTCDocumentListItemViewModel(props);
  const classes = useTCDocumentListItemStyles();

  const {
    document,
    additionalMetaInformation,
    additionalMetaInformationProps,
  } = props;

  const metaInformation = useMemo(
    () =>
      additionalMetaInformation?.map((information) => (
        <Box key={nanoid()} {...additionalMetaInformationProps}>
          {information}
        </Box>
      )),
    [additionalMetaInformation, additionalMetaInformationProps]
  );

  if (!viewModel) {
    return null;
  }

  return (
    <Fragment>
      {viewModel.documentToDisplay && (
        <TCDisplayDocument
          document={viewModel.documentToDisplay}
          onClose={viewModel.onDisplayDocumentClose}
          open={!!viewModel.documentToDisplay}
        />
      )}

      <Box
        {...omit(props.linkWrapperProps, "className")}
        className={clsx(classes.linkWrapper, props.linkWrapperProps?.className)}
        key={document.id}
      >
        <Box className={classes.fileNameWrapper}>
          <Button
            className={classes.fileNameButton}
            onClick={viewModel.onDisplayDocument}
          >
            {document.name}
          </Button>
        </Box>

        <Box className={classes.controls}>
          {metaInformation}

          {props.isUsingThreeDotMenu && (
            <TCMenuThreeDot menuOptions={viewModel.menuOptions} />
          )}

          {props.isUsingDeleteIcon && (
            <IconButton
              onClick={viewModel.onDocumentDelete}
              className={classes.deleteIcon}
            >
              <DeleteForeverIcon />
            </IconButton>
          )}
        </Box>
      </Box>
    </Fragment>
  );
};

export default TCDocumentListItem;
