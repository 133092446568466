import { IconButton, Tooltip } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import React, { Fragment, useContext } from "react";
import { TCListNavigationContext } from "../TCListNavigationContext";
import { NavigationTitleProps } from "./TCNavigationTitleProps";
import { useTCNavigationTitleStyles } from "./TCNavigationTitleStyles";

export const NavigationTitle: React.FunctionComponent<NavigationTitleProps> = (
  props
) => {
  const context = useContext(TCListNavigationContext);

  const classes = useTCNavigationTitleStyles({
    isDetailsOpen: context?.detailsOpen,
    hasHeader: context?.hasHeader ?? false,
  });

  const detailsTitle = (
    <div className={classes.detailsTitle}>
      <Tooltip title="Zurück">
        <IconButton
          onClick={context?.closeDetails}
          className={classes.backButton}
        >
          <ArrowBackIcon />
        </IconButton>
      </Tooltip>

      <div className={classes.titleContainer}>
        {typeof props.detailsTitle === "string" ? (
          <h3>{props.detailsTitle}</h3>
        ) : (
          props.detailsTitle
        )}
      </div>

      {props.rightActionButton}
    </div>
  );

  const listTitle = (
    <Fragment>
      <div className={classes.titleContainer}>
        {typeof props.title === "string" ? <h3>{props.title}</h3> : props.title}
      </div>

      {props.rightActionButton}
    </Fragment>
  );

  return (
    <div className={classes.title}>
      {context?.detailsOpen ? detailsTitle : listTitle}
    </div>
  );
};
