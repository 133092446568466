import { createStyles, makeStyles } from "@material-ui/core";
import { decorationIconRight } from "../../../helpers/styles/decorationIconRight";
import { CustomMuiTheme } from "../../../interfaces/CustomMuiTheme";

export const useTCDatePickerStyles = makeStyles((theme: CustomMuiTheme) =>
  createStyles({
    wrapper: {
      textAlign: "left",
    },
    iconWrapper: {
      position: "relative",
    },
    icon: decorationIconRight(theme),
  })
);
