// tslint:disable
/**
 * TaMoC API V1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs';
import {
    EComparisonType,
    EMatchingType, MatchingRule,
    MatchingRulePost,
    MatchingRulePut
} from '../models';
import { BaseAPI, encodeURI, HttpHeaders, HttpQuery, OperationOpts, RawAjaxResponse, throwIfNullOrUndefined } from '../runtime';

export interface CreateMatchingRuleRequest {
    accessToken: string;
    client: string;
    uid: string;
    matchingRulePost?: MatchingRulePost;
}

export interface DeleteMatchingRuleRequest {
    accessToken: string;
    client: string;
    uid: string;
    id: number;
}

export interface GetMatchingRuleRequest {
    accessToken: string;
    client: string;
    uid: string;
    sortBy?: GetMatchingRuleSortByEnum;
    id?: number | string;
    matchingType?: EMatchingType;
    createdAt?: string;
    updatedAt?: string;
    comparisonType?: EComparisonType;
}

export interface GetMatchingRuleByIdRequest {
    accessToken: string;
    client: string;
    uid: string;
    id: number;
}

export interface PatchMatchingRuleRequest {
    accessToken: string;
    client: string;
    uid: string;
    id: number;
    matchingRulePut?: MatchingRulePut;
}

export interface UpdateMatchingRuleRequest {
    accessToken: string;
    client: string;
    uid: string;
    id: number;
    matchingRulePut?: MatchingRulePut;
}

/**
 * no description
 */
export class MatchingRuleApi extends BaseAPI {

    /**
     * Create MatchingRule
     */
    createMatchingRule({ accessToken, client, uid, matchingRulePost }: CreateMatchingRuleRequest): Observable<MatchingRule>
    createMatchingRule({ accessToken, client, uid, matchingRulePost }: CreateMatchingRuleRequest, opts?: OperationOpts): Observable<RawAjaxResponse<MatchingRule>>
    createMatchingRule({ accessToken, client, uid, matchingRulePost }: CreateMatchingRuleRequest, opts?: OperationOpts): Observable<MatchingRule | RawAjaxResponse<MatchingRule>> {
        throwIfNullOrUndefined(accessToken, 'accessToken', 'createMatchingRule');
        throwIfNullOrUndefined(client, 'client', 'createMatchingRule');
        throwIfNullOrUndefined(uid, 'uid', 'createMatchingRule');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(accessToken != null ? { 'access-token': String(accessToken) } : undefined),
            ...(client != null ? { 'client': String(client) } : undefined),
            ...(uid != null ? { 'uid': String(uid) } : undefined),
        };

        return this.request<MatchingRule>({
            url: '/api/v1/matching_rules/',
            method: 'POST',
            headers,
            body: matchingRulePost,
        }, opts?.responseOpts);
    };

    /**
     * Deletes a MatchingRule
     */
    deleteMatchingRule({ accessToken, client, uid, id }: DeleteMatchingRuleRequest): Observable<void>
    deleteMatchingRule({ accessToken, client, uid, id }: DeleteMatchingRuleRequest, opts?: OperationOpts): Observable<void | RawAjaxResponse<void>>
    deleteMatchingRule({ accessToken, client, uid, id }: DeleteMatchingRuleRequest, opts?: OperationOpts): Observable<void | RawAjaxResponse<void>> {
        throwIfNullOrUndefined(accessToken, 'accessToken', 'deleteMatchingRule');
        throwIfNullOrUndefined(client, 'client', 'deleteMatchingRule');
        throwIfNullOrUndefined(uid, 'uid', 'deleteMatchingRule');
        throwIfNullOrUndefined(id, 'id', 'deleteMatchingRule');

        const headers: HttpHeaders = {
            ...(accessToken != null ? { 'access-token': String(accessToken) } : undefined),
            ...(client != null ? { 'client': String(client) } : undefined),
            ...(uid != null ? { 'uid': String(uid) } : undefined),
        };

        return this.request<void>({
            url: '/api/v1/matching_rules/{id}'.replace('{id}', encodeURI(id)),
            method: 'DELETE',
            headers,
        }, opts?.responseOpts);
    };

    /**
     * Retrieve a MatchingRule list
     */
    getMatchingRule({ accessToken, client, uid, sortBy, id, matchingType, createdAt, updatedAt, comparisonType }: GetMatchingRuleRequest): Observable<Array<MatchingRule>>
    getMatchingRule({ accessToken, client, uid, sortBy, id, matchingType, createdAt, updatedAt, comparisonType }: GetMatchingRuleRequest, opts?: OperationOpts): Observable<RawAjaxResponse<Array<MatchingRule>>>
    getMatchingRule({ accessToken, client, uid, sortBy, id, matchingType, createdAt, updatedAt, comparisonType }: GetMatchingRuleRequest, opts?: OperationOpts): Observable<Array<MatchingRule> | RawAjaxResponse<Array<MatchingRule>>> {
        throwIfNullOrUndefined(accessToken, 'accessToken', 'getMatchingRule');
        throwIfNullOrUndefined(client, 'client', 'getMatchingRule');
        throwIfNullOrUndefined(uid, 'uid', 'getMatchingRule');

        const headers: HttpHeaders = {
            ...(accessToken != null ? { 'access-token': String(accessToken) } : undefined),
            ...(client != null ? { 'client': String(client) } : undefined),
            ...(uid != null ? { 'uid': String(uid) } : undefined),
        };

        const query: HttpQuery = {};

        if (sortBy != null) { query['sort_by'] = sortBy; }
        if (id != null) { query['id'] = id; }
        if (matchingType != null) { query['matching_type'] = matchingType; }
        if (createdAt != null) { query['created_at'] = (createdAt as any).toISOString(); }
        if (updatedAt != null) { query['updated_at'] = (updatedAt as any).toISOString(); }
        if (comparisonType != null) { query['comparison_type'] = comparisonType; }

        return this.request<Array<MatchingRule>>({
            url: '/api/v1/matching_rules/',
            method: 'GET',
            headers,
            query,
        }, opts?.responseOpts);
    };

    /**
     * Retrieves a MatchingRule
     */
    getMatchingRuleById({ accessToken, client, uid, id }: GetMatchingRuleByIdRequest): Observable<MatchingRule>
    getMatchingRuleById({ accessToken, client, uid, id }: GetMatchingRuleByIdRequest, opts?: OperationOpts): Observable<RawAjaxResponse<MatchingRule>>
    getMatchingRuleById({ accessToken, client, uid, id }: GetMatchingRuleByIdRequest, opts?: OperationOpts): Observable<MatchingRule | RawAjaxResponse<MatchingRule>> {
        throwIfNullOrUndefined(accessToken, 'accessToken', 'getMatchingRuleById');
        throwIfNullOrUndefined(client, 'client', 'getMatchingRuleById');
        throwIfNullOrUndefined(uid, 'uid', 'getMatchingRuleById');
        throwIfNullOrUndefined(id, 'id', 'getMatchingRuleById');

        const headers: HttpHeaders = {
            ...(accessToken != null ? { 'access-token': String(accessToken) } : undefined),
            ...(client != null ? { 'client': String(client) } : undefined),
            ...(uid != null ? { 'uid': String(uid) } : undefined),
        };

        return this.request<MatchingRule>({
            url: '/api/v1/matching_rules/{id}'.replace('{id}', encodeURI(id)),
            method: 'GET',
            headers,
        }, opts?.responseOpts);
    };

    /**
     * Patch a MatchingRule
     */
    patchMatchingRule({ accessToken, client, uid, id, matchingRulePut }: PatchMatchingRuleRequest): Observable<MatchingRule>
    patchMatchingRule({ accessToken, client, uid, id, matchingRulePut }: PatchMatchingRuleRequest, opts?: OperationOpts): Observable<RawAjaxResponse<MatchingRule>>
    patchMatchingRule({ accessToken, client, uid, id, matchingRulePut }: PatchMatchingRuleRequest, opts?: OperationOpts): Observable<MatchingRule | RawAjaxResponse<MatchingRule>> {
        throwIfNullOrUndefined(accessToken, 'accessToken', 'patchMatchingRule');
        throwIfNullOrUndefined(client, 'client', 'patchMatchingRule');
        throwIfNullOrUndefined(uid, 'uid', 'patchMatchingRule');
        throwIfNullOrUndefined(id, 'id', 'patchMatchingRule');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(accessToken != null ? { 'access-token': String(accessToken) } : undefined),
            ...(client != null ? { 'client': String(client) } : undefined),
            ...(uid != null ? { 'uid': String(uid) } : undefined),
        };

        return this.request<MatchingRule>({
            url: '/api/v1/matching_rules/{id}'.replace('{id}', encodeURI(id)),
            method: 'PATCH',
            headers,
            body: matchingRulePut,
        }, opts?.responseOpts);
    };

    /**
     * Update a MatchingRule
     */
    updateMatchingRule({ accessToken, client, uid, id, matchingRulePut }: UpdateMatchingRuleRequest): Observable<MatchingRule>
    updateMatchingRule({ accessToken, client, uid, id, matchingRulePut }: UpdateMatchingRuleRequest, opts?: OperationOpts): Observable<RawAjaxResponse<MatchingRule>>
    updateMatchingRule({ accessToken, client, uid, id, matchingRulePut }: UpdateMatchingRuleRequest, opts?: OperationOpts): Observable<MatchingRule | RawAjaxResponse<MatchingRule>> {
        throwIfNullOrUndefined(accessToken, 'accessToken', 'updateMatchingRule');
        throwIfNullOrUndefined(client, 'client', 'updateMatchingRule');
        throwIfNullOrUndefined(uid, 'uid', 'updateMatchingRule');
        throwIfNullOrUndefined(id, 'id', 'updateMatchingRule');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(accessToken != null ? { 'access-token': String(accessToken) } : undefined),
            ...(client != null ? { 'client': String(client) } : undefined),
            ...(uid != null ? { 'uid': String(uid) } : undefined),
        };

        return this.request<MatchingRule>({
            url: '/api/v1/matching_rules/{id}'.replace('{id}', encodeURI(id)),
            method: 'PUT',
            headers,
            body: matchingRulePut,
        }, opts?.responseOpts);
    };

}

/**
 * @export
 * @enum {string}
 */
export enum GetMatchingRuleSortByEnum {
    IdAsc = 'id_asc',
    IdDesc = 'id_desc',
    MatchingTypeAsc = 'matching_type_asc',
    MatchingTypeDesc = 'matching_type_desc',
    CreatedAtAsc = 'created_at_asc',
    CreatedAtDesc = 'created_at_desc',
    UpdatedAtAsc = 'updated_at_asc',
    UpdatedAtDesc = 'updated_at_desc',
    ComparisonTypeAsc = 'comparison_type_asc',
    ComparisonTypeDesc = 'comparison_type_desc'
}
