import { createStyles, makeStyles } from "@material-ui/core";
import { CustomMuiTheme } from "../../../../../interfaces/CustomMuiTheme";

export const useTCEditFieldIntStyles = makeStyles((theme: CustomMuiTheme) =>
  createStyles({
    rangeLabel: {
      color: theme.palette.secondary.main,
      minWidth: theme.spacing(6),
      textAlign: "right",
      fontSize: theme.typography.fontSize * 0.8,
    },
    sliderWrap: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
  })
);
