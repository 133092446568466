import { FormControl, MenuItem, Select } from "@material-ui/core";
import { nanoid } from "nanoid";
import React, { useCallback, useRef } from "react";
import { randomId } from "../../../../../../../helpers/randomId";
import {
  TCCreateNewTimeSlotsPatternOption,
  TCCreateNewTimeSlotsPatternProps,
} from "./TCCreateNewTimeSlotsPatternProps";
import { useTCCreateNewTimeSlotsPatternViewModel } from "./TCCreateNewTimeSlotsPatternViewModel";
import { useTCCreateNewTimeSlotsPatternStyles } from "./useTCCreateNewTimeSlotsPatternStyles";

/**
 * Used to create a new time slots pattern.
 */
export const TCCreateNewTimeSlotsPattern: React.FunctionComponent<TCCreateNewTimeSlotsPatternProps> = ({
  onNew,
  optionsFromDisplayConfig,
}) => {
  const classes = useTCCreateNewTimeSlotsPatternStyles(onNew);
  const {
    placeholderOption,
    onOptionSelect,
    allOptions,
  } = useTCCreateNewTimeSlotsPatternViewModel(onNew, optionsFromDisplayConfig);

  const labelId = useRef(randomId()).current;

  const optionToMenuItem = useCallback(
    (option: TCCreateNewTimeSlotsPatternOption) => (
      <MenuItem key={nanoid()} value={option.numberOfShifts}>
        {option.label}
      </MenuItem>
    ),
    []
  );

  return (
    <FormControl variant="outlined" className={classes.formControl}>
      <Select
        labelId={labelId}
        value={placeholderOption.numberOfShifts}
        onChange={onOptionSelect}
      >
        {allOptions.map(optionToMenuItem)}
      </Select>
    </FormControl>
  );
};
