import React, { useEffect, useState } from "react";
import { blobToFile } from "../../../helpers/file/blobToFile";
import { useDownloadDocumentSwr } from "../../../hooks/tamocApiHooks/documentHooks";
import TCDisplayBlob from "./TCDisplayBlob/TCDisplayBlob";
import { TCDisplayDocumentProps } from "./TCDisplayDocumentProps";

/**
 * Renders a preview of a document.
 *
 * In the process a blob is being downloaded and displayed via TCDisplayBlob
 */
const TCDisplayDocument: React.FunctionComponent<TCDisplayDocumentProps> = (
  props
) => {
  const { document, onClose, open } = props;

  const [file, setFile] = useState<File | undefined>();
  const downloadDocument = useDownloadDocumentSwr({
    documentId: document.id,
  });

  useEffect(() => {
    if (downloadDocument.result) {
      setFile(
        blobToFile(downloadDocument.result, document.metaInformation.name)
      );
    }
  }, [document.metaInformation.name, downloadDocument.result]);

  if (!open) {
    return null;
  }

  return file ? (
    <TCDisplayBlob
      onClose={onClose}
      open={open}
      mediaType={document.metaInformation.contentType}
      fileName={document.metaInformation.name}
      file={file}
      isLoading={false}
    />
  ) : (
    <TCDisplayBlob
      onClose={onClose}
      open={open}
      mediaType={document.metaInformation.contentType}
      fileName={document.metaInformation.name}
      file={undefined}
      isLoading={true}
    />
  );
};

export default TCDisplayDocument;
