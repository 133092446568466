import { InputLabel, TextField, TextFieldProps } from "@material-ui/core";
import React, { ChangeEvent, Fragment, useState } from "react";
import {
  EFieldTypes,
  ValidationLocation,
  ValueValidationLocation,
} from "../../../../../generated/api-service";
import { fieldIsRequired } from "../../../../../helpers/forms/fieldIsRequired";
import { fieldValuesFromFormikContext } from "../../../../../helpers/forms/fieldValuesFromFormikContext";
import { updateFormikValue } from "../../../../../helpers/forms/updateFormikValue";
import {
  labelsForIdentifiers,
  LocationProp,
  locationProps,
} from "../../../../../helpers/staticData";
import { useTCFormikContext } from "../../../../../hooks/useTCFormikContext";
import {
  hasFieldAndFieldGroup,
  TCFieldComponent,
} from "../../../../../interfaces/dynamicFieldInterfaces";
import { useTCEditFieldLocationStyles } from "./TCEditFieldLocationStyles";

export const TCEditFieldLocation: TCFieldComponent = (props) => {
  const cityOnly = props.field.fieldType.name === EFieldTypes.LocationCityOnly;

  const classes = useTCEditFieldLocationStyles({ cityOnly });
  const formikContext = useTCFormikContext();

  const {
    formikIdentifier,
    fieldData,
    fieldValue,
  } = fieldValuesFromFormikContext<ValueValidationLocation>(
    formikContext,
    props.field
  );

  const [
    fieldLocationValue,
    setFieldLocationValue,
  ] = useState<ValueValidationLocation>(fieldValue);

  const syncLocalStateToFormik = () => {
    updateFormikValue(formikContext, formikIdentifier, {
      ...fieldData,
      value: fieldLocationValue,
    });
  };

  if (!hasFieldAndFieldGroup(props)) {
    return null;
  }

  const required = fieldIsRequired(props);

  const changeField = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    property: LocationProp
  ): void => {
    setFieldLocationValue({
      ...fieldLocationValue,
      [property]: event.currentTarget.value,
    });
  };

  const inputFields = locationProps.map((property) => {
    if (cityOnly && property !== nameof<ValidationLocation>((o) => o.city)) {
      return null;
    }
    const textFieldProps: TextFieldProps = {
      className: classes[property],
      InputProps: {
        onBlur: syncLocalStateToFormik,
        onChange: (e) => changeField(e, property),
        placeholder: labelsForIdentifiers[property],
        required,
        value: fieldLocationValue[property] || "",
      },
    };

    return <TextField {...textFieldProps} key={property} />;
  });

  return (
    <Fragment>
      <InputLabel required={required}>{props.field.label}</InputLabel>

      <div className={classes.inputWrap}>{inputFields}</div>
    </Fragment>
  );
};
