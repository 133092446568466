import { createStyles, makeStyles } from "@material-ui/core";

interface StyleProps {
  detailsOpened: boolean | undefined;
}

export const useTCNavigationListStyles = makeStyles(() =>
  createStyles({
    mainContainer: {
      flex: 1,
      overflowY: "hidden",
      position: "relative",
    },
    childContainer: {
      position: "absolute",
      width: "100%",
    },
    listContainer: (props: StyleProps) => ({
      height: "100%",
      overflowY: "scroll",
      transition: "0.2s ease-in-out",
      width: "100%",
      position: "absolute",
      opacity: props.detailsOpened ? 0 : 1,
      left: props.detailsOpened ? "-30%" : "0",
      pointerEvents: props.detailsOpened ? "none" : "all",
    }),
  })
);
