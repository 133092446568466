import { ReactComponent as CalendarIcon } from "@iconset/calendar.svg";
import { Box } from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";
import React, { FunctionComponent } from "react";
import { TCDatePickerProps } from "./TCDatePickerProps";
import { useTCDatePickerStyles } from "./TCDatePickerStyles";
import { useTCDatePickerViewModel } from "./TCDatePickerViewModel";

/**
 * A date picker, encapsulating date parsing.
 */
export const TCDatePicker: FunctionComponent<TCDatePickerProps> = (
  props: TCDatePickerProps
) => {
  const classes = useTCDatePickerStyles();
  const {
    mergedWrapperProps,
    mergedPickerProps,
    labelElement,
  } = useTCDatePickerViewModel(props, classes);

  return (
    <Box {...mergedWrapperProps}>
      {labelElement}

      <Box className={classes.iconWrapper}>
        <DatePicker {...mergedPickerProps} />

        <CalendarIcon className={classes.icon} />
      </Box>
    </Box>
  );
};
