import { CSSProperties } from "@material-ui/core/styles/withStyles";
import { CustomMuiTheme } from "../../interfaces/CustomMuiTheme";

export function decorationIconRight(theme: CustomMuiTheme): CSSProperties {
  return {
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    pointerEvents: "none",
    right: theme.spacing(2),
    zIndex: 10,
  };
}
