import { ValueValidationTimeSlots } from "../../../../../../generated/api-service";
import { TimeSlotsPattern } from "../../../../../../interfaces/typeAliases";

/**
 * As soon as we have the first use case needing other defaults
 * we should get them from the display configuration.
 */

const model2shifts: TimeSlotsPattern = {
  name: "2-Schichtmodell",
  slots: [
    {
      name: "Frühschicht",
      startTime: "05:00",
      endTime: "12:00",
    },
    {
      name: "Spätschicht",
      startTime: "12:00",
      endTime: "18:00",
    },
  ],
};

const model3shifts: TimeSlotsPattern = {
  name: "3-Schichtmodell",
  slots: [
    {
      name: "Frühschicht",
      startTime: "05:00",
      endTime: "12:00",
    },
    {
      name: "Spätschicht",
      startTime: "12:00",
      endTime: "18:00",
    },
    {
      name: "Nachtschicht",
      startTime: "18:00",
      endTime: "02:00",
    },
  ],
};

const modelCoreTime: TimeSlotsPattern = {
  name: "Kernarbeitszeit",
  slots: [
    {
      name: "Geschäftszeiten",
      startTime: "09:00",
      endTime: "17:00",
    },
  ],
};

export const defaultWorkingShiftPatterns: ValueValidationTimeSlots = {
  name: "Default working shift name.",
  timeSlots: [model2shifts, model3shifts, modelCoreTime],
};
