import { Overrides } from "@material-ui/core/styles/overrides";
import { noOutline } from "../../../helpers/styles/noOutline";
import { CustomMuiTheme } from "../../../interfaces/CustomMuiTheme";
import { tcCustom } from "./tcCustom";
import { elevationLevelForCardShadow } from "./variables";

export const overrides: (theme: CustomMuiTheme) => Overrides = (theme) => ({
  MuiTab: {
    root: {
      textTransform: "none",
      fontWeight: "bold",
      fontSize: "1rem",
      letterSpacing: 0,
    },
    textColorInherit: {
      opacity: 1,
      color: theme.palette.secondary.main,
      "&.Mui-selected": {
        color: theme.palette.grey[900],
      },
    },
  },
  MuiButton: {
    root: {
      borderRadius: 0,
      textTransform: "none",
      fontWeight: 400,
      color: theme.palette.grey[900],
      float: "left",
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
  },
  MuiDialog: {
    root: {
      [theme.breakpoints.down("xs")]: {
        overflow: "scroll",
      },
    },
    container: {
      [theme.breakpoints.down("xs")]: {
        height: "unset",
      },
    },
    paper: {
      padding: theme.spacing(10),
      textAlign: "left",
      minWidth: "800px",
      boxShadow: tcCustom.shadows.cardShadow,
      borderRadius: "0",
      [theme.breakpoints.down("xs")]: {
        minWidth: "unset",
        padding: theme.spacing(3),
        margin: "0",
        overflow: "scroll",
      },
    },
  },
  MuiDialogTitle: {
    root: {
      color: theme.palette.grey[900],
      fontWeight: 700,
      textAlign: "center",
    },
  },
  MuiDialogContentText: {
    root: {
      lineHeight: "1.5",
    },
  },
  MuiDrawer: {
    paper: {
      boxShadow: theme.shadows[10],
    },
  },
  MuiAccordion: {
    root: {
      "&:before": {
        display: "none",
      },
    },
  },
  MuiAccordionSummary: {
    root: {
      "&.Mui-expanded": {
        minHeight: 0,
      },
    },
    content: {
      margin: theme.spacing(3, 0),
      "&.Mui-expanded": {
        margin: theme.spacing(2, 0),
      },
      [theme.breakpoints.down("xs")]: {
        flexWrap: "wrap",
        justifyContent: "center",
      },
    },
  },
  MuiAccordionDetails: {
    root: {
      padding: theme.spacing[0],
    },
  },
  MuiDialogActions: {
    root: {
      display: "flex",
      justifyContent: "center",
    },
  },
  MuiCircularProgress: {
    root: {
      color: theme.palette.primary.main,
      margin: `0 auto`,
      display: "block",
      alignSelf: "center",
      justifySelf: "center",
    },
  },
  MuiAutocomplete: {
    listbox: {
      backgroundColor: `${theme.palette.grey[200]} !important`,
    },
    option: {
      backgroundColor: `${theme.palette.grey[200]} !important`,
      "&:hover": {
        backgroundColor: `${theme.palette.grey[400]} !important`,
      },
    },
    tag: {
      backgroundColor: `${theme.palette.grey[400]} !important`,
    },
  },
  MuiFilledInput: {
    root: {
      color: theme.palette.grey[900],
      border: "1px solid",
      borderRadius: "0px!important",
      borderColor: theme.palette.grey[400],
      backgroundColor: theme.palette.grey[200],
      "&:hover": {
        backgroundColor: `${theme.palette.grey[400]} !important`,
      },
      "&:active": {
        backgroundColor: `${theme.palette.grey[400]} !important`,
      },
      "&:focus": {
        backgroundColor: `${theme.palette.grey[400]} !important`,
      },
      "&.Mui-focused": {
        backgroundColor: theme.palette.grey[200],
      },
    },
    underline: {
      "&:before": {
        borderBottom: "none!important",
      },
    },
    input: {
      padding: "18px",
    },
  },
  MuiOutlinedInput: {
    root: {
      color: theme.palette.grey[900],
      border: "1px solid!important",
      borderRadius: "0px!important",
      borderColor: `${theme.palette.grey[400]} !important`,
      backgroundColor: theme.palette.grey[200],
      "&:hover": {
        border: "1px solid",
        borderColor: theme.palette.grey[400],
      },
    },
    notchedOutline: {
      border: "1px solid!important",
      borderColor: `${theme.palette.grey[200]} !important`,
    },
  },
  MuiInputBase: {
    multiline: {
      padding: "18px!important",
    },
  },
  MuiInputLabel: {
    root: {
      fontWeight: "normal",
      marginBottom: "0.8rem",
      color: theme.palette.grey[900],
      textAlign: "left",
      transform: "none!important",
    },
    formControl: {
      position: "relative",
    },
  },
  MuiFormControlLabel: {
    label: {
      textAlign: "left",
    },
  },
  MuiFormLabel: {
    root: {
      display: "inline-block",
      textAlign: "left",
      marginBottom: "0.8rem",
      "&.Mui-error": {
        color: "inherit",
      },
    },
  },
  MuiFormHelperText: {
    contained: {
      marginLeft: 0,
    },
  },
  MuiRadio: {
    root: {
      color: theme.palette.grey[900],
    },
    colorPrimary: {
      "&.Mui-checked": {
        color: theme.palette.grey[900],
      },
    },
  },
  MuiDropzoneArea: {
    root: {
      border: "dashed 2px",
      borderColor: theme.palette.primary.light,
      ...noOutline,
    },
    text: {
      color: theme.palette.primary.light,
    },
    icon: {
      "& path": {
        fill: theme.palette.primary.main,
      },
    },
  },
  MuiDropzonePreviewList: {
    image: {
      borderRadius: 0,
      boxShadow: theme.shadows[10],
    },
  },
  MuiFab: {
    root: {
      backgroundColor: "white",
      boxShadow: theme.shadows[10],
    },
  },
  MuiStepLabel: {
    label: {
      color: theme.palette.grey[900],
    },
  },
  MuiBackdrop: {
    root: {
      backdropFilter: "blur(5px)",
      backgroundColor: "transparent",
    },
  },
  MuiCard: {
    root: {
      borderRadius: 0,
      padding: "0.8rem",
    },
  },
  MuiPaper: {
    root: {
      color: theme.palette.grey[900],
    },
    [`elevation${elevationLevelForCardShadow}`]: {
      boxShadow: theme.tcCustom.shadows.cardShadow,
    },
  },
  MuiTooltip: {
    tooltip: {
      fontSize: ".8rem",
      backgroundColor: theme.palette.secondary.dark,
      color: theme.palette.primary.contrastText,
    },
    tooltipArrow: {
      backgroundColor: theme.palette.secondary.dark,
    },
  },
});
