import { merge } from "lodash";
import React from "react";
import { useTCFormikContext } from "../../../hooks/useTCFormikContext";
import { TCButtonProps, TCPrimaryButton } from "../TCPrimaryButton";

type TCSubmitButtonProps = TCButtonProps;

const defaults: TCSubmitButtonProps = {
  type: "submit",
  children: "Speichern",
};

export const TCSubmitButton: React.FunctionComponent<TCSubmitButtonProps> = (
  props
) => {
  const form = useTCFormikContext();
  return (
    <TCPrimaryButton
      {...merge({}, defaults, props, {
        isLoading: form.isSubmitting,
      })}
    />
  );
};
