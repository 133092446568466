import { InputLabel, TextField, TextFieldProps } from "@material-ui/core";
import React, { Fragment } from "react";
import { DisplayConfigurationValueEditingRangeRenderingTypeEnum } from "../../../../../generated/api-service";
import { fieldIsRequired } from "../../../../../helpers/forms/fieldIsRequired";
import {
  hasFieldAndFieldGroup,
  TCFieldComponent,
} from "../../../../../interfaces/dynamicFieldInterfaces";
import { TCEditFieldDateRange } from "./TCEditFieldDateRange/TCEditFieldDateRange";
import { useTCDateRangePickerStyles } from "./TCEditFieldDateRange/TCEditFieldDateRangeStyles";
import { TCEditFieldTimeRange } from "./TCEditFieldTimeRange/TCEditFieldTimeRange";

export const TCEditFieldRange: TCFieldComponent = (props) => {
  const classes = useTCDateRangePickerStyles();

  const renderingType: DisplayConfigurationValueEditingRangeRenderingTypeEnum =
    props.displayConfiguration?.valueEditing?.rangeRenderingType ||
    DisplayConfigurationValueEditingRangeRenderingTypeEnum.Text;

  if (!hasFieldAndFieldGroup(props)) {
    return null;
  }

  const fromInputProps: TextFieldProps = {
    className: classes.wrap,
    inputProps: {
      placeholder: "Beginn",
    },
  };

  const toInputProps: TextFieldProps = {
    className: classes.wrap,
    inputProps: {
      placeholder: "Ende",
    },
  };
  const additionalLabel = props.displayConfiguration.label?.additionalLabel;

  switch (renderingType) {
    case DisplayConfigurationValueEditingRangeRenderingTypeEnum.Date:
      return <TCEditFieldDateRange {...props} />;
    case DisplayConfigurationValueEditingRangeRenderingTypeEnum.Text:
      return (
        <Fragment>
          {additionalLabel && (
            <InputLabel required={fieldIsRequired(props)}>
              {additionalLabel}
            </InputLabel>
          )}

          <div className={classes.wrap}>
            <InputLabel className={classes.label}>
              {props.field.label.replace(/schicht/g, "")}
            </InputLabel>

            <TextField {...fromInputProps} />

            {"-"}

            <TextField {...toInputProps} />
          </div>
        </Fragment>
      );
    case DisplayConfigurationValueEditingRangeRenderingTypeEnum.Time:
      return <TCEditFieldTimeRange {...props} />;
    default:
      return <h1>cannot handle {renderingType}</h1>;
  }
};
