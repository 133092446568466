import {
  CustomMuiTheme,
  TCAuthPageMuiProps,
} from "../../../interfaces/CustomMuiTheme";
import { TCTenantInformation } from "../../../interfaces/TCTenantInformation";
import { RecursivePartial } from "../../../interfaces/typeAliases";
import logoSvg from "../logo.svg";
import mobileLogoSvg from "../logoMobile.svg";
import backgroundImageUrl from "../registrierung-asset.jpg";
import welcomeSvg from "../welcome.svg";

const tenantInformation: TCTenantInformation = {
  platformName: "NVON",
  managingDirector: {
    firstName: "Felix",
    lastName: "Buchmüller",
    gender: "male",
  },
  dataProtectionOfficer: {
    firstName: "Felix",
    lastName: "Buchmüller",
    company: "NVON GmbH",
    gender: "male",
    contact: {
      email: "email@nvon.com",
      telephone: "+49 6131 6246560",
    },
    address: {
      city: "Mainz",
      postalCode: "55118",
      street: "Taunusstr.",
      streetNumber: "59-61",
    },
  },
  responsibleForContent: {
    firstName: "Felix",
    lastName: "Buchmüller",
    address: {
      city: "Mainz",
      postalCode: "55118",
      street: "Taunusstr.",
      streetNumber: "59-61",
    },
  },
  company: {
    name: "NVON GmbH",
    commercialRegisterIdentifier: "HRB 50583",
    commercialRegisterPlace: "Amtsgericht Mainz",
    seatCity: "Mainz",
    ustId: "DE 345354616",
  },
  address: {
    city: "Mainz",
    postalCode: "55118",
    street: "Taunusstr.",
    streetNumber: "59-61",
  },
  contact: {
    email: "email@nvon.com",
    telephone: "+49 6131 6246560",
  },
};

const loginScreen: TCAuthPageMuiProps = {
  headlineLeft: "Willkommen",
  heroSectionProps: {
    headline: "Ihr Zugang zu mehreren tausend Mitarbeitern aus der IT-Branche",
    description:
      "Mit unserer branchenspezifischen Matching-Plattform finden Sie mit einem Klick die passenden und verfügbaren Arbeitskräfte.",
    backgroundImageUrl,
  },
};

export const tcCustom: RecursivePartial<CustomMuiTheme["tcCustom"]> = {
  welcomeSvg,
  logoSvg,
  loginScreen,
  contractSigning: {
    helloSignClientId: "8cf97545143e744599a1a02c69948445",
    useContractSigning: true,
  },
  registerScreen: {
    worker: {
      ...loginScreen,
      headlineLeft: "Erhalten Sie vollautomatisch passende Stellen.",
    },
    employer: {
      ...loginScreen,
      headlineLeft: "Finden Sie jetzt unkompliziert passende Mitarbeiter",
    },
  },
  mobileLogoSvg,
  workerName: "Arbeitskraft",
  employerName: "Unternehmen",
  layout: {
    authSectionMaxWidth: 650,
    messagesDrawerMaxWidth: 421,
  },
  tenantInformation,
};
