import { useCallback, useMemo } from "react";
import {
  Field,
  FieldValueValuable,
  ValueValidationFile,
} from "../generated/api-service";
import { constructFormikFieldIdentifier } from "../helpers/dynamicFields/fieldName";
import { fieldValuesFromFormikContext } from "../helpers/forms/fieldValuesFromFormikContext";
import { useTCFormikContext } from "./useTCFormikContext";

export interface FormikFieldData<T> {
  formikIdentifier: string;
  setFieldValue: (newValue: T | undefined) => void;
  fieldValue: T | undefined;
}

/**
 * A basic hook for formik, providing:
 * - the formikIdentifier (aka name) of the field
 * - it's actual value (e.g. … 5 … for a number)
 * - a setter to update the value: (newNumber) => void
 */
export const useTCFormikField = <T extends FieldValueValuable["value"]>(
  field: Field
): FormikFieldData<T> => {
  const formikContext = useTCFormikContext();
  const { setFieldValue: formikSetFieldValue } = formikContext;

  const formikIdentifier = constructFormikFieldIdentifier(field);
  const currentFormikData = formikContext.values[formikIdentifier];

  const fieldValue = useMemo(() => currentFormikData?.value, [
    currentFormikData,
  ]) as T | undefined;

  fieldValuesFromFormikContext<ValueValidationFile>(formikContext, field);

  const setFieldValue = useCallback(
    (newValue: T | undefined) => {
      formikSetFieldValue(
        formikIdentifier,
        newValue
          ? {
              ...currentFormikData,
              value: newValue,
            }
          : undefined
      );
    },
    [formikSetFieldValue, formikIdentifier, currentFormikData]
  );

  return useMemo(
    () => ({
      formikIdentifier,
      setFieldValue,
      fieldValue,
    }),
    [formikIdentifier, setFieldValue, fieldValue]
  );
};
