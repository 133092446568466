import { createStyles, makeStyles } from "@material-ui/core";
import { CustomMuiTheme } from "../../../../interfaces/CustomMuiTheme";

export const useTCRenderPdfStyles = makeStyles((theme: CustomMuiTheme) =>
  createStyles({
    pdfNavigation: { display: "flex", justifyContent: "space-between" },
    pageIndicator: { display: "flex", flexDirection: "row" },
    pageStatus: {
      margin: theme.spacing(0, 3),
    },
    renderedPdfBox: {
      display: "flex",
      justifyContent: "center",
    },
  })
);
