import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import React, { useCallback, useMemo } from "react";
import { dbTimeFromMoment } from "../../../../../../helpers/date-time/dbTimeFromMoment";
import { momentFromDbTime } from "../../../../../../helpers/date-time/momentFromDbTime";
import { getFieldLabel } from "../../../../../../helpers/dynamicFields/getFieldLabel";
import { useTCFormikField } from "../../../../../../hooks/useTCFormikField";
import { TCFieldComponent } from "../../../../../../interfaces/dynamicFieldInterfaces";
import { TCTimeRangePicker } from "../../../../../pickers/TCTimeRangePicker/TCTimeRangePicker";
import { TCTimeRangePickerProps } from "../../../../../pickers/TCTimeRangePicker/TCTimeRangePickerProps";

const leftProps: TCTimeRangePickerProps["leftPickerProps"] = {
  pickerProps: {
    placeholder: "Beginn",
  },
};
const rightProps: TCTimeRangePickerProps["rightPickerProps"] = {
  pickerProps: {
    placeholder: "Ende",
  },
};

export const TCEditFieldTimeRange: TCFieldComponent = (props) => {
  const { fieldValue, setFieldValue } = useTCFormikField<{
    minValue?: string;
    maxValue?: string;
  }>(props.field);

  const formattedFrom = useMemo(() => momentFromDbTime(fieldValue?.minValue), [
    fieldValue?.minValue,
  ]);
  const formattedTo = useMemo(() => momentFromDbTime(fieldValue?.maxValue), [
    fieldValue?.maxValue,
  ]);

  const leftOnChange = useCallback(
    (newTime: MaterialUiPickersDate) => {
      setFieldValue({
        ...fieldValue,
        minValue: dbTimeFromMoment(newTime),
      });
    },
    [fieldValue, setFieldValue]
  );

  const rightOnChange = useCallback(
    (newTime: MaterialUiPickersDate) => {
      setFieldValue({
        ...fieldValue,
        maxValue: dbTimeFromMoment(newTime),
      });
    },
    [fieldValue, setFieldValue]
  );

  const label = getFieldLabel(props.field);

  return (
    <TCTimeRangePicker
      leftOnChange={leftOnChange}
      rightOnChange={rightOnChange}
      leftValue={formattedFrom}
      label={label}
      rightValue={formattedTo}
      leftPickerProps={leftProps}
      rightPickerProps={rightProps}
    />
  );
};
