import { createStyles, fade, makeStyles } from "@material-ui/core";
import { CustomMuiTheme } from "../../../interfaces/CustomMuiTheme";

export const useTCConversationUserStyles = makeStyles((theme: CustomMuiTheme) =>
  createStyles({
    detailsViewContainer: {
      padding: 10,
      height: "100%",
    },
    conversationUserCellButton: {
      width: "100%",
    },
    listHeaderContainer: {
      display: "flex",
      alignItems: "flex-start",
      width: "100%",
      height: 90,
      overflowX: "scroll",
      padding: "5px 10px",
      borderBottom: `solid 1px ${fade(theme.palette.secondary.main, 0.55)}`,
    },
  })
);
