import { Field } from "../../generated/api-service";

export const idFieldSeparator = "__";

export default function fieldId(field: Field): string {
  // Field id should not be optional. Check can be removed as soon as it's not.
  if (!field.id) {
    throw Error(`Field ${field.name} was delivered without an id.`);
  }
  return `${field.id}${idFieldSeparator}${Math.round(Math.random() * 10000)}`;
}
