import mime from "mime-types";

export const fileEndingFromMediaType = (mediaType: string): string => {
  const extension = mime.extension(mediaType);

  return extension || mediaType;
};

export const fileEndingsFromMediaTypes = (mediaTypes: string[]): string[] => mediaTypes.map((mediaType) => fileEndingFromMediaType(mediaType));

export const mediaTypeIsPdf = (mediaType: string): boolean => {
  const extension = mime.extension(mediaType);
  return extension === "pdf";
};

export const mediaTypeIsImage = (mediaType: string): boolean => mediaType.includes("image/");
