// tslint:disable
/**
 * TaMoC API V1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs';
import {
    EParentType, Field,
    FieldPost,
    FieldPut
} from '../models';
import { BaseAPI, encodeURI, HttpHeaders, HttpQuery, OperationOpts, RawAjaxResponse, throwIfNullOrUndefined } from '../runtime';

export interface CreateFieldRequest {
    accessToken: string;
    client: string;
    uid: string;
    fieldPost?: FieldPost;
}

export interface DeleteFieldRequest {
    accessToken: string;
    client: string;
    uid: string;
    id: number;
}

export interface GetFieldRequest {
    accessToken: string;
    client: string;
    uid: string;
    sortBy?: GetFieldSortByEnum;
    id?: number | string;
    name?: string;
    fieldTypeId?: number | string;
    displayConfiguration?: string;
    parentType?: EParentType;
    createdAt?: string;
    updatedAt?: string;
    label?: string;
    referencedFieldId?: number | string;
}

export interface GetFieldByIdRequest {
    accessToken: string;
    client: string;
    uid: string;
    id: number;
}

export interface PatchFieldRequest {
    accessToken: string;
    client: string;
    uid: string;
    id: number;
    fieldPut?: FieldPut;
}

export interface UpdateFieldRequest {
    accessToken: string;
    client: string;
    uid: string;
    id: number;
    fieldPut?: FieldPut;
}

/**
 * no description
 */
export class FieldApi extends BaseAPI {

    /**
     * Create Field
     */
    createField({ accessToken, client, uid, fieldPost }: CreateFieldRequest): Observable<Field>
    createField({ accessToken, client, uid, fieldPost }: CreateFieldRequest, opts?: OperationOpts): Observable<RawAjaxResponse<Field>>
    createField({ accessToken, client, uid, fieldPost }: CreateFieldRequest, opts?: OperationOpts): Observable<Field | RawAjaxResponse<Field>> {
        throwIfNullOrUndefined(accessToken, 'accessToken', 'createField');
        throwIfNullOrUndefined(client, 'client', 'createField');
        throwIfNullOrUndefined(uid, 'uid', 'createField');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(accessToken != null ? { 'access-token': String(accessToken) } : undefined),
            ...(client != null ? { 'client': String(client) } : undefined),
            ...(uid != null ? { 'uid': String(uid) } : undefined),
        };

        return this.request<Field>({
            url: '/api/v1/fields/',
            method: 'POST',
            headers,
            body: fieldPost,
        }, opts?.responseOpts);
    };

    /**
     * Deletes a Field
     */
    deleteField({ accessToken, client, uid, id }: DeleteFieldRequest): Observable<void>
    deleteField({ accessToken, client, uid, id }: DeleteFieldRequest, opts?: OperationOpts): Observable<void | RawAjaxResponse<void>>
    deleteField({ accessToken, client, uid, id }: DeleteFieldRequest, opts?: OperationOpts): Observable<void | RawAjaxResponse<void>> {
        throwIfNullOrUndefined(accessToken, 'accessToken', 'deleteField');
        throwIfNullOrUndefined(client, 'client', 'deleteField');
        throwIfNullOrUndefined(uid, 'uid', 'deleteField');
        throwIfNullOrUndefined(id, 'id', 'deleteField');

        const headers: HttpHeaders = {
            ...(accessToken != null ? { 'access-token': String(accessToken) } : undefined),
            ...(client != null ? { 'client': String(client) } : undefined),
            ...(uid != null ? { 'uid': String(uid) } : undefined),
        };

        return this.request<void>({
            url: '/api/v1/fields/{id}'.replace('{id}', encodeURI(id)),
            method: 'DELETE',
            headers,
        }, opts?.responseOpts);
    };

    /**
     * Retrieve a Field list
     */
    getField({ accessToken, client, uid, sortBy, id, name, fieldTypeId, displayConfiguration, parentType, createdAt, updatedAt, label, referencedFieldId }: GetFieldRequest): Observable<Array<Field>>
    getField({ accessToken, client, uid, sortBy, id, name, fieldTypeId, displayConfiguration, parentType, createdAt, updatedAt, label, referencedFieldId }: GetFieldRequest, opts?: OperationOpts): Observable<RawAjaxResponse<Array<Field>>>
    getField({ accessToken, client, uid, sortBy, id, name, fieldTypeId, displayConfiguration, parentType, createdAt, updatedAt, label, referencedFieldId }: GetFieldRequest, opts?: OperationOpts): Observable<Array<Field> | RawAjaxResponse<Array<Field>>> {
        throwIfNullOrUndefined(accessToken, 'accessToken', 'getField');
        throwIfNullOrUndefined(client, 'client', 'getField');
        throwIfNullOrUndefined(uid, 'uid', 'getField');

        const headers: HttpHeaders = {
            ...(accessToken != null ? { 'access-token': String(accessToken) } : undefined),
            ...(client != null ? { 'client': String(client) } : undefined),
            ...(uid != null ? { 'uid': String(uid) } : undefined),
        };

        const query: HttpQuery = {};

        if (sortBy != null) { query['sort_by'] = sortBy; }
        if (id != null) { query['id'] = id; }
        if (name != null) { query['name'] = name; }
        if (fieldTypeId != null) { query['field_type_id'] = fieldTypeId; }
        if (displayConfiguration != null) { query['display_configuration'] = displayConfiguration; }
        if (parentType != null) { query['parent_type'] = parentType; }
        if (createdAt != null) { query['created_at'] = (createdAt as any).toISOString(); }
        if (updatedAt != null) { query['updated_at'] = (updatedAt as any).toISOString(); }
        if (label != null) { query['label'] = label; }
        if (referencedFieldId != null) { query['referenced_field_id'] = referencedFieldId; }

        return this.request<Array<Field>>({
            url: '/api/v1/fields/',
            method: 'GET',
            headers,
            query,
        }, opts?.responseOpts);
    };

    /**
     * Retrieves a Field
     */
    getFieldById({ accessToken, client, uid, id }: GetFieldByIdRequest): Observable<Field>
    getFieldById({ accessToken, client, uid, id }: GetFieldByIdRequest, opts?: OperationOpts): Observable<RawAjaxResponse<Field>>
    getFieldById({ accessToken, client, uid, id }: GetFieldByIdRequest, opts?: OperationOpts): Observable<Field | RawAjaxResponse<Field>> {
        throwIfNullOrUndefined(accessToken, 'accessToken', 'getFieldById');
        throwIfNullOrUndefined(client, 'client', 'getFieldById');
        throwIfNullOrUndefined(uid, 'uid', 'getFieldById');
        throwIfNullOrUndefined(id, 'id', 'getFieldById');

        const headers: HttpHeaders = {
            ...(accessToken != null ? { 'access-token': String(accessToken) } : undefined),
            ...(client != null ? { 'client': String(client) } : undefined),
            ...(uid != null ? { 'uid': String(uid) } : undefined),
        };

        return this.request<Field>({
            url: '/api/v1/fields/{id}'.replace('{id}', encodeURI(id)),
            method: 'GET',
            headers,
        }, opts?.responseOpts);
    };

    /**
     * Patch a Field
     */
    patchField({ accessToken, client, uid, id, fieldPut }: PatchFieldRequest): Observable<Field>
    patchField({ accessToken, client, uid, id, fieldPut }: PatchFieldRequest, opts?: OperationOpts): Observable<RawAjaxResponse<Field>>
    patchField({ accessToken, client, uid, id, fieldPut }: PatchFieldRequest, opts?: OperationOpts): Observable<Field | RawAjaxResponse<Field>> {
        throwIfNullOrUndefined(accessToken, 'accessToken', 'patchField');
        throwIfNullOrUndefined(client, 'client', 'patchField');
        throwIfNullOrUndefined(uid, 'uid', 'patchField');
        throwIfNullOrUndefined(id, 'id', 'patchField');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(accessToken != null ? { 'access-token': String(accessToken) } : undefined),
            ...(client != null ? { 'client': String(client) } : undefined),
            ...(uid != null ? { 'uid': String(uid) } : undefined),
        };

        return this.request<Field>({
            url: '/api/v1/fields/{id}'.replace('{id}', encodeURI(id)),
            method: 'PATCH',
            headers,
            body: fieldPut,
        }, opts?.responseOpts);
    };

    /**
     * Update a Field
     */
    updateField({ accessToken, client, uid, id, fieldPut }: UpdateFieldRequest): Observable<Field>
    updateField({ accessToken, client, uid, id, fieldPut }: UpdateFieldRequest, opts?: OperationOpts): Observable<RawAjaxResponse<Field>>
    updateField({ accessToken, client, uid, id, fieldPut }: UpdateFieldRequest, opts?: OperationOpts): Observable<Field | RawAjaxResponse<Field>> {
        throwIfNullOrUndefined(accessToken, 'accessToken', 'updateField');
        throwIfNullOrUndefined(client, 'client', 'updateField');
        throwIfNullOrUndefined(uid, 'uid', 'updateField');
        throwIfNullOrUndefined(id, 'id', 'updateField');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(accessToken != null ? { 'access-token': String(accessToken) } : undefined),
            ...(client != null ? { 'client': String(client) } : undefined),
            ...(uid != null ? { 'uid': String(uid) } : undefined),
        };

        return this.request<Field>({
            url: '/api/v1/fields/{id}'.replace('{id}', encodeURI(id)),
            method: 'PUT',
            headers,
            body: fieldPut,
        }, opts?.responseOpts);
    };

}

/**
 * @export
 * @enum {string}
 */
export enum GetFieldSortByEnum {
    IdAsc = 'id_asc',
    IdDesc = 'id_desc',
    NameAsc = 'name_asc',
    NameDesc = 'name_desc',
    FieldTypeIdAsc = 'field_type_id_asc',
    FieldTypeIdDesc = 'field_type_id_desc',
    DisplayConfigurationAsc = 'display_configuration_asc',
    DisplayConfigurationDesc = 'display_configuration_desc',
    ParentTypeAsc = 'parent_type_asc',
    ParentTypeDesc = 'parent_type_desc',
    CreatedAtAsc = 'created_at_asc',
    CreatedAtDesc = 'created_at_desc',
    UpdatedAtAsc = 'updated_at_asc',
    UpdatedAtDesc = 'updated_at_desc',
    LabelAsc = 'label_asc',
    LabelDesc = 'label_desc',
    ReferencedFieldIdAsc = 'referenced_field_id_asc',
    ReferencedFieldIdDesc = 'referenced_field_id_desc'
}
