import { createStyles, makeStyles } from "@material-ui/core";

export const useTCMenuThreeDotStyles = makeStyles(() =>
  createStyles({
    menuButtonBox: {
      display: "flex",
      alignSelf: "center",
    },
  })
);
