import { useCallback, useEffect, useRef, useState } from "react";
import {
  useDownloadHelloSignFilesForContract,
  useDownloadPdfContractCallable,
} from "../../../../hooks/tamocApiHooks/contractHooks";
import { RequestStatus } from "../../../../interfaces/requestStatus";
import { TCRenderPdfProps } from "./TCRenderPdfProps";

interface TCRenderPdfViewModel {
  onDocumentLoadSuccess: (data) => void;
  onClickNextPage: () => void;
  onClickPreviousPage: () => void;
  numberOfPages: number | null;
  pageNumber: number;
  onRefreshContract: () => void;
  pdfToRender: Blob | undefined;
  contractRefreshPending: boolean;
}

export const useTCRenderPdfViewModel = (
  props: TCRenderPdfProps
): TCRenderPdfViewModel => {
  const { pdf, contract } = props;
  const downloadHelloSignFiles = useDownloadHelloSignFilesForContract();
  const downloadPdf = useDownloadPdfContractCallable();
  const [numberOfPages, setNumberOfPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [pdfToRender, setPdfToRender] = useState<Blob | undefined>();

  useEffect(() => {
    setPdfToRender(pdf);
  }, [pdf]);

  const callDownloadPdf = downloadPdf.call;
  useEffect(() => {
    if (downloadHelloSignFiles.result && contract) {
      callDownloadPdf({ contractId: contract.id });
    }
  }, [downloadHelloSignFiles.result, contract, callDownloadPdf]);

  useEffect(() => {
    if (downloadPdf.result) {
      setPdfToRender(downloadPdf.result);
    }
  }, [downloadPdf.result, downloadHelloSignFiles]);

  const callDownloadHelloSignFiles = downloadHelloSignFiles.call;
  const onRefreshContract = useCallback(() => {
    if (contract) {
      callDownloadHelloSignFiles({ id: contract.id });
    }
  }, [contract, callDownloadHelloSignFiles]);

  const onDocumentLoadSuccess = useRef((data) => {
    // eslint-disable-next-line no-underscore-dangle
    setNumberOfPages(data._pdfInfo.numPages);
  }).current;

  const onClickNextPage = useCallback(() => {
    setPageNumber(pageNumber + 1);
  }, [pageNumber]);

  const onClickPreviousPage = useCallback(() => {
    setPageNumber(pageNumber <= 2 ? 1 : pageNumber - 1);
  }, [pageNumber]);

  const contractRefreshPending =
    downloadHelloSignFiles.status === RequestStatus.Pending ||
    downloadPdf.status === RequestStatus.Pending;

  return {
    onDocumentLoadSuccess,
    onClickNextPage,
    onClickPreviousPage,
    numberOfPages,
    pageNumber,
    pdfToRender,
    onRefreshContract,
    contractRefreshPending,
  };
};
