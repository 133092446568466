/**
 * Generate a unique SWR key for an api call.
 */

export const getUniqueSwrKeyFromParameter = (
  nameOfApiCall: string[],
  filterObject?: { [key: string]: string | number | undefined }
): string => {
  const uniqueSwrKey = nameOfApiCall;

  if (filterObject) {
    Object.keys(filterObject)
      .sort()
      .forEach((objectKey) => {
        const key = stringFromObjectKey(objectKey);
        const value = filterObject[key];

        if (value) {
          uniqueSwrKey.push(key);
          uniqueSwrKey.push(`${value}`);
        }
      });
  }

  return uniqueSwrKey.join("__");
};

function stringFromObjectKey(objectKey: string | number | symbol): string {
  if (["symbol", "number"].includes(typeof objectKey)) {
    return objectKey.toString();
  }
  return objectKey as string;
}
