import { makeStyles } from "@material-ui/core";
import { TCIconProps } from "./TCIconProps";

export const useTCIconStyles = makeStyles({
  circled: (props: TCIconProps) => ({
    padding: 3,
    color: props.fill,
    borderRadius: "50%",
    background: props.background,
  }),
});
