// tslint:disable
/**
 * TaMoC API V1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs';
import {
    FieldMatchingRule,
    FieldMatchingRulePost,
    FieldMatchingRulePut
} from '../models';
import { BaseAPI, encodeURI, HttpHeaders, HttpQuery, OperationOpts, RawAjaxResponse, throwIfNullOrUndefined } from '../runtime';

export interface CreateFieldMatchingRuleRequest {
    accessToken: string;
    client: string;
    uid: string;
    fieldMatchingRulePost?: FieldMatchingRulePost;
}

export interface DeleteFieldMatchingRuleRequest {
    accessToken: string;
    client: string;
    uid: string;
    id: number;
}

export interface GetFieldMatchingRuleRequest {
    accessToken: string;
    client: string;
    uid: string;
    sortBy?: GetFieldMatchingRuleSortByEnum;
    id?: number | string;
    order?: number | string;
    matchingRuleId?: number | string;
    fieldId?: number | string;
    createdAt?: string;
    updatedAt?: string;
}

export interface GetFieldMatchingRuleByIdRequest {
    accessToken: string;
    client: string;
    uid: string;
    id: number;
}

export interface PatchFieldMatchingRuleRequest {
    accessToken: string;
    client: string;
    uid: string;
    id: number;
    fieldMatchingRulePut?: FieldMatchingRulePut;
}

export interface UpdateFieldMatchingRuleRequest {
    accessToken: string;
    client: string;
    uid: string;
    id: number;
    fieldMatchingRulePut?: FieldMatchingRulePut;
}

/**
 * no description
 */
export class FieldMatchingRuleApi extends BaseAPI {

    /**
     * Create FieldMatchingRule
     */
    createFieldMatchingRule({ accessToken, client, uid, fieldMatchingRulePost }: CreateFieldMatchingRuleRequest): Observable<FieldMatchingRule>
    createFieldMatchingRule({ accessToken, client, uid, fieldMatchingRulePost }: CreateFieldMatchingRuleRequest, opts?: OperationOpts): Observable<RawAjaxResponse<FieldMatchingRule>>
    createFieldMatchingRule({ accessToken, client, uid, fieldMatchingRulePost }: CreateFieldMatchingRuleRequest, opts?: OperationOpts): Observable<FieldMatchingRule | RawAjaxResponse<FieldMatchingRule>> {
        throwIfNullOrUndefined(accessToken, 'accessToken', 'createFieldMatchingRule');
        throwIfNullOrUndefined(client, 'client', 'createFieldMatchingRule');
        throwIfNullOrUndefined(uid, 'uid', 'createFieldMatchingRule');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(accessToken != null ? { 'access-token': String(accessToken) } : undefined),
            ...(client != null ? { 'client': String(client) } : undefined),
            ...(uid != null ? { 'uid': String(uid) } : undefined),
        };

        return this.request<FieldMatchingRule>({
            url: '/api/v1/field_matching_rules/',
            method: 'POST',
            headers,
            body: fieldMatchingRulePost,
        }, opts?.responseOpts);
    };

    /**
     * Deletes a FieldMatchingRule
     */
    deleteFieldMatchingRule({ accessToken, client, uid, id }: DeleteFieldMatchingRuleRequest): Observable<void>
    deleteFieldMatchingRule({ accessToken, client, uid, id }: DeleteFieldMatchingRuleRequest, opts?: OperationOpts): Observable<void | RawAjaxResponse<void>>
    deleteFieldMatchingRule({ accessToken, client, uid, id }: DeleteFieldMatchingRuleRequest, opts?: OperationOpts): Observable<void | RawAjaxResponse<void>> {
        throwIfNullOrUndefined(accessToken, 'accessToken', 'deleteFieldMatchingRule');
        throwIfNullOrUndefined(client, 'client', 'deleteFieldMatchingRule');
        throwIfNullOrUndefined(uid, 'uid', 'deleteFieldMatchingRule');
        throwIfNullOrUndefined(id, 'id', 'deleteFieldMatchingRule');

        const headers: HttpHeaders = {
            ...(accessToken != null ? { 'access-token': String(accessToken) } : undefined),
            ...(client != null ? { 'client': String(client) } : undefined),
            ...(uid != null ? { 'uid': String(uid) } : undefined),
        };

        return this.request<void>({
            url: '/api/v1/field_matching_rules/{id}'.replace('{id}', encodeURI(id)),
            method: 'DELETE',
            headers,
        }, opts?.responseOpts);
    };

    /**
     * Retrieve a FieldMatchingRule list
     */
    getFieldMatchingRule({ accessToken, client, uid, sortBy, id, order, matchingRuleId, fieldId, createdAt, updatedAt }: GetFieldMatchingRuleRequest): Observable<Array<FieldMatchingRule>>
    getFieldMatchingRule({ accessToken, client, uid, sortBy, id, order, matchingRuleId, fieldId, createdAt, updatedAt }: GetFieldMatchingRuleRequest, opts?: OperationOpts): Observable<RawAjaxResponse<Array<FieldMatchingRule>>>
    getFieldMatchingRule({ accessToken, client, uid, sortBy, id, order, matchingRuleId, fieldId, createdAt, updatedAt }: GetFieldMatchingRuleRequest, opts?: OperationOpts): Observable<Array<FieldMatchingRule> | RawAjaxResponse<Array<FieldMatchingRule>>> {
        throwIfNullOrUndefined(accessToken, 'accessToken', 'getFieldMatchingRule');
        throwIfNullOrUndefined(client, 'client', 'getFieldMatchingRule');
        throwIfNullOrUndefined(uid, 'uid', 'getFieldMatchingRule');

        const headers: HttpHeaders = {
            ...(accessToken != null ? { 'access-token': String(accessToken) } : undefined),
            ...(client != null ? { 'client': String(client) } : undefined),
            ...(uid != null ? { 'uid': String(uid) } : undefined),
        };

        const query: HttpQuery = {};

        if (sortBy != null) { query['sort_by'] = sortBy; }
        if (id != null) { query['id'] = id; }
        if (order != null) { query['order'] = order; }
        if (matchingRuleId != null) { query['matching_rule_id'] = matchingRuleId; }
        if (fieldId != null) { query['field_id'] = fieldId; }
        if (createdAt != null) { query['created_at'] = (createdAt as any).toISOString(); }
        if (updatedAt != null) { query['updated_at'] = (updatedAt as any).toISOString(); }

        return this.request<Array<FieldMatchingRule>>({
            url: '/api/v1/field_matching_rules/',
            method: 'GET',
            headers,
            query,
        }, opts?.responseOpts);
    };

    /**
     * Retrieves a FieldMatchingRule
     */
    getFieldMatchingRuleById({ accessToken, client, uid, id }: GetFieldMatchingRuleByIdRequest): Observable<FieldMatchingRule>
    getFieldMatchingRuleById({ accessToken, client, uid, id }: GetFieldMatchingRuleByIdRequest, opts?: OperationOpts): Observable<RawAjaxResponse<FieldMatchingRule>>
    getFieldMatchingRuleById({ accessToken, client, uid, id }: GetFieldMatchingRuleByIdRequest, opts?: OperationOpts): Observable<FieldMatchingRule | RawAjaxResponse<FieldMatchingRule>> {
        throwIfNullOrUndefined(accessToken, 'accessToken', 'getFieldMatchingRuleById');
        throwIfNullOrUndefined(client, 'client', 'getFieldMatchingRuleById');
        throwIfNullOrUndefined(uid, 'uid', 'getFieldMatchingRuleById');
        throwIfNullOrUndefined(id, 'id', 'getFieldMatchingRuleById');

        const headers: HttpHeaders = {
            ...(accessToken != null ? { 'access-token': String(accessToken) } : undefined),
            ...(client != null ? { 'client': String(client) } : undefined),
            ...(uid != null ? { 'uid': String(uid) } : undefined),
        };

        return this.request<FieldMatchingRule>({
            url: '/api/v1/field_matching_rules/{id}'.replace('{id}', encodeURI(id)),
            method: 'GET',
            headers,
        }, opts?.responseOpts);
    };

    /**
     * Patch a FieldMatchingRule
     */
    patchFieldMatchingRule({ accessToken, client, uid, id, fieldMatchingRulePut }: PatchFieldMatchingRuleRequest): Observable<FieldMatchingRule>
    patchFieldMatchingRule({ accessToken, client, uid, id, fieldMatchingRulePut }: PatchFieldMatchingRuleRequest, opts?: OperationOpts): Observable<RawAjaxResponse<FieldMatchingRule>>
    patchFieldMatchingRule({ accessToken, client, uid, id, fieldMatchingRulePut }: PatchFieldMatchingRuleRequest, opts?: OperationOpts): Observable<FieldMatchingRule | RawAjaxResponse<FieldMatchingRule>> {
        throwIfNullOrUndefined(accessToken, 'accessToken', 'patchFieldMatchingRule');
        throwIfNullOrUndefined(client, 'client', 'patchFieldMatchingRule');
        throwIfNullOrUndefined(uid, 'uid', 'patchFieldMatchingRule');
        throwIfNullOrUndefined(id, 'id', 'patchFieldMatchingRule');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(accessToken != null ? { 'access-token': String(accessToken) } : undefined),
            ...(client != null ? { 'client': String(client) } : undefined),
            ...(uid != null ? { 'uid': String(uid) } : undefined),
        };

        return this.request<FieldMatchingRule>({
            url: '/api/v1/field_matching_rules/{id}'.replace('{id}', encodeURI(id)),
            method: 'PATCH',
            headers,
            body: fieldMatchingRulePut,
        }, opts?.responseOpts);
    };

    /**
     * Update a FieldMatchingRule
     */
    updateFieldMatchingRule({ accessToken, client, uid, id, fieldMatchingRulePut }: UpdateFieldMatchingRuleRequest): Observable<FieldMatchingRule>
    updateFieldMatchingRule({ accessToken, client, uid, id, fieldMatchingRulePut }: UpdateFieldMatchingRuleRequest, opts?: OperationOpts): Observable<RawAjaxResponse<FieldMatchingRule>>
    updateFieldMatchingRule({ accessToken, client, uid, id, fieldMatchingRulePut }: UpdateFieldMatchingRuleRequest, opts?: OperationOpts): Observable<FieldMatchingRule | RawAjaxResponse<FieldMatchingRule>> {
        throwIfNullOrUndefined(accessToken, 'accessToken', 'updateFieldMatchingRule');
        throwIfNullOrUndefined(client, 'client', 'updateFieldMatchingRule');
        throwIfNullOrUndefined(uid, 'uid', 'updateFieldMatchingRule');
        throwIfNullOrUndefined(id, 'id', 'updateFieldMatchingRule');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(accessToken != null ? { 'access-token': String(accessToken) } : undefined),
            ...(client != null ? { 'client': String(client) } : undefined),
            ...(uid != null ? { 'uid': String(uid) } : undefined),
        };

        return this.request<FieldMatchingRule>({
            url: '/api/v1/field_matching_rules/{id}'.replace('{id}', encodeURI(id)),
            method: 'PUT',
            headers,
            body: fieldMatchingRulePut,
        }, opts?.responseOpts);
    };

}

/**
 * @export
 * @enum {string}
 */
export enum GetFieldMatchingRuleSortByEnum {
    IdAsc = 'id_asc',
    IdDesc = 'id_desc',
    OrderAsc = 'order_asc',
    OrderDesc = 'order_desc',
    MatchingRuleIdAsc = 'matching_rule_id_asc',
    MatchingRuleIdDesc = 'matching_rule_id_desc',
    FieldIdAsc = 'field_id_asc',
    FieldIdDesc = 'field_id_desc',
    CreatedAtAsc = 'created_at_asc',
    CreatedAtDesc = 'created_at_desc',
    UpdatedAtAsc = 'updated_at_asc',
    UpdatedAtDesc = 'updated_at_desc'
}
