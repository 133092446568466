import { IconButton, InputAdornment, TextField } from "@material-ui/core";
import { Send } from "@material-ui/icons";
import React, { useMemo } from "react";
import { TCChatInputProps } from "./TCChatInputProps";
import { useTCChatInputStyles } from "./TCChatInputStyles";
import { useTCChatInputViewModel } from "./TCChatInputViewModel";

const TCChatInput: React.FunctionComponent<TCChatInputProps> = (props) => {
  const viewModel = useTCChatInputViewModel(props);
  const classes = useTCChatInputStyles();

  const inputProps = useMemo(() => {
    const sendButton = (
      <InputAdornment
        position="end"
        classes={{
          root: classes.inputAdornment,
        }}
      >
        <IconButton
          aria-label="toggle password visibility"
          onClick={viewModel.sendMessage}
          className={classes.sendButton}
          edge="end"
        >
          <Send />
        </IconButton>
      </InputAdornment>
    );

    return {
      placeholder: "Starten Sie die Konversation",
      autoFocus: true,
      inputRef: viewModel.inputRef,
      disabled: viewModel.isLoading,
      value: viewModel.messageBody,
      multiline: true,
      rowsMax: 4,
      onChange: viewModel.setMessage,
      onKeyPress: viewModel.catchEnter,
      endAdornment: sendButton,
      classes: {
        root: classes.input,
      },
    };
  }, [
    classes.input,
    classes.inputAdornment,
    classes.sendButton,
    viewModel.catchEnter,
    viewModel.inputRef,
    viewModel.isLoading,
    viewModel.messageBody,
    viewModel.sendMessage,
    viewModel.setMessage,
  ]);

  return <TextField InputProps={inputProps} />;
};

export default TCChatInput;
