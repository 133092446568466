// tslint:disable
/**
 * TaMoC API V1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs';
import {
    Company,
    CompanyPost,
    CompanyPut
} from '../models';
import { BaseAPI, encodeURI, HttpHeaders, HttpQuery, OperationOpts, RawAjaxResponse, throwIfNullOrUndefined } from '../runtime';

export interface CreateCompanyRequest {
    accessToken: string;
    client: string;
    uid: string;
    companyPost?: CompanyPost;
}

export interface DeleteCompanyRequest {
    accessToken: string;
    client: string;
    uid: string;
    id: number;
}

export interface GetCompanyRequest {
    accessToken: string;
    client: string;
    uid: string;
    sortBy?: GetCompanySortByEnum;
    id?: number | string;
    companyName?: string;
    companyType?: string;
    createdAt?: string;
    updatedAt?: string;
}

export interface GetCompanyByIdRequest {
    accessToken: string;
    client: string;
    uid: string;
    id: number;
}

export interface PatchCompanyRequest {
    accessToken: string;
    client: string;
    uid: string;
    id: number;
    companyPut?: CompanyPut;
}

export interface UpdateCompanyRequest {
    accessToken: string;
    client: string;
    uid: string;
    id: number;
    companyPut?: CompanyPut;
}

/**
 * no description
 */
export class CompanyApi extends BaseAPI {

    /**
     * Create Company
     */
    createCompany({ accessToken, client, uid, companyPost }: CreateCompanyRequest): Observable<Company>
    createCompany({ accessToken, client, uid, companyPost }: CreateCompanyRequest, opts?: OperationOpts): Observable<RawAjaxResponse<Company>>
    createCompany({ accessToken, client, uid, companyPost }: CreateCompanyRequest, opts?: OperationOpts): Observable<Company | RawAjaxResponse<Company>> {
        throwIfNullOrUndefined(accessToken, 'accessToken', 'createCompany');
        throwIfNullOrUndefined(client, 'client', 'createCompany');
        throwIfNullOrUndefined(uid, 'uid', 'createCompany');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(accessToken != null ? { 'access-token': String(accessToken) } : undefined),
            ...(client != null ? { 'client': String(client) } : undefined),
            ...(uid != null ? { 'uid': String(uid) } : undefined),
        };

        return this.request<Company>({
            url: '/api/v1/companies/',
            method: 'POST',
            headers,
            body: companyPost,
        }, opts?.responseOpts);
    };

    /**
     * Deletes a Company
     */
    deleteCompany({ accessToken, client, uid, id }: DeleteCompanyRequest): Observable<void>
    deleteCompany({ accessToken, client, uid, id }: DeleteCompanyRequest, opts?: OperationOpts): Observable<void | RawAjaxResponse<void>>
    deleteCompany({ accessToken, client, uid, id }: DeleteCompanyRequest, opts?: OperationOpts): Observable<void | RawAjaxResponse<void>> {
        throwIfNullOrUndefined(accessToken, 'accessToken', 'deleteCompany');
        throwIfNullOrUndefined(client, 'client', 'deleteCompany');
        throwIfNullOrUndefined(uid, 'uid', 'deleteCompany');
        throwIfNullOrUndefined(id, 'id', 'deleteCompany');

        const headers: HttpHeaders = {
            ...(accessToken != null ? { 'access-token': String(accessToken) } : undefined),
            ...(client != null ? { 'client': String(client) } : undefined),
            ...(uid != null ? { 'uid': String(uid) } : undefined),
        };

        return this.request<void>({
            url: '/api/v1/companies/{id}'.replace('{id}', encodeURI(id)),
            method: 'DELETE',
            headers,
        }, opts?.responseOpts);
    };

    /**
     * Retrieve a Company list
     */
    getCompany({ accessToken, client, uid, sortBy, id, companyName, companyType, createdAt, updatedAt }: GetCompanyRequest): Observable<Array<Company>>
    getCompany({ accessToken, client, uid, sortBy, id, companyName, companyType, createdAt, updatedAt }: GetCompanyRequest, opts?: OperationOpts): Observable<RawAjaxResponse<Array<Company>>>
    getCompany({ accessToken, client, uid, sortBy, id, companyName, companyType, createdAt, updatedAt }: GetCompanyRequest, opts?: OperationOpts): Observable<Array<Company> | RawAjaxResponse<Array<Company>>> {
        throwIfNullOrUndefined(accessToken, 'accessToken', 'getCompany');
        throwIfNullOrUndefined(client, 'client', 'getCompany');
        throwIfNullOrUndefined(uid, 'uid', 'getCompany');

        const headers: HttpHeaders = {
            ...(accessToken != null ? { 'access-token': String(accessToken) } : undefined),
            ...(client != null ? { 'client': String(client) } : undefined),
            ...(uid != null ? { 'uid': String(uid) } : undefined),
        };

        const query: HttpQuery = {};

        if (sortBy != null) { query['sort_by'] = sortBy; }
        if (id != null) { query['id'] = id; }
        if (companyName != null) { query['company_name'] = companyName; }
        if (companyType != null) { query['company_type'] = companyType; }
        if (createdAt != null) { query['created_at'] = (createdAt as any).toISOString(); }
        if (updatedAt != null) { query['updated_at'] = (updatedAt as any).toISOString(); }

        return this.request<Array<Company>>({
            url: '/api/v1/companies/',
            method: 'GET',
            headers,
            query,
        }, opts?.responseOpts);
    };

    /**
     * Retrieves a Company
     */
    getCompanyById({ accessToken, client, uid, id }: GetCompanyByIdRequest): Observable<Company>
    getCompanyById({ accessToken, client, uid, id }: GetCompanyByIdRequest, opts?: OperationOpts): Observable<RawAjaxResponse<Company>>
    getCompanyById({ accessToken, client, uid, id }: GetCompanyByIdRequest, opts?: OperationOpts): Observable<Company | RawAjaxResponse<Company>> {
        throwIfNullOrUndefined(accessToken, 'accessToken', 'getCompanyById');
        throwIfNullOrUndefined(client, 'client', 'getCompanyById');
        throwIfNullOrUndefined(uid, 'uid', 'getCompanyById');
        throwIfNullOrUndefined(id, 'id', 'getCompanyById');

        const headers: HttpHeaders = {
            ...(accessToken != null ? { 'access-token': String(accessToken) } : undefined),
            ...(client != null ? { 'client': String(client) } : undefined),
            ...(uid != null ? { 'uid': String(uid) } : undefined),
        };

        return this.request<Company>({
            url: '/api/v1/companies/{id}'.replace('{id}', encodeURI(id)),
            method: 'GET',
            headers,
        }, opts?.responseOpts);
    };

    /**
     * Patch a Company
     */
    patchCompany({ accessToken, client, uid, id, companyPut }: PatchCompanyRequest): Observable<Company>
    patchCompany({ accessToken, client, uid, id, companyPut }: PatchCompanyRequest, opts?: OperationOpts): Observable<RawAjaxResponse<Company>>
    patchCompany({ accessToken, client, uid, id, companyPut }: PatchCompanyRequest, opts?: OperationOpts): Observable<Company | RawAjaxResponse<Company>> {
        throwIfNullOrUndefined(accessToken, 'accessToken', 'patchCompany');
        throwIfNullOrUndefined(client, 'client', 'patchCompany');
        throwIfNullOrUndefined(uid, 'uid', 'patchCompany');
        throwIfNullOrUndefined(id, 'id', 'patchCompany');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(accessToken != null ? { 'access-token': String(accessToken) } : undefined),
            ...(client != null ? { 'client': String(client) } : undefined),
            ...(uid != null ? { 'uid': String(uid) } : undefined),
        };

        return this.request<Company>({
            url: '/api/v1/companies/{id}'.replace('{id}', encodeURI(id)),
            method: 'PATCH',
            headers,
            body: companyPut,
        }, opts?.responseOpts);
    };

    /**
     * Update a Company
     */
    updateCompany({ accessToken, client, uid, id, companyPut }: UpdateCompanyRequest): Observable<Company>
    updateCompany({ accessToken, client, uid, id, companyPut }: UpdateCompanyRequest, opts?: OperationOpts): Observable<RawAjaxResponse<Company>>
    updateCompany({ accessToken, client, uid, id, companyPut }: UpdateCompanyRequest, opts?: OperationOpts): Observable<Company | RawAjaxResponse<Company>> {
        throwIfNullOrUndefined(accessToken, 'accessToken', 'updateCompany');
        throwIfNullOrUndefined(client, 'client', 'updateCompany');
        throwIfNullOrUndefined(uid, 'uid', 'updateCompany');
        throwIfNullOrUndefined(id, 'id', 'updateCompany');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(accessToken != null ? { 'access-token': String(accessToken) } : undefined),
            ...(client != null ? { 'client': String(client) } : undefined),
            ...(uid != null ? { 'uid': String(uid) } : undefined),
        };

        return this.request<Company>({
            url: '/api/v1/companies/{id}'.replace('{id}', encodeURI(id)),
            method: 'PUT',
            headers,
            body: companyPut,
        }, opts?.responseOpts);
    };

}

/**
 * @export
 * @enum {string}
 */
export enum GetCompanySortByEnum {
    IdAsc = 'id_asc',
    IdDesc = 'id_desc',
    CompanyNameAsc = 'company_name_asc',
    CompanyNameDesc = 'company_name_desc',
    CompanyTypeAsc = 'company_type_asc',
    CompanyTypeDesc = 'company_type_desc',
    CreatedAtAsc = 'created_at_asc',
    CreatedAtDesc = 'created_at_desc',
    UpdatedAtAsc = 'updated_at_asc',
    UpdatedAtDesc = 'updated_at_desc'
}
