import { Button } from "@material-ui/core";
import { extendElementWithDefaults } from "../../helpers/extendElementWithDefaults";

export const TCForwardButton = extendElementWithDefaults({
  baseElement: Button,
  defaults: {
    variant: "contained",
    disableElevation: true,
    color: "primary",
    children: "weiter",
  },
});
