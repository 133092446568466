import isArray from "lodash/isArray";
import isNumber from "lodash/isNumber";
import isObject from "lodash/isObject";
import isString from "lodash/isString";
import { useCallback } from "react";
import { LoggingSeverity, tclog } from "../helpers/tclog";
import { ApiError } from "../interfaces/error";
import { useTCSnackbars } from "./useTCSnackbars";

const errorMessageFromResponse = (error: ApiError): string | undefined => {
  const { message } = error.response;

  if (isString(message)) {
    return message;
  }

  if (isObject(message)) {
    const listOfMessages = Object.entries(message).map(([key, value]) => {
      let valueToUse: string | undefined;

      if (isString(value)) {
        valueToUse = value;
      }

      if (isNumber(value)) {
        valueToUse = value.toString();
      }

      if (isArray(value)) {
        valueToUse = value.filter((val) => isString(val)).join(",");
      }

      return !valueToUse ? undefined : `${key}: ${valueToUse}`;
    });

    return listOfMessages.join(", ");
  }

  return undefined;
};

export const useErrorHandler = (): ((error: ApiError) => void) => {
  const { errorSnackbar } = useTCSnackbars();

  return useCallback(
    (error: ApiError) => {
      const message = errorMessageFromResponse(error);

      errorSnackbar(`Es ist ein Fehler aufgetreten \n${message}`);

      if (message) {
        tclog(message, LoggingSeverity.Error);
      }
    },
    [errorSnackbar]
  );
};
