import { ReactComponent as TimeIcon } from "@iconset/time.svg";
import { Box } from "@material-ui/core";
import { TimePicker, TimePickerProps } from "@material-ui/pickers";
import clsx from "clsx";
import React, { FunctionComponent } from "react";
import { dbTimeFormat } from "../../../Consts";
import { useMergedProps } from "../../../hooks/useMergedProps";
import { TCTimePickerProps } from "./TCTimePickerProps";
import { useTCTimePickerStyles } from "./useTCTimePickerStyles";

const defaultPickerProps: Partial<TimePickerProps> = {
  inputVariant: "outlined",
  orientation: "portrait",
  ampm: false,
  DialogProps: {
    PaperProps: {
      style: {
        padding: 0,
      },
    },
  },
  cancelLabel: "abbrechen",
  format: dbTimeFormat,
  minutesStep: 5,
};

/**
 * Provides a time picker, encapsulating parsing.
 */
export const TCTimePicker: FunctionComponent<TCTimePickerProps> = (props) => {
  const classes = useTCTimePickerStyles();
  const { onChange, value } = props;

  const mergedPickerProps = useMergedProps(defaultPickerProps, {
    ...props.pickerProps,
    value,
    onChange,
  }) as TimePickerProps;

  return (
    <Box
      {...props.wrapperProps}
      className={clsx(classes.wrapper, props.className)}
    >
      <TimePicker {...mergedPickerProps} />

      <TimeIcon className={classes.icon} />
    </Box>
  );
};
