import { ButtonBase, IconButton, Tooltip } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import React, { Fragment } from "react";
import { closeMessagesDrawer } from "../../../dataHandling/services/chatApi";
import { TCListNavigation } from "../../lists/TCListNavigation/TCListNavigation";
import { TCListNavigationDetailsView } from "../../lists/TCListNavigation/TCListNavigationDetailsView/TCListNavigationDetailsView";
import { TCChat } from "../TCChat";
import { TCConversationTitle } from "./TCConversationTitle/TCConversationTitle";
import { TCConversationUserCell } from "./TCConversationUserCell/TCConversationUserCell";
import { useTCConversationUserStyles } from "./TCConversationUserStyles";
import { useTCConversationUserViewModel } from "./TCConversationUserViewModel";
import { TCHeaderAvatarIcons } from "./TCHeaderAvatarIcons/TCHeaderAvatarIcons";

export const TCConversationUser: React.FunctionComponent = () => {
  const viewModel = useTCConversationUserViewModel();
  const classes = useTCConversationUserStyles();

  const list = (
    <Fragment>
      {viewModel.conversationUsersWithMessages.map((conversationUser) => (
        <ButtonBase
          className={classes.conversationUserCellButton}
          key={conversationUser.id}
        >
          <TCConversationUserCell
            onClick={viewModel.setConversationUser}
            conversationUser={conversationUser}
          />
        </ButtonBase>
      ))}
    </Fragment>
  );

  const details = (
    <TCListNavigationDetailsView>
      {viewModel.currentConversationUser?.conversation && (
        <div className={classes.detailsViewContainer}>
          <TCChat
            conversation={viewModel.currentConversationUser.conversation}
          />
        </div>
      )}
    </TCListNavigationDetailsView>
  );
  const detailsTitle = viewModel.currentConversationUser ? (
    <TCConversationTitle conversationUser={viewModel.currentConversationUser} />
  ) : null;

  const rightActionButton = (
    <Tooltip title="Close">
      <IconButton color="secondary" onClick={closeMessagesDrawer}>
        <CloseIcon />
      </IconButton>
    </Tooltip>
  );

  const listHeader =
    viewModel.newConversationUsers.length > 0 ? (
      <div className={classes.listHeaderContainer}>
        {viewModel.newConversationUsers.map((conversationUser) => (
          <TCHeaderAvatarIcons
            key={conversationUser.id}
            conversationUser={conversationUser}
          />
        ))}
      </div>
    ) : null;

  const ListNavigation = (
    <TCListNavigation
      onBackAction={viewModel.clearCurrentConversationUser}
      selectedItem={viewModel.currentConversationUser}
      listComponent={list}
      List={list}
      detailsViewComponent={details}
      detailsTitle={detailsTitle}
      rightActionButton={rightActionButton}
      title="Nachrichten"
      listHeader={listHeader}
    />
  );

  return ListNavigation;
};
