import { Box, CircularProgress } from "@material-ui/core";
import React from "react";
import { mergeDefaultPropsAndClass } from "../../../helpers/forComponents/mergeDefaultPropsAndClass";
import { TCLoadingIndicatorProps } from "./TCLoadingIndicatorProps";
import { useTCLoadingIndicatorStyles } from "./TCLoadingIndicatorStyles";

export const TCLoadingIndicator: React.FunctionComponent<TCLoadingIndicatorProps> = (
  props
) => {
  const classes = useTCLoadingIndicatorStyles();

  return props.isLoading ? (
    <Box className={classes.loadingWrapper}>
      <CircularProgress
        {...mergeDefaultPropsAndClass(
          {},
          props.circularProgressProps,
          classes.circle
        )}
      />
    </Box>
  ) : null;
};
