import { ReactComponent as DeleteForeverIcon } from "@iconset/trashweis.svg";
import { Box, Button, IconButton, TextField } from "@material-ui/core";
import { nanoid } from "nanoid";
import React, { useCallback } from "react";
import { useLocalFieldValue } from "../../../../../../../dataHandling/hooks/useLocalFieldValue";
import { TimeSlot } from "../../../../../../../interfaces/typeAliases";
import { TCEditSingleShift } from "../TCEditSingleShift/TCEditSingleShift";
import { TCEditSingleTimeSlotsPatternProps } from "./TCEditFieldTimeSlotsPatternProps";
import { useTCEditSingleTimeSlotsPatternStyles } from "./TCEditSingleTimeSlotsPatternStyles";

/**
 * Represents a single time slot pattern,
 *  consisting of multiple slots and a name.
 */
export const TCEditSingleTimeSlotsPattern: React.FunctionComponent<TCEditSingleTimeSlotsPatternProps> = ({
  onChange,
  timeSlotsPattern,
  onDelete,
}) => {
  const classes = useTCEditSingleTimeSlotsPatternStyles();

  const textFieldProps = useLocalFieldValue(
    (newName) =>
      onChange({
        ...timeSlotsPattern,
        name: newName,
      }),
    timeSlotsPattern.name || ""
  );

  const handleChange = useCallback(
    (index: number) => (changedShift: TimeSlot) => {
      // Replace a single slot on a copy of the old slots element.
      const newSlots = [...timeSlotsPattern.slots];
      newSlots.splice(index, 1, changedShift);
      onChange({
        ...timeSlotsPattern,
        slots: newSlots,
      });
    },
    [onChange, timeSlotsPattern]
  );

  return (
    <Box className={classes.wrapper}>
      <TextField
        required={true}
        className={classes.patternName}
        value={textFieldProps.value}
        onChange={textFieldProps.onChange}
        onBlur={textFieldProps.onBlur}
      />

      {timeSlotsPattern.slots.map((shift, index) => (
        <TCEditSingleShift
          onChange={handleChange(index)}
          key={nanoid()}
          {...{ shift }}
        />
      ))}

      <Button
        onClick={onDelete}
        endIcon={
          <IconButton>
            <DeleteForeverIcon />
          </IconButton>
        }
        color="primary"
        className={classes.deleteButton}
      >
        {textFieldProps.value || "Arbeitszeitmodell"} löschen
      </Button>
    </Box>
  );
};
