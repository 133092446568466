import { Observable } from "rxjs";
import useSWR from "swr";
import { contractApi } from "../../dataHandling/autogeneratedApiServices";
import {
  Contract,
  DownloadHelloSignFilesRequest,
  GetContractRequest,
  RenderContractAsPDFRequest,
} from "../../generated/api-service";
import { getUniqueSwrKeyFromParameter } from "../../helpers/getUniqueSwrKeyFromParameter";
import { WithoutAuthentication } from "../../interfaces/authentication";
import { BasicSwrInterface } from "../../interfaces/basicSwrInterface";
import { ObservableHookResult } from "../../interfaces/observableHook";
import { useParameterWithAuthentication } from "./authentication/useParameterWithAuthentication";
import { useObservableWithAuthentication } from "./useObservable/useObservableWithAuthentication";

export const useDownloadPdfContract = (
  filterParameters: WithoutAuthentication<RenderContractAsPDFRequest>
): BasicSwrInterface<Blob> => {
  const parameters = useParameterWithAuthentication(filterParameters);

  const fetcher = () => contractApi.renderContractAsPDF(parameters).toPromise();

  const key = getUniqueSwrKeyFromParameter(
    ["contractApi", "renderContractAsPDF"],
    {
      ...filterParameters,
    }
  );

  const { data, error } = useSWR(key, fetcher, {
    refreshInterval: 0,
    revalidateOnMount: true,
    revalidateOnFocus: true,
  });

  return {
    result: data,
    isLoading: !error && !data,
    isError: error,
  };
};

export const useContracts = (
  filterParameters: WithoutAuthentication<GetContractRequest>
): BasicSwrInterface<Contract[]> => {
  const parameters = useParameterWithAuthentication<GetContractRequest>(
    filterParameters
  );

  const fetcher = () => contractApi.getContract(parameters).toPromise();

  const key = getUniqueSwrKeyFromParameter(["contractApi", "getContract"], {
    ...filterParameters,
  });

  const { data, error } = useSWR(key, fetcher);

  return {
    result: data,
    isLoading: !error && !data,
    isError: error,
  };
};

export const useDownloadPdfContractCallable = (): ObservableHookResult<
  WithoutAuthentication<RenderContractAsPDFRequest>,
  Blob
> =>
  useObservableWithAuthentication(
    contractApi.renderContractAsPDF.bind(contractApi) as (
      parameter
    ) => Observable<Blob>
  );

export const useDownloadHelloSignFilesForContract = (): ObservableHookResult<
  WithoutAuthentication<DownloadHelloSignFilesRequest>,
  Contract
> =>
  useObservableWithAuthentication(
    contractApi.downloadHelloSignFiles.bind(contractApi) as (
      parameter
    ) => Observable<Contract>
  );
