// tslint:disable
/**
 * TaMoC API V1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs';
import {
    ConversationUser,
    ConversationUserPost
} from '../models';
import { BaseAPI, encodeURI, HttpHeaders, HttpQuery, OperationOpts, RawAjaxResponse, throwIfNullOrUndefined } from '../runtime';

export interface CreateConversationUserRequest {
    accessToken: string;
    client: string;
    uid: string;
    conversationUserPost?: ConversationUserPost;
}

export interface GetConversationUserRequest {
    accessToken: string;
    client: string;
    uid: string;
    sortBy?: GetConversationUserSortByEnum;
    id?: number | string;
    archived?: string;
    userId?: number | string;
    conversationId?: string;
    createdAt?: string;
    updatedAt?: string;
}

export interface GetConversationUserByIdRequest {
    accessToken: string;
    client: string;
    uid: string;
    id: number;
}

/**
 * no description
 */
export class ConversationUserApi extends BaseAPI {

    /**
     * Create ConversationUser
     */
    createConversationUser({ accessToken, client, uid, conversationUserPost }: CreateConversationUserRequest): Observable<ConversationUser>
    createConversationUser({ accessToken, client, uid, conversationUserPost }: CreateConversationUserRequest, opts?: OperationOpts): Observable<RawAjaxResponse<ConversationUser>>
    createConversationUser({ accessToken, client, uid, conversationUserPost }: CreateConversationUserRequest, opts?: OperationOpts): Observable<ConversationUser | RawAjaxResponse<ConversationUser>> {
        throwIfNullOrUndefined(accessToken, 'accessToken', 'createConversationUser');
        throwIfNullOrUndefined(client, 'client', 'createConversationUser');
        throwIfNullOrUndefined(uid, 'uid', 'createConversationUser');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(accessToken != null ? { 'access-token': String(accessToken) } : undefined),
            ...(client != null ? { 'client': String(client) } : undefined),
            ...(uid != null ? { 'uid': String(uid) } : undefined),
        };

        return this.request<ConversationUser>({
            url: '/api/v1/conversation_users/',
            method: 'POST',
            headers,
            body: conversationUserPost,
        }, opts?.responseOpts);
    };

    /**
     * Retrieve a ConversationUser list
     */
    getConversationUser({ accessToken, client, uid, sortBy, id, archived, userId, conversationId, createdAt, updatedAt }: GetConversationUserRequest): Observable<Array<ConversationUser>>
    getConversationUser({ accessToken, client, uid, sortBy, id, archived, userId, conversationId, createdAt, updatedAt }: GetConversationUserRequest, opts?: OperationOpts): Observable<RawAjaxResponse<Array<ConversationUser>>>
    getConversationUser({ accessToken, client, uid, sortBy, id, archived, userId, conversationId, createdAt, updatedAt }: GetConversationUserRequest, opts?: OperationOpts): Observable<Array<ConversationUser> | RawAjaxResponse<Array<ConversationUser>>> {
        throwIfNullOrUndefined(accessToken, 'accessToken', 'getConversationUser');
        throwIfNullOrUndefined(client, 'client', 'getConversationUser');
        throwIfNullOrUndefined(uid, 'uid', 'getConversationUser');

        const headers: HttpHeaders = {
            ...(accessToken != null ? { 'access-token': String(accessToken) } : undefined),
            ...(client != null ? { 'client': String(client) } : undefined),
            ...(uid != null ? { 'uid': String(uid) } : undefined),
        };

        const query: HttpQuery = {};

        if (sortBy != null) { query['sort_by'] = sortBy; }
        if (id != null) { query['id'] = id; }
        if (archived != null) { query['archived'] = archived; }
        if (userId != null) { query['user_id'] = userId; }
        if (conversationId != null) { query['conversation_id'] = conversationId; }
        if (createdAt != null) { query['created_at'] = (createdAt as any).toISOString(); }
        if (updatedAt != null) { query['updated_at'] = (updatedAt as any).toISOString(); }

        return this.request<Array<ConversationUser>>({
            url: '/api/v1/conversation_users/',
            method: 'GET',
            headers,
            query,
        }, opts?.responseOpts);
    };

    /**
     * Retrieves a ConversationUser
     */
    getConversationUserById({ accessToken, client, uid, id }: GetConversationUserByIdRequest): Observable<ConversationUser>
    getConversationUserById({ accessToken, client, uid, id }: GetConversationUserByIdRequest, opts?: OperationOpts): Observable<RawAjaxResponse<ConversationUser>>
    getConversationUserById({ accessToken, client, uid, id }: GetConversationUserByIdRequest, opts?: OperationOpts): Observable<ConversationUser | RawAjaxResponse<ConversationUser>> {
        throwIfNullOrUndefined(accessToken, 'accessToken', 'getConversationUserById');
        throwIfNullOrUndefined(client, 'client', 'getConversationUserById');
        throwIfNullOrUndefined(uid, 'uid', 'getConversationUserById');
        throwIfNullOrUndefined(id, 'id', 'getConversationUserById');

        const headers: HttpHeaders = {
            ...(accessToken != null ? { 'access-token': String(accessToken) } : undefined),
            ...(client != null ? { 'client': String(client) } : undefined),
            ...(uid != null ? { 'uid': String(uid) } : undefined),
        };

        return this.request<ConversationUser>({
            url: '/api/v1/conversation_users/{id}'.replace('{id}', encodeURI(id)),
            method: 'GET',
            headers,
        }, opts?.responseOpts);
    };

}

/**
 * @export
 * @enum {string}
 */
export enum GetConversationUserSortByEnum {
    IdAsc = 'id_asc',
    IdDesc = 'id_desc',
    ArchivedAsc = 'archived_asc',
    ArchivedDesc = 'archived_desc',
    UserIdAsc = 'user_id_asc',
    UserIdDesc = 'user_id_desc',
    ConversationIdAsc = 'conversation_id_asc',
    ConversationIdDesc = 'conversation_id_desc',
    CreatedAtAsc = 'created_at_asc',
    CreatedAtDesc = 'created_at_desc',
    UpdatedAtAsc = 'updated_at_asc',
    UpdatedAtDesc = 'updated_at_desc'
}
