import { createStyles, makeStyles } from "@material-ui/core";
import { CustomMuiTheme } from "../../../interfaces/CustomMuiTheme";

export const useTCInfoBlockStyles = makeStyles((theme: CustomMuiTheme) =>
  createStyles({
    infoBlockWrapper: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      alignContent: "center",
      [theme.breakpoints.down("xs")]: { flexDirection: "column" },
    },
    infoBlockIcon: {
      display: "flex",
      padding: theme.spacing(2),
      alignItems: "center",
      [theme.breakpoints.down("xs")]: { justifyContent: "center" },
    },
    infoBlockContent: {
      display: "flex",
      padding: theme.spacing(2, 2, 2, 0),
      alignItems: "center",
      [theme.breakpoints.down("xs")]: {
        justifyContent: "center",
        padding: theme.spacing(2),
      },
    },
  })
);
