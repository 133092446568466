// tslint:disable
/**
 * TaMoC API V1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum EPurpose {
    EditProfileEmployer = 'edit_profile_employer',
    EditProfileWorker = 'edit_profile_worker',
    EmployerDetailForWorker = 'employer_detail_for_worker',
    JobCompanyInfoForEmployer = 'job_company_info_for_employer',
    JobCreation = 'job_creation',
    JobDetailForEmployer = 'job_detail_for_employer',
    JobDetailForWorker = 'job_detail_for_worker',
    JobListForWorker = 'job_list_for_worker',
    JobUserListForEmployer = 'job_user_list_for_employer',
    OnboardingEmployer = 'onboarding_employer',
    OnboardingWorker = 'onboarding_worker',
    PasswordForgotten = 'password_forgotten',
    PasswordReset = 'password_reset',
    RegistrationEmployer = 'registration_employer',
    RegistrationWorker = 'registration_worker',
    UserValidationDetailEmployer = 'user_validation_detail_employer',
    UserValidationDetailWorker = 'user_validation_detail_worker',
    UserValidationListEmployer = 'user_validation_list_employer',
    UserValidationListWorker = 'user_validation_list_worker',
    WorkerDetailForEmployer = 'worker_detail_for_employer',
    WorkerListForEmployer = 'worker_list_for_employer',
    JobCancellationWorker = 'job_cancellation_worker'
}

