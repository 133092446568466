import { createStyles, makeStyles } from "@material-ui/core";
import { CustomMuiTheme } from "../../../../../../interfaces/CustomMuiTheme";

export const useTCEditFieldFileCurrentStateStyles = makeStyles(
  (theme: CustomMuiTheme) =>
    createStyles({
      fileListWrapper: {
        backgroundColor: theme.palette.grey[200],
        padding: theme.spacing(1, 0, 1, 2),
        margin: theme.spacing(1, 0),
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: theme.spacing(1),
      },
      linkWrapper: { margin: theme.spacing(1, 0) },
      fileListLabel: {
        color: theme.palette.secondary.main,
        margin: theme.spacing(1, 3, 1, 0),
        textAlign: "left",
      },
    })
);
