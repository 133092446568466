import { Button, PropTypes } from "@material-ui/core";
import CheckCircleOutlineRoundedIcon from "@material-ui/icons/CheckCircleOutlineRounded";
import React from "react";
import { useMergedProps } from "../../../hooks/useMergedProps";
import { TCAcceptButtonProps } from "./TCAcceptButtonProps";
import { useTCAcceptButtonStyles } from "./TCAcceptButtonStyles";

const color: PropTypes.Color = "primary";

const defaultProps: Partial<TCAcceptButtonProps> = {
  buttonProps: {
    "aria-label": "Annehmen",
    color,
    size: "small",
  },
  useIcon: true,
};

/**
 * A colored accept button that can use an icon (useIcon=true) and/or
 * a text (buttonText != '')
 */
export const TCAcceptButton: React.FunctionComponent<TCAcceptButtonProps> = (
  props
) => {
  const classes = useTCAcceptButtonStyles(props);

  const buttonProps = useMergedProps(
    defaultProps.buttonProps,
    props.buttonProps,
    classes.button
  );

  return (
    <Button {...buttonProps} onClick={props.onClick}>
      {props.useIcon ? (
        <CheckCircleOutlineRoundedIcon className={classes.icon} />
      ) : null}

      {props.buttonText}
    </Button>
  );
};
