import { useMemo } from "react";
import { mergeDefaultPropsAndClass } from "../helpers/forComponents/mergeDefaultPropsAndClass";
import { CanHaveClassName } from "../interfaces/typeAliases";

/**
 * Merges a set of default values with values passed as props.
 * Additionally, a className (e.g. from `makeStyles`) can be passed.
 * While props will override defaults, classNames will be added.
 */
export const useMergedProps = <T extends CanHaveClassName>(
  defaultProps: Partial<T> | undefined,
  props: Partial<T> | undefined,
  className?: string | undefined
): Partial<T> =>
  useMemo(() => mergeDefaultPropsAndClass(defaultProps, props, className), [
    defaultProps,
    props,
    className,
  ]);
