import { Typography } from "@material-ui/core";
import moment from "moment";
import React, { Fragment } from "react";
import { TCMessageCellProps } from "./TCMessageCellProps";
import { useTCMessageCellStyles } from "./TCMessageCellStyles";

export const TCMessageCell: React.FunctionComponent<TCMessageCellProps> = (
  props
) => {
  const isMessageFromCurrentUser =
    props.currentUserId === props.message.sentMessageableId;
  const classes = useTCMessageCellStyles({ isMessageFromCurrentUser });

  const messageTimeStamp = (): string => {
    const createdAt = moment(props.message.createdAt);
    if (moment().diff(createdAt, "days", true) > 1) {
      return `${createdAt.calendar()}, ${createdAt.format("LT")} Uhr`;
    }
    return createdAt.fromNow();
  };

  return (
    <Fragment>
      {props.isLastFromGroup && (
        <span className={classes.time}>{messageTimeStamp()}</span>
      )}

      <div className={classes.messageCell}>
        <Typography className={classes.messageBody}>
          {props.message.body}
        </Typography>
      </div>
    </Fragment>
  );
};
