import { createStyles, makeStyles } from "@material-ui/core";
import { CustomMuiTheme } from "../../../interfaces/CustomMuiTheme";
import { TCAcceptButtonProps } from "./TCAcceptButtonProps";

export const useTCAcceptButtonStyles = makeStyles((theme: CustomMuiTheme) =>
  createStyles({
    button: {
      margin: theme.spacing(1, 1, 0, 1),
      minWidth: "auto",
      padding: 0,
      backgroundColor: "transparent",
      color: theme.palette.primary.main,
      "& svg": {
        fontSize: "xx-large",
      },
      "&:hover": {
        backgroundColor: "transparent ",
      },
    },
    icon: {
      marginRight: (props: TCAcceptButtonProps) =>
        props.buttonText ? theme.spacing(1) : 0,
    },
  })
);
