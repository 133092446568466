import FileSaver from "file-saver";
import { useCallback, useRef, useState } from "react";
import { TCBlobListItemProps } from "./TCBlobListItemProps";

interface TCBlobListItemViewModel {
  onDownloadFile: () => void;
  blobDelete: () => void;
  fileToDisplay: File | undefined;
  onDisplayFile: () => void;
  onDisplayFileClose: () => void;
}

export const useTCBlobListItemViewModel = (
  props: TCBlobListItemProps
): TCBlobListItemViewModel => {
  const { file, onBlobDelete, index } = props;

  const [fileToDisplay, setFileToDisplay] = useState<File | undefined>();

  const onDownloadFile = useCallback(() => {
    FileSaver.saveAs(file, file.name);
  }, [file]);

  const blobDelete = useCallback(() => {
    if (onBlobDelete) {
      onBlobDelete(file, index);
    }
  }, [file, onBlobDelete, index]);

  const onDisplayFile = useCallback(() => {
    setFileToDisplay(file);
  }, [file]);

  const onDisplayFileClose = useRef(() => {
    setFileToDisplay(undefined);
  }).current;

  return {
    blobDelete,
    onDownloadFile,
    fileToDisplay,
    onDisplayFile,
    onDisplayFileClose,
  };
};
