import { Typography } from "@material-ui/core";
import React, { Fragment, useMemo } from "react";
import { TcDocument } from "../../../../../../generated/api-service";
import { byteToMegaByte } from "../../../../../../helpers/file/fileSizes";
import { fileEndingFromMediaType } from "../../../../../../helpers/file/mediaTypes";
import { formatNumber } from "../../../../../../helpers/formatNumber";
import { TCDisplayFieldProps } from "../../../../../../interfaces/dynamicFieldInterfaces";
import { TCDialogDeleteDocument } from "../../../../../dialogs/TCDialogDeleteDocument/TCDialogDeleteDocument";
import { TCDocumentList } from "../../../../../lists/TCDocumentBlobList/TCDocumentList/TCDocumentList";
import { useTCDisplayFieldComplexFileEditableStyles } from "./TCDisplayFieldComplexFileEditableStyles";
import { useTCDisplayFieldComplexFileViewModel } from "./TCDisplayFieldComplexFileEditableViewModel";

export const TCDisplayFieldComplexFileEditable: React.FunctionComponent<TCDisplayFieldProps> = (
  props
) => {
  const { field } = props;

  const classes = useTCDisplayFieldComplexFileEditableStyles();
  const viewModel = useTCDisplayFieldComplexFileViewModel();

  const additionalMetaInformation = useMemo(
    () => (document: TcDocument) => [
        <Typography
          key={field.label}
          className={classes.additionalMetaInformationTypography}
        >
          {field.label}
        </Typography>,
        <Typography
          key={"mimeType"}
          className={classes.additionalMetaInformationTypography}
        >
          {fileEndingFromMediaType(document.metaInformation.contentType)}
        </Typography>,
        <Typography
          key={"fileSize"}
          className={classes.additionalMetaInformationTypography}
        >
          {formatNumber(byteToMegaByte(document.metaInformation.size), "mb")}
        </Typography>,
      ],
    [field, classes]
  );

  return props.initialFiles && props.initialFiles.length > 0 ? (
    <Fragment>
      {viewModel.documentToDelete && (
        <TCDialogDeleteDocument
          dialogOpen={viewModel.showDeleteDocument}
          document={viewModel.documentToDelete}
          onAfterDelete={viewModel.onCloseOrAfterDelete}
          onClose={viewModel.onCloseOrAfterDelete}
        />
      )}

      {props.initialFiles.map((document) => (
        <TCDocumentList
          key={document.id}
          documents={[document]}
          isUsingDeleteIcon={false}
          isUsingThreeDotMenu={true}
          onDocumentDelete={viewModel.onDocumentDelete}
          additionalMetaInformation={additionalMetaInformation(document)}
          additionalMetaInformationProps={{
            className: classes.additionalMetaInformation,
          }}
        />
      ))}
    </Fragment>
  ) : null;
};
