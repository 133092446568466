import { FormControlLabel, Switch } from "@material-ui/core";
import { Field, FieldProps } from "formik";
import React from "react";
import { constructFormikFieldIdentifier } from "../../../../helpers/dynamicFields/fieldName";
import { fieldIsRequired } from "../../../../helpers/forms/fieldIsRequired";
import { fieldValuesFromFormikContext } from "../../../../helpers/forms/fieldValuesFromFormikContext";
import { updateFormikValue } from "../../../../helpers/forms/updateFormikValue";
import { useTCFormikContext } from "../../../../hooks/useTCFormikContext";
import {
  hasFieldAndFieldGroup,
  TCFieldComponent,
} from "../../../../interfaces/dynamicFieldInterfaces";
import { TCFormikValues } from "../../../../interfaces/formikInterfaces";

export const TCEditFieldBool: TCFieldComponent = (props) => {
  const formikContext = useTCFormikContext();
  const { formikIdentifier, fieldData } = fieldValuesFromFormikContext<string>(
    formikContext,
    props.field
  );
  if (!hasFieldAndFieldGroup(props)) {
    return null;
  }
  const required = fieldIsRequired(props);
  return (
    <Field name={constructFormikFieldIdentifier(props.field)}>
      {(fieldProps: FieldProps<TCFormikValues>) => (
          <FormControlLabel
            control={
              <Switch
                edge="start"
                {...fieldProps.field}
                required={required}
                onChange={() =>
                  updateFormikValue(formikContext, formikIdentifier, {
                    ...fieldData,
                    value: !fieldProps.field.value.value,
                  })
                }
              />
            }
            label={props.field.label}
          />
        )}
    </Field>
  );
};
