import { useMemo } from "react";
import { WithoutAuthentication } from "../../../interfaces/authentication";
import { useAuthenticationParameter } from "./useAuthenticationParameter";

export const useParameterWithAuthentication = <T>(
  parameters: WithoutAuthentication<T>
): T => {
  const authenticationParameter = useAuthenticationParameter();

  return useMemo(() => ({ ...(parameters as T), ...authenticationParameter }), [authenticationParameter, parameters]);
};
