// tslint:disable
/**
 * TaMoC API V1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs';
import {
    ESignatureStatus,
    ESignerRole, Signature,
    SignaturePost,
    SignaturePut
} from '../models';
import { BaseAPI, encodeURI, HttpHeaders, HttpQuery, OperationOpts, RawAjaxResponse, throwIfNullOrUndefined } from '../runtime';

export interface CreateSignatureRequest {
    accessToken: string;
    client: string;
    uid: string;
    signaturePost?: SignaturePost;
}

export interface DeleteSignatureRequest {
    accessToken: string;
    client: string;
    uid: string;
    id: number;
}

export interface GetSignatureRequest {
    accessToken: string;
    client: string;
    uid: string;
    sortBy?: GetSignatureSortByEnum;
    id?: number | string;
    contractId?: number | string;
    signerId?: number | string;
    signerRole?: ESignerRole;
    signatureStatus?: ESignatureStatus;
    helloSignSignatureId?: string;
    helloSignEmbedUrl?: string;
    createdAt?: string;
    updatedAt?: string;
}

export interface GetSignatureByIdRequest {
    accessToken: string;
    client: string;
    uid: string;
    id: number;
}

export interface PatchSignatureRequest {
    accessToken: string;
    client: string;
    uid: string;
    id: number;
    signaturePut?: SignaturePut;
}

export interface RenewEmbedUrlRequest {
    accessToken: string;
    client: string;
    uid: string;
    id: number;
}

export interface UpdateSignatureRequest {
    accessToken: string;
    client: string;
    uid: string;
    id: number;
    signaturePut?: SignaturePut;
}

/**
 * no description
 */
export class SignatureApi extends BaseAPI {

    /**
     * Create Signature
     */
    createSignature({ accessToken, client, uid, signaturePost }: CreateSignatureRequest): Observable<Signature>
    createSignature({ accessToken, client, uid, signaturePost }: CreateSignatureRequest, opts?: OperationOpts): Observable<RawAjaxResponse<Signature>>
    createSignature({ accessToken, client, uid, signaturePost }: CreateSignatureRequest, opts?: OperationOpts): Observable<Signature | RawAjaxResponse<Signature>> {
        throwIfNullOrUndefined(accessToken, 'accessToken', 'createSignature');
        throwIfNullOrUndefined(client, 'client', 'createSignature');
        throwIfNullOrUndefined(uid, 'uid', 'createSignature');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(accessToken != null ? { 'access-token': String(accessToken) } : undefined),
            ...(client != null ? { 'client': String(client) } : undefined),
            ...(uid != null ? { 'uid': String(uid) } : undefined),
        };

        return this.request<Signature>({
            url: '/api/v1/signatures/',
            method: 'POST',
            headers,
            body: signaturePost,
        }, opts?.responseOpts);
    };

    /**
     * Deletes a Signature
     */
    deleteSignature({ accessToken, client, uid, id }: DeleteSignatureRequest): Observable<void>
    deleteSignature({ accessToken, client, uid, id }: DeleteSignatureRequest, opts?: OperationOpts): Observable<void | RawAjaxResponse<void>>
    deleteSignature({ accessToken, client, uid, id }: DeleteSignatureRequest, opts?: OperationOpts): Observable<void | RawAjaxResponse<void>> {
        throwIfNullOrUndefined(accessToken, 'accessToken', 'deleteSignature');
        throwIfNullOrUndefined(client, 'client', 'deleteSignature');
        throwIfNullOrUndefined(uid, 'uid', 'deleteSignature');
        throwIfNullOrUndefined(id, 'id', 'deleteSignature');

        const headers: HttpHeaders = {
            ...(accessToken != null ? { 'access-token': String(accessToken) } : undefined),
            ...(client != null ? { 'client': String(client) } : undefined),
            ...(uid != null ? { 'uid': String(uid) } : undefined),
        };

        return this.request<void>({
            url: '/api/v1/signatures/{id}'.replace('{id}', encodeURI(id)),
            method: 'DELETE',
            headers,
        }, opts?.responseOpts);
    };

    /**
     * Retrieve a Signature list
     */
    getSignature({ accessToken, client, uid, sortBy, id, contractId, signerId, signerRole, signatureStatus, helloSignSignatureId, helloSignEmbedUrl, createdAt, updatedAt }: GetSignatureRequest): Observable<Array<Signature>>
    getSignature({ accessToken, client, uid, sortBy, id, contractId, signerId, signerRole, signatureStatus, helloSignSignatureId, helloSignEmbedUrl, createdAt, updatedAt }: GetSignatureRequest, opts?: OperationOpts): Observable<RawAjaxResponse<Array<Signature>>>
    getSignature({ accessToken, client, uid, sortBy, id, contractId, signerId, signerRole, signatureStatus, helloSignSignatureId, helloSignEmbedUrl, createdAt, updatedAt }: GetSignatureRequest, opts?: OperationOpts): Observable<Array<Signature> | RawAjaxResponse<Array<Signature>>> {
        throwIfNullOrUndefined(accessToken, 'accessToken', 'getSignature');
        throwIfNullOrUndefined(client, 'client', 'getSignature');
        throwIfNullOrUndefined(uid, 'uid', 'getSignature');

        const headers: HttpHeaders = {
            ...(accessToken != null ? { 'access-token': String(accessToken) } : undefined),
            ...(client != null ? { 'client': String(client) } : undefined),
            ...(uid != null ? { 'uid': String(uid) } : undefined),
        };

        const query: HttpQuery = {};

        if (sortBy != null) { query['sort_by'] = sortBy; }
        if (id != null) { query['id'] = id; }
        if (contractId != null) { query['contract_id'] = contractId; }
        if (signerId != null) { query['signer_id'] = signerId; }
        if (signerRole != null) { query['signer_role'] = signerRole; }
        if (signatureStatus != null) { query['signature_status'] = signatureStatus; }
        if (helloSignSignatureId != null) { query['hello_sign_signature_id'] = helloSignSignatureId; }
        if (helloSignEmbedUrl != null) { query['hello_sign_embed_url'] = helloSignEmbedUrl; }
        if (createdAt != null) { query['created_at'] = (createdAt as any).toISOString(); }
        if (updatedAt != null) { query['updated_at'] = (updatedAt as any).toISOString(); }

        return this.request<Array<Signature>>({
            url: '/api/v1/signatures/',
            method: 'GET',
            headers,
            query,
        }, opts?.responseOpts);
    };

    /**
     * Retrieves a Signature
     */
    getSignatureById({ accessToken, client, uid, id }: GetSignatureByIdRequest): Observable<Signature>
    getSignatureById({ accessToken, client, uid, id }: GetSignatureByIdRequest, opts?: OperationOpts): Observable<RawAjaxResponse<Signature>>
    getSignatureById({ accessToken, client, uid, id }: GetSignatureByIdRequest, opts?: OperationOpts): Observable<Signature | RawAjaxResponse<Signature>> {
        throwIfNullOrUndefined(accessToken, 'accessToken', 'getSignatureById');
        throwIfNullOrUndefined(client, 'client', 'getSignatureById');
        throwIfNullOrUndefined(uid, 'uid', 'getSignatureById');
        throwIfNullOrUndefined(id, 'id', 'getSignatureById');

        const headers: HttpHeaders = {
            ...(accessToken != null ? { 'access-token': String(accessToken) } : undefined),
            ...(client != null ? { 'client': String(client) } : undefined),
            ...(uid != null ? { 'uid': String(uid) } : undefined),
        };

        return this.request<Signature>({
            url: '/api/v1/signatures/{id}'.replace('{id}', encodeURI(id)),
            method: 'GET',
            headers,
        }, opts?.responseOpts);
    };

    /**
     * Patch a Signature
     */
    patchSignature({ accessToken, client, uid, id, signaturePut }: PatchSignatureRequest): Observable<Signature>
    patchSignature({ accessToken, client, uid, id, signaturePut }: PatchSignatureRequest, opts?: OperationOpts): Observable<RawAjaxResponse<Signature>>
    patchSignature({ accessToken, client, uid, id, signaturePut }: PatchSignatureRequest, opts?: OperationOpts): Observable<Signature | RawAjaxResponse<Signature>> {
        throwIfNullOrUndefined(accessToken, 'accessToken', 'patchSignature');
        throwIfNullOrUndefined(client, 'client', 'patchSignature');
        throwIfNullOrUndefined(uid, 'uid', 'patchSignature');
        throwIfNullOrUndefined(id, 'id', 'patchSignature');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(accessToken != null ? { 'access-token': String(accessToken) } : undefined),
            ...(client != null ? { 'client': String(client) } : undefined),
            ...(uid != null ? { 'uid': String(uid) } : undefined),
        };

        return this.request<Signature>({
            url: '/api/v1/signatures/{id}'.replace('{id}', encodeURI(id)),
            method: 'PATCH',
            headers,
            body: signaturePut,
        }, opts?.responseOpts);
    };

    /**
     * Renew embed url
     */
    renewEmbedUrl({ accessToken, client, uid, id }: RenewEmbedUrlRequest): Observable<Signature>
    renewEmbedUrl({ accessToken, client, uid, id }: RenewEmbedUrlRequest, opts?: OperationOpts): Observable<RawAjaxResponse<Signature>>
    renewEmbedUrl({ accessToken, client, uid, id }: RenewEmbedUrlRequest, opts?: OperationOpts): Observable<Signature | RawAjaxResponse<Signature>> {
        throwIfNullOrUndefined(accessToken, 'accessToken', 'renewEmbedUrl');
        throwIfNullOrUndefined(client, 'client', 'renewEmbedUrl');
        throwIfNullOrUndefined(uid, 'uid', 'renewEmbedUrl');
        throwIfNullOrUndefined(id, 'id', 'renewEmbedUrl');

        const headers: HttpHeaders = {
            ...(accessToken != null ? { 'access-token': String(accessToken) } : undefined),
            ...(client != null ? { 'client': String(client) } : undefined),
            ...(uid != null ? { 'uid': String(uid) } : undefined),
        };

        return this.request<Signature>({
            url: '/api/v1/signatures/{id}/renew_embed_url'.replace('{id}', encodeURI(id)),
            method: 'PATCH',
            headers,
        }, opts?.responseOpts);
    };

    /**
     * Update a Signature
     */
    updateSignature({ accessToken, client, uid, id, signaturePut }: UpdateSignatureRequest): Observable<Signature>
    updateSignature({ accessToken, client, uid, id, signaturePut }: UpdateSignatureRequest, opts?: OperationOpts): Observable<RawAjaxResponse<Signature>>
    updateSignature({ accessToken, client, uid, id, signaturePut }: UpdateSignatureRequest, opts?: OperationOpts): Observable<Signature | RawAjaxResponse<Signature>> {
        throwIfNullOrUndefined(accessToken, 'accessToken', 'updateSignature');
        throwIfNullOrUndefined(client, 'client', 'updateSignature');
        throwIfNullOrUndefined(uid, 'uid', 'updateSignature');
        throwIfNullOrUndefined(id, 'id', 'updateSignature');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(accessToken != null ? { 'access-token': String(accessToken) } : undefined),
            ...(client != null ? { 'client': String(client) } : undefined),
            ...(uid != null ? { 'uid': String(uid) } : undefined),
        };

        return this.request<Signature>({
            url: '/api/v1/signatures/{id}'.replace('{id}', encodeURI(id)),
            method: 'PUT',
            headers,
            body: signaturePut,
        }, opts?.responseOpts);
    };

}

/**
 * @export
 * @enum {string}
 */
export enum GetSignatureSortByEnum {
    IdAsc = 'id_asc',
    IdDesc = 'id_desc',
    ContractIdAsc = 'contract_id_asc',
    ContractIdDesc = 'contract_id_desc',
    SignerIdAsc = 'signer_id_asc',
    SignerIdDesc = 'signer_id_desc',
    SignerRoleAsc = 'signer_role_asc',
    SignerRoleDesc = 'signer_role_desc',
    SignatureStatusAsc = 'signature_status_asc',
    SignatureStatusDesc = 'signature_status_desc',
    HelloSignSignatureIdAsc = 'hello_sign_signature_id_asc',
    HelloSignSignatureIdDesc = 'hello_sign_signature_id_desc',
    HelloSignEmbedUrlAsc = 'hello_sign_embed_url_asc',
    HelloSignEmbedUrlDesc = 'hello_sign_embed_url_desc',
    CreatedAtAsc = 'created_at_asc',
    CreatedAtDesc = 'created_at_desc',
    UpdatedAtAsc = 'updated_at_asc',
    UpdatedAtDesc = 'updated_at_desc'
}
