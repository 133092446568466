// tslint:disable
/**
 * TaMoC API V1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs';
import {
    ComplexType,
    ComplexTypePost,
    ComplexTypePut,
    EComplexType
} from '../models';
import { BaseAPI, encodeURI, HttpHeaders, HttpQuery, OperationOpts, RawAjaxResponse, throwIfNullOrUndefined } from '../runtime';

export interface CreateComplexTypeRequest {
    accessToken: string;
    client: string;
    uid: string;
    complexTypePost?: ComplexTypePost;
}

export interface DeleteComplexTypeRequest {
    accessToken: string;
    client: string;
    uid: string;
    id: number;
}

export interface GetComplexTypeRequest {
    accessToken: string;
    client: string;
    uid: string;
    sortBy?: GetComplexTypeSortByEnum;
    id?: number | string;
    valueValidationSchema?: string;
    complexType?: EComplexType;
    createdAt?: string;
    updatedAt?: string;
}

export interface GetComplexTypeByIdRequest {
    accessToken: string;
    client: string;
    uid: string;
    id: number;
}

export interface PatchComplexTypeRequest {
    accessToken: string;
    client: string;
    uid: string;
    id: number;
    complexTypePut?: ComplexTypePut;
}

export interface UpdateComplexTypeRequest {
    accessToken: string;
    client: string;
    uid: string;
    id: number;
    complexTypePut?: ComplexTypePut;
}

/**
 * no description
 */
export class ComplexTypeApi extends BaseAPI {

    /**
     * Create ComplexType
     */
    createComplexType({ accessToken, client, uid, complexTypePost }: CreateComplexTypeRequest): Observable<ComplexType>
    createComplexType({ accessToken, client, uid, complexTypePost }: CreateComplexTypeRequest, opts?: OperationOpts): Observable<RawAjaxResponse<ComplexType>>
    createComplexType({ accessToken, client, uid, complexTypePost }: CreateComplexTypeRequest, opts?: OperationOpts): Observable<ComplexType | RawAjaxResponse<ComplexType>> {
        throwIfNullOrUndefined(accessToken, 'accessToken', 'createComplexType');
        throwIfNullOrUndefined(client, 'client', 'createComplexType');
        throwIfNullOrUndefined(uid, 'uid', 'createComplexType');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(accessToken != null ? { 'access-token': String(accessToken) } : undefined),
            ...(client != null ? { 'client': String(client) } : undefined),
            ...(uid != null ? { 'uid': String(uid) } : undefined),
        };

        return this.request<ComplexType>({
            url: '/api/v1/complex_types/',
            method: 'POST',
            headers,
            body: complexTypePost,
        }, opts?.responseOpts);
    };

    /**
     * Deletes a ComplexType
     */
    deleteComplexType({ accessToken, client, uid, id }: DeleteComplexTypeRequest): Observable<void>
    deleteComplexType({ accessToken, client, uid, id }: DeleteComplexTypeRequest, opts?: OperationOpts): Observable<void | RawAjaxResponse<void>>
    deleteComplexType({ accessToken, client, uid, id }: DeleteComplexTypeRequest, opts?: OperationOpts): Observable<void | RawAjaxResponse<void>> {
        throwIfNullOrUndefined(accessToken, 'accessToken', 'deleteComplexType');
        throwIfNullOrUndefined(client, 'client', 'deleteComplexType');
        throwIfNullOrUndefined(uid, 'uid', 'deleteComplexType');
        throwIfNullOrUndefined(id, 'id', 'deleteComplexType');

        const headers: HttpHeaders = {
            ...(accessToken != null ? { 'access-token': String(accessToken) } : undefined),
            ...(client != null ? { 'client': String(client) } : undefined),
            ...(uid != null ? { 'uid': String(uid) } : undefined),
        };

        return this.request<void>({
            url: '/api/v1/complex_types/{id}'.replace('{id}', encodeURI(id)),
            method: 'DELETE',
            headers,
        }, opts?.responseOpts);
    };

    /**
     * Retrieve a ComplexType list
     */
    getComplexType({ accessToken, client, uid, sortBy, id, valueValidationSchema, complexType, createdAt, updatedAt }: GetComplexTypeRequest): Observable<Array<ComplexType>>
    getComplexType({ accessToken, client, uid, sortBy, id, valueValidationSchema, complexType, createdAt, updatedAt }: GetComplexTypeRequest, opts?: OperationOpts): Observable<RawAjaxResponse<Array<ComplexType>>>
    getComplexType({ accessToken, client, uid, sortBy, id, valueValidationSchema, complexType, createdAt, updatedAt }: GetComplexTypeRequest, opts?: OperationOpts): Observable<Array<ComplexType> | RawAjaxResponse<Array<ComplexType>>> {
        throwIfNullOrUndefined(accessToken, 'accessToken', 'getComplexType');
        throwIfNullOrUndefined(client, 'client', 'getComplexType');
        throwIfNullOrUndefined(uid, 'uid', 'getComplexType');

        const headers: HttpHeaders = {
            ...(accessToken != null ? { 'access-token': String(accessToken) } : undefined),
            ...(client != null ? { 'client': String(client) } : undefined),
            ...(uid != null ? { 'uid': String(uid) } : undefined),
        };

        const query: HttpQuery = {};

        if (sortBy != null) { query['sort_by'] = sortBy; }
        if (id != null) { query['id'] = id; }
        if (valueValidationSchema != null) { query['value_validation_schema'] = valueValidationSchema; }
        if (complexType != null) { query['complex_type'] = complexType; }
        if (createdAt != null) { query['created_at'] = (createdAt as any).toISOString(); }
        if (updatedAt != null) { query['updated_at'] = (updatedAt as any).toISOString(); }

        return this.request<Array<ComplexType>>({
            url: '/api/v1/complex_types/',
            method: 'GET',
            headers,
            query,
        }, opts?.responseOpts);
    };

    /**
     * Retrieves a ComplexType
     */
    getComplexTypeById({ accessToken, client, uid, id }: GetComplexTypeByIdRequest): Observable<ComplexType>
    getComplexTypeById({ accessToken, client, uid, id }: GetComplexTypeByIdRequest, opts?: OperationOpts): Observable<RawAjaxResponse<ComplexType>>
    getComplexTypeById({ accessToken, client, uid, id }: GetComplexTypeByIdRequest, opts?: OperationOpts): Observable<ComplexType | RawAjaxResponse<ComplexType>> {
        throwIfNullOrUndefined(accessToken, 'accessToken', 'getComplexTypeById');
        throwIfNullOrUndefined(client, 'client', 'getComplexTypeById');
        throwIfNullOrUndefined(uid, 'uid', 'getComplexTypeById');
        throwIfNullOrUndefined(id, 'id', 'getComplexTypeById');

        const headers: HttpHeaders = {
            ...(accessToken != null ? { 'access-token': String(accessToken) } : undefined),
            ...(client != null ? { 'client': String(client) } : undefined),
            ...(uid != null ? { 'uid': String(uid) } : undefined),
        };

        return this.request<ComplexType>({
            url: '/api/v1/complex_types/{id}'.replace('{id}', encodeURI(id)),
            method: 'GET',
            headers,
        }, opts?.responseOpts);
    };

    /**
     * Patch a ComplexType
     */
    patchComplexType({ accessToken, client, uid, id, complexTypePut }: PatchComplexTypeRequest): Observable<ComplexType>
    patchComplexType({ accessToken, client, uid, id, complexTypePut }: PatchComplexTypeRequest, opts?: OperationOpts): Observable<RawAjaxResponse<ComplexType>>
    patchComplexType({ accessToken, client, uid, id, complexTypePut }: PatchComplexTypeRequest, opts?: OperationOpts): Observable<ComplexType | RawAjaxResponse<ComplexType>> {
        throwIfNullOrUndefined(accessToken, 'accessToken', 'patchComplexType');
        throwIfNullOrUndefined(client, 'client', 'patchComplexType');
        throwIfNullOrUndefined(uid, 'uid', 'patchComplexType');
        throwIfNullOrUndefined(id, 'id', 'patchComplexType');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(accessToken != null ? { 'access-token': String(accessToken) } : undefined),
            ...(client != null ? { 'client': String(client) } : undefined),
            ...(uid != null ? { 'uid': String(uid) } : undefined),
        };

        return this.request<ComplexType>({
            url: '/api/v1/complex_types/{id}'.replace('{id}', encodeURI(id)),
            method: 'PATCH',
            headers,
            body: complexTypePut,
        }, opts?.responseOpts);
    };

    /**
     * Update a ComplexType
     */
    updateComplexType({ accessToken, client, uid, id, complexTypePut }: UpdateComplexTypeRequest): Observable<ComplexType>
    updateComplexType({ accessToken, client, uid, id, complexTypePut }: UpdateComplexTypeRequest, opts?: OperationOpts): Observable<RawAjaxResponse<ComplexType>>
    updateComplexType({ accessToken, client, uid, id, complexTypePut }: UpdateComplexTypeRequest, opts?: OperationOpts): Observable<ComplexType | RawAjaxResponse<ComplexType>> {
        throwIfNullOrUndefined(accessToken, 'accessToken', 'updateComplexType');
        throwIfNullOrUndefined(client, 'client', 'updateComplexType');
        throwIfNullOrUndefined(uid, 'uid', 'updateComplexType');
        throwIfNullOrUndefined(id, 'id', 'updateComplexType');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(accessToken != null ? { 'access-token': String(accessToken) } : undefined),
            ...(client != null ? { 'client': String(client) } : undefined),
            ...(uid != null ? { 'uid': String(uid) } : undefined),
        };

        return this.request<ComplexType>({
            url: '/api/v1/complex_types/{id}'.replace('{id}', encodeURI(id)),
            method: 'PUT',
            headers,
            body: complexTypePut,
        }, opts?.responseOpts);
    };

}

/**
 * @export
 * @enum {string}
 */
export enum GetComplexTypeSortByEnum {
    IdAsc = 'id_asc',
    IdDesc = 'id_desc',
    ValueValidationSchemaAsc = 'value_validation_schema_asc',
    ValueValidationSchemaDesc = 'value_validation_schema_desc',
    ComplexTypeAsc = 'complex_type_asc',
    ComplexTypeDesc = 'complex_type_desc',
    CreatedAtAsc = 'created_at_asc',
    CreatedAtDesc = 'created_at_desc',
    UpdatedAtAsc = 'updated_at_asc',
    UpdatedAtDesc = 'updated_at_desc'
}
