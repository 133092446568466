import { createStyles, makeStyles } from "@material-ui/core";
import { CustomMuiTheme } from "../../../interfaces/CustomMuiTheme";

export const useTCPlaceholderStyles = makeStyles((theme: CustomMuiTheme) =>
  createStyles({
    placeHolderWrapper: {
      textAlign: "center",
      maxWidth: "400px",
      padding: theme.spacing(10, 3),
      alignSelf: "flex-start",
      margin: "0 auto",
    },
    placeholderIcon: {
      height: "45px",
      width: "auto",
      marginBottom: theme.spacing(2),
    },
    placeholderText: {
      color: theme.palette.secondary.main,
    },
  })
);
