import { Grid } from "@material-ui/core";
import React from "react";
import { isFirefox } from "../../../../helpers/isFirefox";
import { TCMessageCell } from "../TCMessageCell/TCMessageCell";
import { TCMessageListProps } from "./TCMessageListProps";
import { useTCMessageListStyles } from "./TCMessageListStyles";
import { useTCMessageListViewModel } from "./TCMessageListViewModel";

export const TCMessageList: React.FunctionComponent<TCMessageListProps> = (
  props
) => {
  const classes = useTCMessageListStyles(isFirefox);
  const viewModel = useTCMessageListViewModel(props);

  if (!viewModel) {
    return null;
  }

  return (
    <Grid container={true} className={classes.messagesContainer}>
      {viewModel.messages.map((message, index) => {
        const isLastFromGroup = viewModel.isMessageLastFromGroup(
          message,
          index
        );

        return (
          <TCMessageCell
            key={message.id}
            message={message}
            currentUserId={viewModel.currentUserId}
            isLastFromGroup={isLastFromGroup}
          />
        );
      })}
    </Grid>
  );
};
