import { createStyles, makeStyles } from "@material-ui/core";
import { CustomMuiTheme } from "../../../interfaces/CustomMuiTheme";

export const useTCEditFieldGroupsDialogStyles = makeStyles(
  (theme: CustomMuiTheme) =>
    createStyles({
      saveOrDismissButtonGroup: {
        marginTop: theme.spacing(8),
      },
    })
);
