import { useRef } from "react";
import { Observable } from "rxjs";
import useSWR, { SWRConfiguration } from "swr";
import { userApi } from "../../dataHandling/autogeneratedApiServices";
import {
  ForgotPasswordRequest,
  GetUserByIdRequest,
  GetUserRequest,
  LoginResponse,
  PatchUserRequest,
  UpdateAvatarRequest,
  UpdatePasswordRequest,
  User,
  UserPostUser,
  ValidatePasswordTokenRequest,
} from "../../generated/api-service";
import { getUniqueSwrKeyFromParameter } from "../../helpers/getUniqueSwrKeyFromParameter";
import { WithoutAuthentication } from "../../interfaces/authentication";
import { BasicSwrInterface } from "../../interfaces/basicSwrInterface";
import { ObservableHookResult } from "../../interfaces/observableHook";
import { useParameterWithAuthentication } from "./authentication/useParameterWithAuthentication";
import { useObservable } from "./useObservable/useObservable";
import { useObservableWithAuthentication } from "./useObservable/useObservableWithAuthentication";

export const useUsersSwr = (
  requestParameters: WithoutAuthentication<GetUserRequest>
): BasicSwrInterface<User[]> => {
  const parameters = useParameterWithAuthentication(requestParameters);
  const fetcher = () => userApi.getUser(parameters).toPromise();

  const key = getUniqueSwrKeyFromParameter(["userApi", "getUser"], {
    ...parameters,
  });

  const { data, error } = useSWR(key, fetcher);

  return {
    result: data,
    isLoading: !error && !data,
    isError: error,
  };
};

export const useUsers = (): ObservableHookResult<
  WithoutAuthentication<GetUserRequest>,
  User[]
> =>
  useObservableWithAuthentication(
    userApi.getUser.bind(userApi) as (parameter) => Observable<User[]>
  );

export const useGetUserById = (
  requestParameters: WithoutAuthentication<GetUserByIdRequest>,
  swrConfig?: SWRConfiguration
): BasicSwrInterface<User> => {
  const parameters = useParameterWithAuthentication(requestParameters);
  const fetcher = () => userApi.getUserById(parameters).toPromise();

  const key = `userAPI__getUserById__${parameters.id}`;
  const { data, error } = useSWR(key, fetcher, { ...swrConfig });
  return {
    result: data,
    isLoading: !error && !data,
    isError: error,
  };
};

export const useUpdateUser = (): ObservableHookResult<
  WithoutAuthentication<PatchUserRequest>,
  User
> =>
  useObservableWithAuthentication(
    userApi.patchUser.bind(userApi) as (parameter) => Observable<User>
  );

export const useUpdateAvatar = (): ObservableHookResult<
  WithoutAuthentication<UpdateAvatarRequest>,
  User
> =>
  useObservableWithAuthentication(
    userApi.updateAvatar.bind(userApi) as (parameter) => Observable<User>
  );

export const useUpdatePassword = (): ObservableHookResult<
  UpdatePasswordRequest,
  LoginResponse
> =>
  useObservable(
    userApi.updatePassword.bind(userApi) as (
      parameter
    ) => Observable<LoginResponse>
  );

export const useValidateToken = (): ObservableHookResult<
  ValidatePasswordTokenRequest,
  void
> =>
  useObservable(
    userApi.validatePasswordToken.bind(userApi) as (
      parameter
    ) => Observable<void>
  );

export const useForgotPassword = (): ObservableHookResult<
  ForgotPasswordRequest,
  void
> =>
  useObservable(
    userApi.forgotPassword.bind(userApi) as (parameter) => Observable<void>
  );

export const useRegistrationService = (): ObservableHookResult<
  UserPostUser,
  LoginResponse
> => {
  const callback = useRef((parameter: UserPostUser) =>
    userApi.registerUser({
      userPost: {
        user: parameter,
      },
    })
  ).current;

  return useObservable(callback);
};
