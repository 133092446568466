import { FieldGroup } from "../../generated/api-service";
import { fieldIdClass } from "../dynamicFields/dynamicFieldClasses";
import { elementsFromSelectors } from "../elementsFromSelectors";

type FormInput = HTMLInputElement | HTMLTextAreaElement;

/**
 * Validate all fields of a field group using native browser validation.
 * @returns whether or not the group is valid
 */
export function validateFormGroupNatively(fieldGroup: FieldGroup): boolean {
  const selectors = (
    fieldGroup.fields?.map((field) => [
      `.${fieldIdClass(field)} input`,
      `.${fieldIdClass(field)} textarea`,
    ]) || []
  ).flat();
  const formFieldElements = elementsFromSelectors<FormInput>(selectors);
  return formFieldElements.every((element) => element.reportValidity());
}
