import { createStyles, makeStyles } from "@material-ui/core";
import { fieldTopMargin } from "../../../../helpers/styles/variables";
import { CustomMuiTheme } from "../../../../interfaces/CustomMuiTheme";

export const useTCEditFieldStyles = makeStyles((theme: CustomMuiTheme) =>
  createStyles({
    wrapper: {
      marginTop: fieldTopMargin(theme),
      "&:first-child": {
        marginTop: 0,
      },
    },
  })
);
