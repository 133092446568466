import { ReactComponent as arrowLogoutIcon } from "@iconset/arrow.logout.svg";
import { ReactComponent as commentIcon } from "@iconset/comment.svg";
import {
  AppBar,
  Badge,
  IconButton,
  SvgIcon,
  Toolbar,
  Tooltip,
} from "@material-ui/core";
import clsx from "clsx";
import React, { ReactElement, useCallback } from "react";
import { openMessagesDrawer } from "../../../dataHandling/services/chatApi";
import { useNavigate } from "../../../hooks/routing/useNavigate";
import { useMessagesBadge } from "../../../hooks/useMessagesBadge";
import { TCAppRoutes } from "../../../interfaces/TCAppRoutes";
import { TCProfileButton } from "../TCProfileButton/TCProfileButton";
import { TCTenantLogo } from "../TCTenantLogo/TCTenantLogo";
import { TCAppBarProps } from "./TCAppBarProps";
import { useTCAppBarStyles } from "./TCAppBarStyles";

export const TCAppBar: React.FunctionComponent<TCAppBarProps> = (
  props
): ReactElement | null => {
  const styles = useTCAppBarStyles({ newNotifications: false });
  const { messageBadgeCounter } = useMessagesBadge();
  const navigate = useNavigate();

  const navigateToUserProfile = useCallback(
    () => navigate(TCAppRoutes.User, props.user.id),
    [navigate, props.user.id]
  );

  if (!props.user) {
    return null;
  }

  return (
    <AppBar position="static">
      <Toolbar disableGutters={true} classes={{ root: styles.toolbar }}>
        <TCTenantLogo className={styles.logo} />

        <Tooltip arrow={true} title="Nachrichten">
          <IconButton
            onClick={openMessagesDrawer}
            aria-label="Nachrichten"
            className={styles.iconButton}
          >
            <Badge color="error" badgeContent={messageBadgeCounter}>
              <SvgIcon htmlColor="transparent" component={commentIcon} />
            </Badge>
          </IconButton>
        </Tooltip>

        <TCProfileButton
          className={styles.profileButton}
          name={`${props.user.firstName} ${props.user.lastName}`}
          user={props.user}
          onClick={navigateToUserProfile}
        />

        <Tooltip arrow={true} title="Ausloggen">
          <IconButton
            data-cy="tc-logout-button"
            onClick={props.onLogoutClick}
            aria-label="Ausloggen"
            className={clsx(styles.iconButton, styles.logoutButton)}
          >
            <SvgIcon htmlColor="transparent" component={arrowLogoutIcon} />
          </IconButton>
        </Tooltip>
      </Toolbar>
    </AppBar>
  );
};
