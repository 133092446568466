import { EComplexType, Field } from "../../generated/api-service";

const wrongFieldTypeError = `You tried to get the referenced field of a field which is not of type ${EComplexType.FieldReference}. Please check before accessing this function.`;
const noReferencedFieldError = (fieldName): string =>
  `Field ${fieldName} is a reference field, but does not have a referenced field attached.`;

/**
 * A field of type 'field_reference' points to a referenced field. Get it.
 *
 * @throws if there is no referenced field.
 */
export const getReferencedField = (field: Field): Field => {
  if (
    field.fieldType.complexType?.complexType !== EComplexType.FieldReference
  ) {
    throw Error(wrongFieldTypeError);
  }
  const { referencedField } = field;
  if (!referencedField) {
    throw Error(noReferencedFieldError(field.name));
  }
  return referencedField;
};
