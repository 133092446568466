import { Grid, Typography } from "@material-ui/core";
import React, { FunctionComponent, ReactElement, SVGProps } from "react";
import { useTCPlaceholderStyles } from "./useTCPlaceholderStyles";

interface TCPlaceholderProps {
  icon: FunctionComponent<SVGProps<SVGSVGElement>>;
  text: ReactElement | string;
  children?: null;
}

export const TCPlaceholder: React.FunctionComponent<TCPlaceholderProps> = (
  props
) => {
  const classes = useTCPlaceholderStyles();
  return (
    <Grid
      className={classes.placeHolderWrapper}
      item={true}
      container={true}
      xs={12}
      justify="center"
      alignItems={"center"}
      direction="column"
    >
      <Grid item={true}>
        <props.icon className={classes.placeholderIcon} />
      </Grid>

      <Grid item={true}>
        <Typography className={classes.placeholderText}>
          {props.text}
        </Typography>
      </Grid>
    </Grid>
  );
};
