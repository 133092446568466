import React, { ReactElement, useEffect, useState } from "react";
import { webSocket, WebSocketSubject } from "rxjs/webSocket";
import { CableContext } from "../appContext";
import { cableURL } from "../Consts";
import { keysToCamel } from "../helpers/keysToCamel";
import { userIsLoggedIn } from "../helpers/userLoggedIn";
import { useUser } from "../hooks/useUser";
import { ActiveCableSubject } from "../interfaces/activeCable";

export const TCCableProvider = ({
  children,
}: {
  children: ReactElement;
}): ReactElement | null => {
  const user = useUser();

  const [cableSubject, setCableSubject] = useState<
    WebSocketSubject<ActiveCableSubject> | undefined
  >(undefined);

  useEffect(() => {
    if (!user) {
      setCableSubject(undefined);
    } else {
      const cableTokenUrl = `${cableURL}?access_token=${user.accessToken}&client=${user.client}&uid=${user.uid}`;

      const actionCableSubject = webSocket<ActiveCableSubject>({
        url: cableTokenUrl,
        deserializer: ({ data }) => keysToCamel(JSON.parse(data)),
      });
      setCableSubject(actionCableSubject);
    }
  }, [user]);

  if (!cableSubject && userIsLoggedIn(user)) {
    return null;
  }

  if (!cableSubject && !userIsLoggedIn(user)) {
    return children;
  }

  return (
    <CableContext.Provider value={cableSubject}>
      {children}
    </CableContext.Provider>
  );
};
