import { TCRoute } from "../interfaces/routing";
import { TCAppRoutes } from "../interfaces/TCAppRoutes";

/**
 * The acutal data for the routes present in our app.
 */
export const routes: {
  [route in TCAppRoutes]: TCRoute;
} = {
  [TCAppRoutes.Home]: {
    path: "/",
    backButtonText: "zum Dashboard",
  },
  [TCAppRoutes.Dashboard]: {
    path: "/dashboard",
    backButtonText: "zum Dashboard",
  },
  [TCAppRoutes.Admin]: {
    path: "/admin",
    backButtonText: "zum Dashboard",
  },
  [TCAppRoutes.Signing]: {
    path: "/signing",
    backButtonText: "zu den Unterschriften",
  },
  [TCAppRoutes.Logout]: {
    path: "/logout",
    backButtonText: "zum Logout",
  },
  [TCAppRoutes.Contracts]: {
    path: "/contracts",
    backButtonText: "zu den Verträgen",
  },
  [TCAppRoutes.UserValidation]: {
    path: "/validation",
    backButtonText: "zur User Validierung",
  },
  [TCAppRoutes.Registration]: {
    path: "/register",
    backButtonText: "zur Registrierung",
  },
  [TCAppRoutes.Login]: {
    path: "/login",
    backButtonText: "zum Login",
  },
  [TCAppRoutes.PasswordRecovery]: {
    path: "/password_recovery",
    backButtonText: "zum Passwort zurücksetzen",
  },
  [TCAppRoutes.Projects]: {
    path: "/projects",
    backButtonText: "zu den Projekten",
  },
  [TCAppRoutes.Playground]: {
    path: "/playground",
    backButtonText: "zum Playground",
  },
  [TCAppRoutes.User]: {
    path: "/user",
    backButtonText: "zum Nutzer",
    needsAnId: true,
  },
  [TCAppRoutes.JobUser]: {
    path: "/jobUser",
    backButtonText: "zum Interessenten",
    needsAnId: true,
  },
  [TCAppRoutes.Job]: {
    path: "/job",
    backButtonText: "zum Job",
    needsAnId: true,
  },
  [TCAppRoutes.DataProtection]: {
    path: "/data-protection",
    backButtonText: "Datenschutzerklärung",
  },
  [TCAppRoutes.Imprint]: {
    path: "/imprint",
    backButtonText: "Impressum",
  },
};
