import { Box } from "@material-ui/core";
import clsx from "clsx";
import React from "react";
import { formatNumber } from "../../../../helpers/formatNumber";
import { TCIconBeforeValue } from "../TCIconBeforeValue/TCIconBeforeValue";
import { TCLabeledFieldValueProps } from "./TCLabeledFieldValueProps";
import { useTCLabeledFieldValueStyles } from "./TCLabeledFieldValueStyles";

export const TCLabeledFieldValue: React.FunctionComponent<TCLabeledFieldValueProps> = (
  props
) => {
  let valueToDisplay = props.value;
  const hideLabel = props.displayConfiguration?.label?.labelHide;
  const classes = useTCLabeledFieldValueStyles();

  const suffix =
    props.displayConfiguration.suffix &&
    ` ${props.displayConfiguration.suffix}`;

  const numberOfDecimals =
    props.displayConfiguration.valueDisplay?.numberOfDecimals;

  if (numberOfDecimals) {
    if (typeof valueToDisplay === "string") {
      valueToDisplay = formatNumber(
        parseFloat(valueToDisplay as string),
        undefined,
        numberOfDecimals
      );
    } else if (typeof valueToDisplay === "number") {
      valueToDisplay = formatNumber(
        valueToDisplay,
        undefined,
        numberOfDecimals
      );
    }
  }

  return (
    <div
      className={clsx(
        "iconAndFieldWrapper",
        classes.iconAndFieldWrapper,
        props.className,
        props.fieldDisplayWrapperProps?.className
      )}
    >
      <TCIconBeforeValue
        iconProps={props.fieldIconBeforeValueProps}
        {...props}
      />

      <div className={classes.labelAndValueWrapper}>
        {props.label && !hideLabel && (
          <Box
            {...props.fieldLabelDisplayProps}
            className={clsx(
              classes.typographyTCLabeledFieldValueStyles,
              props.fieldLabelDisplayProps?.className
            )}
          >
            {props.label}
          </Box>
        )}

        <Box
          {...props.fieldValueDisplayProps}
          className={clsx(
            classes.typographyTCLabeledFieldValueStyles,
            props.fieldValueDisplayProps?.className
          )}
        >
          {valueToDisplay}

          {suffix}
        </Box>
      </div>
    </div>
  );
};
