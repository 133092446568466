// tslint:disable
/**
 * TaMoC API V1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface ValidationFile
 */
export interface ValidationFile {
    /**
     * Allowed mime types for the uploaded file. List of types: https://wiki.selfhtml.org/wiki/MIME-Type/%C3%9Cbersicht
     * @type {Array<string>}
     * @memberof ValidationFile
     */
    allowedMimeTypes?: Array<string>;
    /**
     * Maximum file size in mb
     * @type {number}
     * @memberof ValidationFile
     */
    maxFileSize?: number;
    /**
     * @type {boolean}
     * @memberof ValidationFile
     */
    needsValidation: boolean;
    /**
     * @type {string}
     * @memberof ValidationFile
     */
    validationType?: ValidationFileValidationTypeEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum ValidationFileValidationTypeEnum {
    Manual = 'manual',
    HelloSign = 'helloSign'
}

