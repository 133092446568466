import { createStyles, fade, makeStyles } from "@material-ui/core";
import { CustomMuiTheme } from "../../../interfaces/CustomMuiTheme";

export const useTCFieldDebuggerStyles = makeStyles((theme: CustomMuiTheme) =>
  createStyles({
    wrapper: {
      display: "block",
      position: "relative",
    },
    overlay: {
      backgroundColor: fade(theme.palette.grey[50], 0.85),
      zIndex: 10,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      bottom: 0,
      top: 0,
      left: 0,
      right: 0,
      position: "absolute",
    },
    button: {},
  })
);
