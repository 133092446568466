import { ButtonBase, StepLabel } from "@material-ui/core";
import CheckCircleOutlinedIcon from "@material-ui/icons/CheckCircleOutlined";
import React from "react";
import { TCStepButtonProps } from "./TCStepButtonProps";
import { useTCStepButtonStyles } from "./TCStepButtonStyles";

export const TCStepButton: React.FunctionComponent<TCStepButtonProps> = (
  props
) => {
  const classes = useTCStepButtonStyles(props);
  return (
    <ButtonBase
      onClick={props.onClick}
      disabled={!props.onClick}
      className={classes.stepButtonRoot}
    >
      {props.noCompleteIcon || !props.completed ? (
        <StepLabel>
          <div className={classes.counterLabel}>
            {`${props.currentStep}/${props.numberOfSteps}`}
          </div>

          {props.label}
        </StepLabel>
      ) : (
        <CheckCircleOutlinedIcon className={classes.checkIcon} />
      )}
    </ButtonBase>
  );
};
