import { Location } from "history";
import { clientHost } from "../Consts";
import { TCRoute, TCRouterHistory } from "../interfaces/routing";
import { TCAppRoutes } from "../interfaces/TCAppRoutes";
import { routes } from "../routes/routes";

export const pathWithId = (route: TCAppRoutes, id: number): string =>
  `${routes[route].path}/${id}`;

export const path = (route: TCAppRoutes, id?: number): string => {
  const theRoute = routes[route];
  if (theRoute.needsAnId && !id) {
    throw Error(`Cannot go to ${theRoute.path} without an id.`);
  }
  if (!theRoute.needsAnId && id) {
    throw Error(`No id required for going to ${theRoute.path}.`);
  }
  return theRoute.needsAnId ? `${theRoute.path}/${id}` : theRoute.path;
};

export const genericPath = (route: TCRoute): string =>
  route.needsAnId ? `${route.path}/:id` : route.path;

export const reactRouterStateFromLocation = (
  location: Location<TCRouterHistory>
): TCRouterHistory => ({
    formerPath: location.pathname,
  });

export const urlForRoute = (route: TCAppRoutes, id?: number): string => clientHost + path(route, id);
