import { ReactComponent as CloudUploadIcon } from "@iconset/cloud.upload.svg";
import { Typography } from "@material-ui/core";
import { DropzoneAreaBase } from "material-ui-dropzone";
import React, { Fragment } from "react";
import { byteToMegaByte } from "../../../../../../helpers/file/fileSizes";
import { fileEndingsFromMediaTypes } from "../../../../../../helpers/file/mediaTypes";
import { formatNumber } from "../../../../../../helpers/formatNumber";
import { TCEditFieldFileDropzoneProps } from "./TCEditFieldFileDropzoneProps";
import { useTCEditFieldFileDropzoneStyles } from "./TCEditFieldFileDropzoneStyles";
import { useTCEditFieldFileDropzoneViewModel } from "./TCEditFieldFileDropzoneViewModel";

const dropzoneText = "Drag and Drop oder Datei öffnen";

const getFileLimitExceedMessage = (limit: number) =>
  `Es können nur ${limit} Dateien hochgeladen werden.`;

const getFileAddedMessage = (filename: string) =>
  `Datei '${filename}' erfolgreich hinzugefügt!`;

const getFileRemovedMessage = (filename: string) =>
  `Datei '${filename}' entfernt.`;

const getDropRejectMessage = (rejectedFile: File) =>
  `Datei '${rejectedFile.name}' konnte nicht hochgeladen werden.`;

export const TCEditFieldFileDropzone: React.FunctionComponent<TCEditFieldFileDropzoneProps> = (
  props
) => {
  const classes = useTCEditFieldFileDropzoneStyles();
  const viewModel = useTCEditFieldFileDropzoneViewModel(props);

  const { allowedMimeTypes, maxFileSize } = props;

  return (
    <Fragment>
      <DropzoneAreaBase
        // https://github.com/Yuvaleros/material-ui-dropzone/issues/267
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        Icon={CloudUploadIcon}
        fileObjects={viewModel.uploadedFiles}
        showPreviews={false}
        showAlerts={false}
        showPreviewsInDropzone={false}
        onDelete={viewModel.onDropzoneDelete}
        onAdd={viewModel.onDropzoneAdd}
        acceptedFiles={allowedMimeTypes}
        onAlert={viewModel.onAlert}
        {...{
          getFileAddedMessage,
          getFileRemovedMessage,
          getDropRejectMessage,
          getFileLimitExceedMessage,
          dropzoneText,
        }}
        maxFileSize={props.maxFileSize}
      />

      {allowedMimeTypes && (
        <Typography className={classes.allowedMimeTypes}>
          {`Erlaubte Dateiendungen:`}

          <br />

          {fileEndingsFromMediaTypes(allowedMimeTypes).join(", ")}
        </Typography>
      )}

      {maxFileSize && (
        <Typography className={classes.allowedMimeTypes}>
          {`Maximale Dateigröße:`}

          <br />

          {formatNumber(byteToMegaByte(maxFileSize), "mb")}
        </Typography>
      )}
    </Fragment>
  );
};
