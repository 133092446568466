import { createStyles, makeStyles } from "@material-ui/core";
import { subtleBorder } from "../../../../helpers/styles/variables";
import { CustomMuiTheme } from "../../../../interfaces/CustomMuiTheme";

export const useTCFieldGroupHeaderStyles = makeStyles((theme: CustomMuiTheme) =>
  createStyles({
    fieldGroupHeader: {
      display: "flex",
      flexDirection: "row",
      marginBottom: theme.spacing(3),
      paddingBottom: theme.spacing(3),
      borderBottom: subtleBorder(theme),
    },
    fieldGroupPreTitleAndSubtitleElement: {
      marginRight: theme.spacing(3),
      position: "relative",
    },
    fieldGroupTitleAndSubtitle: {},
    fieldGroupTitle: {
      color: theme.palette.grey[900],
      fontSize: theme.typography.fontSize,
      marginBottom: theme.spacing(1),
    },
    fieldGroupTitleIconBefore: {
      height: theme.typography.fontSize * 0.8,
      width: theme.typography.fontSize * 0.8,
      display: "flex",
      marginRight: theme.spacing(1),
      "& > svg": {
        height: theme.typography.fontSize * 0.8,
        width: theme.typography.fontSize * 0.8,
      },
    },
    fieldGroupSubTitle: {
      "& > div": {
        marginBottom: theme.spacing(1),
        "&:last-child": { marginBottom: theme.spacing(0) },
      },
    },
    fieldGroupSubTitleValue: {
      color: theme.palette.secondary.main,
      fontSize: theme.typography.fontSize * 0.7,
    },
    fieldGroupSubTitleIconBefore: {
      color: theme.palette.secondary.main,
      height: theme.typography.fontSize * 0.8,
      width: theme.typography.fontSize * 0.8,
      display: "flex",
      marginRight: theme.spacing(1),
      "& > svg": {
        height: theme.typography.fontSize * 0.8,
        width: theme.typography.fontSize * 0.8,
      },
    },
  })
);
