import { User } from "../generated/api-service";

export default function userNotLoggedIn(
  user: User | undefined
): user is undefined {
  return !user;
}

export const userIsLoggedIn = (user: User | undefined): user is User =>
  !userNotLoggedIn(user);
