import { makeStyles } from "@material-ui/core";
import { Styles } from "@material-ui/core/styles/withStyles";
import clsx from "clsx";
import { merge } from "lodash";
import React, { FunctionComponent, ReactElement } from "react";
import { CustomMuiTheme } from "../interfaces/CustomMuiTheme";
import { AnyObject } from "../interfaces/typeAliases";

interface Input<T extends AnyObject> {
  baseElement: (props: T) => ReactElement<T>;
  defaults?: Partial<T> | ((props: T) => Partial<T>);
  makeStylesInput?: Styles<CustomMuiTheme, T>;
}

export function extendElementWithDefaults<T extends { className?: string }>(
  input: Input<T>
): FunctionComponent<T> {
  const useStyles = makeStyles(input.makeStylesInput || {});
  return (props: T) => {
    const defaults =
      typeof input.defaults === "function"
        ? input.defaults(props)
        : input.defaults;
    const styles = useStyles(props);
    const classes = Object.values(styles);
    return (
      <input.baseElement
        {...merge({}, defaults, props, {
          className: clsx(props.className, classes),
        })}
      />
    );
  };
}
