// tslint:disable
/**
 * TaMoC API V1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum FieldNames {
    EmailAddress = 'email_address',
    FirstName = 'first_name',
    LastName = 'last_name',
    LocationWithoutCountry = 'location_without_country',
    PhoneNumber = 'phone_number',
    PrivacyOptIn = 'privacy_opt_in',
    PasswordNew = 'password_new',
    Salutation = 'salutation',
    PasswordOld = 'password_old',
    JobCancellationReason = 'job_cancellation_reason',
    JobCancellationReasonMessage = 'job_cancellation_reason_message',
    JobTitle = 'job_title',
    JobLocation = 'job_location',
    JobWorkExperienceInYears = 'job_work_experience_in_years',
    JobWorker = 'job_worker',
    JobWorkerSpecialisation = 'job_worker_specialisation',
    JobLanguages = 'job_languages',
    JobDocuments = 'job_documents',
    JobDrivingLicenseType = 'job_driving_license_type',
    JobHourlyWage = 'job_hourly_wage',
    JobWorkingClothesProvided = 'job_working_clothes_provided',
    JobDescription = 'job_description',
    JobTimingTypeToggle = 'job_timing_type_toggle',
    JobSingleShiftDate = 'job_single_shift_date',
    JobSingleShiftTimespan = 'job_single_shift_timespan',
    JobPeriodDateSpan = 'job_period_date_span',
    JobPeriodHoursPerWeek = 'job_period_hours_per_week',
    JobSingleDateSelectWidget = 'job_single_date_select_widget',
    JobWorkingClothes = 'job_working_clothes',
    JobPublicTransport = 'job_public_transport',
    EmployerName = 'employer_name',
    Website = 'website',
    NumberOfEmployees = 'number_of_employees',
    EmployerDescription = 'employer_description',
    EmployerNumber = 'employer_number',
    EmployerWorkingShiftsPattern = 'employer_working_shifts_pattern',
    Citizenship = 'citizenship',
    WorkPermit = 'work_permit',
    WorkPermitCertificateFiles = 'work_permit_certificate_files',
    Education = 'education',
    EducationCertificateFiles = 'education_certificate_files',
    WorkExperienceInYears = 'work_experience_in_years',
    WorkerJobTitle = 'worker_job_title',
    WorkerJobMainFocus = 'worker_job_main_focus',
    WorkerJobMainFocusCertificateFiles = 'worker_job_main_focus_certificate_files',
    Languages = 'languages',
    JobPosition = 'job_position',
    FormerOrCurrentEmployer = 'former_or_current_employer',
    OperationArea = 'operation_area',
    WillingnessToTravel = 'willingness_to_travel',
    ReadyToSpendNightAway = 'ready_to_spend_night_away',
    CitizenshipCertificationFiles = 'citizenship_certification_files',
    WorkerDescription = 'worker_description',
    DrivingLicenseType = 'driving_license_type',
    DrivingLicenseCertificateFiles = 'driving_license_certificate_files',
    SocialSecurityNumber = 'social_security_number',
    TaxId = 'tax_id',
    HealthInsuranceName = 'health_insurance_name',
    HealthInsuranceMemberId = 'health_insurance_member_id',
    IdNumber = 'id_number',
    BankAccountSepa = 'bank_account_sepa',
    BankAccountBic = 'bank_account_bic',
    JobSingleShiftReferenceToShift = 'job_single_shift_reference_to_shift',
    JobPeriodReferenceToShiftPattern = 'job_period_reference_to_shift_pattern'
}

