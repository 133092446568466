import { Box, useTheme } from "@material-ui/core";
import CheckCircleOutlineOutlinedIcon from "@material-ui/icons/CheckCircleOutlineOutlined";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import ReportProblemOutlinedIcon from "@material-ui/icons/ReportProblemOutlined";
import ScheduleOutlinedIcon from "@material-ui/icons/ScheduleOutlined";
import clsx from "clsx";
import { merge, omit } from "lodash";
import React, { ReactElement } from "react";
import { InfoBlockTyp, TCInfoBlockProps } from "./TCInfoBlockProps";
import { useTCInfoBlockStyles } from "./TCInfoBlockStyles";

export const TCInfoBlock: React.FunctionComponent<TCInfoBlockProps> = (
  props
) => {
  const classes = useTCInfoBlockStyles();
  const theme = useTheme();

  const iconMap: {
    [key in InfoBlockTyp]: ReactElement;
  } = {
    [InfoBlockTyp.Error]: <ReportProblemOutlinedIcon />,
    [InfoBlockTyp.Info]: <InfoOutlinedIcon />,
    [InfoBlockTyp.Success]: <CheckCircleOutlineOutlinedIcon />,
    [InfoBlockTyp.Waiting]: <ScheduleOutlinedIcon />,
  };

  const colorMap: {
    [key in InfoBlockTyp]: string;
  } = {
    [InfoBlockTyp.Error]: theme.palette.error.light,
    [InfoBlockTyp.Info]: "#f3f5ff",
    [InfoBlockTyp.Success]: theme.palette.success.light,
    [InfoBlockTyp.Waiting]: "#fff4e5",
  };

  return (
    <Box
      className={clsx(
        classes.infoBlockWrapper,
        props.wrapperBoxProps?.className
      )}
      style={merge(
        { backgroundColor: colorMap[props.infoBlockType] },
        props.wrapperBoxProps ? props.wrapperBoxProps.style : null
      )}
      {...omit(props.wrapperBoxProps, "className")}
    >
      <Box className={classes.infoBlockIcon}>
        {iconMap[props.infoBlockType]}
      </Box>

      <Box className={classes.infoBlockContent}>{props.children}</Box>
    </Box>
  );
};
