import { createStyles, makeStyles } from "@material-ui/core";
import { CustomMuiTheme } from "../../../interfaces/CustomMuiTheme";

export const useTCSaveOrDismissButtonsStyles = makeStyles(
  (theme: CustomMuiTheme) =>
    createStyles({
      saveOrDismissButtonGroup: {
        marginTop: theme.spacing(8),
        transition: "all .25s ease-in-out",
        "& button:nth-child(2)": {
          marginLeft: theme.spacing(3),
        },
        opacity: (props: { hasChanges: boolean }) => (props.hasChanges ? 1 : 0),
      },
    })
);
