import { Checkbox, TextField } from "@material-ui/core";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";
import { Field as FormikField, FieldProps } from "formik";
import { isArray, isEmpty } from "lodash";
import React, { FunctionComponent, useEffect, useState } from "react";
import { fieldIsEmpty } from "../../../../../helpers/dynamicFields/fieldShouldBeRendered/fieldIsEmpty";
import { fieldIsRequired } from "../../../../../helpers/forms/fieldIsRequired";
import { fieldValuesFromFormikContext } from "../../../../../helpers/forms/fieldValuesFromFormikContext";
import { updateFormikValue } from "../../../../../helpers/forms/updateFormikValue";
import { useTCFormikContext } from "../../../../../hooks/useTCFormikContext";
import { ValueListComponentProps } from "../../../../../interfaces/dynamicFieldInterfaces";

export const TCEditFieldMultiSelect: FunctionComponent<ValueListComponentProps> = (
  props
) => {
  const formikContext = useTCFormikContext();

  const {
    formikIdentifier,
    fieldData,
    fieldValue,
  } = fieldValuesFromFormikContext<string[]>(formikContext, props.field);

  const defaultSelectedDropDown = props.displayConfiguration.valueEditing
    ?.defaultValue as string[] | undefined;

  const [
    userOnChangeSelectedDropdown,
    setUserOnChangeSelectedDropdown,
  ] = useState(false);

  useEffect(() => {
    if (
      (!fieldValue || isEmpty(fieldValue)) &&
      defaultSelectedDropDown &&
      !userOnChangeSelectedDropdown
    ) {
      updateFormikValue(formikContext, formikIdentifier, {
        ...fieldData,
        value: defaultSelectedDropDown,
      });
    }
  }, [
    defaultSelectedDropDown,
    fieldData,
    fieldValue,
    formikContext,
    formikIdentifier,
    userOnChangeSelectedDropdown,
  ]);

  /**
   * We check whether this field is required in this component and only make it required when
   * no data is present. This information is passed to the material UI component. Therefore we
   * need to handle the display of the * in the label on our own
   */
  const [label, setLabel] = useState<string>();
  const labelFromProps = props.field.label;

  useEffect(() => {
    setLabel(labelFromProps);
  }, [labelFromProps]);

  useEffect(() => {
    const currentFieldIsRequired = fieldIsRequired(props);

    if (!currentFieldIsRequired) {
      return;
    }

    const currentFieldIsEmpty = fieldIsEmpty(fieldValue);

    if (!currentFieldIsEmpty && !label?.search("/*")) {
      setLabel(`${labelFromProps} *`);
    } else if (currentFieldIsEmpty) {
      setLabel(labelFromProps);
    }
  }, [props, labelFromProps, fieldValue, label]);

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  return (
    <FormikField name={props.fieldName}>
      {(fieldProps: FieldProps<string[]>) => (
        <Autocomplete
          multiple={true}
          options={props.options}
          disableCloseOnSelect={true}
          defaultValue={defaultSelectedDropDown}
          onChange={(e, newValue) => {
            setUserOnChangeSelectedDropdown(true);
            updateFormikValue(formikContext, formikIdentifier, {
              ...fieldData,
              value: newValue,
            });
          }}
          // When using value directly, this will break.
          value={isArray(fieldValue) ? fieldValue : []}
          getOptionLabel={(option) => option}
          renderOption={(option, { selected }) => (
            <>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />

              {option}
            </>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              inputProps={{
                ...params.inputProps,
              }}
              label={label}
              placeholder={
                !fieldProps.field.value || !fieldProps.field.value.length
                  ? props.field.label
                  : undefined
              }
              required={
                fieldIsEmpty(fieldValue) ? fieldIsRequired(props) : false
              }
            />
          )}
        />
      )}
    </FormikField>
  );
};
