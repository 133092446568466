// tslint:disable
/**
 * TaMoC API V1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs';
import {
    EParentableType,
    EValuableType, FieldValue,
    FieldValuePostFieldValue,
    FieldValuePutFieldValue,
    FilesMeta
} from '../models';
import { BaseAPI, encodeURI, HttpHeaders, HttpQuery, OperationOpts, RawAjaxResponse, throwIfNullOrUndefined } from '../runtime';

export interface CreateFieldValueRequest {
    accessToken: string;
    client: string;
    uid: string;
    fieldValue: FieldValuePostFieldValue;
    files?: Array<Blob>;
    filesMeta?: FilesMeta;
}

export interface DeleteFieldValueRequest {
    accessToken: string;
    client: string;
    uid: string;
    id: number;
}

export interface GetFieldValueRequest {
    accessToken: string;
    client: string;
    uid: string;
    sortBy?: GetFieldValueSortByEnum;
    id?: number | string;
    parentableId?: number | string;
    parentableType?: EParentableType;
    valuableType?: EValuableType;
    valuableId?: number | string;
    fieldId?: number | string;
    deleted?: string;
    createdAt?: string;
    updatedAt?: string;
}

export interface GetFieldValueByIdRequest {
    accessToken: string;
    client: string;
    uid: string;
    id: number;
}

export interface PatchFieldValueRequest {
    accessToken: string;
    client: string;
    uid: string;
    id: number;
    fieldValue: FieldValuePutFieldValue;
    files?: Array<Blob>;
    filesMeta?: FilesMeta;
}

export interface UpdateFieldValueRequest {
    accessToken: string;
    client: string;
    uid: string;
    id: number;
    fieldValue: FieldValuePutFieldValue;
    files?: Array<Blob>;
    filesMeta?: FilesMeta;
}

/**
 * no description
 */
export class FieldValueApi extends BaseAPI {

    /**
     * Create FieldValue
     */
    createFieldValue({ accessToken, client, uid, fieldValue, files, filesMeta }: CreateFieldValueRequest): Observable<FieldValue>
    createFieldValue({ accessToken, client, uid, fieldValue, files, filesMeta }: CreateFieldValueRequest, opts?: OperationOpts): Observable<RawAjaxResponse<FieldValue>>
    createFieldValue({ accessToken, client, uid, fieldValue, files, filesMeta }: CreateFieldValueRequest, opts?: OperationOpts): Observable<FieldValue | RawAjaxResponse<FieldValue>> {
        throwIfNullOrUndefined(accessToken, 'accessToken', 'createFieldValue');
        throwIfNullOrUndefined(client, 'client', 'createFieldValue');
        throwIfNullOrUndefined(uid, 'uid', 'createFieldValue');
        throwIfNullOrUndefined(fieldValue, 'fieldValue', 'createFieldValue');

        const headers: HttpHeaders = {
            ...(accessToken != null ? { 'access-token': String(accessToken) } : undefined),
            ...(client != null ? { 'client': String(client) } : undefined),
            ...(uid != null ? { 'uid': String(uid) } : undefined),
        };

        const formData = new FormData();
        if (fieldValue !== undefined) {
          let formElement: string | Blob;
          if (typeof fieldValue === "object") {
            if ((fieldValue as any) instanceof Blob) {
              formElement = (fieldValue as any) as Blob;
            } else {
              formElement = JSON.stringify(fieldValue);
            }
          } else {
            formElement = fieldValue;
          }

          formData.append('fieldValue', formElement); }
        if (files !== undefined) {
              if (Array.isArray(files)) {
                for (let file of files) {
                  formData.append('files[]', file);
                }
              } else {
                formData.append('files', files);
              }
        }

        if (filesMeta !== undefined) {
          let formElement: string | Blob;
          if (typeof filesMeta === "object") {
            if ((filesMeta as any) instanceof Blob) {
              formElement = (filesMeta as any) as Blob;
            } else {
              formElement = JSON.stringify(filesMeta);
            }
          } else {
            formElement = filesMeta;
          }

          formData.append('filesMeta', formElement); }

        return this.request<FieldValue>({
            url: '/api/v1/field_values/',
            method: 'POST',
            headers,
            body: formData,
        }, opts?.responseOpts);
    };

    /**
     * Deletes a FieldValue
     */
    deleteFieldValue({ accessToken, client, uid, id }: DeleteFieldValueRequest): Observable<void>
    deleteFieldValue({ accessToken, client, uid, id }: DeleteFieldValueRequest, opts?: OperationOpts): Observable<void | RawAjaxResponse<void>>
    deleteFieldValue({ accessToken, client, uid, id }: DeleteFieldValueRequest, opts?: OperationOpts): Observable<void | RawAjaxResponse<void>> {
        throwIfNullOrUndefined(accessToken, 'accessToken', 'deleteFieldValue');
        throwIfNullOrUndefined(client, 'client', 'deleteFieldValue');
        throwIfNullOrUndefined(uid, 'uid', 'deleteFieldValue');
        throwIfNullOrUndefined(id, 'id', 'deleteFieldValue');

        const headers: HttpHeaders = {
            ...(accessToken != null ? { 'access-token': String(accessToken) } : undefined),
            ...(client != null ? { 'client': String(client) } : undefined),
            ...(uid != null ? { 'uid': String(uid) } : undefined),
        };

        return this.request<void>({
            url: '/api/v1/field_values/{id}'.replace('{id}', encodeURI(id)),
            method: 'DELETE',
            headers,
        }, opts?.responseOpts);
    };

    /**
     * Retrieve a FieldValue list
     */
    getFieldValue({ accessToken, client, uid, sortBy, id, parentableId, parentableType, valuableType, valuableId, fieldId, deleted, createdAt, updatedAt }: GetFieldValueRequest): Observable<Array<FieldValue>>
    getFieldValue({ accessToken, client, uid, sortBy, id, parentableId, parentableType, valuableType, valuableId, fieldId, deleted, createdAt, updatedAt }: GetFieldValueRequest, opts?: OperationOpts): Observable<RawAjaxResponse<Array<FieldValue>>>
    getFieldValue({ accessToken, client, uid, sortBy, id, parentableId, parentableType, valuableType, valuableId, fieldId, deleted, createdAt, updatedAt }: GetFieldValueRequest, opts?: OperationOpts): Observable<Array<FieldValue> | RawAjaxResponse<Array<FieldValue>>> {
        throwIfNullOrUndefined(accessToken, 'accessToken', 'getFieldValue');
        throwIfNullOrUndefined(client, 'client', 'getFieldValue');
        throwIfNullOrUndefined(uid, 'uid', 'getFieldValue');

        const headers: HttpHeaders = {
            ...(accessToken != null ? { 'access-token': String(accessToken) } : undefined),
            ...(client != null ? { 'client': String(client) } : undefined),
            ...(uid != null ? { 'uid': String(uid) } : undefined),
        };

        const query: HttpQuery = {};

        if (sortBy != null) { query['sort_by'] = sortBy; }
        if (id != null) { query['id'] = id; }
        if (parentableId != null) { query['parentable_id'] = parentableId; }
        if (parentableType != null) { query['parentable_type'] = parentableType; }
        if (valuableType != null) { query['valuable_type'] = valuableType; }
        if (valuableId != null) { query['valuable_id'] = valuableId; }
        if (fieldId != null) { query['field_id'] = fieldId; }
        if (deleted != null) { query['deleted'] = (deleted as any).toISOString(); }
        if (createdAt != null) { query['created_at'] = (createdAt as any).toISOString(); }
        if (updatedAt != null) { query['updated_at'] = (updatedAt as any).toISOString(); }

        return this.request<Array<FieldValue>>({
            url: '/api/v1/field_values/',
            method: 'GET',
            headers,
            query,
        }, opts?.responseOpts);
    };

    /**
     * Retrieves a FieldValue
     */
    getFieldValueById({ accessToken, client, uid, id }: GetFieldValueByIdRequest): Observable<FieldValue>
    getFieldValueById({ accessToken, client, uid, id }: GetFieldValueByIdRequest, opts?: OperationOpts): Observable<RawAjaxResponse<FieldValue>>
    getFieldValueById({ accessToken, client, uid, id }: GetFieldValueByIdRequest, opts?: OperationOpts): Observable<FieldValue | RawAjaxResponse<FieldValue>> {
        throwIfNullOrUndefined(accessToken, 'accessToken', 'getFieldValueById');
        throwIfNullOrUndefined(client, 'client', 'getFieldValueById');
        throwIfNullOrUndefined(uid, 'uid', 'getFieldValueById');
        throwIfNullOrUndefined(id, 'id', 'getFieldValueById');

        const headers: HttpHeaders = {
            ...(accessToken != null ? { 'access-token': String(accessToken) } : undefined),
            ...(client != null ? { 'client': String(client) } : undefined),
            ...(uid != null ? { 'uid': String(uid) } : undefined),
        };

        return this.request<FieldValue>({
            url: '/api/v1/field_values/{id}'.replace('{id}', encodeURI(id)),
            method: 'GET',
            headers,
        }, opts?.responseOpts);
    };

    /**
     * Patch a FieldValue
     */
    patchFieldValue({ accessToken, client, uid, id, fieldValue, files, filesMeta }: PatchFieldValueRequest): Observable<FieldValue>
    patchFieldValue({ accessToken, client, uid, id, fieldValue, files, filesMeta }: PatchFieldValueRequest, opts?: OperationOpts): Observable<RawAjaxResponse<FieldValue>>
    patchFieldValue({ accessToken, client, uid, id, fieldValue, files, filesMeta }: PatchFieldValueRequest, opts?: OperationOpts): Observable<FieldValue | RawAjaxResponse<FieldValue>> {
        throwIfNullOrUndefined(accessToken, 'accessToken', 'patchFieldValue');
        throwIfNullOrUndefined(client, 'client', 'patchFieldValue');
        throwIfNullOrUndefined(uid, 'uid', 'patchFieldValue');
        throwIfNullOrUndefined(id, 'id', 'patchFieldValue');
        throwIfNullOrUndefined(fieldValue, 'fieldValue', 'patchFieldValue');

        const headers: HttpHeaders = {
            ...(accessToken != null ? { 'access-token': String(accessToken) } : undefined),
            ...(client != null ? { 'client': String(client) } : undefined),
            ...(uid != null ? { 'uid': String(uid) } : undefined),
        };

        const formData = new FormData();
        if (fieldValue !== undefined) {
          let formElement: string | Blob;
          if (typeof fieldValue === "object") {
            if ((fieldValue as any) instanceof Blob) {
              formElement = (fieldValue as any) as Blob;
            } else {
              formElement = JSON.stringify(fieldValue);
            }
          } else {
            formElement = fieldValue;
          }

          formData.append('fieldValue', formElement); }
        if (files !== undefined) {
              if (Array.isArray(files)) {
                for (let file of files) {
                  formData.append('files[]', file);
                }
              } else {
                formData.append('files', files);
              }
        }

        if (filesMeta !== undefined) {
          let formElement: string | Blob;
          if (typeof filesMeta === "object") {
            if ((filesMeta as any) instanceof Blob) {
              formElement = (filesMeta as any) as Blob;
            } else {
              formElement = JSON.stringify(filesMeta);
            }
          } else {
            formElement = filesMeta;
          }

          formData.append('filesMeta', formElement); }

        return this.request<FieldValue>({
            url: '/api/v1/field_values/{id}'.replace('{id}', encodeURI(id)),
            method: 'PATCH',
            headers,
            body: formData,
        }, opts?.responseOpts);
    };

    /**
     * Update a FieldValue
     */
    updateFieldValue({ accessToken, client, uid, id, fieldValue, files, filesMeta }: UpdateFieldValueRequest): Observable<FieldValue>
    updateFieldValue({ accessToken, client, uid, id, fieldValue, files, filesMeta }: UpdateFieldValueRequest, opts?: OperationOpts): Observable<RawAjaxResponse<FieldValue>>
    updateFieldValue({ accessToken, client, uid, id, fieldValue, files, filesMeta }: UpdateFieldValueRequest, opts?: OperationOpts): Observable<FieldValue | RawAjaxResponse<FieldValue>> {
        throwIfNullOrUndefined(accessToken, 'accessToken', 'updateFieldValue');
        throwIfNullOrUndefined(client, 'client', 'updateFieldValue');
        throwIfNullOrUndefined(uid, 'uid', 'updateFieldValue');
        throwIfNullOrUndefined(id, 'id', 'updateFieldValue');
        throwIfNullOrUndefined(fieldValue, 'fieldValue', 'updateFieldValue');

        const headers: HttpHeaders = {
            ...(accessToken != null ? { 'access-token': String(accessToken) } : undefined),
            ...(client != null ? { 'client': String(client) } : undefined),
            ...(uid != null ? { 'uid': String(uid) } : undefined),
        };

        const formData = new FormData();
        if (fieldValue !== undefined) {
          let formElement: string | Blob;
          if (typeof fieldValue === "object") {
            if ((fieldValue as any) instanceof Blob) {
              formElement = (fieldValue as any) as Blob;
            } else {
              formElement = JSON.stringify(fieldValue);
            }
          } else {
            formElement = fieldValue;
          }

          formData.append('fieldValue', formElement); }
        if (files !== undefined) {
              if (Array.isArray(files)) {
                for (let file of files) {
                  formData.append('files[]', file);
                }
              } else {
                formData.append('files', files);
              }
        }

        if (filesMeta !== undefined) {
          let formElement: string | Blob;
          if (typeof filesMeta === "object") {
            if ((filesMeta as any) instanceof Blob) {
              formElement = (filesMeta as any) as Blob;
            } else {
              formElement = JSON.stringify(filesMeta);
            }
          } else {
            formElement = filesMeta;
          }

          formData.append('filesMeta', formElement); }

        return this.request<FieldValue>({
            url: '/api/v1/field_values/{id}'.replace('{id}', encodeURI(id)),
            method: 'PUT',
            headers,
            body: formData,
        }, opts?.responseOpts);
    };

}

/**
 * @export
 * @enum {string}
 */
export enum GetFieldValueSortByEnum {
    IdAsc = 'id_asc',
    IdDesc = 'id_desc',
    ParentableIdAsc = 'parentable_id_asc',
    ParentableIdDesc = 'parentable_id_desc',
    ParentableTypeAsc = 'parentable_type_asc',
    ParentableTypeDesc = 'parentable_type_desc',
    ValuableTypeAsc = 'valuable_type_asc',
    ValuableTypeDesc = 'valuable_type_desc',
    ValuableIdAsc = 'valuable_id_asc',
    ValuableIdDesc = 'valuable_id_desc',
    FieldIdAsc = 'field_id_asc',
    FieldIdDesc = 'field_id_desc',
    DeletedAsc = 'deleted_asc',
    DeletedDesc = 'deleted_desc',
    CreatedAtAsc = 'created_at_asc',
    CreatedAtDesc = 'created_at_desc',
    UpdatedAtAsc = 'updated_at_asc',
    UpdatedAtDesc = 'updated_at_desc'
}
