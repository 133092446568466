import { useCallback, useMemo } from "react";
import { ECableActions, ECableChannels } from "../../../generated/api-service";
import { constructRoomName } from "../../../helpers/constructRoomName";
import { useCable } from "../../useCable";
import {
  FieldValueCableMessage,
  TCFieldValuesCableProps,
} from "./useFieldValuesCableInterfaces";

export const useFieldValuesCables = (props: TCFieldValuesCableProps): void => {
  const {
    parentable: { parentableId, parentableType },
    updateList,
  } = props;

  const onReceived = useCallback(
    (stream: FieldValueCableMessage) => {
      switch (stream.action) {
        case ECableActions.FieldValueParentableUpdate:
          updateList(stream.fieldValue);
          break;

        default:
          break;
      }
    },
    [updateList]
  );

  const roomName = useMemo(
    () => constructRoomName(parentableType, parentableId),
    [parentableId, parentableType]
  );

  useCable<FieldValueCableMessage>({
    channel: ECableChannels.FieldValueParentableChannel,
    room: roomName,
    onReceived,
  });
};
