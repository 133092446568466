import { Box, Button, IconButton } from "@material-ui/core";
import { ReactComponent as DeleteForeverIcon } from "@iconset/trashweis.svg";
import clsx from "clsx";
import { omit } from "lodash";
import React, { Fragment } from "react";
import TCDisplayBlob from "../../../../dialogs/TCDisplayDocument/TCDisplayBlob/TCDisplayBlob";
import { TCBlobListItemProps } from "./TCBlobListItemProps";
import { useTCBlobListItemStyles } from "./TCBlobListItemStyles";
import { useTCBlobListItemViewModel } from "./TCBlobListItemViewModel";

const TCBlobListItem: React.FunctionComponent<TCBlobListItemProps> = (
  props
) => {
  const { file } = props;

  const classes = useTCBlobListItemStyles();
  const viewModel = useTCBlobListItemViewModel(props);

  return (
    <Fragment>
      {viewModel.fileToDisplay && (
        <TCDisplayBlob
          mediaType={file.type}
          key="display-blob"
          isLoading={false}
          file={viewModel.fileToDisplay}
          fileName={file.name}
          onClose={viewModel.onDisplayFileClose}
          open={!!viewModel.fileToDisplay}
        />
      )}

      <Box
        {...omit(props.linkWrapperProps, "className")}
        key="blob-actions"
        className={clsx(classes.linkWrapper, props.linkWrapperProps?.className)}
      >
        <Button
          onClick={viewModel.onDisplayFile}
          className={classes.fileNameButton}
        >
          {file.name}
        </Button>

        {props.isUsingDeleteIcon && (
          <IconButton
            onClick={viewModel.blobDelete}
            className={classes.deleteIcon}
          >
            <DeleteForeverIcon />
          </IconButton>
        )}
      </Box>
    </Fragment>
  );
};

export default TCBlobListItem;
