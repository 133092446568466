import { createMuiTheme, responsiveFontSizes } from "@material-ui/core";
import { Overrides } from "@material-ui/core/styles/overrides";
import { merge } from "lodash";
import { CustomMuiTheme } from "../../interfaces/CustomMuiTheme";
import { caresomeTheme } from "../caresome/theme";
import { globalCss } from "../caresome/themeData/globalCss";
import { overrides } from "../caresome/themeData/overrides";
import { props } from "../caresome/themeData/props";
import { palette } from "./themeData/palette";
import { tcCustom } from "./themeData/tcCustom";
import { typography } from "./themeData/typography";

const baseTheme: CustomMuiTheme = caresomeTheme;

const theme: CustomMuiTheme = {
  tcCustom: merge({}, baseTheme.tcCustom, tcCustom),
  ...createMuiTheme({ props, typography, palette }),
};

const mergedOverrides: Overrides = merge({}, theme.overrides, {
  ...overrides(theme),
  MuiCssBaseline: {
    ...globalCss,
  },
});

const themeWithOverrides: CustomMuiTheme = {
  ...theme,
  overrides: mergedOverrides,
};

export const sureTheme = responsiveFontSizes(themeWithOverrides, {
  factor: 3,
  disableAlign: true,
}) as CustomMuiTheme;
