import { Box, TextField } from "@material-ui/core";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import React, { FunctionComponent, useCallback, useMemo } from "react";
import { useLocalFieldValue } from "../../../../../../../dataHandling/hooks/useLocalFieldValue";
import { momentFromDbTime } from "../../../../../../../helpers/date-time/momentFromDbTime";
import { TCTimeRangePicker } from "../../../../../../pickers/TCTimeRangePicker/TCTimeRangePicker";
import { TCEditSingleShiftProps } from "./TCEditSingleShiftProps";
import { useTCEditSingleShiftStyles } from "./useTCEditSingleShiftStyles";

/**
 * Represents a single time slot from a time slot pattern.
 */
export const TCEditSingleShift: FunctionComponent<TCEditSingleShiftProps> = ({
  shift,
  onChange,
}) => {
  const classes = useTCEditSingleShiftStyles();
  const textFieldProps = useLocalFieldValue(
    (newName) =>
      onChange({
        ...shift,
        name: newName,
      }),
    shift.name || ""
  );

  const leftValue = useMemo(() => momentFromDbTime(shift.startTime), [
    shift.startTime,
  ]);

  const rightValue = useMemo(() => momentFromDbTime(shift.endTime), [
    shift.endTime,
  ]);

  const genericHandler = useCallback(
    (newValue: MaterialUiPickersDate, timeToAdjust: "startTime" | "endTime") =>
      onChange({
        ...shift,
        [timeToAdjust]: newValue,
      }),
    [shift, onChange]
  );

  const leftHandler = useCallback(
    (newValue: MaterialUiPickersDate) => genericHandler(newValue, "startTime"),
    [genericHandler]
  );

  const rightHandler = useCallback(
    (newValue: MaterialUiPickersDate) => genericHandler(newValue, "endTime"),
    [genericHandler]
  );

  return (
    <Box className={classes.wrapper}>
      <TextField
        className={classes.name}
        required={true}
        value={textFieldProps.value}
        onChange={textFieldProps.onChange}
        onBlur={textFieldProps.onBlur}
      />

      <TCTimeRangePicker
        leftValue={leftValue}
        rightValue={rightValue}
        leftOnChange={leftHandler}
        rightOnChange={rightHandler}
      />
    </Box>
  );
};
