import { TextField } from "@material-ui/core";
import { Field } from "formik";
import { merge } from "lodash";
import React, { useCallback, useState } from "react";
import { constructFormikFieldIdentifier } from "../../../../helpers/dynamicFields/fieldName";
import { fieldValuesFromFormikContext } from "../../../../helpers/forms/fieldValuesFromFormikContext";
import { useGetFieldPropsForNumber } from "../../../../hooks/dynamicFields/getFieldPropsForNumber";
import { useTCFormikContext } from "../../../../hooks/useTCFormikContext";
import { TCFieldComponent } from "../../../../interfaces/dynamicFieldInterfaces";

export const TCEditFieldDecimal: TCFieldComponent = (props) => {
  const formikContext = useTCFormikContext();

  const {
    fieldValue: initialFieldValue,
  } = fieldValuesFromFormikContext<number>(formikContext, props.field);

  const [fieldIntValue, setFieldIntValue] = useState<number>(initialFieldValue);

  const onChange = useCallback((event) => {
    const { value } = event.target;
    if (value.length === 0) {
      setFieldIntValue(0);
      return;
    }
    const floatValue = parseFloat(event.target.value);
    const twoDecimalsValue = Math.round(floatValue * 100) / 100;

    if (!Number.isNaN(twoDecimalsValue)) {
      setFieldIntValue(twoDecimalsValue);
    }
  }, []);

  const textFieldProps = useGetFieldPropsForNumber({
    dataTypeFieldProps: props,
    fieldIntValue,
    onChange,
  });

  return (
    <Field name={constructFormikFieldIdentifier(props.field)}>
      {() => <TextField {...merge({}, textFieldProps)} />}
    </Field>
  );
};
