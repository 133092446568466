import { FormikHelpers } from "formik";
import { TCFormikValues } from "../../interfaces/formikInterfaces";
import {
  CustomFormikSubmitHandler,
  FormikSubmitHandler,
} from "../../interfaces/typeAliases";

/**
 * Formik, by default, passes all values of a form to the onSubmit handler.
 * In many cases though, we only want to send the values that have changed,
 *  so we need to pass the initialValues object to the submit handler.
 *
 * This function helps to create a submit handler by adding initialValues
 *  to the values passed by formik.
 */
export function createSubmitHandlerWithInitialValues(
  initialValues: TCFormikValues,
  customHandler: CustomFormikSubmitHandler
): FormikSubmitHandler {
  return (values: TCFormikValues, form: FormikHelpers<TCFormikValues>) =>
    customHandler(
      {
        current: values,
        initial: initialValues,
      },
      form
    );
}
