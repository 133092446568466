import { createStyles, fade, makeStyles } from "@material-ui/core";
import { CustomMuiTheme } from "../../../../interfaces/CustomMuiTheme";

interface StyleProps {
  isDetailsOpen: boolean | undefined;
  hasHeader: boolean;
}

export const useTCNavigationTitleStyles = makeStyles((theme: CustomMuiTheme) =>
  createStyles({
    title: (props: StyleProps) => ({
      width: "100%",
      padding: "0 10px",
      alignItems: "center",
      display: "flex",
      minHeight: 61,
      borderBottom:
        props.hasHeader && !props.isDetailsOpen
          ? "none"
          : `solid 0.5px ${fade(theme.palette.secondary.main, 0.5)}`,
    }),
    detailsTitle: {
      width: "100%",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
    },

    titleContainer: (props) => ({
      paddingLeft: props.isDetailsOpen ? 0 : 18,
      flex: 1,
      "& button": {
        marginRight: 10,
      },
    }),

    backButton: {
      color: theme.palette.primary.main,
    },
  })
);
