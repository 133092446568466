// tslint:disable
/**
 * TaMoC API V1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Select a value from a referenced field.
 * @export
 * @interface ValidationFieldReference
 */
export interface ValidationFieldReference {
    /**
     * A switch to toggle between different kinds of frontend rendering. Handled individually for each field type.
     * @type {string}
     * @memberof ValidationFieldReference
     */
    displayAs: ValidationFieldReferenceDisplayAsEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum ValidationFieldReferenceDisplayAsEnum {
    Dropdown = 'dropdown',
    ComplexDropdown = 'complexDropdown'
}

