import { createStyles, makeStyles, Typography } from "@material-ui/core";
import React, { Fragment, ReactElement } from "react";
import { fieldTopMargin } from "../../../helpers/styles/variables";
import { CustomMuiTheme } from "../../../interfaces/CustomMuiTheme";

export interface FieldGroupIntroProps {
  headline: string | ReactElement;
  text?: string;
}

const useStyles = makeStyles((theme: CustomMuiTheme) =>
  createStyles({
    typo: {
      marginTop: fieldTopMargin(theme),
      [theme.breakpoints.down("sm")]: {
        marginTop: 0,
      },
    },
  })
);

export const FieldGroupIntro: React.FunctionComponent<FieldGroupIntroProps> = (
  props
) => {
  const classes = useStyles();
  const header =
    typeof props.headline === "string" ? (
      <Typography variant="h3" component="h2">
        {props.headline}
      </Typography>
    ) : (
      props.headline
    );
  return (
    <Fragment>
      {header}

      {props.text && (
        <Typography className={classes.typo} align="center">
          {props.text}
        </Typography>
      )}
    </Fragment>
  );
};
