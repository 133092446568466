import clsx from "clsx";
import { omit } from "lodash";
import { CanHaveClassName } from "../../interfaces/typeAliases";

export function addClassNameToProps<T extends CanHaveClassName>(
  props: T | undefined,
  className: string
): T & { className: string } {
  return {
    ...omit(props, "className"),
    className: clsx(props?.className, className),
    /**
     * Not sure why the type assertion is needed here.
     * If this function behaves unexpectedly, remove it and investigate further.
     */
  } as T & { className: string };
}
