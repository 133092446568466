import React from "react";
import { TCListNavigationContext } from "./TCListNavigationContext";
import { TCListNavigationDetailsView } from "./TCListNavigationDetailsView/TCListNavigationDetailsView";
import { TCListNavigationList } from "./TCListNavigationList/TCListNavigationList";
import {
  TCListNavigationComposition,
  TCListNavigationProps,
} from "./TCListNavigationProps";
import { useTCNavigationListStyles } from "./TCListNavigationStyles";
import { useTCListNavigationViewModel } from "./TCListNavigationViewModel";
import { NavigationTitle } from "./TCNavigationTitle/TCNavigationTitle";

export const TCListNavigation: React.FunctionComponent<TCListNavigationProps> &
  TCListNavigationComposition = (props) => {
  const viewModel = useTCListNavigationViewModel(props);

  const classes = useTCNavigationListStyles({
    detailsOpened: viewModel.detailsOpen,
  });

  const navigation = <NavigationTitle {...props} />;

  return (
    <TCListNavigationContext.Provider value={viewModel.providerValue}>
      {navigation}

      <div className={classes.mainContainer}>
        <div className={classes.listContainer}>
          {props.listHeader}

          {props.listComponent}
        </div>

        {props.detailsViewComponent}
      </div>
    </TCListNavigationContext.Provider>
  );
};

TCListNavigation.List = TCListNavigationList;
TCListNavigation.DetailsView = TCListNavigationDetailsView;
