import slugify from "@sindresorhus/slugify";
import { Field } from "../../generated/api-service";

export const fieldIdClass = (field: Field): string => `field-id-${field.id}`;
export const fieldNameClass = (field: Field): string =>
  `field-name-${slugify(field.name)}`;
export const fieldTypeIdClass = (field: Field): string =>
  `field-type-id-${field.fieldTypeId}`;
export const fieldTypeNameClass = (field: Field): string =>
  `field-type-name-${slugify(field.fieldType.name)}`;

export const dynamicFieldClasses = (field: Field): string[] => [
    fieldIdClass(field),
    fieldNameClass(field),
    fieldTypeIdClass(field),
    fieldTypeNameClass(field),
  ];
