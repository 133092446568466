import { createStyles, makeStyles } from "@material-ui/core";
import { CustomMuiTheme } from "../../../../../interfaces/CustomMuiTheme";

export const useTCEditFieldFieldStyles = makeStyles((theme: CustomMuiTheme) =>
  createStyles({
    ButtonRightAlign: {
      float: "right",
    },
    IconFill: {
      "& path": {
        fill: theme.palette.primary.main,
      },
    },
  })
);
