import { createStyles, makeStyles } from "@material-ui/core";
import { CustomMuiTheme } from "../../../interfaces/CustomMuiTheme";

interface StyleProps {
  numberOfGroups: number;
  isLoadingContent?: boolean;
  isShowingWelcomeScreen: boolean;
}

export const useTCTabDialogStyles = makeStyles((theme: CustomMuiTheme) =>
  createStyles({
    wrapper: {
      flexDirection: "row",
      width: "90%",
      marginLeft: "5%",
      height: "100%",
      backgroundColor: theme.palette.grey[50],
    },
    stepper: {
      width: "100%",
      justifyContent: "center",
      alignItems: "stretch",
      transition: "opacity 0.2s ease-in-out",
      opacity: (props: StyleProps) => (props.isLoadingContent ? 0 : 1),
      [theme.breakpoints.down("xs")]: {
        display: (props: StyleProps) =>
          props.isShowingWelcomeScreen ? "none" : "flex",
        padding: 0,
        marginTop: "-1rem",
      },
    },
    fixBackIcon: {
      /**
       * The back icon is not centered for some reason.
       * TODO: file an issue/PR for this …
       */
      transform: "translateX(20%)",
    },
    stepNavButton: {
      display: "none",
      alignSelf: "center",
      [theme.breakpoints.down("xs")]: {
        display: "block",
      },
    },
    step: {
      display: "flex",
      padding: theme.spacing(0, "1.1rem"),
    },
    stepsWrapper: {
      width: (props: StyleProps) => `${(props.numberOfGroups || 1) * 100}%`,
      display: "flex",
      flexDirection: "row",
      alignItems: "flex-start",
      marginTop: "5rem",
      [theme.breakpoints.down("sm")]: {
        marginTop: 0,
      },
    },
    singleStepWrapper: {
      width: (props: StyleProps) => `${100 / props.numberOfGroups}%`,
      [theme.breakpoints.down("sm")]: {
        paddingTop: theme.spacing(6),
      },
    },
  })
);
