// tslint:disable
/**
 * TaMoC API V1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ECableChannels {
    ContractsStatusChannel = 'ContractsStatusChannel',
    ConversationsChannel = 'ConversationsChannel',
    JobChannel = 'JobChannel',
    UsersAdminChannel = 'UsersAdminChannel',
    JobsWorkerChannel = 'JobsWorkerChannel',
    ContractsParentableChannel = 'ContractsParentableChannel',
    FieldValueParentableChannel = 'FieldValueParentableChannel',
    MessagesChannel = 'MessagesChannel',
    JobsEmployerChannel = 'JobsEmployerChannel'
}

