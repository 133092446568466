// tslint:disable
/**
 * TaMoC API V1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs';
import {
    TcDocument
} from '../models';
import { BaseAPI, encodeURI, HttpHeaders, HttpQuery, OperationOpts, RawAjaxResponse, throwIfNullOrUndefined } from '../runtime';

export interface DeleteDocumentRequest {
    accessToken: string;
    client: string;
    uid: string;
    id: number;
}

export interface DownloadDocumentRequest {
    accessToken: string;
    client: string;
    uid: string;
    documentId: number;
}

export interface GetDocumentRequest {
    accessToken: string;
    client: string;
    uid: string;
    sortBy?: GetDocumentSortByEnum;
    id?: number | string;
    name?: string;
    userId?: number | string;
    createdAt?: string;
    updatedAt?: string;
    documentableType?: string;
    documentableId?: number | string;
}

export interface GetDocumentByIdRequest {
    accessToken: string;
    client: string;
    uid: string;
    id: number;
}

export interface PatchDocumentRequest {
    accessToken: string;
    client: string;
    uid: string;
    id: number;
    file?: Blob;
    name?: string;
    userId?: string;
}

export interface PostDocumentRequest {
    accessToken: string;
    client: string;
    uid: string;
    file: Blob;
    name: string;
    userId: string;
}

export interface PutDocumentRequest {
    accessToken: string;
    client: string;
    uid: string;
    id: number;
    file?: Blob;
    name?: string;
    userId?: string;
}

/**
 * no description
 */
export class DocumentApi extends BaseAPI {

    /**
     * Deletes a Document
     */
    deleteDocument({ accessToken, client, uid, id }: DeleteDocumentRequest): Observable<void>
    deleteDocument({ accessToken, client, uid, id }: DeleteDocumentRequest, opts?: OperationOpts): Observable<void | RawAjaxResponse<void>>
    deleteDocument({ accessToken, client, uid, id }: DeleteDocumentRequest, opts?: OperationOpts): Observable<void | RawAjaxResponse<void>> {
        throwIfNullOrUndefined(accessToken, 'accessToken', 'deleteDocument');
        throwIfNullOrUndefined(client, 'client', 'deleteDocument');
        throwIfNullOrUndefined(uid, 'uid', 'deleteDocument');
        throwIfNullOrUndefined(id, 'id', 'deleteDocument');

        const headers: HttpHeaders = {
            ...(accessToken != null ? { 'access-token': String(accessToken) } : undefined),
            ...(client != null ? { 'client': String(client) } : undefined),
            ...(uid != null ? { 'uid': String(uid) } : undefined),
        };

        return this.request<void>({
            url: '/api/v1/documents/{id}'.replace('{id}', encodeURI(id)),
            method: 'DELETE',
            headers,
        }, opts?.responseOpts);
    };

    /**
     * Document as blob
     */
    downloadDocument({ accessToken, client, uid, documentId }: DownloadDocumentRequest): Observable<Blob>
    downloadDocument({ accessToken, client, uid, documentId }: DownloadDocumentRequest, opts?: OperationOpts): Observable<RawAjaxResponse<Blob>>
    downloadDocument({ accessToken, client, uid, documentId }: DownloadDocumentRequest, opts?: OperationOpts): Observable<Blob | RawAjaxResponse<Blob>> {
        throwIfNullOrUndefined(accessToken, 'accessToken', 'downloadDocument');
        throwIfNullOrUndefined(client, 'client', 'downloadDocument');
        throwIfNullOrUndefined(uid, 'uid', 'downloadDocument');
        throwIfNullOrUndefined(documentId, 'documentId', 'downloadDocument');

        const headers: HttpHeaders = {
            ...(accessToken != null ? { 'access-token': String(accessToken) } : undefined),
            ...(client != null ? { 'client': String(client) } : undefined),
            ...(uid != null ? { 'uid': String(uid) } : undefined),
        };

        return this.request<Blob>({
            url: '/api/v1/documents/{document_id}/download'.replace('{document_id}', encodeURI(documentId)),
            method: 'GET',
            headers,
            responseType: 'blob',
        }, opts?.responseOpts);
    };

    /**
     * Retrieve a Document list
     */
    getDocument({ accessToken, client, uid, sortBy, id, name, userId, createdAt, updatedAt, documentableType, documentableId }: GetDocumentRequest): Observable<Array<TcDocument>>
    getDocument({ accessToken, client, uid, sortBy, id, name, userId, createdAt, updatedAt, documentableType, documentableId }: GetDocumentRequest, opts?: OperationOpts): Observable<RawAjaxResponse<Array<TcDocument>>>
    getDocument({ accessToken, client, uid, sortBy, id, name, userId, createdAt, updatedAt, documentableType, documentableId }: GetDocumentRequest, opts?: OperationOpts): Observable<Array<TcDocument> | RawAjaxResponse<Array<TcDocument>>> {
        throwIfNullOrUndefined(accessToken, 'accessToken', 'getDocument');
        throwIfNullOrUndefined(client, 'client', 'getDocument');
        throwIfNullOrUndefined(uid, 'uid', 'getDocument');

        const headers: HttpHeaders = {
            ...(accessToken != null ? { 'access-token': String(accessToken) } : undefined),
            ...(client != null ? { 'client': String(client) } : undefined),
            ...(uid != null ? { 'uid': String(uid) } : undefined),
        };

        const query: HttpQuery = {};

        if (sortBy != null) { query['sort_by'] = sortBy; }
        if (id != null) { query['id'] = id; }
        if (name != null) { query['name'] = name; }
        if (userId != null) { query['user_id'] = userId; }
        if (createdAt != null) { query['created_at'] = (createdAt as any).toISOString(); }
        if (updatedAt != null) { query['updated_at'] = (updatedAt as any).toISOString(); }
        if (documentableType != null) { query['documentable_type'] = documentableType; }
        if (documentableId != null) { query['documentable_id'] = documentableId; }

        return this.request<Array<TcDocument>>({
            url: '/api/v1/documents/',
            method: 'GET',
            headers,
            query,
        }, opts?.responseOpts);
    };

    /**
     * Retrieves a Document
     */
    getDocumentById({ accessToken, client, uid, id }: GetDocumentByIdRequest): Observable<TcDocument>
    getDocumentById({ accessToken, client, uid, id }: GetDocumentByIdRequest, opts?: OperationOpts): Observable<RawAjaxResponse<TcDocument>>
    getDocumentById({ accessToken, client, uid, id }: GetDocumentByIdRequest, opts?: OperationOpts): Observable<TcDocument | RawAjaxResponse<TcDocument>> {
        throwIfNullOrUndefined(accessToken, 'accessToken', 'getDocumentById');
        throwIfNullOrUndefined(client, 'client', 'getDocumentById');
        throwIfNullOrUndefined(uid, 'uid', 'getDocumentById');
        throwIfNullOrUndefined(id, 'id', 'getDocumentById');

        const headers: HttpHeaders = {
            ...(accessToken != null ? { 'access-token': String(accessToken) } : undefined),
            ...(client != null ? { 'client': String(client) } : undefined),
            ...(uid != null ? { 'uid': String(uid) } : undefined),
        };

        return this.request<TcDocument>({
            url: '/api/v1/documents/{id}'.replace('{id}', encodeURI(id)),
            method: 'GET',
            headers,
        }, opts?.responseOpts);
    };

    /**
     * Patch a Document
     */
    patchDocument({ accessToken, client, uid, id, file, name, userId }: PatchDocumentRequest): Observable<TcDocument>
    patchDocument({ accessToken, client, uid, id, file, name, userId }: PatchDocumentRequest, opts?: OperationOpts): Observable<RawAjaxResponse<TcDocument>>
    patchDocument({ accessToken, client, uid, id, file, name, userId }: PatchDocumentRequest, opts?: OperationOpts): Observable<TcDocument | RawAjaxResponse<TcDocument>> {
        throwIfNullOrUndefined(accessToken, 'accessToken', 'patchDocument');
        throwIfNullOrUndefined(client, 'client', 'patchDocument');
        throwIfNullOrUndefined(uid, 'uid', 'patchDocument');
        throwIfNullOrUndefined(id, 'id', 'patchDocument');

        const headers: HttpHeaders = {
            ...(accessToken != null ? { 'access-token': String(accessToken) } : undefined),
            ...(client != null ? { 'client': String(client) } : undefined),
            ...(uid != null ? { 'uid': String(uid) } : undefined),
        };

        const formData = new FormData();
        if (file !== undefined) {
          let formElement: string | Blob;
          if (typeof file === "object") {
            if ((file as any) instanceof Blob) {
              formElement = (file as any) as Blob;
            } else {
              formElement = JSON.stringify(file);
            }
          } else {
            formElement = file;
          }

          formData.append('file', formElement); }
        if (name !== undefined) {
          let formElement: string | Blob;
          if (typeof name === "object") {
            if ((name as any) instanceof Blob) {
              formElement = (name as any) as Blob;
            } else {
              formElement = JSON.stringify(name);
            }
          } else {
            formElement = name;
          }

          formData.append('name', formElement); }
        if (userId !== undefined) {
          let formElement: string | Blob;
          if (typeof userId === "object") {
            if ((userId as any) instanceof Blob) {
              formElement = (userId as any) as Blob;
            } else {
              formElement = JSON.stringify(userId);
            }
          } else {
            formElement = userId;
          }

          formData.append('userId', formElement); }

        return this.request<TcDocument>({
            url: '/api/v1/documents/{id}'.replace('{id}', encodeURI(id)),
            method: 'PATCH',
            headers,
            body: formData,
        }, opts?.responseOpts);
    };

    /**
     * Create Document
     */
    postDocument({ accessToken, client, uid, file, name, userId }: PostDocumentRequest): Observable<TcDocument>
    postDocument({ accessToken, client, uid, file, name, userId }: PostDocumentRequest, opts?: OperationOpts): Observable<RawAjaxResponse<TcDocument>>
    postDocument({ accessToken, client, uid, file, name, userId }: PostDocumentRequest, opts?: OperationOpts): Observable<TcDocument | RawAjaxResponse<TcDocument>> {
        throwIfNullOrUndefined(accessToken, 'accessToken', 'postDocument');
        throwIfNullOrUndefined(client, 'client', 'postDocument');
        throwIfNullOrUndefined(uid, 'uid', 'postDocument');
        throwIfNullOrUndefined(file, 'file', 'postDocument');
        throwIfNullOrUndefined(name, 'name', 'postDocument');
        throwIfNullOrUndefined(userId, 'userId', 'postDocument');

        const headers: HttpHeaders = {
            ...(accessToken != null ? { 'access-token': String(accessToken) } : undefined),
            ...(client != null ? { 'client': String(client) } : undefined),
            ...(uid != null ? { 'uid': String(uid) } : undefined),
        };

        const formData = new FormData();
        if (file !== undefined) {
          let formElement: string | Blob;
          if (typeof file === "object") {
            if ((file as any) instanceof Blob) {
              formElement = (file as any) as Blob;
            } else {
              formElement = JSON.stringify(file);
            }
          } else {
            formElement = file;
          }

          formData.append('file', formElement); }
        if (name !== undefined) {
          let formElement: string | Blob;
          if (typeof name === "object") {
            if ((name as any) instanceof Blob) {
              formElement = (name as any) as Blob;
            } else {
              formElement = JSON.stringify(name);
            }
          } else {
            formElement = name;
          }

          formData.append('name', formElement); }
        if (userId !== undefined) {
          let formElement: string | Blob;
          if (typeof userId === "object") {
            if ((userId as any) instanceof Blob) {
              formElement = (userId as any) as Blob;
            } else {
              formElement = JSON.stringify(userId);
            }
          } else {
            formElement = userId;
          }

          formData.append('userId', formElement); }

        return this.request<TcDocument>({
            url: '/api/v1/documents/',
            method: 'POST',
            headers,
            body: formData,
        }, opts?.responseOpts);
    };

    /**
     * Put a Document
     */
    putDocument({ accessToken, client, uid, id, file, name, userId }: PutDocumentRequest): Observable<TcDocument>
    putDocument({ accessToken, client, uid, id, file, name, userId }: PutDocumentRequest, opts?: OperationOpts): Observable<RawAjaxResponse<TcDocument>>
    putDocument({ accessToken, client, uid, id, file, name, userId }: PutDocumentRequest, opts?: OperationOpts): Observable<TcDocument | RawAjaxResponse<TcDocument>> {
        throwIfNullOrUndefined(accessToken, 'accessToken', 'putDocument');
        throwIfNullOrUndefined(client, 'client', 'putDocument');
        throwIfNullOrUndefined(uid, 'uid', 'putDocument');
        throwIfNullOrUndefined(id, 'id', 'putDocument');

        const headers: HttpHeaders = {
            ...(accessToken != null ? { 'access-token': String(accessToken) } : undefined),
            ...(client != null ? { 'client': String(client) } : undefined),
            ...(uid != null ? { 'uid': String(uid) } : undefined),
        };

        const formData = new FormData();
        if (file !== undefined) {
          let formElement: string | Blob;
          if (typeof file === "object") {
            if ((file as any) instanceof Blob) {
              formElement = (file as any) as Blob;
            } else {
              formElement = JSON.stringify(file);
            }
          } else {
            formElement = file;
          }

          formData.append('file', formElement); }
        if (name !== undefined) {
          let formElement: string | Blob;
          if (typeof name === "object") {
            if ((name as any) instanceof Blob) {
              formElement = (name as any) as Blob;
            } else {
              formElement = JSON.stringify(name);
            }
          } else {
            formElement = name;
          }

          formData.append('name', formElement); }
        if (userId !== undefined) {
          let formElement: string | Blob;
          if (typeof userId === "object") {
            if ((userId as any) instanceof Blob) {
              formElement = (userId as any) as Blob;
            } else {
              formElement = JSON.stringify(userId);
            }
          } else {
            formElement = userId;
          }

          formData.append('userId', formElement); }

        return this.request<TcDocument>({
            url: '/api/v1/documents/{id}'.replace('{id}', encodeURI(id)),
            method: 'PUT',
            headers,
            body: formData,
        }, opts?.responseOpts);
    };

}

/**
 * @export
 * @enum {string}
 */
export enum GetDocumentSortByEnum {
    IdAsc = 'id_asc',
    IdDesc = 'id_desc',
    NameAsc = 'name_asc',
    NameDesc = 'name_desc',
    UserIdAsc = 'user_id_asc',
    UserIdDesc = 'user_id_desc',
    CreatedAtAsc = 'created_at_asc',
    CreatedAtDesc = 'created_at_desc',
    UpdatedAtAsc = 'updated_at_asc',
    UpdatedAtDesc = 'updated_at_desc',
    DocumentableTypeAsc = 'documentable_type_asc',
    DocumentableTypeDesc = 'documentable_type_desc',
    DocumentableIdAsc = 'documentable_id_asc',
    DocumentableIdDesc = 'documentable_id_desc'
}
