import { FieldFieldGroup } from "../generated/api-service";
import { PropsWithFieldAndFieldGroup } from "../interfaces/dynamicFieldInterfaces";

export function getFieldFieldGroup(
  props: PropsWithFieldAndFieldGroup
): FieldFieldGroup | undefined {
  const fieldFieldGroups = props.fieldGroup?.fieldFieldGroups;
  if (!fieldFieldGroups) {
    return undefined;
  }
  return fieldFieldGroups.find(
    (possiblyMatchingFieldFieldGroup) =>
      possiblyMatchingFieldFieldGroup.fieldId === props.field.id
  );
}
