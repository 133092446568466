import { Button, useTheme } from "@material-ui/core";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import React, { Fragment, FunctionComponent } from "react";
import { CustomMuiTheme } from "../../../interfaces/CustomMuiTheme";
import { TCSubmitButton } from "../../button/TCSubmitButton/TCSubmitButton";
import TCFormikBulkUpdateForPurposeContext from "../../dynamicFields/purpose/TCFormikBulkUpdateForPurposeContext/TCFormikBulkUpdateForPurposeContext";
import { FormikSaveHandler } from "../../dynamicFields/purpose/TCFormikBulkUpdateForPurposeContext/TCFormikBulkUpdateForPurposeContextProps";
import { TCDialogStep } from "../TCDialogStep/TCDialogStep";
import { useTCDialogStepStyles } from "../TCDialogStep/TCDialogStepStyles";
import { TCDialogStepGrid } from "../TCDialogStepGrid/TCDialogStepGrid";
import { TCTabDialog } from "../TCTabDialog/TCTabDialog";
import { TCTabDialogTab } from "../TCTabDialog/TCTabDialogProps";
import { FieldGroupIntro } from "./FieldGroupIntro";
import { useTCOnboardingViewModel } from "./TCOnboardingDialogViewModel";

export const TCOnboarding: React.FunctionComponent = () => {
  const theme = useTheme<CustomMuiTheme>();
  const classes = useTCDialogStepStyles();
  const viewModel = useTCOnboardingViewModel();

  if (!viewModel) {
    return null;
  }

  const formComponent: FunctionComponent = (formComponentProps) => (
    <TCFormikBulkUpdateForPurposeContext
      saveHandler={FormikSaveHandler.InComponent}
      noFormValidate={true}
      bulkUpdateInformation={viewModel.bulkUpdateInformation}
    >
      {formComponentProps.children}
    </TCFormikBulkUpdateForPurposeContext>
  );

  const renderTabSteps = (goForward, goBackward): TCTabDialogTab[] => {
    if (!viewModel.purposeContext) {
      return [];
    }

    const { fieldGroups } = viewModel.purposeContext;

    return fieldGroups.map((fieldGroup, index) => {
      const isLastStep = index === fieldGroups.length - 1;
      const forwardHandler = isLastStep ? undefined : goForward;
      const backwardHandler = index > 0 ? goBackward : undefined;
      const saveButton = isLastStep
        ? () => (
            <TCSubmitButton className={classes.withTopMargin}>
              Fertig
            </TCSubmitButton>
          )
        : null;

      return {
        title: fieldGroup.label,
        element: (
          <TCDialogStep
            {...{
              fieldGroup,
              forwardHandler,
              backwardHandler,
              fieldValueMap: viewModel.purposeContext
                ? viewModel.purposeContext.fieldValueMap
                : {},
              contentAfterInput: saveButton,
            }}
          />
        ),
      };
    });
  };

  const welcomeStep = (goForward: () => void) => (
    <TCDialogStepGrid
      gridPropsLeft={{ className: classes.welcomeSection }}
      contentLeft={
        <div className={classes.pullingDiv}>
          <FieldGroupIntro
            headline="Herzlich willkommen!"
            text="Schön, dass Sie da sind. Jetzt noch ein paar Schritte und Sie können loslegen!"
          />
        </div>
      }
      contentRight={
        <Fragment>
          <img key="img" src={theme.tcCustom.welcomeSvg} alt="" />

          <Button
            key="button"
            endIcon={<ArrowForwardIcon style={{ marginLeft: "1rem" }} />}
            variant="text"
            color="primary"
            onClick={goForward}
          >
            {"los geht's"}
          </Button>
        </Fragment>
      }
    />
  );

  return (
    <TCTabDialog
      stepsNotClickable={true}
      title="Registrierung abschließen"
      open={viewModel.isVisible}
      renderWelcomeScreen={welcomeStep}
      render={renderTabSteps}
      contentWrapper={formComponent}
    />
  );
};
