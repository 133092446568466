import { EParentableType, EParentType } from "../../generated/api-service";

export function parentableTypeFromParentType(
  parentType: EParentType
): EParentableType {
  if (parentType === EParentType.Job) {
    return EParentableType.Job;
  }

  if (parentType === EParentType.JobUser) {
    return EParentableType.JobUser;
  }

  return EParentableType.User;
}
